import React, { useState, useEffect, useRef } from "react";
import { BsInfoCircle } from "react-icons/bs";

const SatellitePhone = ({
    id,
    label,
    placeholder,
    value,
    onChange,
    margin = "mt-4",
    style = null,
    isLoading = false,
    isDisabled = false,
    isInfo = "",
    isRequired = false,
    extra = ""
}) => {
    const inputRef = useRef(null);
    const [showTooltip, setShowTooltip] = useState(false);
    const [isCtrlPressed, setIsCtrlPressed] = useState(false);
    const [isHovered, setIsHovered] = useState(false); // Track hover state

    // Phone number formatting function
    const formatPhoneNumber = (input) => {
        const cleaned = input.replace(/\D/g, ''); // Remove non-digit characters
        const truncated = cleaned.slice(0, 10); // Limit to 10 digits

        // Apply formatting based on length
        if (truncated.length <= 3) {
            return truncated;
        } else if (truncated.length <= 6) {
            return `(${truncated.slice(0, 3)}) ${truncated.slice(3)}`;
        } else {
            return `(${truncated.slice(0, 3)}) ${truncated.slice(3, 6)}-${truncated.slice(6)}`;
        }
    };

    // Phone number validation function
    const isValidPhoneNumber = (phoneNumber) => {
        const digits = phoneNumber.replace(/\D/g, '');
        return digits.length === 10;
    };

    useEffect(() => {
        // Handling keydown and keyup for detecting Control key press
        const handleKeyDown = (e) => {
            if (e.key === "Control") {
                setIsCtrlPressed(true);
            }
        };

        const handleKeyUp = (e) => {
            if (e.key === "Control") {
                setIsCtrlPressed(false);
            }
        };

        window.addEventListener("keydown", handleKeyDown);
        window.addEventListener("keyup", handleKeyUp);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
            window.removeEventListener("keyup", handleKeyUp);
        };
    }, []);

    // Input change handler
    const handleInputChange = (e) => {
        const input = e.target.value;
        const formatted = formatPhoneNumber(input);
        onChange({
            ...e,
            target: {
                ...e.target,
                value: formatted
            }
        });
    };

    const handleClick = (e) => {
        // Phone number handling - initiate call with Ctrl
        if (isCtrlPressed && isValidPhoneNumber(value)) {
            const phoneDigits = value.replace(/\D/g, '');
            setIsCtrlPressed(false);
            window.location.href = `tel:+1${phoneDigits}`;
        }
    };

    return (
        <div className={`relative flex ${style ?? "items-center justify-between"} ${margin}`}>
            {label && (
                <label htmlFor={id} className={`${style ? "w-full" : label ? "w-1/3" : "w-full"} text-inputLabel flex items-center gap-1 font-normal`}>
                    {`${label}`} {isRequired ? "*" : ""}
                    {isInfo?.length > 0 && (
                        <div className="relative" onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
                            <BsInfoCircle size={16} color="#666666" />
                            {showTooltip && <div className="absolute top-full left-0 min-w-72 mt-1 bg-gray-800 text-white text-sm rounded px-2 py-1 z-10">{isInfo}</div>}
                        </div>
                    )}
                    {isLoading ? (
                        <svg className="w-4 h-4 text-cardTextGray" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path className="fill-current" d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity=".25" />
                            <path className="fill-current animate-spin origin-center" d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0=0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z" />
                        </svg>
                    ) : ""}
                </label>
            )}
            <div className={`relative ${style ? "w-full" : label ? "w-2/3" : "w-full"}`}>
                <input
                    ref={inputRef}
                    id={id}
                    type="tel"
                    value={value}
                    onChange={handleInputChange}
                    onClick={handleClick}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    placeholder={placeholder}
                    disabled={isDisabled}
                    className={`w-full text-cardTextGray bg-inputBg border-none rounded-full py-2 h-9 px-4 leading-tight focus:outline-none focus:ring-1             focus:ring-dashInActiveBtnText

                    ${isCtrlPressed && isHovered ? "hover:text-blueText underline" : ""}`} // Apply styles when Ctrl is pressed and input is hovered
                />
                {value && !isValidPhoneNumber(value) && (
                    <div className="absolute left-0 -bottom-6 text-red-500 text-sm">Please enter a valid 10-digit phone number</div>
                )}
            </div>
        </div>
    );
};

export default SatellitePhone;
