import { InfoWindow, Map, AdvancedMarker, Pin } from "@vis.gl/react-google-maps";
import React, { useCallback, useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import Loader from "../UI/loaders/Loader";

const MapWithSingleMarker = ({ geocodedMarker, initialCenter, isAddressesLoaded, zoomVal = 6, mapUniqueId = "Single" }) => {
	console.log({ geocodedMarker, initialCenter });

	return isAddressesLoaded ? <MapComp marker={geocodedMarker} initialCenter={initialCenter} zoomVal={zoomVal} mapUniqueId={mapUniqueId} /> : <Loader />;
};

const MapComp = ({ marker, initialCenter, zoomVal, mapUniqueId }) => {
	const [mapRef, setMapRef] = useState(null);
	const [infoWindowOpen, setInfoWindowOpen] = useState(false);

	useEffect(() => {
		if (mapRef && marker.lat && marker.lng) {
			const bounds = new window.google.maps.LatLngBounds();
			bounds.extend({ lat: marker.lat, lng: marker.lng });
			mapRef.fitBounds(bounds);
		}
	}, [marker, mapRef]);

	const handleMarkerClick = useCallback(() => {
		setInfoWindowOpen(true);
	}, []);

	const handleInfoWindowClose = useCallback(() => {
		setInfoWindowOpen(false);
	}, []);

	return (
		<div className="h-full w-full z-0 overflow-hidden rounded-secondary_radii">
			<Map
				className="h-full w-full z-0"
				mapId={mapUniqueId}
				gestureHandling="cooperative"
				defaultCenter={initialCenter}
				// center={initialCenter}
				defaultZoom={zoomVal}
				zoomControl={true}
				onLoad={(map) => setMapRef(map)}
			>
				{marker && (
					<>
						{infoWindowOpen && (
							<InfoWindow onCloseClick={handleInfoWindowClose} position={{ lat: marker.lat, lng: marker.lng }}>
								<div className="bg-white shadow-lg rounded-lg overflow-hidden relative">
									<div className="flex justify-between items-center bg-gray-100 p-2 border-b border-gray-300">
										<h2 className="text-lg font-semibold text-gray-800">{marker.name}</h2>
										<button onClick={handleInfoWindowClose} className="text-gray-500 hover:text-red-500 transition-colors duration-200">
											<IoMdClose size={24} />
										</button>
									</div>
									<div className="border-t border-b h-fit p-4 text-sm">
										<div className="flex gap-6">
											<div className="font-medium text-nowrap w-1/3">
												<p>Service Address:</p>
												<p>OCTO No:</p>
												<p>Service Type:</p>
												<p>Service Frequency:</p>
												<p>Route Assigned:</p>
											</div>
											<div className="w-2/3">
												<p className="text-nowrap">{marker.formattedAdd}</p>
												<p>{marker.octoNumber}</p>
												<p>{marker.serviceType?.join(", ")}</p>
												<p>{marker.serviceFrequency ?? "--"}</p>
												<p>{marker.assignedRoutes ?? "None"}</p>
											</div>
										</div>
									</div>
								</div>
								<style>{`
        .gm-style-iw.gm-style-iw-c {
          padding: 0 !important;
          overflow: hidden !important;
        }
        .gm-style-iw-ch {
          padding: 0 !important;
          overflow: hidden !important;
        }
        .gm-ui-hover-effect {
          display: none !important;
        }
        .gm-style-iw-d {
          padding: 0 !important;
          overflow: hidden !important;
        }
      `}</style>
							</InfoWindow>
						)}
						<AdvancedMarker position={{ lat: marker.lat, lng: marker.lng }} onClick={handleMarkerClick}>
							<Pin />
						</AdvancedMarker>
					</>
				)}
			</Map>
		</div>
	);
};

export default React.memo(MapWithSingleMarker);
