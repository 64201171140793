const stateOptions = [
	{ value: "AZ", label: "AZ" },
	{ value: "CA", label: "CA" },
	{ value: "FL", label: "FL" },
	{ value: "GA", label: "GA" },
	{ value: "IL", label: "IL" },
	{ value: "MI", label: "MI" },
	{ value: "MA", label: "MA" },
	{ value: "NC", label: "NC" },
	{ value: "NJ", label: "NJ" },
	{ value: "NY", label: "NY" },
	{ value: "OH", label: "OH" },
	{ value: "PA", label: "PA" },
	{ value: "TX", label: "TX" },
	{ value: "VA", label: "VA" },
	{ value: "WA", label: "WA" },
];

const vendorOptions = [
	{ value: "Supply Vendor", label: "Supply Vendor" },
	{ value: "Service Vendor", label: "Service Vendor" },
];

const VendorStatus = [
	{ value: "Active", label: "Active" },
	{ value: "Inactive", label: "Inactive" },
];

import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../../../../../config/firebase";
import { createNewVendor, GetVendorData, EditVendorDetails } from "../../../../../utils/firebaseOperations";
import { useGeocoding } from "../../../../../hooks/treatmentGeocoding";
import Input from "../../../../../components/UI/Input";
import MultiSelectRounded from "../../../../../components/UI/dropdowns/MultiSelectRounded";
import Dropdown from "../../../../../components/UI/dropdowns/Dropdown";
import Button from "../../../../../components/UI/Button";
import Textarea from "../../../../../components/UI/Textarea";
import PhoneInput from "../../../../../components/UI/PhoneInput";
import UploadFiles from "../../../../transporters/UploadFiles";
import FileUpload from "../../../../../components/UI/FileUpload";

const AddNewVendor = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const { geocodeAddress } = useGeocoding();
	const [vendorData, setVendorData] = useState(null);

	const defaultValues = {
		name: "",
		apartment: "",
		state: "",
		phoneNumber: "",
		email: "",
		vendorType: [],
		vendorStatus: "",
		street: "",
		vendorWebsite: "",
		city: "",
		zipCode: "",
		notes: "",
		altphone: "",
		uploadFile: null,
		vendorCoordinates: null,
	};

	const {
		control,
		handleSubmit,
		reset,
		formState: { errors },
		watch,
	} = useForm({ defaultValues });

	const onSubmit = async (data) => {
		console.log("Submiting data:- ", data);

		try {
			const address = {
				street: data.street,
				city: data.city,
				state: data.state,
				zip: data.zipCode,
			};

			const coordinates = await geocodeAddress(address);
			const vendorDataToSave = {
				...data,
				vendorCoordinates: coordinates || null,
			};

			let { uploadFile, ...toSave } = vendorDataToSave;

			if (data.uploadFile) {
				console.log(data.uploadFile);

				const token = Math.random().toString(36).substring(2);
				const storageRef = ref(storage, `/vendors/files/${data.uploadFile.name}`);
				const snapshot = await uploadBytes(storageRef, data.uploadFile);
				const downloadURL = await getDownloadURL(snapshot.ref);

				toSave.file = {
					name: data.uploadFile.name,
					link: downloadURL,
					mime: data.uploadFile?.type,
					path: storageRef.fullPath,
					ref: "",
					token: token,
				};
			}

			console.log("VendorData to save,", toSave);

			if (id) {
				await EditVendorDetails(toSave, id);
				toast.success("Vendor updated successfully!");
			} else {
				await createNewVendor(toSave);
				toast.success("Vendor added successfully!");
				reset(defaultValues);
			}
			// navigate("/admin/assets/manage-vendor");
		} catch (error) {
			toast.error(error.message || "Failed to save vendor");
		}
	};

	console.log("sdg", vendorData);

	useEffect(() => {
		const fetchVendor = async () => {
			if (id) {
				try {
					const data = await GetVendorData(id);
					if (data) {
						setVendorData(data);
						reset(data);
					}
				} catch (error) {
					toast.error("Failed to fetch vendor data");
				}
			}
		};

		fetchVendor();
	}, [id, reset]);

	return (
		<div className="p-6">
			<div className="flex flex-col border border-cardBorderCol bg-white rounded-cardRadii h-full">
				<ToastContainer />
				<div className="flex justify-between my-4 mx-6">
					<h6 className="font-medium text-lg">Vendor Profile</h6>
				</div>
				<div className="border-b mb-2 border-[#CCCCCC]" />
				<div className="mx-6">
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="flex gap-8">
							<div className="w-1/2">
								<Controller
									name="name"
									control={control}
									rules={{ required: "Vendor Name is required" }}
									render={({ field: { value, onChange } }) => (
										<div>
											<Input
												value={value}
												onChange={onChange}
												label="Vendor Name*"
												className="w-full px-2 py-1 text-sm"
											/>
											{errors.name && <p className="text-red-500 text-sm mt-1">{errors.name?.message}</p>}
										</div>
									)}
								/>

								<Controller
									name="street"
									control={control}
									render={({ field: { value, onChange } }) => (
										<div>
											<Input value={value} onChange={onChange} label="Street" className="w-full px-2 py-1 text-sm" />
											{errors.street && <p className="text-red-500 text-sm mt-1">{errors.street?.message}</p>}
										</div>
									)}
								/>

								<Controller
									name="city"
									control={control}
									render={({ field: { value, onChange } }) => (
										<div>
											<Input value={value} onChange={onChange} label="City" className="w-full px-2 py-1 text-sm" />
											{errors.city && <p className="text-red-500 text-sm mt-1">{errors.city?.message}</p>}
										</div>
									)}
								/>

								<Controller
									name="state"
									control={control}
									render={({ field: { value, onChange } }) => (
										<div>
											<Dropdown
												value={value}
												onChange={onChange}
												options={stateOptions}
												label="State"
												className="text-sm"
											/>
											{errors.state && <p className="text-red-500 text-sm mt-1">{errors.state?.message}</p>}
										</div>
									)}
								/>

								<Controller
									name="zipCode"
									control={control}
									render={({ field: { value, onChange } }) => (
										<div>
											<Input value={value} onChange={onChange} label="Zip" className="w-full px-2 py-1 text-sm" />
											{errors.zipCode && <p className="text-red-500 text-sm mt-1">{errors.zipCode?.message}</p>}
										</div>
									)}
								/>

								<Controller
									name="vendorWebsite"
									control={control}
									rules={{
										pattern: {
											value: /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/,
											message: "Please enter a valid URL",
										},
									}}
									render={({ field: { value, onChange } }) => (
										<div>
											<Input
												value={value}
												onChange={onChange}
												type="url"
												label="Vendor Website"
												className="w-full px-2 py-1 text-sm"
												placeholder="https://example.com"
											/>
											{errors.vendorWebsite && (
												<p className="text-red-500 text-sm mt-1">{errors.vendorWebsite?.message}</p>
											)}
										</div>
									)}
								/>
							</div>

							<div className="w-1/2">
								<Controller
									name="email"
									control={control}
									rules={{
										pattern: {
											value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
											message: "Please enter a valid email address",
										},
									}}
									render={({ field: { value, onChange } }) => (
										<div>
											<Input value={value} onChange={onChange} label="Email" className="w-full px-2 py-1 text-sm" />
											{errors.email && <p className="text-red-500 text-sm mt-1">{errors.email?.message}</p>}
										</div>
									)}
								/>

								<Controller
									name="phoneNumber"
									control={control}
									render={({ field }) => (
										<div>
											<PhoneInput id="phoneNumber" label="Phone Number" value={field.value} onChange={field.onChange} />
											{errors.phoneNumber && <p className="text-red-500 text-sm mt-1">{errors.phoneNumber?.message}</p>}
										</div>
									)}
								/>

								<Controller
									name="vendorType"
									control={control}
									render={({ field: { value, onChange } }) => (
										<div>
											<MultiSelectRounded
												value={value}
												onChange={onChange}
												options={vendorOptions}
												label="Vendor Type"
												className="text-sm"
											/>
											{errors.vendorType && <p className="text-red-500 text-sm mt-1">{errors.vendorType?.message}</p>}
										</div>
									)}
								/>

								<Controller
									name="vendorStatus"
									control={control}
									render={({ field: { value, onChange } }) => (
										<div>
											<Dropdown
												value={value}
												onChange={onChange}
												options={VendorStatus}
												label="Vendor Status"
												className="text-sm"
											/>
											{errors.vendorStatus && (
												<p className="text-red-500 text-sm mt-1">{errors.vendorStatus?.message}</p>
											)}
										</div>
									)}
								/>

								<Controller
									name="notes"
									control={control}
									render={({ field: { value, onChange } }) => (
										<div>
											<Textarea value={value} onChange={onChange} label="Notes" className="w-full px-2 py-1 text-sm" />
											{errors.notes && <p className="text-red-500 text-sm mt-1">{errors.notes?.message}</p>}
										</div>
									)}
								/>

								<FileUpload
									control={control}
									errors={errors}
									existingFile={vendorData?.file}
									name={"uploadFile"}
									onDeleteFile={() => console.log("Clicked to delete file")}
								/>
							</div>
						</div>

						<div className="flex justify-end gap-8 py-10">
							<Button
								onClick={() => navigate("/admin/assets/manage-vendor")}
								btnStyle="form_nav_secondary"
								text="View Vendor List"
							/>
							<Button type="submit" btnStyle="form_nav_primary" text={"Save"} />
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default AddNewVendor;
