import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
	getPinImage,
	showErrorToastMessage,
	showInternalServerErrorToastMessage,
	showLoadingToastMessage,
	showSuccessToastMessage,
} from "../../../../../../utils/helpers";
import { getRoutes } from "../../../../../../utils/firebaseOperations";
import { Map, Pin } from "@vis.gl/react-google-maps";
import { Polyline } from "../../../../../../components/maps/PolyLine";
import { collection, doc, getDoc, onSnapshot, updateDoc } from "firebase/firestore";
import { COLLECTIONS, db } from "../../../../../../config/firebase";
import { LOCATION_TYPE, serviceTypes } from "../../../../../../utils/constants";
import WaypointMarkers from "./WaypointMarkers";
import { getColorByIndex } from "../../../../../../utils/colors";

const RoutesOptimization = () => {
	const [routes, setRoutes] = useState([]);
	const [loading, setLoading] = useState(true);
	const [routesToShow, setRoutesToShow] = useState([]);
	const [waypointToShow, setWaypointToShow] = useState([]);
	const navigate = useNavigate();
	const [routeOptions, setRouteOptions] = useState([]);
	useEffect(() => {
		let unsubscribe = null;
		setLoading(true);
		try {
			unsubscribe = onSnapshot(collection(db, COLLECTIONS.routes), async (snap) => {
				if (!snap.docs.length) return;
				const tempRoutes = [];
				const allRouteIds = [];
				const allWaypointsIds = [];
				const tempRouteOptions = [];

				const jobs = snap.docs.map(async (el) => {
					if (!el.exists()) return;
					const routeData = el.data();

					routeData.id = el.id;
					tempRouteOptions.push({
						label: routeData.routeLabel,
						value: routeData.id,
					});
					allRouteIds.push(el.id);
					if (!routeData?.waypoints?.length) return;
					const tempWaypoints = [];
					for (const waypoint of routeData.waypoints) {
						const temp = { ...waypoint };
						if (waypoint.type === LOCATION_TYPE.GENERATOR) {
							const generatorRes = await getDoc(doc(db, COLLECTIONS.generators, waypoint.generatorId));
							if (!generatorRes.exists()) return;
							temp.generatorData = generatorRes.data();
							const scheduleIdRes = await getDoc(doc(db, COLLECTIONS.serviceSchedules, waypoint.scheduleId));
							if (!scheduleIdRes.exists()) return;
							temp.scheduleData = scheduleIdRes.data();
						}
						allWaypointsIds.push(waypoint.scheduleId);
						tempWaypoints.push(temp);
					}

					routeData.waypoints = [...tempWaypoints];
					console.log({ routeData });
					tempRoutes.push(routeData);
				});
				await Promise.all(jobs);

				setRoutes(tempRoutes);
				setRoutesToShow(allRouteIds);
				setWaypointToShow(allWaypointsIds);
				setRouteOptions(tempRouteOptions);
			});
		} catch (err) {
			console.log(err);
			showErrorToastMessage("Unable to get routes.");
		} finally {
			setLoading(false);
		}

		return () => {
			if (unsubscribe) {
				unsubscribe();
			}
		};
	}, []);
	useEffect(() => {
		console.log(routes);
	}, [routes]);

	const renderRouteList = () => {
		return (
			<div className="absolute top-5 left-5 bg-white shadow-xl rounded-xl">
				<div className="collapse collapse-arrow">
					<input type="checkbox" className="" />
					<div className="collapse-title">Route List</div>
					<div className="collapse-content">
						{routes.map((route, i) => (
							<div key={route.id} className="flex gap-1 flex-col">
								<div className="flex gap-2">
									<input
										type="checkbox"
										checked={routesToShow.findIndex((el) => el === route.id) > -1 ? true : false}
										onChange={(e) => {
											if (e.currentTarget.checked) {
												setRoutesToShow((prev) => [...prev, route.id]);
											} else {
												setRoutesToShow((prev) => prev.filter((el) => el !== route.id));
											}
										}}
									/>
									<div className="flex gap-2 items-center ">
										<p>{`${route.routeLabel}`.substring(0, 20)}</p>
										<span
											className={`w-5 h-5 border rounded-full`}
											style={{ backgroundColor: getColorByIndex(i) }}
										></span>
									</div>
								</div>
								{routesToShow.findIndex((el) => el === route.id) > -1 && (
									<div className="pl-5">
										{route.waypoints.map((waypoint) => (
											<div key={waypoint.scheduleId} className="flex gap-2">
												<input
													type="checkbox"
													checked={waypointToShow.findIndex((el) => el === waypoint.scheduleId) > -1 ? true : false}
													onChange={(e) => {
														if (e.currentTarget.checked) {
															setWaypointToShow((prev) => [...prev, waypoint.scheduleId]);
														} else {
															setWaypointToShow((prev) => prev.filter((el) => el !== waypoint.scheduleId));
														}
													}}
												/>
												<div onClick={() => {}}>{`${waypoint.name}`.substring(0, 20)}</div>
											</div>
										))}
									</div>
								)}
							</div>
						))}
					</div>
				</div>
			</div>
		);
	};
	return (
		<div className="p-6">
			<div className="main-container flex flex-col border border-cardBorderCol bg-white rounded-cardRadii h-full">
				<div className="text-lg px-8 py-4 flex justify-between items-center">
					<div>Routes Optimization</div>
				</div>
				<div className="h-[calc(80vh)] w-full p-5 rounded-xl">
					<Map
						reuseMaps
						mapId={`routeOptimization`}
						defaultZoom={12}
						defaultCenter={{ lat: 32.7364432, lng: -117.1460877 }}
						gestureHandling={"greedy"}
						disableDefaultUI
						zoomControl
					>
						{routes.length > 0 &&
							routes.map((route, i) => {
								console.log(route.id);

								return (
									routesToShow.findIndex((el) => el === route.id) > -1 &&
									route.waypoints.map(
										(waypoint) =>
											waypointToShow.findIndex((el) => el === waypoint.scheduleId) > -1 && (
												<WaypointMarkers
													key={waypoint.scheduleId}
													position={waypoint.location}
													headerContent={
														<p className="text-base font-semibold absolute top-3 left-3">
															{waypoint.generatorData.generatorName.substring(0, 100)}
														</p>
													}
													pin={
														<Pin background={`${getColorByIndex(i)}`} borderColor={`${getColorByIndex(i)}`}>
															<p className="text-base whitespace-nowrap text-black absolute -top-4 -left-8">
																{waypoint.generatorData.generatorName}
															</p>
														</Pin>
													}
												>
													<div className="grid gap-2">
														<div className="grid grid-cols-3 gap-1">
															<p className="">Generator Name:</p>
															<p className="col-span-2">{waypoint.generatorData.generatorName}</p>
															<p>Generator Address: </p>
															<p className="col-span-2">
																{waypoint.generatorData?.serviceAddress?.street ?? ""}
																{waypoint?.generatorData?.serviceAddress?.suite
																	? `, ${waypoint.generatorData.serviceAddress.suite}`
																	: ""}
																, {waypoint?.generatorData?.serviceAddress?.city ?? ""},
																{waypoint?.generatorData?.serviceAddress?.state ?? ""}
																{waypoint?.generatorData?.serviceAddress?.zipCode ?? ""}
															</p>
															<p>Service Frequency: </p>
															<p className="col-span-2">{waypoint?.scheduleData?.serviceFrequency?.type}</p>
															<p>Service Type: </p>
															<p className="col-span-2">
																{waypoint?.scheduleData?.serviceType?.length &&
																	waypoint?.scheduleData?.serviceType?.map(
																		(type) => serviceTypes.find((el) => el.value === type).label
																	)}
															</p>
															<p>Assigned Route: </p>
															<p className="col-span-2">{route.routeLabel}</p>
														</div>
														<div className="py-2 w-full">
															<form
																id={`${route.id + waypoint.scheduleId}`}
																onSubmit={(e) => {
																	e.preventDefault();
																	const form = document.getElementById(`${route.id + waypoint.scheduleId}`);
																	const formData = new FormData(form);
																	const currentRoute = formData.get("currentRouteId");
																	console.log({ currentRoute });
																	try {
																		showLoadingToastMessage("Please wait we are updating the routes.");
																		updateDoc(doc(db, COLLECTIONS.serviceSchedules, waypoint.scheduleId), {
																			routeId: currentRoute,
																		});
																		showSuccessToastMessage(
																			"Route updated successfully. It will be reflected in the map in a while."
																		);
																	} catch (error) {
																		console.log(error);
																		showInternalServerErrorToastMessage();
																	}
																}}
																className="grid grid-cols-2"
															>
																<select
																	name="currentRouteId"
																	defaultValue={route.id}
																	className="select select-secondary select-xs"
																>
																	{routeOptions.map((el) => (
																		<option value={el.value} key={el.value}>
																			{el.label}
																		</option>
																	))}
																</select>
																<button type="submit" className="btn btn-primary btn-xs">
																	Change Route
																</button>
															</form>
														</div>
													</div>
												</WaypointMarkers>
											)
									)
								);
							})}
						{renderRouteList()}
					</Map>
				</div>
			</div>
		</div>
	);
};

export default RoutesOptimization;
