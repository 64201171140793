import PropTypes from "prop-types";

import React from "react";

const TodaysReportsProgressBar = ({ pending = 20, inProgress = 50, complete = 30 }) => {
	const totalServices = pending + inProgress + complete;

	const pendingPercentage = ((pending / totalServices) * 100).toFixed(2);
	const inProgressPercentage = ((inProgress / totalServices) * 100).toFixed(2);
	const completePercentage = ((complete / totalServices) * 100).toFixed(2);

	return (
		<div className="bg-gray-200 rounded-full h-5 w-full flex text-gray-700 text-md">
			{pending > 0 && (
				<div className="relative bg-red-500 h-5 rounded-l-full" style={{ width: `${pendingPercentage}%` }}>
					<div className="absolute top-5 left-0">{pending}</div>
					<div className="absolute bottom-5 left-0">Pending Service(s)</div>
				</div>
			)}
			{inProgress > 0 && (
				<div className="relative bg-blue-500 h-5" style={{ width: `${inProgressPercentage}%` }}>
					<div className="absolute top-5 left-0">{inProgress}</div>
					<div className="absolute bottom-5 left-0">Inprogress Service(s)</div>
				</div>
			)}
			{complete > 0 && (
				<div className="relative bg-green-500 h-5 rounded-r-full" style={{ width: `${completePercentage}%` }}>
					<div className="absolute top-5 left-0">{complete}</div>
					<div className="absolute bottom-5 left-0">Complete Service(s)</div>
				</div>
			)}
		</div>
	);
};

TodaysReportsProgressBar.propTypes = {
	complete: PropTypes.number,
	inProgress: PropTypes.number,
	pending: PropTypes.number,
};
export default TodaysReportsProgressBar;
