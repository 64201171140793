import React, { useEffect, useRef, useState, forwardRef } from "react";
import { HiOutlineChevronDown, HiX } from "react-icons/hi";

const MultiSelectRounded = forwardRef(({ label, options, value, onChange, placeholder = "", styles = "", margin = "4", error, isDisabled, isRequired = false }, ref) => {
	const [isOpen, setIsOpen] = useState(false);
	const [selectedOptions, setSelectedOptions] = useState([]);
	const [highlightedIndex, setHighlightedIndex] = useState(-1);
	const dropdownRef = useRef(null);
	const buttonRef = useRef(null);
	const optionsRef = useRef([]);

	const toggleDropdown = () => setIsOpen(!isOpen);

	const handleSelect = (option) => {
		let updatedSelection;
		if (selectedOptions.some((item) => item.value === option.value)) {
			updatedSelection = selectedOptions.filter((item) => item.value !== option.value);
		} else {
			updatedSelection = [...selectedOptions, option];
		}
		setSelectedOptions(updatedSelection);
		onChange(updatedSelection.map((item) => item.value));
	};

	const removeOption = (optionToRemove, e) => {
		e.stopPropagation();
		const updatedSelection = selectedOptions.filter((option) => option.value !== optionToRemove.value);
		setSelectedOptions(updatedSelection);
		onChange(updatedSelection.map((item) => item.value));
	};

	const handleKeyDown = (event) => {
		if (event.key === "Enter" || event.key === " ") {
			event.preventDefault();
			if (!isOpen) {
				setIsOpen(true);
			} else if (highlightedIndex !== -1) {
				handleSelect(options[highlightedIndex]);
			}
		} else if (event.key === "Escape") {
			setIsOpen(false);
			buttonRef.current.focus();
		} else if (isOpen) {
			switch (event.key) {
				case "ArrowDown":
					event.preventDefault();
					setHighlightedIndex((prev) => (prev < options.length - 1 ? prev + 1 : 0));
					break;
				case "ArrowUp":
					event.preventDefault();
					setHighlightedIndex((prev) => (prev > 0 ? prev - 1 : options.length - 1));
					break;
				default:
					break;
			}
		}
	};

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setIsOpen(false);
			}
		};
		document.addEventListener("mousedown", handleClickOutside);
		return () => document.removeEventListener("mousedown", handleClickOutside);
	}, []);

	useEffect(() => {
		// Sync the selectedOptions with the value prop when value changes
		const selectedOptionObjects = options.filter((option) => value?.includes(option.value));
		setSelectedOptions(selectedOptionObjects);
	}, [value, options]);

	useEffect(() => {
		if (!isOpen) {
			setHighlightedIndex(-1);
		}
	}, [isOpen]);

	useEffect(() => {
		if (isOpen && highlightedIndex !== -1 && optionsRef.current[highlightedIndex]) {
			optionsRef.current[highlightedIndex].scrollIntoView({ block: "nearest" });
		}
	}, [isOpen, highlightedIndex]);

	return (
		<div className={`relative flex  ${styles ? styles : "items-center justify-between"} my-${margin}`} ref={dropdownRef}>
			{label && (
				<label htmlFor={label?.toLowerCase().replace(" ", "-")} className={`${styles ? "w-full" : label ? "w-1/3" : "w-full"} text-inputLabel font-normal`}>
					{`${label} ${isRequired ? "*" : ""}`}
				</label>
			)}
			<div
				className={`${label && !styles ? "w-2/3" : "w-full"} ${
					isDisabled ? "bg-white" : "cursor-pointer bg-inputBg  focus:ring-1 focus:ring-dashInActiveBtnText"
				} border-none rounded-full max-h-9 pr-4 text-left text-cardTextGray flex items-center justify-between focus:outline-none`}
				style={{ minHeight: "36px" }}
				onClick={isDisabled ? () => {} : toggleDropdown}
				onKeyDown={handleKeyDown}
				tabIndex={isDisabled ? -1 : 0}
				role="combobox"
				ref={buttonRef}
			>
				<div className="w-full flex overflow-x-scroll pr-2 rounded-full scrollbar-hide">
					{selectedOptions.length > 0 ? (
						selectedOptions.map((option) => (
							<span key={option.value} className={` bg-gray-200 text-nowrap text-gray-700 rounded-full w-fit px-2 py-1 m-1 gap-1 text-sm flex items-center`}>
								{option.label}
								<HiX
									className={` ${isDisabled ? "cursor-default focus:outline-none" : ""} h-4 w-4`}
									onClick={(e) => !isDisabled && removeOption(option, e)}
									tabIndex={isDisabled ? -1 : 0}
									onKeyDown={(e) => {
										if ((e.key === "Enter" || e.key === " ") && !isDisabled) {
											removeOption(option, e);
										}
									}}
									role="button"
								/>
							</span>
						))
					) : (
						<span className="opacity-50">{placeholder}</span>
					)}
				</div>
				<HiOutlineChevronDown className={`h-5 w-5 text-cardTextGray transition-transform duration-250 ${isOpen ? "transform rotate-180" : ""} ${isDisabled ? "hidden" : "block"}`} />
			</div>
			{isOpen && (
				<ul
					className={`absolute z-10 overflow-y-auto mt-1 min-w-fit h-fit max-h-40 ${
						label && !styles ? "w-2/3" : "w-full"
					} text-nowrap top-full right-0 bg-white border border-dropdownDownArrow rounded-2xl shadow-md`}
					role="listbox"
				>
					{options.map((option, index) => (
						<li
							key={option.value}
							ref={(el) => (optionsRef.current[index] = el)}
							onClick={() => handleSelect(option)}
							onMouseEnter={() => setHighlightedIndex(index)}
							className={`px-4 h-9 py-2 hover:bg-[#BBBBBB3B] cursor-pointer transition-colors duration-150 flex items-center ${highlightedIndex === index ? "bg-[#BBBBBB3B]" : ""}`}
							role="option"
						>
							<input type="checkbox" checked={selectedOptions.some((item) => item.value === option.value)} onChange={() => {}} className="mr-2" />
							{option.label}
						</li>
					))}
				</ul>
			)}
			{error && <p className="absolute left-0 bottom-[-20px] text-red-500 text-xs">{error}</p>}
		</div>
	);
});

MultiSelectRounded.displayName = "MultiSelectRounded";

export default MultiSelectRounded;
