import React from "react";
import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { UsersNotifications } from "./Notifications";

const tabVariants = {
	active: {
		transition: { duration: 0.3 },
	},
	inactive: {
		transition: { duration: 0.3 },
	},
};

const Header = ({ user, logOut, activeTab, setActiveTab, tabs }) => {
	const { data: octoUserData, loading: userLoading } = useSelector((state) => state.octoUserData);
	const navigate = useNavigate();

	const tabClickHandler = (tab, index) => {
		setActiveTab(index);
		navigate(tab.link);
	};

	console.log({ octoUserData });

	return (
		<header className="fixed w-full top-0 z-50 bg-white border-b border-[#DCDCDC]">
			<div className="container relative mx-auto px-4 flex items-center justify-between">
				<div className="flex items-center">
					<Link to="/market" className="font-bold text-2xl text-[#2E2C34]">
						Octopus Saas
					</Link>
				</div>
				<nav className="flex items-center gap-8">
					<ul className="flex gap-8 min-h-full cursor-pointer">
						{tabs.map((tab, index) => (
							<motion.li
								key={index}
								className={`${
									activeTab === index
										? "text-[#007AFF] border-b-2 border-[#007AFF]"
										: "text-[#202020] hover:text-[#007AFF]"
								} py-5 font-medium transition-all duration-150`}
								onClick={() => tabClickHandler(tab, index)}
								variants={tabVariants}
								animate={activeTab === index ? "active" : "inactive"}
							>
								<p className="px-2 h-full w-full">{tab.label}</p>
							</motion.li>
						))}
					</ul>
					<UsersNotifications />
					<motion.button
						className="bg-[#007AFF] h-fit text-white py-2 px-8 rounded-full font-medium hover:bg-primaryBlue"
						whileHover={{ scale: 1.05 }}
						onClick={logOut}
					>
						{user ? "Log Out" : "Log In"}
					</motion.button>
				</nav>
			</div>
			{octoUserData?.profileCompleted && !octoUserData?.approved && (
				<div className="w-full mx-auto h-10 absolute flex justify-center top-full">
					<div className="h-8 rounded-b-lg flex items-center gap-2 justify-center px-8 bg-red-400">
						<BsFillInfoCircleFill size={20} color="white" />
						<p className="text-white font-medium text-lg text-center">
							{octoUserData.rejected == true
								? "Your account approval request has been rejected, please contact administrator!"
								: "Your account is still under review, after getting approved you'd be able to send connect request"}
						</p>
					</div>
				</div>
			)}
		</header>
	);
};

export default Header;
