import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
	doc,
	updateDoc,
	onSnapshot,
	arrayRemove,
	arrayUnion,
	serverTimestamp,
	Timestamp,
	getDoc,
} from "firebase/firestore";
import { db } from "../../config/firebase";
import { formattedDate } from "../../utils/helpers";
import { formatRelativeTimeDetailed, simplifyConnections } from "../../utils/constants";

// External listener to maintain across Redux store updates
let unsubscribeListener = null;

// Initial State
const initialState = {
	notifications: [],
	connections: [],
	receivedConnections: [],
	sentConnections: [],
	unreadCount: 0,
	loading: false,
	error: null,
};

// Utility function to convert Timestamp to serializable format
const convertTimestampToDate = (notification) => {
	// console.log({ notification });

	let timestamp = notification.timestamp;
	// If it's already a string or invalid, return as is
	if (!timestamp || typeof timestamp === "string") {
		timestamp = timestamp;
	}

	// If it's a Firestore Timestamp
	if (timestamp?.toDate instanceof Function) {
		timestamp = timestamp.toDate();
	}

	// If it's a regular Date object
	if (timestamp instanceof Date) {
		timestamp = timestamp;
	}

	// If it's a Unix timestamp (number)
	if (typeof timestamp === "number") {
		timestamp = new Date(timestamp);
	}

	// Handle seconds-based timestamps from Firestore
	if (timestamp?.seconds) {
		timestamp = new Date(timestamp.seconds * 1000);
	}

	// console.log(timestamp, typeof timestamp);

	// Return original value if no conversion possible
	notification.timestamp = formatRelativeTimeDetailed(timestamp);
	// console.log(notification);
	return notification;
};

// Async Thunk for setting up real-time notification listener
export const setupUserNotificationListener = createAsyncThunk(
	"notifications/setupListener",
	(userId, { dispatch, rejectWithValue }) => {
		try {
			if (!userId) {
				throw new Error("No user ID provided");
			}

			// Clear existing listener if it exists
			if (unsubscribeListener) {
				unsubscribeListener();
			}

			// Reference to user document
			const userDocRef = doc(db, "octoMarketUsers", userId);

			// Set up real-time listener on user document
			unsubscribeListener = onSnapshot(
				userDocRef,
				(doc) => {
					const userData = doc.data();
					// console.log(userData);
					const notifications = userData?.notifications || [];

					const connections = simplifyConnections(userData?.connections) ?? {};

					console.log("raw connections", userData?.connections);

					console.log("raw notifications", notifications);

					let accepted = Object.entries(connections)
						.filter(([_, data]) => data.status === "accepted")
						.map(([userId, data]) => ({
							userId,
							...data,
						}))
						.sort((a, b) => new Date(a.acceptedAt) < new Date(b.acceptedAt));

					console.log({ accepted });

					let received = Object.entries(connections)
						.filter(([_, data]) => data.status === "pending" && data.type === "received")
						.map(([userId, data]) => ({
							userId,
							...data,
						}))
						.sort((a, b) => new Date(a.receivedAt) < new Date(b.receivedAt));

					let sent = Object.entries(connections)
						.filter(([_, data]) => data.status === "pending" && data.type === "sent")
						.map(([userId, data]) => ({
							userId,
							...data,
						}))
						.sort((a, b) => new Date(a.requestedAt) < new Date(b.requestedAt));

					// Convert timestamps and sort notifications
					const processedNotifications = notifications
						.sort((a, b) => {
							const dateA = a.timestamp?.toDate();
							const dateB = b.timestamp?.toDate();
							return dateB - dateA;
						})
						?.map((item) => convertTimestampToDate(item));

					// console.log({ processedNotifications });

					// Dispatch actions to update notifications and unread count
					dispatch(notificationSlice.actions.setNotifications(processedNotifications));
					dispatch(notificationSlice.actions.setAccepted(accepted));
					dispatch(notificationSlice.actions.setSent(sent));
					dispatch(notificationSlice.actions.setReceived(received));
					dispatch(notificationSlice.actions.setUnreadCount(processedNotifications.filter((n) => !n.read).length));
				},
				(error) => {
					dispatch(notificationSlice.actions.setError(error.message));
				}
			);

			return null;
		} catch (error) {
			console.error("Error starting listener:", error);
			throw error;
		}
	}
);

// Async Thunk for marking a notification as read
export const markNotificationAsRead = createAsyncThunk(
	"notifications/markAsRead",
	async ({ userId, notificationId }, { getState, rejectWithValue }) => {
		try {
			const state = getState();
			const userRef = doc(db, "octoMarketUsers", userId);
			let userData = await getDoc(userRef);

			console.log({ state });

			// Find the specific notification
			const notificationToUpdate = userData.data().notifications.find((n) => n.id === notificationId);
			let filteredNotification = userData.data().notifications.filter((n) => n.id !== notificationId);

			console.log("NotificationToUpdate", notificationToUpdate);

			if (!notificationToUpdate) {
				throw new Error("Notification not found");
			}

			const updatedNotification = {
				...notificationToUpdate,
				read: true,
			};

			console.log({ updatedNotification });

			await updateDoc(userRef, {
				notifications: [...filteredNotification, updatedNotification],
			});

			return notificationId;
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

// Async Thunk for adding a new notification
export const addNotification = createAsyncThunk(
	"notifications/add",
	async ({ userId, notification }, { rejectWithValue }) => {
		try {
			const userRef = doc(db, "octoMarketUsers", userId);

			// Add notification with server timestamp
			const newNotification = {
				...notification,
				id: `notification_${Date.now()}`,
				timestamp: serverTimestamp(),
				read: false,
			};

			await updateDoc(userRef, {
				notifications: arrayUnion(newNotification),
			});

			return newNotification;
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

// Create Notification Slice
const notificationSlice = createSlice({
	name: "notifications",
	initialState,
	reducers: {
		// Set notifications directly
		setNotifications: (state, action) => {
			console.log("For adding all the notifications", action.payload);
			state.notifications = action.payload;
		},
		// Set notifications directly
		setAccepted: (state, action) => {
			console.log("For adding all the notifications", action.payload);
			state.connections = action.payload;
		},
		// Set notifications directly
		setSent: (state, action) => {
			console.log("For adding all the notifications", action.payload);
			state.sentConnections = action.payload;
		},
		// Set notifications directly
		setReceived: (state, action) => {
			console.log("For adding all the notifications", action.payload);
			state.receivedConnections = action.payload;
		},
		// Set unread count
		setUnreadCount: (state, action) => {
			state.unreadCount = action.payload;
		},
		// Set error state
		setError: (state, action) => {
			state.error = action.payload;
			state.loading = false;
		},
		// Clear notification listener
		clearNotificationListener: () => {
			if (unsubscribeListener) {
				unsubscribeListener();
				unsubscribeListener = null;
			}
		},
	},
	extraReducers: (builder) => {
		// Handle setup listener async thunk
		builder
			.addCase(setupUserNotificationListener.pending, (state) => {
				state.loading = true;
			})
			.addCase(setupUserNotificationListener.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(setupUserNotificationListener.rejected, (state, action) => {
				state.error = action.payload;
				state.loading = false;
			});

		// Handle mark as read async thunk
		builder
			.addCase(markNotificationAsRead.pending, (state) => {
				state.loading = true;
			})
			.addCase(markNotificationAsRead.fulfilled, (state, action) => {
				// Optimistic update
				state.notifications = state.notifications.map((notification) =>
					notification.id === action.payload ? { ...notification, read: true } : notification
				);
				state.unreadCount = state.notifications.filter((n) => !n.read).length;
				state.loading = false;
			})
			.addCase(markNotificationAsRead.rejected, (state, action) => {
				state.error = action.payload;
				state.loading = false;
			});
	},
});

// Export actions and reducer
export const { setNotifications, setUnreadCount, setError, clearNotificationListener } = notificationSlice.actions;

export default notificationSlice.reducer;
