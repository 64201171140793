import { ChevronLeft, ChevronRight } from "lucide-react";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { PiCalendarDotsLight } from "react-icons/pi";
import "react-datepicker/dist/react-datepicker.css";

const DateRangePicker = ({
	startDate,
	endDate,
	setStartDate,
	setEndDate,
	label,
	labelClass = "",
	borderClass = "",
	isDisabled = false,
}) => {
	const [dates, setDates] = useState([startDate, endDate]);
	const [start, end] = dates;

	const CustomInputForCalendar = React.forwardRef(({ value, onClick }, ref) => (
		<div
			className={`bg-inputBg rounded-full text-cardTextGray py-2 px-4 h-9 justify-between gap-4 flex items-center ${borderClass}`}
			ref={ref}
		>
			<span className="truncate text-cardTextGray">
				{value?.split("-")[0]?.slice(6, 11)} {value ? " - " : ""}
				{value?.split("-")[1]?.slice(6, 12)}
			</span>
			<PiCalendarDotsLight
				className={`text-cardTextGray ${isDisabled ? "" : "hover:text-blueText hover:cursor-pointer"}`}
				size={20}
				onClick={isDisabled ? () => {} : onClick}
			/>
		</div>
	));

	const CustomHeader = ({
		date,
		changeYear,
		changeMonth,
		decreaseMonth,
		increaseMonth,
		prevMonthButtonDisabled,
		nextMonthButtonDisabled,
	}) => {
		const years = Array.from({ length: 20 }, (_, i) => new Date().getFullYear() - 10 + i);
		const months = [
			"January",
			"February",
			"March",
			"April",
			"May",
			"June",
			"July",
			"August",
			"September",
			"October",
			"November",
			"December",
		];
		const currentDate = date instanceof Date ? date : new Date();

		return (
			<div className="custom-header flex items-center justify-between p-2">
				<button
					type="button"
					onClick={decreaseMonth}
					disabled={prevMonthButtonDisabled}
					className="nav-button p-1 hover:bg-gray-300"
				>
					<ChevronLeft size={18} />
				</button>
				<div className="flex space-x-2">
					<select
						value={currentDate.getFullYear()}
						onChange={({ target: { value } }) => changeYear(Number(value))}
						className="bg-white text-gray-700 px-2 py-1 cursor-pointer"
					>
						{years.map((year) => (
							<option key={year} value={year}>
								{year}
							</option>
						))}
					</select>
					<select
						value={months[currentDate.getMonth()]}
						onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
						className="bg-white text-gray-700 px-2 p-1 cursor-pointer"
					>
						{months.map((month) => (
							<option key={month} value={month}>
								{month}
							</option>
						))}
					</select>
				</div>
				<button
					type="button"
					onClick={increaseMonth}
					disabled={nextMonthButtonDisabled}
					className="nav-button p-1 hover:bg-gray-300"
				>
					<ChevronRight size={18} />
				</button>
			</div>
		);
	};

	return (
		<div className="flex flex-col w-full">
			{label && <label className={`text-sm font-medium ${labelClass}`}>{label}</label>}
			<DatePicker
				selected={start}
				onChange={(update) => {
					setDates(update);
					setStartDate(update[0]);
					setEndDate(update[1]);
				}}
				startDate={start}
				endDate={end}
				selectsRange
				customInput={<CustomInputForCalendar />}
				renderCustomHeader={CustomHeader}
				maxDate={new Date()}
				dateFormat="EEE, dd MMM yyyy"
				showPopperArrow={false}
				calendarClassName="shadow-lg border rounded-lg overflow-hidden"
				dayClassName={(date) => {
					if (date > new Date()) {
						return "text-gray-300";
					}
					return start && date >= start && date <= (end || start) ? "bg-blue-50 text-blue-600" : undefined;
				}}
				monthClassName={() => "w-full"}
			/>
		</div>
	);
};

export default DateRangePicker;
