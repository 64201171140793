import PropTypes from "prop-types";
import { useCallback, useEffect, useRef, useState } from "react";
import { AdvancedMarker, Map, Pin, useMapsLibrary } from "@vis.gl/react-google-maps";
import { getPinImage, showErrorToastMessage, showSuccessToastMessage } from "../../../../../../utils/helpers";
import { MarkerWithInfoWindow } from "../../../../../../components/maps/MarkerWithInfoWindow.jsx";
import Directions from "../../../../../../components/maps/Directions.jsx";
import { Polyline } from "../../../../../../components/maps/PolyLine.jsx";
import GeneratorSingleMarkerWithInfoWindow from "../../../../../../components/maps/GeneratorSingleMarkerWithInfoWindow.jsx";
import VendorSingleMarkerWithInfoWindow from "../../../../../../components/maps/VendorSingleMarkerWithInfoWindow copy.jsx";
import TreatmentSingleMarkerWithInfoWindow from "../../../../../../components/maps/TreatmentSingleMarkerWithInfoWindow.jsx";
import { LOCATION_TYPE } from "../../../../../../utils/constants.js";

function RouteSetupMap({
	start,
	end,
	setStart,
	setEnd,
	path,
	setPath,
	otherPaths = [],
	waypoints = [],
	otherGeneratorMarkers = [],
	vendorMarkers = [],
	treatmentFacilities = [],
	setFormValue = () => {},
}) {
	const [markerPositions, setMarkerPositions] = useState([]);
	const nameRef = useRef();
	const [currentEditingId, setCurrentEditingId] = useState("");
	const [plotStart, setPlotStart] = useState(null);
	const [plotEnd, setPlotEnd] = useState(null);
	const geocoderLibrary = useMapsLibrary("geocoding");
	const [geocoderService, setGeocoderService] = useState(null);
	const [routesToShow, setRoutesToShow] = useState([]);
	useEffect(() => {
		if (!geocoderLibrary) return;
		setGeocoderService(new geocoderLibrary.Geocoder());
	}, [geocoderLibrary]);
	console.log({ start, end });

	const handleMapClick = useCallback(
		async (event) => {
			if (markerPositions.length <= 1) {
				let name = "";
				let placeId = event?.detail?.placeId ?? "";
				let formatted_address = "";
				try {
					if (!geocoderService) {
						throw new Error("Geocoder Service not found. You need to set location name manually.");
					}
					if (event?.detail?.placeId) {
						const res = await geocoderService.geocode({ placeId: event?.detail?.placeId });
						console.log({ "with placeid": res });
						name = res.results[0].formatted_address;
						formatted_address = res.results[0].formatted_address;
					} else {
						const res = await geocoderService.geocode({ location: event.detail.latLng });
						console.log({ "without placeid": res });
						name = res.results[0].formatted_address;
						placeId = res.results[0].place_id;
						formatted_address = res.results[0].formatted_address;
					}
				} catch (error) {
					console.log({ error });
				}

				setMarkerPositions((positions) => [
					...positions,
					{
						name,
						id: Math.floor(Math.random() * 10000).toString(),
						...event.detail.latLng,
						placeId,
						formatted_address,
					},
				]);
			}
		},
		[markerPositions, geocoderService]
	);

	const isCurrentLocation = (position, point) => {
		if (!position || !point) {
			return false;
		}
		if (position.lat === point.lat && position.lng === position.lng) {
			return true;
		} else {
			return false;
		}
	};
	const setStartPositionHandler = (position) => {
		if (!position?.name?.length) {
			showErrorToastMessage("Please set a name for the Location to proceed further.");
			return;
		}
		setStart(position);
		showSuccessToastMessage("Start location set successfully.");
	};
	const setEndPositionHandler = (position) => {
		if (!position?.name?.length) {
			showErrorToastMessage("Please set a name for the Location to proceed further.");
			return;
		}
		setEnd(position);
		showSuccessToastMessage("End location set successfully.");
	};
	const removeLocationHandler = (position) => {
		if (start && position.lat === start.lat && position.lng === start.lng) {
			setStart(null);
		}
		if (end && position.lat === end.lat && position.lng === end.lng) {
			setEnd(null);
		}
		setMarkerPositions((prev) => prev.filter((el) => el.id !== position.id));
		showSuccessToastMessage("Location removed successfully.");
	};
	const triggerPlotRoute = () => {
		console.log("Triggering route plot");

		if (!start || !end) {
			showErrorToastMessage("Please place two points on the map to plot a route.");
			return;
		}
		setPlotStart(start);
		setPlotEnd(end);
	};
	useEffect(() => {
		setRoutesToShow(() => otherPaths.map((el) => el.id));
	}, [otherPaths]);
	const renderLegends = () => {
		return (
			<div className="absolute top-5 left-5 bg-white shadow-xl rounded-xl">
				<div className="collapse collapse-arrow">
					<input type="checkbox" />
					<div className="collapse-title">
						<p className="">Legends</p>
					</div>
					<div className="collapse-content">
						<div className="grid grid-rows-6 grid-flow-col-dense ">
							<div className="grid grid-cols-2 ">
								<span>Generator</span>
								<span className="">
									<img className="w-10 h-10" src="/generator.svg" alt="Generator" />
								</span>
							</div>
							<div className="grid grid-cols-2">
								<span>Transfer Facility</span>
								<span>
									<img className="w-10 h-10" src="/transfer_facilities.svg" alt="Transfer Facilities" />
								</span>
							</div>
							<div className="grid grid-cols-2">
								<span>Treatment Facility</span>
								<span>
									<img className="w-10 h-10" src="/treatment_facilities.svg" alt="Treatment Facilities" />
								</span>
							</div>
							<div className="grid grid-cols-2">
								<span>Vendor</span>
								<span>
									<img className="w-10 h-10" src="/vendor.svg" alt="Vendor" />
								</span>
							</div>
							<div className="grid grid-cols-2">
								<span>Route Start</span>
								<span>
									<img className="w-10 h-10" src="/route_start.svg" alt="Route Start" />
								</span>
							</div>
							<div className="grid grid-cols-2">
								<span>Route End</span>
								<span>
									<img className="w-10 h-10" src="/route_end.svg" alt="Route End" />
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};
	const renderRouteList = () => {
		return (
			<div className="absolute bottom-5 left-5 bg-white shadow-xl rounded-xl">
				<div className="collapse collapse-arrow">
					<input type="checkbox" />
					<div className="collapse-title">
						<p className="">Route List</p>
					</div>
					<div className="collapse-content">
						{otherPaths.map((route) => (
							<div key={route.id} className="text-sm flex gap-2">
								<input
									type="checkbox"
									checked={routesToShow.findIndex((el) => el === route.id) > -1 ? true : false}
									onChange={(e) => {
										if (e.currentTarget.checked) {
											setRoutesToShow((prev) => [...prev, route.id]);
										} else {
											setRoutesToShow((prev) => prev.filter((el) => el !== route.id));
										}
									}}
								/>
								<div onClick={() => {}}>{`${route.routeLabel}`.substring(0, 20)}</div>
							</div>
						))}
					</div>
				</div>
			</div>
		);
	};
	const renderTreatmentFacilities = () => {
		return treatmentFacilities?.length > 0
			? treatmentFacilities.map((mark, i) => (
					<TreatmentSingleMarkerWithInfoWindow
						key={i}
						marker={{
							lat: mark?.treatmentCoordinates.lat,
							lng: mark?.treatmentCoordinates.lng,
							formattedAdd: `${mark?.street ?? ""}, ${mark?.city ?? ""}, ${mark?.state ?? ""} ${mark?.zipCode ?? ""}`,
							name: mark?.name,
						}}
					/>
			  ))
			: null;
	};
	const renderVendors = () => {
		return vendorMarkers?.length > 0
			? vendorMarkers.map((mark, i) => (
					<VendorSingleMarkerWithInfoWindow
						key={i}
						marker={{
							lat: mark?.vendorCoordinates.lat,
							lng: mark?.vendorCoordinates.lng,
							formattedAdd: `${mark?.street ?? ""}, ${mark?.city ?? ""}, ${mark?.state ?? ""} ${mark?.zipCode ?? ""}`,
							name: mark?.name,
							vendorType: mark?.vendorType,
						}}
					/>
			  ))
			: null;
	};
	const renderGenerators = () => {
		return otherGeneratorMarkers?.length > 0
			? otherGeneratorMarkers.map((mark, i) => (
					<GeneratorSingleMarkerWithInfoWindow
						key={i}
						isCurrent={false}
						marker={{
							lat: mark?.serviceAddCoordinates.lat,
							lng: mark?.serviceAddCoordinates.lng,
							assignedRoutes: mark?.assignedRoutes,
							formattedAdd: `${mark.serviceAddress?.street}, ${mark.serviceAddress?.city}, ${mark.serviceAddress?.state} ${mark.serviceAddress?.zipCode}`,
							name: mark?.generatorName,
							octoNumber: mark?.octoNumber,
							serviceFrequency: mark?.serviceFrequency,
							serviceType: mark?.serviceType,
						}}
					/>
			  ))
			: null;
	};

	return (
		<div className="h-96 overflow-hidden rounded-secondary_radii">
			{/* <dialog id="nameEditingModal" className="modal ">
				<div className="modal-box bg-white">
					<form method="dialog">
						<button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
					</form>
					<div>
						<h3 className="font-bold text-lg">Enter Location Name</h3>
						<input type="text" name="name" id="name" defaultValue={""} ref={nameRef} />
					</div>
					<div>
						<button
							className="btn"
							onClick={() => {
								console.log(nameRef);
								setMarkerPositions((prev) =>
									prev.map((el) => {
										if (el.id === currentEditingId) {
											el.name = nameRef?.current?.value ?? "N/A";
										}
										return el;
									})
								);
								document.getElementById("nameEditingModal").close();
							}}
						>
							Save
						</button>
					</div>
				</div>
			</dialog> */}
			<Map
				reuseMaps
				mapId={`routeSetupMap`}
				defaultZoom={12}
				defaultCenter={start ?? { lat: 32.7364432, lng: -117.1460877 }}
				gestureHandling={"greedy"}
				// onClick={handleMapClick}
				disableDefaultUI
			>
				{start && (
					<MarkerWithInfoWindow
						position={{ lat: start.lat, lng: start.lng }}
						name={start?.name ?? "--"}
						pin={
							<Pin>
								<img src={getPinImage(start.type)} />
							</Pin>
						}
					></MarkerWithInfoWindow>
				)}
				{end && (
					<MarkerWithInfoWindow
						position={{ lat: end.lat, lng: end.lng }}
						name={end?.name ?? "--"}
						pin={
							<Pin>
								<img src={getPinImage(end.type)} />
							</Pin>
						}
					></MarkerWithInfoWindow>
				)}
				{/* simple marker */}
				{/* {markerPositions.map((position, i) => (
					<MarkerWithInfoWindow
						key={i}
						position={position}
						name={position?.name ?? ""}
						updatePlaceName={() => {
							setCurrentEditingId(position.id);
							document.getElementById("nameEditingModal").showModal();
						}}
						pin={
							<Pin>
								<img
									src={
										isCurrentLocation(position, start)
											? "/route_start.svg"
											: isCurrentLocation(position, end)
											? "/route_end.svg"
											: ""
									}
								/>
							</Pin>
						}
					>
						<div className="flex flex-row gap-2 w-80">
							<div className="flex gap-2 flex-col justify-center  w-full">
								<button
									type="button"
									className="btn btn-primary rounded-xl btn-sm"
									onClick={() =>
										isCurrentLocation(position, start) ? setStart(null) : setStartPositionHandler(position)
									}
								>
									{isCurrentLocation(position, start) ? "Remove Start Location " : "Set As Start Location"}
								</button>
								<button
									type="button"
									className="btn btn-primary rounded-xl btn-sm"
									onClick={() => (isCurrentLocation(position, end) ? setEnd(null) : setEndPositionHandler(position))}
								>
									{isCurrentLocation(position, end) ? "Remove End Location " : "Set As End Location"}
								</button>
								<button
									className="btn btn-primary rounded-xl btn-sm"
									type="button"
									onClick={() => removeLocationHandler(position)}
								>
									Remove Location
								</button>
							</div>
						</div>
					</MarkerWithInfoWindow>
				))} */}

				{/* {path && <Polyline strokeWeight={5} strokeColor={"#ff22cc88"} path={path} />} */}
				{/* {otherPaths.length > 0 &&
					otherPaths.map(
						(path, i) =>
							routesToShow.findIndex((el) => el === path.id) > -1 && (
								<Polyline key={i} strokeWeight={5} strokeColor={"#0000FF"} path={path.path} />
							)
					)} */}

				{/* {otherPaths.length > 0 &&
					otherPaths.map(
						(path) =>
							routesToShow.findIndex((el) => el === path.id) > -1 && (
								<AdvancedMarker key={path.id} position={path.path[0]} title={"Route Start"}>
									<Pin background={"#0000FF"} borderColor={"#0000FF"} glyphColor={"#0f677a"}>
										<img src="/route_start.svg" alt={"Route Start"} />
									</Pin>
								</AdvancedMarker>
							)
					)}
				{otherPaths.length > 0 &&
					otherPaths.map(
						(path) =>
							routesToShow.findIndex((el) => el === path.id) > -1 && (
								<AdvancedMarker key={path.id} position={path.path[path.path.length - 1]} title={"Route End"}>
									<Pin background={"#0000FF"} borderColor={"#0000FF"} glyphColor={"#0f677a"}>
										<img src="/route_end.svg" alt={"Route End"} />
									</Pin>
								</AdvancedMarker>
							)
					)} */}
				{/* {renderGenerators()}
				{renderTreatmentFacilities()}
				{renderRouteList()}
				{renderLegends()}
				{renderVendors()} */}
			</Map>
		</div>
	);
}

RouteSetupMap.propTypes = {
	end: PropTypes.shape({
		lat: PropTypes.number,
		lng: PropTypes.number,
	}),
	otherGeneratorMarkers: PropTypes.array,
	otherMarkers: PropTypes.array,
	otherPaths: PropTypes.array,
	path: PropTypes.array,
	setEnd: PropTypes.func,
	setFormValue: PropTypes.func,
	setPath: PropTypes.any,
	setStart: PropTypes.func,
	start: PropTypes.shape({
		lat: PropTypes.number,
		lng: PropTypes.number,
	}),
	transferFacilities: PropTypes.array,
	transporter: PropTypes.any,
	treatmentFacilities: PropTypes.array,
	vendorMarkers: PropTypes.array,
	waypoints: PropTypes.array,
};

export default RouteSetupMap;
