import React, { useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { BsFileEarmarkArrowUp, BsTrash, BsFileEarmarkCheck } from "react-icons/bs";
import { GrUpdate } from "react-icons/gr";
import { GoPencil } from "react-icons/go";

const FileUpload = ({ control, errors, label = "Attach File", name = "contractFiles", existingFile, onDeleteFile, isRequired = false }) => {
	const fileInputRef = useRef(null);
	const [isUpdating, setIsUpdating] = useState(false);
	const [localFile, setLocalFile] = useState(null);
	const [isEditing, setIsEditing] = useState(false);
	const [fileName, setFileName] = useState("");
	const [fileExtension, setFileExtension] = useState("");

	const handleFilePickerClick = () => {
		fileInputRef.current?.click();
	};

	const getFileNameAndExtension = (fullName) => {
		const lastDotIndex = fullName.lastIndexOf(".");
		if (lastDotIndex === -1) return [fullName, ""];
		return [fullName.substring(0, lastDotIndex), fullName.substring(lastDotIndex)];
	};

	const handleFileChange = (e, onChange) => {
		const file = e.target.files[0];
		if (file) {
			const [baseName, extension] = getFileNameAndExtension(file.name);
			setFileName(baseName);
			setFileExtension(extension);
			setLocalFile(file);
			setIsUpdating(true);
			onChange(file);
		}
	};

	const handleNameChange = (e) => {
		const newBaseName = e.target.value;
		setFileName(newBaseName);

		// Create a new file object with the updated name but same content
		if (localFile) {
			const newFullName = `${newBaseName}${fileExtension}`;
			const newFile = new File([localFile], newFullName, {
				type: localFile.type,
				lastModified: localFile.lastModified,
			});
			setLocalFile(newFile);
			// Update the form value
			control._formValues[name] = newFile;
		}
	};

	const resetFileSelection = (onChange) => {
		if (onChange) onChange(null);
		if (fileInputRef.current) fileInputRef.current.value = null;
		setLocalFile(null);
		setIsUpdating(false);
		setIsEditing(false);
		setFileName("");
		setFileExtension("");
	};

	const handleEditComplete = () => {
		setIsEditing(false);
	};

	const handleKeyDown = (e) => {
		if (e.key === "Enter") {
			handleEditComplete();
		}
	};

	return (
		<div className="flex items-center mt-4 justify-between">
			<label htmlFor={name} className="truncate text-inputLabel font-normal">
				{`${label} ${isRequired ? "*" : ""}`}
			</label>
			<div className="w-2/3">
				<Controller
					name={name}
					control={control}
					render={({ field: { value, onChange } }) => (
						<div className="flex items-center w-full p-2 px-4 h-9 bg-gray-100 rounded-full shadow-sm">
							{!existingFile?.url && !existingFile?.link && !isUpdating && (
								<div className="flex items-center w-full">
									<input type="file" ref={fileInputRef} className="hidden" onChange={(e) => handleFileChange(e, onChange)} />
									<span className="text-cardTextGray flex-grow truncate">{"Choose a file..."}</span>
									<BsFileEarmarkArrowUp className="text-cardTextGray hover:fill-cardTextBlue hover:cursor-pointer" onClick={handleFilePickerClick} size={20} />
								</div>
							)}
							{isUpdating && localFile && (
								<div className="flex items-center gap-3 justify-between w-full">
									{isEditing ? (
										<div className="flex-grow flex items-center">
											<input
												type="text"
												value={fileName}
												onChange={handleNameChange}
												className="flex-grow bg-transparent py-1 my-1 focus:outline-none rounded"
												onBlur={handleEditComplete}
												onKeyDown={handleKeyDown}
												autoFocus
											/>
											<span className="text-cardTextGray">{fileExtension}</span>
										</div>
									) : (
										<span className="text-cardTextGray flex-grow truncate">{fileName + fileExtension}</span>
									)}
									<div className="flex items-center gap-3">
										<button type="button" onClick={() => setIsEditing(true)}>
											<GoPencil size={20} color="#666666" className="hover:fill-cardTextBlue" />
										</button>
										<button type="button" onClick={() => resetFileSelection(onChange)}>
											<BsTrash size={20} color="#666666" className="hover:fill-red-500" />
										</button>
									</div>
								</div>
							)}
							{existingFile?.url ||
								(existingFile?.link && !isUpdating && (
									<div className="flex items-center justify-between w-full gap-4">
										<a
											href={existingFile.url ?? existingFile?.link}
											target="_blank"
											rel="noopener noreferrer"
											className="text-cardTextBlue w-auto flex-grow truncate hover:underline"
										>
											<BsFileEarmarkCheck className="inline mr-2 text-cardTextGreen" size={20} />
											{existingFile.name}
										</a>
										<div className="flex items-center justify-end gap-3 w-fit">
											<input type="file" ref={fileInputRef} className="hidden" onChange={(e) => handleFileChange(e, onChange)} />
											<button type="button" className="text-gray-500 hover:text-gray-700" onClick={handleFilePickerClick}>
												<GrUpdate size={18} className="text-cardTextGray hover:stroke-cardTextBlue" />
											</button>
											<button type="button" onClick={onDeleteFile}>
												<BsTrash size={20} color="#666666" className="hover:fill-red-500" />
											</button>
										</div>
									</div>
								))}
						</div>
					)}
				/>
			</div>
			{errors[name] && <p className="text-red-500 text-sm mt-1">{errors[name]?.message}</p>}
		</div>
	);
};

export default FileUpload;