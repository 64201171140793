import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore, doc, getDoc, setDoc, Timestamp } from "firebase/firestore";
import {
	getAuth,
	signInWithPopup,
	createUserWithEmailAndPassword,
	signInWithEmailAndPassword,
	GoogleAuthProvider,
	signOut,
	sendPasswordResetEmail,
} from "firebase/auth";
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";

const firebaseConfig = {
	apiKey: "AIzaSyBI-JdFKn09RN0JJujT_4x043m2ndrNATw",
	authDomain: "routes-123.firebaseapp.com",
	databaseURL: "https://routes-123-default-rtdb.firebaseio.com",
	projectId: "routes-123",
	storageBucket: "routes-123.appspot.com",
	messagingSenderId: "211998166799",
	appId: "1:211998166799:web:34a768c214413738a3787a",
	measurementId: "G-FJQ9NH9827",
};

// if (import.meta.env.DEV) {
// 	self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
// }

const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);

export const db = getFirestore(firebaseApp);
const appCheck = initializeAppCheck(firebaseApp, {
	provider: new ReCaptchaEnterpriseProvider("6LdSU5oqAAAAAGBuCFSeAqz9zm_XNhs3DpvcvruC"),
	isTokenAutoRefreshEnabled: true,
});

const provider = new GoogleAuthProvider();
provider.setCustomParameters({
	prompt: "select_account",
});

export const storage = getStorage(firebaseApp);
export const signInWithGooglePopup = () => signInWithPopup(auth, provider);
export const sendResetPasswordEmail = (email) => sendPasswordResetEmail(auth, email);

export const createUserDocFromAuth = async (userAuth, role = "User", collectionName = "users", additionalInfo = {}) => {
	if (!userAuth) return;
	const userDocRef = doc(db, collectionName, userAuth.uid);
	const userSnaphot = await getDoc(userDocRef);

	if (!userSnaphot.exists()) {
		const { displayName, email } = userAuth;
		const createdAt = new Date();

		try {
			await setDoc(
				userDocRef,
				{
					name: displayName,
					email,
					createdAt,
					role,
					...additionalInfo,
				},
				{ merge: true }
			);
			return role;
		} catch (error) {
			console.log("Error during creating the user", error.message);
		}
	} else {
		let userData = userSnaphot.data();
		return userData.role;
	}
};

export const createNewUserWithEmailAndPassword = async (email, password) => {
	if (!email || !password) return;
	return createUserWithEmailAndPassword(auth, email, password);
};

export const signInUserWithEmailAndPassword = async (email, password) => {
	if (!email || !password) return;
	return signInWithEmailAndPassword(auth, email, password);
};

export const signOutUser = async () => {
	try {
		let res = await signOut(auth);
		return res;
	} catch (error) {
		console.log(error);
		throw error;
	}
};

export const COLLECTIONS = {
	scheduledServices: "scheduledServices",
	generators: "generators",
	routes: "routes",
	drivers: "drivers",
	serviceSchedules: "serviceSchedules",
	treatments: "treatments",
	path: "path",
	vendors: "vendors",
	satelliteLocation: "satelliteLocation",
	serviceVehicles: "serviceVehicles",
};
