import PropTypes from "prop-types";
import { useMap, useMapsLibrary } from "@vis.gl/react-google-maps";
import { useEffect, useState } from "react";
const Directions = ({ start, end, triggerPlotRoute, setPath, waypoints = [], setFormValue = () => {} }) => {
	const map = useMap();
	const routesLibrary = useMapsLibrary("routes");
	const [directionsService, setDirectionsService] = useState();
	const [directionsRenderer, setDirectionsRenderer] = useState();
	const [routes, setRoutes] = useState([]);
	const [routeIndex, setRouteIndex] = useState(0);
	const selected = routes[routeIndex];
	const leg = selected?.legs[0];

	useEffect(() => {
		if (!routesLibrary || !map) return;
		setDirectionsService(new routesLibrary.DirectionsService());
		setDirectionsRenderer(new routesLibrary.DirectionsRenderer({ map, suppressMarkers: true }));
	}, [routesLibrary, map]);

	
	useEffect(() => {
		if (routes[routeIndex]?.legs) {
			let calculatedDistance = 0;
			let calculatedTime = 0;
			routes[routeIndex]?.legs.map((leg) => {
				if (leg?.duration?.value) {
					calculatedTime += leg.duration.value;
				}
				if (leg?.distance?.value) {
					calculatedDistance += leg.distance.value;
				}
			});

			console.log({ calculatedDistance, calculatedTime });
			setFormValue("totalDistanceInMeters", calculatedDistance);
			setFormValue("totalTimeInSeconds", calculatedTime);
			const steps = routes[routeIndex]?.legs.flatMap((el) => el.steps);
			let path = steps.flatMap((el) => el.path);
			path = path.map((el) => {
				return { lat: el.lat(), lng: el.lng() };
			});
			console.log({ path });
			setPath(path);
		}
		return () => {};
	}, [routeIndex, setPath, routes]);

	useEffect(() => {
		if (!directionsService || !directionsRenderer || !start || !end) return;
		console.log({ waypoints });
		console.log("Fetching route from google maps");

		directionsService
			.route({
				origin: start,
				destination: end,
				travelMode: "DRIVING",
				waypoints: waypoints.map((el) => {
					return { location: el.location, stopover: true };
				}),
				provideRouteAlternatives: true,
			})
			.then((response) => {
				directionsRenderer.setDirections(response);
				setRoutes(response.routes);

				if (response.routes[0]?.legs) {
					console.log(response.routes[0]?.legs);

					let calculatedDistance = 0;
					let calculatedTime = 0;
					response.routes[0]?.legs.map((leg) => {
						if (leg?.duration?.value) {
							calculatedTime += leg.duration.value;
						}
						if (leg?.distance?.value) {
							calculatedDistance += leg.distance.value;
						}
					});

					console.log({ calculatedDistance, calculatedTime });
					setFormValue("totalDistanceInMeters", calculatedDistance);
					setFormValue("totalTimeInSeconds", calculatedTime);
				}
			});

		return () => directionsRenderer.setMap(null);
	}, [directionsService, directionsRenderer, start, end]);

	useEffect(() => {
		if (!directionsRenderer) return;
		directionsRenderer.setRouteIndex(routeIndex);
	}, [routeIndex, directionsRenderer]);

	return (
		<div className="absolute top-0 right-0 p-2">
			<div className="place-self-end">
				<button className="btn btn-sm btn-primary" onClick={() => triggerPlotRoute()} type="button">
					{start && end ? "Reroute" : "Plot Route"}
				</button>
			</div>

			{routes.length > 0 && (
				<div className="stats stats-vertical shadow">
					<div className="stat">
						<div className="stat-title">{selected?.summary ?? "This is a summary"}</div>
						<p className="stat-desc">
							{leg.start_address.split(",")[0]} to {leg.end_address.split(",")[0]}
						</p>
						<p className="stat-desc">Distance: {leg.distance?.text}</p>
						<p className="stat-desc">Duration: {leg.duration?.text}</p>
					</div>
					<div className="">
						<div className="collapse collapse-arrow">
							<input type="checkbox" />
							<div className="collapse-title text-sm">Other Routes</div>
							<div className="collapse-content">
								{routes.map((route, index) => (
									<p key={route.summary} className="text-sm">
										<button type="button" onClick={() => setRouteIndex(index)}>
											{route.summary}
										</button>
									</p>
								))}
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

Directions.propTypes = {
	end: PropTypes.any,
	setFormValue: PropTypes.func,
	setPath: PropTypes.func,
	start: PropTypes.any,
	triggerPlotRoute: PropTypes.func,
	waypoints: PropTypes.array,
};

export default Directions;
