import React, { useState } from "react";
import { MdOutlineDashboard } from "react-icons/md";
import { PiUsers } from "react-icons/pi";
import { Link } from "react-router-dom";

const Sidebar = ({ activeTab }) => {
	return (
		<div>
			<div className="p-8 flex items-center gap-4">
				<img src="" alt="" className="h-6 w-6 md:h-10 md:w-10 rounded-full bg-dashBtnGradient" />
				<h6 className="font-bold text-xl text-black uppercase">Octopus saas</h6>
			</div>
			{/* Tabs */}
			<div className="flex flex-col gap-2 py-4 font-medium">
				<Link
					to={"/market-admin/dashboard"}
					className={`${
						activeTab == "dashboard" ? "text-primary bg-cardTextGray bg-opacity-10" : ""
					} flex gap-4 py-2 px-8 cursor-pointer items-center hover:bg-cardTextGray hover:bg-opacity-10 transition-all duration-300`}
					// onClick={() => setActiveTab("dashboard")}
				>
					<MdOutlineDashboard size={24} />
					<h6>Dashboard</h6>
				</Link>
				<Link
					to={"/market-admin/users"}
					className={`${
						activeTab == "users" ? "text-primary bg-cardTextGray bg-opacity-10" : ""
					} flex gap-4 py-2 px-8 cursor-pointer items-center hover:bg-cardTextGray hover:bg-opacity-10 transition-all duration-300`}
					// onClick={() => setActiveTab("users")}
				>
					<PiUsers size={24} />
					<h6>Users</h6>
				</Link>
			</div>
		</div>
	);
};

export default Sidebar;
