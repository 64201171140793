import React, { useState } from "react";
import DashInfoCard from "../../../components/UI/cards/DashInfoCard";
import QuickAnalytics from "./components/quick-analytics/QuickAnalytics";
import OnGoingPickups from "./components/ongoing-pickups/OnGoingPickups";
import DriverLocations from "./components/driver-locations/DriverLocations";
import OpenCalendar from "./components/open-calendar/OpenCalendar";

const cardInfos = [
	{
		id: "clientcount",
		title: "Today's Total Services",
		count: "020",
		changePercantage: "12",
	},
	{
		id: "drivercount",
		title: "Today's Routes",
		count: "045",
		changePercantage: "12",
	},
	{
		id: "pickupcount",
		title: "Active Drivers",
		count: "018",
		changePercantage: "12",
	},
	{
		id: "revenuecount",
		title: "Active Generators",
		count: "080",
		changePercantage: "12",
	},
];

const Dashboard = () => {
	const [isScrollable, setIsScrollable] = useState(false);

	const scrollHandler = (e) => {
		const target = e.target;
		target.scrollTop > 0 ? setIsScrollable(true) : setIsScrollable(false);
	};

	return (
		// <div className="flex flex-col h-[90%]">
		<div
			className={`
          flex-1
          ${isScrollable ? "shadow-inner" : ""}
          p-6
        `}
			onScroll={scrollHandler}
		>
			<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 lg:gap-6">
				{cardInfos.map(({ changePercantage, id, title, count }, i) => (
					<DashInfoCard key={id} cardColor={i % 2 == 0 ? "green" : "blue"} changePercentage={changePercantage} title={title} count={count} />
				))}
			</div>
			<div className="pt-6 flex flex-col gap-4 lg:gap-6 xl:flex-row">
				<div className="flex flex-col gap-4 lg:gap-6 xl:w-[65%] 2xl:w-[70%]">
					<div className="w-full border border-cardBorderCol rounded-cardRadii">
						<QuickAnalytics />
					</div>
					<div className="flex flex-col w-full gap-4 lg:gap-6 lg:flex-row">
						<div className="w-full rounded-cardRadii border border-cardBorderCol">
							<OnGoingPickups />
						</div>
						<div className="w-full border border-cardBorderCol rounded-cardRadii">
							<DriverLocations />
						</div>
					</div>
				</div>
				<div className="w-full border border-cardBorderCol xl:w-[35%] 2xl:w-[30%] rounded-cardRadii">
					<OpenCalendar />
				</div>
			</div>
		</div>
		// </div>
	);
};

export default Dashboard;
