import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { RxCross2 } from "react-icons/rx";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Input from "../UI/Input";
import Dropdown from "../UI/dropdowns/Dropdown";
import Loader from "../UI/loaders/Loader";
import { DEFAULT_SERVICE_VEHICLE_VALUES } from "../../utils/constants";
import { getAllVehicleSatelliteLocationSnapshot } from "../../utils/firebaseOperations";
import { showErrorToastMessage, showLoadingToastMessage, showSuccessToastMessage } from "../../utils/helpers";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { COLLECTIONS, db } from "../../config/firebase";

const MiniServiceVehicleRegModal = ({ onClose = () => {} }) => {
	const {
		control,
		handleSubmit,
		setValue,
		reset,
		formState: { errors },
	} = useForm({
		defaultValues: DEFAULT_SERVICE_VEHICLE_VALUES,
	});
	const [vehicleLocationOptions, setVehicleLocationOptions] = useState([{ label: "Loading", value: "loading" }]);
	const [inProgress, setInProgress] = useState(false);
	const navigate = useNavigate();
	useEffect(() => {
		let unsubscribe = getAllVehicleSatelliteLocationSnapshot(setVehicleLocationOptions);
		return () => {
			if (unsubscribe) unsubscribe();
		};
	}, []);

	const onSubmitForm = async (data) => {
		console.log({ data });
		showLoadingToastMessage("Creating New Service Vehicle");
		try {
			const serviceVehicleDocRef = await addDoc(collection(db, COLLECTIONS.serviceVehicles), {
				createdAt: serverTimestamp(),
				...data,
			});
			showSuccessToastMessage("Service Vehicle Created Successfully!");
			reset(DEFAULT_SERVICE_VEHICLE_VALUES);
			navigate(`/admin/assets/service-vehicles/${serviceVehicleDocRef.id}/edit`);
			onClose();
		} catch (error) {
			console.log(error);
			showErrorToastMessage("Internal Server Error.");
		} finally {
			setInProgress(false);
		}
	};

	return (
		<div className="z-40 fixed inset-0 bg-[#CCCCCC87] bg-opacity-50 flex justify-center items-center ">
			<div className="bg-white z-20 rounded-cardRadii max-w-md w-full min-h-fit">
				<form onSubmit={handleSubmit(onSubmitForm)} className="flex flex-col justify-between min-h-80 p-6">
					<div className="flex justify-between pb-2">
						<div className="flex items-center gap-4">
							<h6 className="font-bold text-xl text-black">Add New Driver</h6>
						</div>
						<button
							type="button"
							disabled={inProgress}
							className={`flex justify-center items-center h-fit w-fit p-1 rounded-full border shadow-md ${
								inProgress ? "" : "hover:bg-gray-200"
							}`}
							onClick={onClose}
						>
							<RxCross2 size={20} className={`${!inProgress ? "hover:cursor-pointer" : ""}`} />
						</button>
					</div>

					<div className="overflow-auto max-h-[60vh] px-2 pb-40">
						<Controller
							name="name"
							control={control}
							rules={{ required: "Nick name is required." }}
							render={({ field: { value, onChange } }) => (
								<div>
									<Input
										value={value}
										onChange={onChange}
										label="Nick Name"
										isRequired
										className="w-full px-2 py-1 text-sm"
										style="flex-col gap-1"
										margin="mt-1"
									/>
									{errors.name && <p className="text-red-500 text-sm mt-1">{errors.name?.message}</p>}
								</div>
							)}
						/>

						<Controller
							name="locationAssignment"
							control={control}
							rules={{ required: "Vehicle Location Assignment is required." }}
							render={({ field: { value, onChange } }) => (
								<div>
									<Dropdown
										value={value}
										onChange={onChange}
										options={vehicleLocationOptions}
										label="Vehicle Location Assignment *"
										className="text-sm"
										styles="flex-col gap-1"
										margin="1"
									/>
									{errors.locationAssignment && (
										<p className="text-red-500 text-sm mt-1">{errors.locationAssignment?.message}</p>
									)}
								</div>
							)}
						/>
					</div>

					<button
						type="submit"
						className={`capitalize py-2 my-5 text-center px-5 gap-2 hover:opacity-90 ${
							inProgress ? "bg-cardTextGray bg-opacity-20" : "bg-dashBtnGradient"
						} w-full rounded-full text-white`}
					>
						{inProgress ? <Loader height="h-auto" /> : `Add New Service Vehicle`}
					</button>
				</form>
			</div>
		</div>
	);
};

MiniServiceVehicleRegModal.propTypes = {
	onClose: PropTypes.func,
};

export default MiniServiceVehicleRegModal;
