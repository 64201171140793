const ShimmerLoader = ({ cols = 6, rows }) => {
	return (
		<div style={{ height: `calc(100% - 60px)` }} className="w-full">
			{Array.from({ length: rows }).map((_, index) => (
				<div key={index} className={`flex min-w-fit py-4 border-b h-fit border-gray-300 animate-pulse`}>
					<>
						<div className="h-4 bg-gray-300 rounded mx-6 w-8"></div>
						<div className="w-60">
							<span className="w-4/5 h-full block bg-gray-300 rounded"></span>
						</div>
						<div className="w-40">
							<span className="w-4/5 h-full block bg-gray-300 rounded"></span>
						</div>
						<div className="w-60">
							<span className="w-4/5 h-full block bg-gray-300 rounded"></span>
						</div>
						<div className="w-80">
							<span className="w-4/5 h-full block bg-gray-300 rounded"></span>
						</div>
						<div className="w-40">
							<span className="w-4/5 h-full block bg-gray-300 rounded"></span>
						</div>
						<div className="w-60">
							<span className="w-4/5 h-full block bg-gray-300 rounded"></span>
						</div>
						<div className="w-32">
							<span className="w-4/5 h-full block bg-gray-300 rounded"></span>
						</div>
						<div className="w-40">
							<span className="w-4/5 h-full block bg-gray-300 rounded"></span>
						</div>
					</>
				</div>
			))}
		</div>
	);
};

export default ShimmerLoader;
