export const vehicleOptions = [
	{ label: "Cargo Van", value: "CARGO_VAN" },
	{ label: "Passenger Van", value: "PASSENGER_VAN" },
	{ label: "Pickup Truck", value: "PICKUP_TRUCK" },
	{ label: "Semi-Truck", value: "SEMI_TRUCK" },
	{ label: "Truck", value: "TRUCK" },
	{ label: "Van", value: "VAN" },
];

export const fuelOptions = [
	{ label: "Diesel", value: "DIESEL" },
	{ label: "Electric", value: "ELECTRIC" },
	{ label: "Gasoline", value: "GASOLINE" },
];
export const statusOptions = [
	{ label: "Active", value: "ACTIVE" },
	{ label: "Inactive", value: "INACTIVE" },
	{ label: "Maintenance", value: "MAINTENANCE" },
];
