const serviceOptions = [
	{ label: "Consulting Services", value: "CONSULTING_SERVICES" },
	{ label: "Container Supplier (Medical Waste)", value: "CONTAINER_SUPPLIER_MEDICAL_WASTE" },
	{ label: "Container Supplier (Paper Shredding)", value: "CONTAINER_SUPPLIER_PAPER_SHREDDING" },
	{ label: "E-Waste Recycling Services", value: "E_WASTE_RECYCLING_SERVICES" },
	{ label: "Financial Services", value: "FINANCIAL_SERVICES" },
	{ label: "Hazardous Waste Services", value: "HAZARDOUS_WASTE_SERVICES" },
	{ label: "Legal Services", value: "LEGAL_SERVICES" },
	{ label: "Linen & Uniform Services", value: "LINEN_AND_UNIFORM_SERVICES" },
	{ label: "Medical Waste Processing Equipment", value: "MEDICAL_WASTE_PROCESSING_EQUIPMENT" },
	{ label: "Medical Waste Transfer Station (TS)", value: "MEDICAL_WASTE_TRANSFER_STATION" },
	{ label: "Medical Waste Transporter", value: "MEDICAL_WASTE_TRANSPORTER" },
	{ label: "Medical Waste Treatment Facility (TF)", value: "MEDICAL_WASTE_TREATMENT_FACILITY" },
	{ label: "Online OSHA Training", value: "ONLINE_OSHA_TRAINING" },
	{ label: "Paper Shredding Services (Offsite)", value: "PAPER_SHREDDING_SERVICES_OFFSITE" },
	{ label: "Paper Shredding Services (Onsite)", value: "PAPER_SHREDDING_SERVICES_ONSITE" },
	{ label: "Product Supplier Reverse Distributions", value: "PRODUCT_SUPPLIER_REVERSE_DISTRIBUTIONS" },
	{ label: "Training & Certification Services", value: "TRAINING_AND_CERTIFICATION_SERVICES" },
	{ label: "Transportation Insurance Providers", value: "TRANSPORTATION_INSURANCE_PROVIDERS" },
];

import React, { useEffect, useRef, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { motion, AnimatePresence } from "framer-motion";
import { emailRegex } from "../../utils/mimes";
import { RxCross2 } from "react-icons/rx";
import { FiPlus, FiUpload } from "react-icons/fi";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { db, storage } from "../../config/firebase";

const StyledMultiSelect = ({ label, options, selectedOptions, setSelectedOptions, placeholder, disabled = false }) => {
	const [isOpen, setIsOpen] = useState(false);
	const dropdownRef = useRef(null);

	const handleSelect = (option) => {
		if (selectedOptions?.some((item) => item === option?.value)) {
			setSelectedOptions(selectedOptions.filter((item) => item !== option.value));
		} else {
			setSelectedOptions([...selectedOptions, option.value]);
		}
	};

	const removeOption = (optionToRemove, e) => {
		e.stopPropagation();
		setSelectedOptions(selectedOptions.filter((option) => option !== optionToRemove));
	};

	const handleClickOutside = (event) => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
			setIsOpen(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	// Find the label for the selected options
	const getSelectedLabels = () => {
		return selectedOptions.map((selectedValue) => options.find((option) => option.value === selectedValue)?.label);
	};

	const onClickHandler = () => {
		if (!disabled) setIsOpen(!isOpen);
	};

	return (
		<div className="relative" ref={dropdownRef}>
			<div
				type="button"
				onClick={onClickHandler}
				className={`
			w-full p-3 px-5 bg-[#F1F1F4] text-cardTextGray rounded-[24px]
			flex items-center justify-between ${disabled ? "" : "cursor-pointer"}
			outline-none focus:outline focus:outline-2 focus:outline-blue-500
			transition-all min-h-[48px]
		  `}
			>
				<div className="flex flex-wrap overflow-scroll scrollbar-hide gap-1 items-center pr-2">
					{selectedOptions?.length > 0 ? (
						getSelectedLabels().map((label, index) => (
							<span
								key={selectedOptions[index]}
								className="text-nowrap flex-wrap items-center gap-1 px-2 bg-white rounded-full text-sm"
							>
								{label}
								{!disabled && (
									<button
										type="button"
										onClick={(e) => removeOption(selectedOptions[index], e)}
										className="p-0.5 hover:bg-gray-100 rounded-full"
									>
										<RxCross2 className="h-3 w-3" />
									</button>
								)}
							</span>
						))
					) : (
						<span>{placeholder || `Select ${label}`}</span>
					)}
				</div>
				{!disabled && (
					<IoIosArrowDown size={20} className={`transform transition-transform ${isOpen ? "rotate-180" : ""}`} />
				)}
			</div>
			{isOpen && (
				<div
					className="absolute z-10 w-full bg-white shadow-md rounded-md mt-1 max-h-60 overflow-auto"
					style={{
						animation: "scaleIn 200ms ease-out",
					}}
				>
					{options.map((option) => (
						<div
							key={option.value}
							onClick={() => handleSelect(option)}
							className="p-3 px-5 hover:bg-cardTextGray hover:bg-opacity-10 cursor-pointer flex items-center gap-2"
						>
							<input
								type="checkbox"
								checked={selectedOptions?.some((item) => item === option?.value)}
								onChange={() => {}}
								className="h-4 w-4 rounded border-gray-300"
							/>
							{option.label}
						</div>
					))}
				</div>
			)}
			<style
				jsx={`
			@keyframes scaleIn {
			  from {
				opacity: 0;
				transform: scale(0.95);
			  }
			  to {
				opacity: 1;
				transform: scale(1);
			  }
			}
		  `}
			></style>
		</div>
	);
};
const ApproveDetail = () => {
	const [contacts, setContacts] = useState([]);
	const defaultContact = {
		id: "",
		firstName: "",
		lastName: "",
		phone: "",
		email: "",
	};
	const [newContact, setNewContact] = useState(defaultContact);
	const [isAddingContact, setIsAddingContact] = useState(false);
	const [newContactError, setNewContactError] = useState({});
	const [profileImage, setProfileImage] = useState("");
	const [imgLoading, setImgLoading] = useState(true);
	const [isHoveringImage, setIsHoveringImage] = useState(false);
	const [isFormChanged, setIsFormChanged] = useState(false);
	const [openSections, setOpenSections] = useState({});
	const { sendApprovalRequest, approveUserRequest, rejectUserRequest } = useConnectionManager();
	const [isUserIdDataLoaded, setIsUserIdDataLoaded] = useState(false);
	const [userIdData, setUserIdData] = useState({});
	const [alreadySent, setAlreadySent] = useState("");
	const [isApproving, setIsApproving] = useState(false);
	const [isRejecting, setIsRejecting] = useState(false);
	const { id } = useParams();

	const defaultValues = {
		companyName: "",
		address: "",
		phone: "",
		email: "",
		websiteUrl: "",
		contacts: [],
		category: [],
		serviceArea: "",
		localAreas: [],
		shortDesc: "",
		bio: "",
		profileImage: "",
	};

	const methods = useForm({
		defaultValues,
	});

	const toggleSection = (id) => {
		setOpenSections((prev) => ({
			...prev,
			[id]: !prev[id],
		}));
	};

	useEffect(() => {
		if (userIdData) {
			setProfileImage(userIdData["profileImage"]);
			Object.keys(defaultValues).forEach((key) => {
				if (userIdData[key]) methods.setValue(key, userIdData[key]);
			});
			let allContacts = methods.getValues("contacts");
			setContacts(allContacts ?? []);
			setIsFormChanged(false);
			setImgLoading(false);
		}
	}, [userIdData]);

	const fetchUserDetail = async () => {
		if (id) {
			let docSnap = await getDoc(doc(db, "octoMarketUsers", id));
			if (!docSnap.exists) {
				setError(true);
			} else {
				let data = { ...docSnap.data(), id: docSnap.id };
				const userData = {
					id: data.id,
					name: data?.name ?? data.companyName,
					address: data?.address ?? "",
					region: data?.region ?? "",
					specialty: data?.specialty ?? "",
					category: data?.category ?? [],
					websiteUrl: data?.websiteUrl ?? "",
					bio: data?.bio ?? "",
					profileImage: data?.profileImage ?? "",
					contacts: data?.contacts ?? [],
					email: data?.email ?? "",
					companyName: data?.companyName ?? "",
					shortDesc: data?.shortDesc ?? "",
					serviceArea: data?.serviceArea ?? "",
					serviceAreaArr: data?.serviceAreaArr ?? "",
					approved: Boolean(data.approved),
					rejected: Boolean(data.rejected),
					profileCompleted: Boolean(data.profileCompleted),
					phone: data?.phone ?? "",
					localAreas: data?.localAreas ?? [],
					connections: simplifyConnections(data?.connections ?? {}) ?? {},
				};

				setUserIdData(userData);
			}
		}
		setIsUserIdDataLoaded(true);
	};

	useEffect(() => {
		fetchUserDetail();
	}, [id]);

	const onSubmit = async (data) => {};

	const approveUser = async (userId) => {
		setIsApproving(true);
		// After approving
		await approveUserRequest(userId);
		setIsApproving(false);
		await fetchUserDetail();
	};
	const rejectUser = async (userId) => {
		setIsRejecting(true);
		// After rejecting
		await rejectUserRequest(userId);
		setIsRejecting(false);
		await fetchUserDetail();
	};

	if (!isUserIdDataLoaded) return <Loader height="" />;

	return (
		<div className="">
			<div className="container mx-auto py-16">
				{/* Header Banner */}
				<motion.div
					className="h-32 mx-4 bg-[#007AFF] p-4 rounded-t-xl"
					initial={{ opacity: 0, scale: 0.95 }}
					animate={{ opacity: 1, scale: 1 }}
					transition={{ duration: 0.5 }}
				/>

				<FormProvider {...methods}>
					<form onSubmit={methods.handleSubmit(onSubmit)} className="mx-4 px-8 mb-20 bg-white pb-16 rounded-b-xl">
						{/* Profile Header */}
						<motion.div
							className="flex items-center justify-between py-10"
							initial={{ opacity: 0, x: -50 }}
							animate={{ opacity: 1, x: 0 }}
							transition={{ duration: 0.5 }}
						>
							<div className="flex items-center gap-4">
								<div className="relative bg-[#dfdfdf] rounded-lg w-28 h-28 overflow-hidden">
									{imgLoading ? (
										<Loader height="" />
									) : (
										<img
											src={
												profileImage?.length > 0
													? profileImage
													: "https://storage.googleapis.com/routes-123.appspot.com/assets/logo%201.png"
											}
											className="w-full rounded-lg h-full object-cover"
											alt="Profile"
										/>
									)}
								</div>
								<div>
									<h1 className="text-xl font-semibold capitalize">{userIdData?.companyName}</h1>
									<p className="text-gray-500">{userIdData?.address}</p>
								</div>
							</div>
							<div className="flex gap-4">
								<motion.button
									className={`disabled:cursor-not-allowed disabled:bg-[#898989] bg-[#007AFF] hover:bg-blue-700 text-white px-10 py-3 rounded-full`}
									disabled={userIdData?.approved || userIdData?.rejected || isApproving || isRejecting}
									whileHover={{ scale: 1.05 }}
									whileTap={{ scale: 0.95 }}
									onClick={() => approveUser(userIdData.id)}
								>
									{isApproving ? <Loader height="" /> : userIdData?.approved ? "Approved" : "Approve"}
								</motion.button>
								<motion.button
									className={`disabled:cursor-not-allowed disabled:bg-[#898989] bg-red-600 hover:bg-red-700 text-white px-10 py-3 rounded-full`}
									disabled={userIdData?.approved || userIdData?.rejected || isApproving || isRejecting}
									whileHover={{ scale: 1.05 }}
									whileTap={{ scale: 0.95 }}
									onClick={() => rejectUser(userIdData.id)}
								>
									{isRejecting ? <Loader height="" /> : userIdData?.rejected ? "Rejected" : "Reject"}
								</motion.button>
							</div>
						</motion.div>

						{/* Main Content Grid */}
						<div className="grid grid-cols-2 gap-12 xl:gap-20">
							{/* Left Column */}
							<div className="space-y-8">
								<div>
									<label className="block text-black mb-2">Company Name</label>
									<input
										{...methods.register("companyName")}
										className="w-full p-3 px-5 bg-[#F1F1F4] text-gray-700 rounded-full focus:outline-blue-500"
										disabled
									/>
								</div>

								<div>
									<label className="block text-black mb-2">Address</label>
									<input
										{...methods.register("address")}
										className="w-full p-3 px-5 bg-[#F1F1F4] text-gray-700 rounded-full focus:outline-blue-500"
										disabled
									/>
								</div>

								<div>
									<label className="block text-black mb-2">Phone</label>
									<Controller
										name="phone"
										control={methods.control}
										render={({ field: { value, onChange } }) => (
											<PhoneInput
												addStyle={`w-full p-3 px-5 bg-[#F1F1F4] text-gray-700 bg-[#F1F1F4] text-gray-700 rounded-full outline-none rounded-full ${
													newContactError.phone ? "outline-red-500" : "focus:outline-blue-500"
												}`}
												label={null}
												value={value}
												onChange={onChange}
												margin=""
												isDisabled={true}
											/>
										)}
									/>
								</div>
								<div>
									<label className="block text-black mb-2">Email</label>
									<input
										{...methods.register("email")}
										className="w-full p-3 px-5 bg-[#F1F1F4] text-gray-700 rounded-full focus:outline-blue-500"
										disabled
									/>
								</div>
								<div>
									<label className="block text-black mb-2">Website URL</label>
									<input
										{...methods.register("websiteUrl")}
										className="w-full p-3 px-5 bg-[#F1F1F4] text-gray-700 rounded-full focus:outline-blue-500"
										disabled
									/>
								</div>

								{/* Email Section */}
								<div>
									<label className="block text-xl font-medium text-black mb-2">Contacts</label>
									<div className="space-y-3">
										{contacts.map((contact, index) => (
											<div
												key={contact.firstName + contact.lastName + contact.email}
												className="rounded-lg overflow-hidden"
											>
												<button
													type="button"
													onClick={() => toggleSection(contact.id)}
													className="w-full text-[#007AFF] text-lg flex justify-between items-center bg-white transition-colors"
												>
													<span className="font-medium capitalize">{`Contact ${index + 1}`}</span>
													<IoIosArrowDown
														className={`w-5 h-5 transition-transform duration-200 ${
															openSections[contact.id] ? "transform rotate-180" : ""
														}`}
													/>
												</button>

												<div
													className={`overflow-hidden flex justify-between transition-all duration-200 ${
														openSections[contact.id] ? "max-h-48" : "max-h-0"
													}`}
												>
													<div className="p-4 space-y-2">
														<p className="text-sm text-gray-600 capitalize">
															<span className="font-medium">Name:</span> {`${contact.firstName} ${contact.lastName}`}
														</p>
														<p className="text-sm text-gray-600">
															<span className="font-medium">Phone:</span> {contact.phone}
														</p>
														<p className="text-sm text-gray-600">
															<span className="font-medium">Email:</span> {contact.email}
														</p>
													</div>
												</div>
											</div>
										))}
									</div>
								</div>
							</div>

							{/* Right Column */}
							<div className="space-y-8">
								<div>
									<label className="block text-black mb-2">Category</label>
									<Controller
										name="category"
										control={methods.control}
										render={({ field }) => (
											<StyledMultiSelect
												label="Category"
												options={serviceOptions}
												selectedOptions={field.value}
												setSelectedOptions={(value) => field.onChange(value)}
												disabled
											/>
										)}
									/>
								</div>
								<ServiceAreaSelector />
								<div>
									<label className="block text-black mb-2">Short Description</label>
									<textarea
										{...methods.register("shortDesc")}
										className="w-full p-3 px-5 h-[48px] min-h-fit bg-[#F1F1F4] text-gray-700 rounded-[24px] focus:outline-blue-500"
										onInput={(e) => {
											e.target.style.height = "48px"; // Reset height to base height
											e.target.style.height = `${e.target.scrollHeight}px`; // Set height to fit content
										}}
										disabled
									/>
								</div>
								<div>
									<label className="block text-black mb-2">Bio</label>
									<textarea
										{...methods.register("bio")}
										className="w-full p-3 px-5 bg-[#F1F1F4] text-gray-700 rounded-[24px] h-40 focus:outline-blue-500"
										disabled
									/>
								</div>
							</div>
						</div>
					</form>
				</FormProvider>
			</div>
		</div>
	);
};

export default ApproveDetail;

import { useFormContext } from "react-hook-form";
import PhoneInput from "../../components/UI/PhoneInput";
import { FaRegTrashAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import useConnectionManager from "../../hooks/useConnectionManager";
import { useParams } from "react-router-dom";
import { simplifyConnections } from "../../utils/constants";
import Loader from "../../components/UI/loaders/Loader";

const ServiceAreaSelector = ({}) => {
	const {
		register,
		watch,
		setValue,
		formState: { errors },
	} = useFormContext();
	const shippingType = watch("serviceArea");
	const areas = watch("localAreas");
	const [localAreas, setLocalAreas] = useState([]);

	console.log({ areas });

	useEffect(() => {
		setLocalAreas(areas ?? []);
	}, [areas]);

	const containerVariants = {
		hidden: { opacity: 0 },
		visible: {
			opacity: 1,
			transition: { staggerChildren: 0.1 },
		},
	};

	const itemVariants = {
		hidden: { opacity: 0, y: 20 },
		visible: {
			opacity: 1,
			y: 0,
			transition: { type: "spring", stiffness: 300, damping: 24 },
		},
	};

	const radioVariants = {
		unchecked: { scale: 1 },
		checked: {
			scale: 1.1,
			transition: { type: "spring", stiffness: 300, damping: 10 },
		},
	};

	const localFieldsVariants = {
		hidden: {
			opacity: 0,
			height: 0,
			transition: { duration: 0.2 },
		},
		visible: {
			opacity: 1,
			height: "auto",
			transition: { duration: 0.3, ease: "easeOut" },
		},
	};

	return (
		<motion.div className="" initial="hidden" animate="visible" variants={containerVariants}>
			<motion.h2 className="text-lg mb-4" variants={itemVariants}>
				Service Area *
			</motion.h2>

			<motion.div className="flex items-center gap-8" variants={itemVariants}>
				<label className="flex items-center space-x-3 cursor-pointer">
					<motion.div
						variants={radioVariants}
						className="h-5"
						animate={shippingType === "nationwide" ? "checked" : "unchecked"}
					>
						<input
							type="radio"
							value="nationwide"
							disabled
							{...register("serviceArea", { required: "Please select a shipping type" })}
							className="h-5 w-5 text-blue-600 border-gray-300 focus:ring-blue-500"
						/>
					</motion.div>
					<span>Nationwide Shipping</span>
				</label>

				<label className="flex items-center space-x-3 cursor-pointer">
					<motion.div
						variants={radioVariants}
						className="h-5"
						animate={shippingType === "local" ? "checked" : "unchecked"}
					>
						<input
							type="radio"
							value="local"
							disabled
							{...register("serviceArea", { required: "Please select a shipping type" })}
							className="h-5 w-5 text-blue-600 border-gray-300 focus:ring-blue-500"
						/>
					</motion.div>
					<span>City, State, Radius (Miles)</span>
				</label>
			</motion.div>

			<AnimatePresence>
				{shippingType === "local" && (
					<motion.div
						className="flex flex-col pt-4 gap-4"
						initial="hidden"
						animate="visible"
						exit="hidden"
						variants={localFieldsVariants}
					>
						{/* <div className="flex items-center w-full gap-4">
							<motion.div variants={itemVariants} className="w-[75%]">
								<input
									type="text"
									id="serviceAreaArr"
									{...register("serviceAreaArr")}
									placeholder="City, State, Radius (Miles)"
									className="w-full p-3 px-5 bg-[#F1F1F4] text-gray-700 rounded-full focus:outline-blue-500"
								/>
							</motion.div>
							<motion.div variants={itemVariants} className="w-[25%] h-full">
								<button
									type="button"
									onClick={handleAddServiceArea}
									className="bg-blue-500 text-white px-8 w-full py-3 rounded-full flex justify-center items-center hover:bg-blue-600 transition-colors"
								>
									Add
								</button>
							</motion.div>
						</div> */}

						{localAreas.length > 0 && (
							<motion.div variants={itemVariants} initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
								<h3 className="text-md mb-2">Added Service Areas</h3>
								<div className="space-y-2">
									{localAreas.map((area, index) => (
										<motion.div
											key={index}
											className="flex justify-between items-center bg-gray-100 p-3 rounded-full px-5"
											initial={{ opacity: 0, x: -20 }}
											animate={{ opacity: 1, x: 0 }}
											exit={{ opacity: 0, x: 20 }}
										>
											<div>
												<span>{area}</span>
											</div>
											{/* <button
												type="button"
												onClick={() => handleRemoveServiceArea(index)}
												className="text-red-500 hover:text-red-700"
											>
												<FaRegTrashAlt size={20} />
											</button> */}
										</motion.div>
									))}
								</div>
							</motion.div>
						)}
					</motion.div>
				)}
			</AnimatePresence>
		</motion.div>
	);
};
