const statusOptions = [
	{ value: "Active", label: "Active" },
	{ value: "Inactive", label: "Inactive" },
];

const stateOptions = [
	{ value: 'AL', label: 'AL' },
	{ value: 'AK', label: 'AK' },
	{ value: 'AZ', label: 'AZ' },
	{ value: 'AR', label: 'AR' },
	{ value: 'CA', label: 'CA' },
	{ value: 'CO', label: 'CO' },
	{ value: 'CT', label: 'CT' },
	{ value: 'DE', label: 'DE' },
	{ value: 'FL', label: 'FL' },
	{ value: 'GA', label: 'GA' },
	{ value: 'HI', label: 'HI' },
	{ value: 'ID', label: 'ID' },
	{ value: 'IL', label: 'IL' },
	{ value: 'IN', label: 'IN' },
	{ value: 'IA', label: 'IA' },
	{ value: 'KS', label: 'KS' },
	{ value: 'KY', label: 'KY' },
	{ value: 'LA', label: 'LA' },
	{ value: 'ME', label: 'ME' },
	{ value: 'MD', label: 'MD' },
	{ value: 'MA', label: 'MA' },
	{ value: 'MI', label: 'MI' },
	{ value: 'MN', label: 'MN' },
	{ value: 'MS', label: 'MS' },
	{ value: 'MO', label: 'MO' },
	{ value: 'MT', label: 'MT' },
	{ value: 'NE', label: 'NE' },
	{ value: 'NV', label: 'NV' },
	{ value: 'NH', label: 'NH' },
	{ value: 'NJ', label: 'NJ' },
	{ value: 'NM', label: 'NM' },
	{ value: 'NY', label: 'NY' },
	{ value: 'NC', label: 'NC' },
	{ value: 'ND', label: 'ND' },
	{ value: 'OH', label: 'OH' },
	{ value: 'OK', label: 'OK' },
	{ value: 'OR', label: 'OR' },
	{ value: 'PA', label: 'PA' },
	{ value: 'RI', label: 'RI' },
	{ value: 'SC', label: 'SC' },
	{ value: 'SD', label: 'SD' },
	{ value: 'TN', label: 'TN' },
	{ value: 'TX', label: 'TX' },
	{ value: 'UT', label: 'UT' },
	{ value: 'VT', label: 'VT' },
	{ value: 'VA', label: 'VA' },
	{ value: 'WA', label: 'WA' },
	{ value: 'WV', label: 'WV' },
	{ value: 'WI', label: 'WI' },
	{ value: 'WY', label: 'WY' }
];

import React, { useEffect, useState, useCallback } from "react";
import { useFieldArray, Controller, useForm } from "react-hook-form";
import Input from "../../../../../components/UI/Input";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "../../../../../components/UI/Button";
import CustomDatePicker from "../../../../../components/UI/CustomDatePicker";
import { createNewSatellite, GetSatelliteData, editSatelliteDetails, getDrivers } from "../../../../../utils/firebaseOperations";
import Checkbox from "../../../../../components/UI/Checkbox";
import Dropdown from "../../../../../components/UI/dropdowns/Dropdown";
import PhoneInput from "../../../../../components/UI/PhoneInput";
import FileUpload from "../../../../../components/UI/FileUpload";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../../../../../config/firebase";
import { BsPlusSquareFill } from "react-icons/bs";
import Textarea from "../../../../../components/UI/Textarea";
import CustomDropdown from "../satellite/CustomDropdown"
import MultiSelectRounded from "../../../../../components/UI/dropdowns/MultiSelectRounded";
import { useGeocoding } from "../../../../../hooks/treatmentGeocoding";
import SatellitePhone from "./SatellitePhone";


const AddSatellite = () => {
	const { id } = useParams();
	const [shouldFetch, setShouldFetch] = useState(false);
	const { geocodeAddress } = useGeocoding();

	const [billingData, setBillingData] = useState({
		name: "", street: "", suite: "", city: "", state: "", zip: "", email: "", phone: ""
	});

	const [serviceData, setServiceData] = useState({
		name: "", street: "", suite: "", city: "", state: "", zip: "", email: "", phone: ""
	});

	const [billingCopyChecked, setBillingCopyChecked] = useState(false);
	const [serviceCopyChecked, setServiceCopyChecked] = useState(false);

	const {
		control, handleSubmit, reset, setValue, formState: { errors }, watch,
	} = useForm({
		defaultValues: {
			satellite: [{
				name: "", phoneNumber: "", registration1: "", website: "", dot: "", businessHours: "",
				satelliteStatus: "", reviewLink: "", entityId: "", sic: "", cageCode: "", companyDisplayName: "",
				generalEmail: "", registration2: "", companyLogo: null, startOfFiscalYear: "", ein: "", epaId: "", sos: "",
				naicsCode: "", companySlogan: "", companyMissionStatement: "", companyVisionStatement: "", aboutEmail: "", assignedUser: [],

				serviceOffered: {
					medicalWasteTransporter: false, medicalWasteTransferStation: false, medicalWasteTreatmentFacilities: false,
					rollOffContainer: false, offsitePaperShredding: false, onsitePaperShredding: false, solidWaste: false,
					recycling: false,
				},
				octoConnect: { optIn: false, optOut: false },
				createLink: { treatmentFacility: false, transferStation: false },
				billingName: "", billingStreet: "", billingSuite: "", billingCity: "", billingState: "", billingZip: "",
				billingEmail: "", billingPhone: "", serviceName: "", serviceStreet: "", serviceSuite: "", serviceCity: "", serviceState: "",
				serviceZip: "", serviceEmail: "", servicePhone: "", dispatchEmail: "", dispatchPhone: "", octoNumber: "",
				satelliteServiceCoordinates: null,
			}],
		},
	});

	const { fields } = useFieldArray({ control, name: "satellite" });

	const navigate = useNavigate();

	const handlePreviousBtn = () => navigate("/admin/assets/satellite-location");

	const onSubmit = useCallback(async (data) => {
		try {
			// Service Coordinates
			const serviceAddressCoordinates = (
				data.satellite[0].satelliteServiceCoordinates?.lat &&
				data.satellite[0].satelliteServiceCoordinates?.lng
			)
				? data.satellite[0].satelliteServiceCoordinates
				: await geocodeAddress({
					street: data.satellite[0].serviceStreet,
					city: data.satellite[0].serviceCity,
					state: data.satellite[0].serviceState,
					zip: data.satellite[0].serviceZip
				});

			// Billing Coordinates
			const billingAddressCoordinates = (
				data.satellite[0].satelliteBillingCoordinates?.lat &&
				data.satellite[0].satelliteBillingCoordinates?.lng
			)
				? data.satellite[0].satelliteBillingCoordinates
				: await geocodeAddress({
					street: data.satellite[0].billingStreet,
					city: data.satellite[0].billingCity,
					state: data.satellite[0].billingState,
					zip: data.satellite[0].billingZip
				});

			// Fallback coordinates (e.g., San Diego coordinates)
			const finalServiceCoordinates = serviceAddressCoordinates || {
				lat: 32.7157,
				lng: -117.1611
			};

			const finalBillingCoordinates = billingAddressCoordinates || {
				lat: 32.7157,
				lng: -117.1611
			};

			// Prepare satellite data
			const satelliteToSave = {
				...data.satellite[0],
				satelliteServiceCoordinates: finalServiceCoordinates,
				satelliteBillingCoordinates: finalBillingCoordinates
			};

			let { companyLogo, ...toSave } = satelliteToSave;

			// Handle company logo upload 
			if (data.satellite[0].companyLogo) {
				const token = Math.random().toString(36).substring(2);
				const storageRef = ref(storage, `/satellite/companyLogo/${data.satellite[0].companyLogo.name}`);
				const snapshot = await uploadBytes(storageRef, data.satellite[0].companyLogo);
				const downloadURL = await getDownloadURL(snapshot.ref);

				toSave.companyLogo = {
					name: data.satellite[0].companyLogo.name,
					link: downloadURL,
					path: storageRef.fullPath,
					ref: "",
					token: token ?? "",
				};
			}

			// Copy service details to billing if checked
			if (serviceCopyChecked) {
				const { billingName, billingStreet, billingSuite, billingCity, billingState, billingZip, billingEmail, billingPhone } = toSave;
				toSave.serviceName = billingName;
				toSave.serviceStreet = billingStreet;
				toSave.serviceSuite = billingSuite;
				toSave.serviceCity = billingCity;
				toSave.serviceState = billingState;
				toSave.serviceZip = billingZip;
				toSave.serviceEmail = billingEmail;
				toSave.servicePhone = billingPhone;
			}

			// Copy billing details to service if checked
			if (billingCopyChecked) {
				const { serviceName, serviceStreet, serviceSuite, serviceCity, serviceState, serviceZip, serviceEmail, servicePhone } = toSave;
				toSave.billingName = serviceName;
				toSave.billingStreet = serviceStreet;
				toSave.billingSuite = serviceSuite;
				toSave.billingCity = serviceCity;
				toSave.billingState = serviceState;
				toSave.billingZip = serviceZip;
				toSave.billingEmail = serviceEmail;
				toSave.billingPhone = servicePhone;
			}

			// Handle form submission (create or update satellite)
			if (id) {
				await editSatelliteDetails(toSave, id);
				toast.success("Satellite Location details updated successfully!");
				navigate("/admin/assets/satellite-location");
			} else {
				await createNewSatellite(toSave);
				toast.success("Satellite Location added successfully!");
			}

			// Reset form and trigger data fetch
			reset();
			setShouldFetch(true);

		} catch (error) {
			console.error("Error saving Satellite Location:", error);
			toast.error("Failed to save Satellite Location.");
		}
	}, [id, reset, serviceCopyChecked, billingCopyChecked]);



	useEffect(() => {
		const fetchTransporterData = async () => {
			if (id) {
				try {
					const transporterData = await GetSatelliteData(id);
					if (transporterData) {
						reset({ satellite: [transporterData] });

						setBillingData({
							name: transporterData.billingName,
							street: transporterData.billingStreet,
							suite: transporterData.billingSuite,
							city: transporterData.billingCity,
							state: transporterData.billingState,
							zip: transporterData.billingZip,
							email: transporterData.billingEmail,
							phone: transporterData.billingPhone,
						});

						setServiceData({
							name: transporterData.serviceName,
							street: transporterData.serviceStreet,
							suite: transporterData.serviceSuite,
							city: transporterData.serviceCity,
							state: transporterData.serviceState,
							zip: transporterData.serviceZip,
							email: transporterData.serviceEmail,
							phone: transporterData.servicePhone,
						});
					}
				} catch (error) {
					console.error("Error fetching transporter data:", error);
					toast.error("Failed to fetch transporter data.");
				}
			} else {
				reset({ satellite: [{}] });
			}
		};

		fetchTransporterData();
	}, [id, reset, shouldFetch]);

	const handleBillingCopyChange = () => setBillingCopyChecked(!billingCopyChecked);
	const handleServiceCopyChange = () => setServiceCopyChecked(!serviceCopyChecked);

	const [DriverOptions, setDriverOptions] = useState([]);
	useEffect(() => {
		const getDriversdata = async () => {
			const dataWeGot = await getDrivers();
			const OptionsForDropdown = dataWeGot.map(({ firstName, lastName, middleInitial }) => ({
				label: `${firstName} ${middleInitial} ${lastName}`,
				value: `${firstName} ${middleInitial} ${lastName}`,
			}));
			setDriverOptions(OptionsForDropdown);
		};

		getDriversdata();
	}, []);

	const formatPhoneNumber = (input) => {
		const cleaned = input.replace(/\D/g, "");
		const limited = cleaned.slice(0, 10);

		const match = limited.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
		if (match) {
			const parts = [match[1], match[2], match[3]].filter(Boolean);
			return parts.length > 1
				? `(${parts[0]}) ${parts[1]}${parts[2] ? `-${parts[2]}` : ""}`
				: parts[0];
		}
		return input;
	};
	return (
		<>
			<div className="p-6">
				<div className="flex flex-col border border-cardBorderCol bg-white rounded-cardRadii h-full">
					<ToastContainer />
					<div className="my-4 ml-6 text-xl font-semibold">Satellite Location Profile</div>
					<div className="my-2 ml-6">General Information</div>
					<div className="ml-6 text-gray-500">(Information appears in public profile)</div>
					<div className="border-b mb-2 border-[#CCCCCC]"></div>
					<div className="ml-6 mr-6">
						{fields.map((field, index) => (
							<div className="flex gap-8 w-full" key={field.id}>
								<div className="w-1/2">
									<Controller
										name={`satellite.${index}.name`}
										control={control}
										rules={{ required: "Name is required" }}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="Company Name *" value={value} onChange={onChange} />
												{errors.satellite?.[index]?.name && <p className="text-red-500 text-sm mt-1">{errors.satellite[index].name.message}</p>}
											</div>
										)}
									/>
									<Controller
										name={`satellite.${index}.companyDisplayName`}
										control={control}
										rules={{ required: "Company Display Name is required" }}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="Company Display Name *" value={value} onChange={onChange} />
												{errors.satellite?.[index]?.companyDisplayName && <p className="text-red-500 text-sm mt-1">{errors.satellite[index].companyDisplayName.message}</p>}
											</div>
										)}
									/>
									{/* <Controller
										name={`satellite.${index}.octoNumber`}
										control={control}
										rules={{ required: "OCTO Number is required", pattern: { value: /^[0-9]+$/, message: "OCTO Number must be numeric" } }}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="OCTO Number" value={value} onChange={onChange} />
												{errors.satellite?.[index]?.octoNumber && <p className="text-red-500 text-sm mt-1">{errors.satellite[index].octoNumber.message}</p>}
											</div>
										)}
									/> */}

									<Controller
										name={`satellite.${index}.phoneNumber`}
										control={control}
										rules={{ required: "Phone is required" }}
										render={({ field: { onChange, value } }) => (
											<div>
												<PhoneInput label="General Phone " value={value} onChange={onChange} IsRequired={true} />
												{errors.satellite?.[index]?.phoneNumber && <p className="text-red-500 text-sm mt-1">{errors.satellite[index].phoneNumber.message}</p>}
											</div>
										)}
									/>

									<Controller
										name={`satellite.${index}.generalEmail`}
										control={control}
										rules={{
											required: "General Email is required",
											pattern: {
												value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
												message: "Please enter a valid email address",
											},
										}}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="General Email *" value={value} onChange={onChange} />
												{errors.satellite?.[index]?.generalEmail && (
													<p className="text-red-500 text-sm mt-1">
														{errors.satellite[index].generalEmail.message}
													</p>
												)}
											</div>
										)}
									/>
									<div className="mb-8">
										<Controller
											name={`satellite.${index}.website`}
											control={control}
											render={({ field: { onChange, value } }) => (
												<div>
													<Input
														value={value}
														onChange={onChange}
														type="url"
														label="Website URL "
														className="w-full px-2 py-1 text-sm"
														placeholder="https://example.com"
													/>
												</div>
											)}
										/>


									</div>
									<div className="flex items-center justify-between mt-3">
										<label htmlFor={"contractStartDate"} className="truncate text-inputLabel font-normal">
											Start of Fiscal Year
										</label>
										<div className="w-2/3">
											<Controller
												name={`satellite.${index}.startOfFiscalYear`}
												control={control}
												render={({ field: { value, onChange } }) => <CustomDatePicker selectedDate={value} setSelectedDate={onChange} label={"Contract Start Date"} />}
											/>
										</div>
									</div>

									<Controller
										name={`satellite.${index}.businessHours`}
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="Business Hours" value={value} onChange={onChange} />
											</div>
										)}
									/>
									<Controller
										name={`satellite.${index}.reviewLink`}
										control={control}
										rules={{
											pattern: {
												value: /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/,
												message: "Must be a valid link",
											},
										}}
										render={({ field: { value, onChange } }) => (
											<div>
												<Input
													value={value}
													onChange={onChange}
													type="url"
													label="Google Review Link"
													className="w-full px-2 py-1 text-sm"
													placeholder="https://example.com"
												/>
											</div>
										)}
									/>
									<div className="w-full">
										{/* File Upload */}
										<Controller
											name={`satellite.${index}.companyLogo`}
											control={control}
											render={({ field: { onChange, value } }) => (
												<div>
													<FileUpload
														control={control}
														errors={errors}
														label="Company Logo"
														name={`satellite.${index}.companyLogo`}
														existingFile={value}
														isRequired={false}
													/>
												</div>
											)}
										/>

									</div>

									<Controller
										name={`satellite.${index}.satelliteStatus`}
										control={control}
										rules={{ required: "Satellite Location Status is required" }}
										render={({ field: { value, onChange } }) => (
											<div>
												<Dropdown value={value} onChange={onChange} options={statusOptions} label="Satellite Location Status *" />
												{errors.satellite?.[index]?.satelliteStatus && <p className="text-red-500 text-sm mt-1">{errors.satellite[index].satelliteStatus.message}</p>}
											</div>
										)}
									/>
								</div>
								<div className="w-1/2">
									<Controller
										name={`satellite.${index}.registration1`}
										control={control}
										rules={{ required: "Registration Number 1 is required", pattern: { value: /^[0-9]+$/, message: "Registration #1 must be numeric" } }}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="Registration Number 1 *" value={value} onChange={onChange} />
												{errors.satellite?.[index]?.registration1 && <p className="text-red-500 text-sm mt-1">{errors.satellite[index].registration1.message}</p>}
											</div>
										)}
									/>

									<Controller
										name={`satellite.${index}.registration2`}
										control={control}
										//rules={{ required: "Registration Number 2 is required", pattern: { value: /^[0-9]+$/, message: "Registration #2 must be numeric" } }}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="Registration Number 2" value={value} onChange={onChange} />
												{errors.satellite?.[index]?.registration2 && <p className="text-red-500 text-sm mt-1">{errors.satellite[index].registration2.message}</p>}
											</div>
										)}
									/>
									<Controller
										name={`satellite.${index}.epaId`}
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="EPA ID Number" value={value} onChange={onChange} />
											</div>
										)}
									/>
									<Controller
										name={`satellite.${index}.dot`}
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="DOT Number" value={value} onChange={onChange} />
											</div>
										)}
									/>
									<Controller
										name={`satellite.${index}.ein`}
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="EIN" value={value} onChange={onChange} />
											</div>
										)}
									/>
									<Controller
										name={`satellite.${index}.entityId`}
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="Entity ID Number" value={value} onChange={onChange} />
											</div>
										)}
									/>
									<Controller
										name={`satellite.${index}.sos`}
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="SOS Number" value={value} onChange={onChange} />
											</div>
										)}
									/>

									<Controller
										name={`satellite.${index}.sic`}
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="SIC Code" value={value} onChange={onChange} />
											</div>
										)}
									/>
									<Controller
										name={`satellite.${index}.naicsCode`}
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="NAICS Code" value={value} onChange={onChange} />
											</div>
										)}
									/>
									<Controller
										name={`satellite.${index}.cageCode`}
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="CAGE Code" value={value} onChange={onChange} />
											</div>
										)}
									/>
								</div>
							</div>
						))}
					</div>

					<div className="flex gap-8 w-full mt-4 p-6">
						{/* Billing Department */}
						<div className="w-1/2">
							<div className="flex justify-between">
								<div className="pb-4">Billing Department</div>
								<div className="flex">
									<div className="pr-2">
										<Checkbox
											isChecked={billingCopyChecked}
											setIsChecked={handleBillingCopyChange}
										/>
									</div>
									<div className="text-gray-600">Copy from Service</div>
								</div>
							</div>
							<div className="border-t"></div>
							
							<Controller
								name={`satellite.${fields.length - 1}.billingName`}
								control={control}
								render={({ field: { onChange, value } }) => (
									<div>
										<Input
											label="Name"
											type="Text"
											value={billingCopyChecked ? serviceData.name : value}
											onChange={(e) => {
												onChange(e);
												setBillingData({ ...billingData, name: e.target.value });
											}}
										/>
									</div>
								)}
							/>
							{/* Repeat for other Billing fields */}
							<Controller
								name={`satellite.${fields.length - 1}.billingStreet`}
								control={control}
								render={({ field: { onChange, value } }) => (
									<div>
										<Input
											label="Street"
											type="Text"
											value={billingCopyChecked ? serviceData.street : value}
											onChange={(e) => {
												onChange(e);
												setBillingData({ ...billingData, street: e.target.value });
											}}
										/>
									</div>
								)}
							/>
							<Controller
								name={`satellite.${fields.length - 1}.billingSuite`}
								control={control}
								render={({ field: { onChange, value } }) => (
									<div>
										<Input
											label="Suite"
											type="Text"
											value={billingCopyChecked ? serviceData.suite : value}
											onChange={(e) => {
												onChange(e);
												setBillingData({ ...billingData, suite: e.target.value });
											}}
										/>
									</div>
								)}
							/>
							<Controller
								name={`satellite.${fields.length - 1}.billingCity`}
								control={control}
								render={({ field: { onChange, value } }) => (
									<div>
										<Input
											label="City"
											type="Text"
											value={billingCopyChecked ? serviceData.city : value}
											onChange={(e) => {
												onChange(e);
												setBillingData({ ...billingData, city: e.target.value });
											}}
										/>
									</div>
								)}
							/>
							<Controller
								name={`satellite.${fields.length - 1}.billingState`}
								control={control}
								render={({ field: { onChange, value } }) => (
									<div>
										<CustomDropdown
											label="State"
											value={billingCopyChecked ? serviceData.state : value || ""}
											onChange={(selectedValue) => {
												if (selectedValue !== undefined && selectedValue !== null) {
													onChange(selectedValue);
													setBillingData((prevData) => ({
														...prevData,
														state: selectedValue,
													}));
												}
											}}
											options={stateOptions}
										/>
									</div>
								)}
							/>

							<Controller
								name={`satellite.${fields.length - 1}.billingZip`}
								control={control}
								render={({ field: { onChange, value } }) => (
									<div>
										<Input
											label="Zip"
											type="Text"
											value={billingCopyChecked ? serviceData.zip : value}
											onChange={(e) => {
												onChange(e);
												setBillingData({ ...billingData, zip: e.target.value });
											}}
										/>
									</div>
								)}
							/>
							<Controller
								name={`satellite.${fields.length - 1}.billingEmail`}
								control={control}
								rules={{
									pattern: {
										value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
										message: 'Please enter a valid email address'
									}
								}}
								render={({ field: { onChange, value }, fieldState: { error } }) => (
									<div>
										<Input
											label="Email"
											type="text"
											value={billingCopyChecked ? serviceData.email : value}
											onChange={(e) => {
												onChange(e);
												setBillingData({ ...billingData, email: e.target.value });
											}}
											error={error?.message}
										/>
										{error?.message && <p style={{ color: 'red' }}>{error.message}</p>}
									</div>
								)}
							/>

							<Controller
								name={`satellite.${fields.length - 1}.billingPhone`}
								control={control}
								render={({ field: { onChange, value } }) => (
									<div>
										<SatellitePhone
											label="Phone"
											value={billingCopyChecked ? serviceData.phone : value}
											onChange={(e) => {
												const formattedPhone = formatPhoneNumber(e.target.value);
												onChange(formattedPhone);
												setBillingData({ ...billingData, phone: formattedPhone });
											}}
										/>
									</div>
								)}
							/>
						</div>

						{/* Service Department */}
						<div className="w-1/2">
							<div className="flex justify-between">
								<div className="pb-4">Service Department</div>
								<div className="flex">
									<div className="pr-2">
										<Checkbox
											isChecked={serviceCopyChecked}
											setIsChecked={handleServiceCopyChange}
										/>
									</div>
									<div className="text-gray-600">Copy from Billing</div>
								</div>
							</div>
							<div className="border-t"></div>
							<Controller
								name={`satellite.${fields.length - 1}.serviceName`}
								control={control}
								render={({ field: { onChange, value } }) => (
									<div>
										<Input
											label="Name"
											type="Text"
											value={serviceCopyChecked ? billingData.name : value}
											onChange={(e) => {
												onChange(e);
												setServiceData({ ...serviceData, name: e.target.value });
											}}
										/>
									</div>
								)}
							/>
							{/* Repeat for other Service fields */}
							<Controller
								name={`satellite.${fields.length - 1}.serviceStreet`}
								control={control}
								render={({ field: { onChange, value } }) => (
									<div>
										<Input
											label="Street"
											type="Text"
											value={serviceCopyChecked ? billingData.street : value}
											onChange={(e) => {
												onChange(e);
												setServiceData({ ...serviceData, street: e.target.value });
											}}
										/>
									</div>
								)}
							/>
							<Controller
								name={`satellite.${fields.length - 1}.serviceSuite`}
								control={control}
								render={({ field: { onChange, value } }) => (
									<div>
										<Input
											label="Suite"
											type="Text"
											value={serviceCopyChecked ? billingData.suite : value}
											onChange={(e) => {
												onChange(e);
												setServiceData({ ...serviceData, suite: e.target.value });
											}}
										/>
									</div>
								)}
							/>

							<Controller
								name={`satellite.${fields.length - 1}.serviceCity`}
								control={control}
								render={({ field: { onChange, value } }) => (
									<div>
										<Input
											label="City"
											type="Text"
											value={serviceCopyChecked ? billingData.city : value}
											onChange={(e) => {
												onChange(e);
												setServiceData({ ...serviceData, city: e.target.value });
											}}
										/>
									</div>
								)}
							/>
							<Controller
								name={`satellite.${fields.length - 1}.serviceState`}
								control={control}
								render={({ field: { onChange, value } }) => (
									<div>
										<CustomDropdown
											label="State"
											value={serviceCopyChecked ? billingData.state : value || ""}
											onChange={(selectedValue) => {
												onChange(selectedValue);
												setServiceData((prevState) => ({
													...prevState,
													state: selectedValue,
												}));
											}}
											options={stateOptions}
										/>
									</div>
								)}
							/>

							<Controller
								name={`satellite.${fields.length - 1}.serviceZip`}
								control={control}
								render={({ field: { onChange, value } }) => (
									<div>
										<Input
											label="Zip"
											type="Text"
											value={serviceCopyChecked ? billingData.zip : value}
											onChange={(e) => {
												onChange(e);
												setServiceData({ ...serviceData, zip: e.target.value });
											}}
										/>
									</div>
								)}
							/>
							<Controller
								name={`satellite.${fields.length - 1}.serviceEmail`}
								control={control}
								rules={{
									pattern: {
										value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
										message: 'Please enter a valid email address'
									}
								}}
								render={({ field: { onChange, value }, fieldState: { error } }) => (
									<div>
										<Input
											label="Email"
											type="text"
											value={serviceCopyChecked ? billingData.email : value}
											onChange={(e) => {
												onChange(e);
												setServiceData({ ...serviceData, email: e.target.value });
											}}
											error={error?.message}
										/>
										{error?.message && <p style={{ color: 'red' }}>{error.message}</p>}
									</div>
								)}
							/>

							<Controller
								name={`satellite.${fields.length - 1}.servicePhone`}
								control={control}
								render={({ field: { onChange, value } }) => (
									<SatellitePhone
										label="Phone"
										value={serviceCopyChecked ? billingData.phone : value}
										onChange={(e) => {
											const formattedPhone = (e.target.value);
											onChange(formattedPhone);
											setServiceData({ ...serviceData, phone: formattedPhone });
										}}
									/>
								)}
							/>
						</div>
					</div>

					<div className="ml-6">
						<div className="mt-12 mb-4">Services Offered</div>
						<div className="border-t"></div>
						<div className="grid gap-5 grid-cols-3 py-4 mt-2">
							{Object.keys(control._formValues.satellite[0].serviceOffered).map((key) => (
								<Controller
									key={key}
									name={`satellite.${fields.length - 1}.serviceOffered.${key}`}
									control={control}
									render={({ field: { value, onChange } }) => (
										<Checkbox
											label={key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, " $1")}
											isChecked={value}
											setIsChecked={onChange}
										/>
									)}
								/>
							))}
						</div>

						<div className="border-t"></div>
						<div className="grid gap-5 py-4 mt-2">
							<Controller
								name={`satellite.${fields.length - 1}.createLink.treatmentFacility`}
								control={control}
								render={({ field: { value, onChange } }) => <Checkbox label="Create / Link To Treatment Facility" isChecked={value} setIsChecked={onChange} />}
							/>

							<Controller
								name={`satellite.${fields.length - 1}.createLink.transferStation`}
								control={control}
								render={({ field: { value, onChange } }) => <Checkbox label="Create / Link To Transfer Station" isChecked={value} setIsChecked={onChange} />}
							/>
						</div>
					</div>

					{/* <div className="mt-8 ml-6 mb-2 font-normal">About</div>
					<div className="border-t"></div>
					<div className="ml-6 mr-6">
						<div className="flex gap-8 w-full mt-2">
							<div className="w-1/2">
								<Controller
									name={`satellite.${fields.length - 1}.companySlogan`}
									control={control}
									rules={{ required: "Company Slogan is required" }}
									render={({ field: { onChange, value } }) => (
										<div>
											<Textarea label="Company Slogan" type="Text" value={value} onChange={onChange} />
											{errors.satellite?.[fields.length - 1]?.companySlogan && (
												<p className="text-red-500 text-sm mt-1">{errors.satellite[fields.length - 1].companySlogan.message}</p>
											)}
										</div>
									)}
								/>
							</div>
							<div className="w-1/2">
								<Controller
									name={`satellite.${fields.length - 1}.companyMissionStatement`}
									control={control}
									rules={{ required: "Company Mission Statement is required" }}
									render={({ field: { onChange, value } }) => (
										<div>
											<Textarea label="Company Mission Statement" type="Text" value={value} onChange={onChange} />
											{errors.satellite?.[fields.length - 1]?.companyMissionStatement && (
												<p className="text-red-500 text-sm mt-1">{errors.satellite[fields.length - 1].companyMissionStatement.message}</p>
											)}
										</div>
									)}
								/>
							</div>
						</div>

						<div className="flex gap-8 w-full ">
							<div className="w-1/2">
								<Controller
									name={`satellite.${fields.length - 1}.companyVisionStatement`}
									control={control}
									rules={{ required: "Company Vision Statement is required" }}
									render={({ field: { onChange, value } }) => (
										<div>
											<Input label="Company Vision Statement" type="Text" value={value} onChange={onChange} />
											{errors.satellite?.[fields.length - 1]?.companyVisionStatement && (
												<p className="text-red-500 text-sm mt-1">{errors.satellite[fields.length - 1].companyVisionStatement.message}</p>
											)}
										</div>
									)}
								/>
							</div>
						</div>
					</div> */}

					<div className="p-4">Dispatch Department</div>
					<div className="border-t"></div>
					<div className="flex gap-6 w-full px-4">
						<div className="w-1/2">
							<Controller
								name={`satellite.${fields.length - 1}.dispatchEmail`}
								control={control}
								rules={{
									required: "Email is required",
									pattern: {
										value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
										message: 'Please enter a valid email address'
									}
								}}
								render={({ field: { onChange, value }, fieldState: { error } }) => (
									<div>
										<Input
											label="Email *"
											type="text"
											value={value}
											onChange={onChange}
										/>
										{error?.message && (
											<p className="text-red-500 text-sm mt-1">{error.message}</p>
										)}
									</div>
								)}
							/>

						</div>
						<div className="w-1/2">
							<Controller
								name={`satellite.${fields.length - 1}.dispatchPhone`}
								control={control}
								rules={{ required: "Phone is required" }}
								render={({ field: { onChange, value } }) => (
									<div>
										<PhoneInput label="Phone" value={value} onChange={onChange} IsRequired={true} />
										{errors.satellite?.[fields.length - 1]?.dispatchPhone && (
											<p className="text-red-500 text-sm mt-1">{errors.satellite[fields.length - 1].dispatchPhone.message}</p>
										)}
									</div>
								)}
							/>
						</div>
					</div>
					<div className="p-4 mt-6">User Information</div>
					<div className="border-t"></div>
					<div>
						{fields.map((field, index) => (
							<div className="flex justify-between" key={field.id}>
								<div className="w-1/2 pl-4">
									<Controller
										name={`satellite.${index}.assignedUser`}
										control={control}
										rules={{ required: "Assigned User is required" }}
										render={({ field }) => (
											<div>
												<MultiSelectRounded
													options={DriverOptions}
													value={field.value}
													onChange={field.onChange}
													label="Assigned User *"
													className="text-sm"
												/>

												{errors.satellite?.[index]?.assignedUser && (
													<p className="text-red-500 text-sm mt-1">
														{errors.satellite[index].assignedUser.message}
													</p>
												)}
											</div>
										)}
									/>

								</div>
							</div>
						))}
					</div>

					{/* <div className="p-4 mt-6">OCTO Connect: Subcontractor Partnership Program</div>
					<div className="border-t"></div>
					<div className="p-4 w-10/12  text-gray-600">
						By opting into the OCTO Connect Subcontractor Partnership Program, you agree to allow contractors to potentially connect with you based on the proximity of their generator s
						address to your service radius. When a contractor expresses interest in partnering with you, a notification will be sent to inform you of the potential partnership.
					</div>

					<div className="flex py-4 px-4 gap-6">
						<Controller
							name={`satellite.${fields.length - 1}.octoConnect.optIn`}
							control={control}
							render={({ field: { value, onChange } }) => <Checkbox label="Opt In" isChecked={value} setIsChecked={onChange} />}
						/>

						<Controller
							name={`satellite.${fields.length - 1}.octoConnect.optOut`}
							control={control}
							render={({ field: { value, onChange } }) => <Checkbox label="Opt Out" isChecked={value} setIsChecked={onChange} />}
						/>
					</div>

					<div className="flex items-center justify-end pr-4 mt-4">
						<BsPlusSquareFill style={{ color: '#1493d7', marginRight: '8px', fontSize: '24px' }} />
						Add Satellite Location
					</div> */}
					<div className="flex justify-end gap-8 py-16 pr-6">
						<Button btnStyle="form_nav_secondary" text={"View List"} onClick={handlePreviousBtn} />
						<Button btnStyle="form_nav_primary" text={"Save"} onClick={handleSubmit(onSubmit)} />
					</div>
				</div>
			</div>
		</>
	);
};

export default AddSatellite;
