import PropTypes from "prop-types";
import { useState, useEffect, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import ShimmerLoader from "../../../../../../components/UI/loaders/ShimmerLoader";
import { horizontalScrollHandler, showErrorToastMessage } from "../../../../../../utils/helpers";
import { getRoutes } from "../../../../../../utils/firebaseOperations";
import Tooltip from "../../../../../../components/UI/Tooltip";
import MultiselectDropdown from "../../../../../../components/UI/dropdowns/MultiselectDropdown";
import { statusOptions } from "../../../../../../utils/routeConstants";

const ManageRoutes = () => {
	const [selectedDrivers, setSelectedDrivers] = useState([]);
	const [selectAll, setSelectAll] = useState(false);
	const [indeterminate, setIndeterminate] = useState(false);
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [selectedStatuses, setSelectedStatuses] = useState(["all", ...statusOptions.map((el) => el.value)]);
	const navigate = useNavigate();
	const filteredData = useMemo(() => {
		return data.filter((route) => {
			console.log({ route });
			console.log({ selectedStatuses });
			if (selectedStatuses.includes(route.status)) {
				return true;
			}
			return false;
		});
	}, [data, selectedStatuses]);
	useEffect(() => {
		let unsubscribe = null;
		setLoading(true);
		try {
			unsubscribe = getRoutes(setData);
		} catch (err) {
			console.log(err);
			showErrorToastMessage("Unable to get routes.");
		} finally {
			setLoading(false);
		}

		return () => {
			if (unsubscribe) {
				unsubscribe();
			}
		};
	}, []);

	useEffect(() => {
		if (selectedDrivers.length === 0) {
			setSelectAll(false);
			setIndeterminate(false);
		} else if (selectedDrivers.length === filteredData.length) {
			setSelectAll(true);
			setIndeterminate(false);
		} else {
			setSelectAll(false);
			setIndeterminate(true);
		}
	}, [selectedDrivers, filteredData]);

	const handleSelectAllChange = () => {
		const newSelectAll = !selectAll;
		setSelectAll(newSelectAll);
		setIndeterminate(false);
		if (newSelectAll) {
			setSelectedDrivers([...Array(filteredData.length).keys()]);
		} else {
			setSelectedDrivers([]);
		}
	};

	const handleCheckboxChange = (index) => {
		setSelectedDrivers((prev) => {
			if (prev.includes(index)) {
				return prev.filter((i) => i !== index);
			} else {
				return [...prev, index];
			}
		});
	};

	const handleRowClick = (route) => {
		navigate(`${route.id}/edit`);
	};

	const newRouteClickHandler = () => {
		navigate("/admin/assets/routes/add");
	};
	const statusFilterChangeHandler = (selected) => {
		console.log({ selected });

		if (selected.includes("All")) {
			setSelectedStatuses(["all", ...statusOptions.map((el) => el.value)]);
		} else {
			setSelectedStatuses([...selected]);
		}
	};
	return (
		<div className="p-6">
			<div className="main-container flex flex-col border border-cardBorderCol bg-white rounded-cardRadii h-full">
				<div className="text-lg px-8 py-4 flex justify-between items-center">
					<div>Routes List</div>
					<div className=" px-2">
						<MultiselectDropdown
							buttonText="Route Status"
							options={[{ label: "All", value: "all" }, ...statusOptions]}
							selectedOptions={selectedStatuses}
							onSelectedOptionsChange={statusFilterChangeHandler}
						/>
					</div>
				</div>
				<div
					className="overflow-x-scroll overflow-y-hidden"
					style={{
						height: `calc(100% - 10px)`,
					}}
				>
					<div className="bg-[#E5F2FF] flex font-medium min-w-fit py-4">
						<div className="w-8 mx-6 flex justify-center items-center hover:cursor-pointer">
							<input
								type="checkbox"
								className="w-4 h-4 bg-white"
								checked={selectAll}
								ref={(input) => input && (input.indeterminate = indeterminate)}
								onChange={handleSelectAllChange}
							/>
						</div>
						<div className="flex items-center gap-2">
							<div className="truncate w-60">Name</div>
							<div className="truncate w-32">Route Id #</div>
							<div className="truncate w-80">Start Location</div>
							<div className="truncate w-80">End Location</div>
							<div className="truncate w-32">Status</div>
							<div className="truncate w-60">Vehicle Cost Per Mile</div>
							{/* <div className="truncate w-32">Duty Status</div> */}
						</div>
					</div>

					{loading ? (
						<ShimmerLoader cols={6} rows={20} />
					) : (
						<div style={{ height: `calc(100% - 60px)` }} className="min-w-fit overflow-y-scroll overflow-x-hidden">
							{filteredData.length > 0 ? (
								filteredData.map((route, index) => (
									<RouteItem
										key={index}
										route={route}
										index={index}
										selectedDrivers={selectedDrivers}
										handleCheckboxChange={handleCheckboxChange}
										handleRowClick={handleRowClick}
									/>
								))
							) : (
								<div className="flex h-full text-cardTextGray text-xl justify-center items-center">
									<p>No Route found.</p>
								</div>
							)}
						</div>
					)}
				</div>
			</div>

			{/* Add New Driver Button - Hidden when checkboxes are selected */}
			{selectedDrivers.length === 0 && (
				<div className="fixed bottom-8 right-8">
					<button
						onClick={newRouteClickHandler}
						className="bg-lightBlue hover:bg-cardTextBlue text-white rounded-full p-4 shadow-lg transition-all duration-300 flex items-center space-x-2"
					>
						<svg
							className="w-6 h-6"
							fill="none"
							stroke="currentColor"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
						</svg>
						<span>Add New Route</span>
					</button>
				</div>
			)}
		</div>
	);
};

const RouteItem = ({ route, index, selectedDrivers, handleCheckboxChange, handleRowClick }) => {
	console.log({ "Route Item": route });

	const [activeTooltip, setActiveTooltip] = useState(null);
	const [tooltipPosition, setTooltipPosition] = useState({ left: 0, top: 0 });
	const [transformOrigin, setTransformOrigin] = useState("top left");
	const timeoutRef = useRef(null);
	const tooltipRef = useRef(null);

	const handleMouseEnter = (driverId, event) => {
		clearTimeout(timeoutRef.current);
		timeoutRef.current = setTimeout(() => {
			setActiveTooltip(driverId);
			updateTooltipPosition(event);
		}, 1000);
	};

	const handleMouseLeave = (event) => {
		clearTimeout(timeoutRef.current);
		if (
			!tooltipRef.current ||
			!event.relatedTarget ||
			!(event.relatedTarget instanceof Node) ||
			!tooltipRef.current.contains(event.relatedTarget)
		) {
			setActiveTooltip(null);
		}
	};

	const updateTooltipPosition = (event) => {
		const viewportWidth = window.innerWidth;
		const cursorX = event.clientX;
		const cursorY = event.clientY;
		const tooltipWidth = 500;
		const tooltipHeight = 300;
		const offset = 10;
		let left, top;

		if (cursorX > viewportWidth / 2) {
			left = cursorX - tooltipWidth - offset;
			setTransformOrigin("top right");
		} else {
			left = cursorX + offset;
			setTransformOrigin("top left");
		}

		top = cursorY - offset;
		left = Math.max(0, Math.min(left, viewportWidth - tooltipWidth));
		top = Math.max(0, Math.min(top, window.innerHeight - tooltipHeight));

		setTooltipPosition({ left, top });
	};

	const handlePhoneClick = (event, phone) => {
		event.stopPropagation();
		window.location.href = `tel:${phone}`;
	};

	useEffect(() => {
		return () => clearTimeout(timeoutRef.current);
	}, []);

	return (
		<div
			className="flex w-full items-center transition-colors duration-300 border-b border-gray-300 hover:bg-cardTextGray hover:bg-opacity-20"
			style={{ height: "45px" }}
		>
			<div className="min-w-8 mx-6 flex justify-center hover:cursor-pointer">
				<input
					type="checkbox"
					className="w-4 h-4 bg-white"
					checked={selectedDrivers.includes(index)}
					onChange={() => handleCheckboxChange(index)}
				/>
			</div>
			<div
				className="w-full flex items-center cursor-pointer gap-2"
				onClick={() => handleRowClick(route)}
				onMouseEnter={(e) => handleMouseEnter(route.id, e)}
				onMouseLeave={handleMouseLeave}
			>
				<div className="truncate w-60">{`${route?.name ?? "--"} `}</div>
				<div className="truncate w-32">{route.id?.slice(0, 6) || "--"}</div>
				<div className="w-80 truncate">
					<span

					// onClick={(e) => route.phoneNumber && handlePhoneClick(e, route.phoneNumber)}
					>
						{route?.startLocation?.name ?? "--"} [{route?.startLocation?.type ?? "--"}]
					</span>
				</div>
				<div className="w-80 truncate">
					<span

					// onClick={(e) => route.phoneNumber && handlePhoneClick(e, route.phoneNumber)}
					>
						{route?.endLocation?.name ?? "--"} [{route?.endLocation?.type ?? "--"}]
					</span>
				</div>
				<div className="truncate w-32">
					{statusOptions[statusOptions.findIndex((el) => el.value === route.status)].label || "--"}
				</div>
				<div className="truncate w-60">{route.truckCostPerMile || "--"}</div>
			</div>
			{/* <AnimatePresence>
        {activeTooltip === (route.OctoDriverId || route.id) && (
          <motion.div
            ref={tooltipRef}
            className="fixed bg-white rounded-cardRadii min-w-[500px] shadow-2xl border border-cardTextGray border-opacity-10 p-6 z-10"
            style={{
              left: `${tooltipPosition.left}px`,
              top: `${tooltipPosition.top}px`,
              width: "300px",
            }}
            initial={{ opacity: 0, scale: 0.8, transformOrigin }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            transition={{ duration: 0.2 }}
            onMouseEnter={() => setActiveTooltip(route.OctoDriverId || route.id)}
            onMouseLeave={handleMouseLeave}
          >
            <ToolTipContent driver={route} />
          </motion.div>
        )}
      </AnimatePresence> */}
		</div>
	);
};

RouteItem.propTypes = {
	handleCheckboxChange: PropTypes.func,
	handleRowClick: PropTypes.func,
	index: PropTypes.any,
	route: PropTypes.shape({
		endLocation: PropTypes.shape({
			formattedAddress: PropTypes.any,
			formatted_address: PropTypes.any,
		}),
		id: PropTypes.shape({
			slice: PropTypes.func,
		}),
		name: PropTypes.string,
		startLocation: PropTypes.shape({
			formattedAddress: PropTypes.any,
			formatted_address: PropTypes.any,
		}),
		status: PropTypes.any,
		truckCostPerMile: PropTypes.string,
	}),
	selectedDrivers: PropTypes.shape({
		includes: PropTypes.func,
	}),
};

const ToolTipContent = ({ driver }) => {
	const details = [
		{ label: "Name", value: `${driver.firstName} ${driver.middleInitial || ""} ${driver.lastName}` },
		{ label: "Phone", value: driver.phoneNumber || "--" },
		{ label: "Email", value: driver.email || "--" },
		{ label: "Address", value: driver.driverLocation || "--" },
		{ label: "ID", value: driver.OctoDriverId || driver.id || "--" },
		{ label: "Status", value: driver.driverStatus || "Active" },
		{ label: "License", value: driver.driverLicenseInfo || "--" },
	];

	return (
		<div className="w-full">
			{details.map((item) => (
				<div key={item.label} className="w-full flex">
					<div className="w-1/2 p-2 text-cardTextGray truncate">{item.label}</div>
					<div className="w-1/2 p-2 truncate">{item.value}</div>
				</div>
			))}
		</div>
	);
};
const StepsComp = ({ steps }) => {
	const stepsList = [
		{ key: "personalInfo", label: "Personal Info" },
		{ key: "license", label: "License" },
		{ key: "training", label: "Training" },
		{ key: "documents", label: "Documents" },
		{ key: "vehicle", label: "Vehicle" },
	];

	return (
		<div className="flex h-11 items-center justify-center gap-1">
			{stepsList.map((step) => (
				<Tooltip key={step.key} text={step.label}>
					<span className={`h-3.5 w-1.5 rounded-full ${steps[step.key] ? "bg-lightBlue" : "bg-cardTextGray"}`}></span>
				</Tooltip>
			))}
		</div>
	);
};

export default ManageRoutes;
