import React from "react";
import Button from "../../../../../../../../../components/UI/Button";

const FooterActionBtns = ({ nextBtnText = "Save & Next", onClickBack, disabled = false }) => {
	return (
		<div className="flex justify-end gap-8 py-4">
			<Button btnStyle="form_nav_secondary" text="Back" onClick={onClickBack} />
			<Button btnStyle="form_nav_primary" text={nextBtnText} type="submit" disabled={disabled} />
		</div>
	);
};

export default FooterActionBtns;
