import { useState } from "react";
import { useNavigate } from "react-router-dom";
import MapWithMarkers from "../../../../../components/maps/MapWithMarkers";

const WeighingContainer = () => {
	const [isScrollable, setIsScrollable] = useState(false);
	const [hoveredRow, setHoveredRow] = useState(null);
	const navigate = useNavigate();

	// Dummy data
	const dummyData = Array.from({ length: 3 }, (_, index) => {
		const date = new Date(2024, 8, index + 1);
		return {
			id: index + 1,
			date: date.toLocaleDateString(),
			time: date.toLocaleTimeString(),
			Customer: `${index + 1} Customer Name`,
			itemsDetails: `#12345126789 ${index}`,
			email: `user${index + 1}@example.com details`,
			address: `${index + 1} Address details details`,
			billed: false,
			paid: false,
		};
	});

	const [data, setData] = useState(dummyData);

	const handleNextClick = () => {
		console.log("Next button clicked");
		navigate("gate-waste-finder");
	};

	const handlePerviousbtn = () => {
		console.log("Save button clicked"); // Debug log
		navigate(-1); // Navigate back to the previous page
	};

	return (
		<div className="main-container flex flex-col border border-cardBorderCol bg-white rounded-cardRadii h-full">
			<div className="ml-4 mt-4">
				<div className="font-semibold text-2xl">Generator Name:</div>
				<div>OCTO Number:</div>
				<div>Customer Since:</div>
				<div>Service Address:</div>
			</div>
			<div className="border-b border-gray-500 mt-4"></div>
			<div className="text-lg font-normal ml-4 mt-4 text-black">Scan Log</div>

			<div className="mt-6">
				<div className="flex flex-col md:flex-row justify-between text-sm font-medium pl-8 py-4 bg-indigo-50">
					<div className="w-full md:w-1/5 text-center">Date and Time</div>
					<div className="w-full md:w-1/5 text-center">Scan Container</div>
					<div className="w-full md:w-1/5 text-center">Weight</div>
					<div className="w-full md:w-1/5 text-center">Manifest Number</div>
					<div className="w-full md:w-1/5 text-center">Company</div>
				</div>
			</div>

			<div className={`overflow-y-auto ${isScrollable ? "shadow-inner" : ""}`} onScroll={(e) => setIsScrollable(e.target.scrollTop > 0)}>
				{data.map((item, index) => (
					<div
						key={index}
						className={`flex flex-col md:flex-row items-start py-2 border-b pl-8 border-gray-300 ${hoveredRow === index ? "bg-gray-200" : ""}`}
						onMouseEnter={() => setHoveredRow(index)}
						onMouseLeave={() => setHoveredRow(null)}
						style={{ minHeight: "60px" }}
					>
						<div className="w-full md:w-1/5 text-sm font-medium text-center">
							<div>{item.date}</div>
							<div className="text-sm font-medium">{item.time}</div>
						</div>
						<div className="w-full md:w-1/5 text-sm font-medium text-center pt-4">3</div>
						<div className="w-full md:w-1/5 text-sm font-medium text-center pt-4">
							<span className="font-medium">25</span>
						</div>
						<div className="w-full md:w-1/5 text-sm font-medium text-center pt-4">
							<span className="font-medium">123456789</span>
						</div>
						<div className="w-full md:w-1/5 text-sm font-medium text-center pt-4">
							<span className="font-medium">xyz</span>
						</div>
					</div>
				))}
			</div>

			{/* Buttons Section */}
			<div className="flex justify-end p-4 mb-12 mt-10 space-x-4">
				<button
					onClick={handlePerviousbtn}
					style={{ width: "184px", height: "44px" }}
					className="bg-[#007AFF] text-white px-4 py-2 rounded-full border border-[#666666] hover:bg-white hover:text-black hover:border-[#666666] transition-opacity duration-300"
				>
					Previous
				</button>
				<button
					onClick={handleNextClick}
					style={{ width: "184px", height: "44px" }}
					className="bg-[#007AFF] text-white px-4 py-2 rounded-full border border-[#666666] hover:bg-white hover:text-black hover:border-[#666666] transition-opacity duration-300"
				>
					Next
				</button>
			</div>
		</div>
	);
};

export default WeighingContainer;
