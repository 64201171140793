const stateOptions = [
	{ value: 'AL', label: 'AL' },
	{ value: 'AK', label: 'AK' },
	{ value: 'AZ', label: 'AZ' },
	{ value: 'AR', label: 'AR' },
	{ value: 'CA', label: 'CA' },
	{ value: 'CO', label: 'CO' },
	{ value: 'CT', label: 'CT' },
	{ value: 'DE', label: 'DE' },
	{ value: 'FL', label: 'FL' },
	{ value: 'GA', label: 'GA' },
	{ value: 'HI', label: 'HI' },
	{ value: 'ID', label: 'ID' },
	{ value: 'IL', label: 'IL' },
	{ value: 'IN', label: 'IN' },
	{ value: 'IA', label: 'IA' },
	{ value: 'KS', label: 'KS' },
	{ value: 'KY', label: 'KY' },
	{ value: 'LA', label: 'LA' },
	{ value: 'ME', label: 'ME' },
	{ value: 'MD', label: 'MD' },
	{ value: 'MA', label: 'MA' },
	{ value: 'MI', label: 'MI' },
	{ value: 'MN', label: 'MN' },
	{ value: 'MS', label: 'MS' },
	{ value: 'MO', label: 'MO' },
	{ value: 'MT', label: 'MT' },
	{ value: 'NE', label: 'NE' },
	{ value: 'NV', label: 'NV' },
	{ value: 'NH', label: 'NH' },
	{ value: 'NJ', label: 'NJ' },
	{ value: 'NM', label: 'NM' },
	{ value: 'NY', label: 'NY' },
	{ value: 'NC', label: 'NC' },
	{ value: 'ND', label: 'ND' },
	{ value: 'OH', label: 'OH' },
	{ value: 'OK', label: 'OK' },
	{ value: 'OR', label: 'OR' },
	{ value: 'PA', label: 'PA' },
	{ value: 'RI', label: 'RI' },
	{ value: 'SC', label: 'SC' },
	{ value: 'SD', label: 'SD' },
	{ value: 'TN', label: 'TN' },
	{ value: 'TX', label: 'TX' },
	{ value: 'UT', label: 'UT' },
	{ value: 'VT', label: 'VT' },
	{ value: 'VA', label: 'VA' },
	{ value: 'WA', label: 'WA' },
	{ value: 'WV', label: 'WV' },
	{ value: 'WI', label: 'WI' },
	{ value: 'WY', label: 'WY' }
];

const statusOptions = [
	{ value: "Active", label: "Active" },
	{ value: "Inactive", label: "Inactive" },
];

import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../../config/firebase";
import { createNewTransport } from "../../utils/firebaseOperations";
import { useGeocoding } from "../../hooks/treatmentGeocoding";
import Input from "../../components/UI/Input";
import MultiSelectRounded from "../../components/UI/dropdowns/MultiSelectRounded";
import Dropdown from "../../components/UI/dropdowns/Dropdown";
import Button from "../../components/UI/Button";
import Textarea from "../../components/UI/Textarea";
import PhoneInput from "../../components/UI/PhoneInput";
import FileUpload from "../../components/UI/FileUpload";
import Layout from "./Layout/Layout";
import { auth, signOutUser } from "../../config/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import CustomDatePicker from "../../components/UI/CustomDatePicker";
import CustomDropdown from "../admin/assets/components/satellite/CustomDropdown";
import Checkbox from "../../components/UI/Checkbox";
import SatellitePhone from "../admin/assets/components/satellite/SatellitePhone";

const AddTransporter = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const { geocodeAddress } = useGeocoding();
	const [user] = useAuthState(auth);
	const [transporterData, setTransporterData] = useState(null);

	const [billingData, setBillingData] = useState({
		name: "", street: "", suite: "", state: "", zip: "", email: "", phone: ""
	});

	const [serviceData, setServiceData] = useState({
		name: "", street: "", suite: "", state: "", zip: "", email: "", phone: ""
	});

	const [billingCopyChecked, setBillingCopyChecked] = useState(false);
	const [serviceCopyChecked, setServiceCopyChecked] = useState(false);

	const defaultValues = {
		name: "", phoneNumber: "", registration1: "", website: "", dot: "", businessHours: "",
		transportorStatus: "Active", reviewLink: "", entityId: "", sic: "", cageCode: "", companyDisplayName: "",
		generalEmail: "", registration2: "", companyLogo: null, startOfFiscalYear: "", ein: "", epaId: "", sos: "",
		naicsCode: "", companySlogan: "", companyMissionStatement: "", companyVisionStatement: "", aboutEmail: "", assignedUser: [],
		serviceOffered: {
			medicalWasteTransporter: false, medicalWasteTransferStation: false, medicalWasteTreatmentFacilities: false,
			rollOffContainer: false, offsitePaperShredding: false, onsitePaperShredding: false, solidWaste: false,
			recycling: false,
		},
		optedForOctoConnect: true, createLink: { treatmentFacility: false, transferStation: false },
		billingName: "", billingStreet: "", billingSuite: "", billingCity: "", billingState: "", billingZip: "",
		billingEmail: "", billingPhone: "", serviceName: "", serviceStreet: "", serviceSuite: "", serviceState: "", serviceCity: "",
		serviceZip: "", serviceEmail: "", servicePhone: "", dispatchEmail: "", dispatchPhone: "", octoNumber: "",
	};

	const {
		control,
		handleSubmit,
		formState: { errors },
		watch,
		setValue,
	} = useForm({ defaultValues });
	const onSubmit = async (data) => {
		try {
			// Show "Submitting" toast immediately
			const submittingToast = toast.loading('Submitting...');

			const {
				serviceOffered = {},
				createLink = {},
				companyLogo,
				...restData
			} = data;

			const transporterDataToSave = {
				...restData,
				contractedGens: [],
				subContractedGens: [],
				allGens: [],
				connections: {},
				notifications: [],
				contacts: [],
				serviceOffered: {
					medicalWasteTransporter: false,
					medicalWasteTransferStation: false,
					medicalWasteTreatmentFacilities: false,
					rollOffContainer: false,
					offsitePaperShredding: false,
					onsitePaperShredding: false,
					solidWaste: false,
					recycling: false,
					...serviceOffered
				},
				createLink: {
					treatmentFacility: false,
					transferStation: false,
					...createLink
				},
				transportorStatus: restData.transportorStatus || "Active",
				optedForOctoConnect: restData.optedForOctoConnect ?? true,
				assignedUser: restData.assignedUser || [],
				billingName: restData.billingName,
				billingStreet: restData.billingStreet,
				billingSuite: restData.billingSuite,
				billingCity: restData.billingCity,
				billingState: restData.billingState,
				billingZip: restData.billingZip,
				billingEmail: restData.billingEmail,
				billingPhone: restData.billingPhone,
				serviceName: restData.serviceName,
				serviceStreet: restData.serviceStreet,
				serviceSuite: restData.serviceSuite,
				serviceCity: restData.serviceCity,
				serviceState: restData.serviceState,
				serviceZip: restData.serviceZip,
				serviceEmail: restData.serviceEmail,
				servicePhone: restData.servicePhone,
			};

			// Handle copy logic with a more concise approach 
			const copyDetails = (source, target) => {
				const copyFields = [
					'name', 'street', 'suite',
					'city', 'state', 'zip',
					'email', 'phone'
				];

				copyFields.forEach(field => {
					transporterDataToSave[`${target}${field.charAt(0).toUpperCase() + field.slice(1)}`] =
						restData[`${source}${field.charAt(0).toUpperCase() + field.slice(1)}`];
				});
			};

			// Apply copy logic based on checkboxes 
			if (billingCopyChecked) copyDetails('service', 'billing');
			if (serviceCopyChecked) copyDetails('billing', 'service');

			// Handle file upload 
			if (companyLogo) {
				const token = Math.random().toString(36).substring(2);
				const storageRef = ref(storage, `/transporters/companyLogo/${companyLogo.name}`);
				const snapshot = await uploadBytes(storageRef, companyLogo);
				const downloadURL = await getDownloadURL(snapshot.ref);

				transporterDataToSave.companyLogo = {
					name: companyLogo.name,
					link: downloadURL,
					mime: companyLogo?.type,
					path: storageRef.fullPath,
					ref: "",
					token
				};
			}

			// Add user identification 
			transporterDataToSave.userId = user.uid;

			// Create new transport 
			await createNewTransport(transporterDataToSave);

			toast.dismiss(submittingToast);
			toast.success('Transporter submitted successfully!');

			// Wait and sign out
			await new Promise(resolve => setTimeout(resolve, 3000));
			//await signOutUser();
			navigate("/admin");

		} catch (error) {
			console.error(error);
			toast.error(error.message || 'Failed to save Transporter');
		}
	};

	const handleBillingCopyChange = () => setBillingCopyChecked(!billingCopyChecked);
	const handleServiceCopyChange = () => setServiceCopyChecked(!serviceCopyChecked);


	console.log("saved", transporterData);

	const formatPhoneNumber = (input) => {
		const cleaned = input.replace(/\D/g, "");
		const limited = cleaned.slice(0, 10);

		const match = limited.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
		if (match) {
			const parts = [match[1], match[2], match[3]].filter(Boolean);
			return parts.length > 1
				? `(${parts[0]}) ${parts[1]}${parts[2] ? `-${parts[2]}` : ""}`
				: parts[0];
		}
		return input;
	};

	return (

		<>
			<div className="sticky top-0 z-10">
				<Layout />
			</div>
			<div className="p-2 ml-72">

				<div className="flex flex-col border border-cardBorderCol bg-white rounded-cardRadii h-full">
					<ToastContainer />
					<div className="my-4 ml-6 text-xl font-semibold">Transporter Profile</div>
					<div className="my-2 ml-6">General Information</div>
					<div className="ml-6 text-gray-500">(Information appears in public profile)</div>
					<div className="border-b mb-2 border-[#CCCCCC]"></div>
					<div className="mx-6">
						<form onSubmit={handleSubmit(onSubmit)}>
							<div className="flex gap-8">
								<div className="w-1/2">
									<Controller
										name="name"
										control={control}
										rules={{ required: "Company Name is required" }}
										render={({ field: { value, onChange } }) => (
											<div>
												<Input
													value={value}
													onChange={onChange}
													label="Company Name*"
													className="w-full px-2 py-1 text-sm"
												/>
												{errors.name && <p className="text-red-500 text-sm mt-1">{errors.name?.message}</p>}
											</div>
										)}
									/>
									<Controller
										name="companyDisplayName"
										control={control}
										rules={{ required: "Company Display Name is required" }}
										render={({ field: { value, onChange } }) => (
											<div>
												<Input
													value={value}
													onChange={onChange}
													label="Company Display Name*"
													className="w-full px-2 py-1 text-sm"
												/>
												{errors.name && <p className="text-red-500 text-sm mt-1">{errors.name?.message}</p>}
											</div>
										)}
									/>
									<Controller
										name="phoneNumber"
										control={control}
										rules={{ required: "Phone is required" }}
										render={({ field }) => (
											<div>
												<PhoneInput id="phoneNumber" label="General Phone*" value={field.value} onChange={field.onChange} />
												{errors.phoneNumber && <p className="text-red-500 text-sm mt-1">{errors.phoneNumber?.message}</p>}
											</div>
										)}
									/>
									<Controller
										name="generalEmail"
										control={control}
										rules={{
											required: "Email is required" ,
											pattern: {
												value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
												message: "Please enter a valid email address",
											},
										}}
										render={({ field: { value, onChange } }) => (
											<div>
												<Input value={value} onChange={onChange} label="General Email" className="w-full px-2 py-1 text-sm" />
												{errors.generalEmail && <p className="text-red-500 text-sm mt-1">{errors.generalEmail?.message}</p>}
											</div>
										)}
									/>

									<Controller
										name="website"
										control={control}
										rules={{
											// pattern: {
											// 	value: /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/,
											// 	message: "Please enter a valid URL",
											// },
										}}
										render={({ field: { value, onChange } }) => (
											<div >
												<Input
													value={value}
													onChange={onChange}
													type="url"
													label="Website URL"
													className="w-full px-2 py-1 text-sm"
													placeholder="https://example.com"
												/>
												{errors.website && (
													<p className="text-red-500 text-sm mt-4">{errors.website?.message}</p>
												)}
											</div>
										)}
									/>
									<div className="flex items-center justify-between mt-8">
										<label className="truncate text-inputLabel font-normal">
											Start of Fiscal Year
										</label>
										<div className="w-2/3">
											<Controller
												name="startOfFiscalYear"
												control={control}
												render={({ field: { value, onChange } }) => (
													<CustomDatePicker
														selectedDate={value}
														setSelectedDate={onChange}
														label="Start of Fiscal Year"
													/>
												)}
											/>
										</div>
									</div>
									<Controller
										name="businessHours"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="Business Hours*" value={value} onChange={onChange} />
											</div>
										)}
									/>

									<Controller
										name="reviewLink"
										control={control}
										rules={{
											// pattern: {
											// 	value: /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/,
											// 	message: "Please enter a valid URL",
											// },
										}}
										render={({ field: { value, onChange } }) => (
											<div className="mb-8">
												<Input
													value={value}
													onChange={onChange}
													type="url"
													label="Review Link"
													className="w-full px-2 py-1 text-sm"
													placeholder="https://example.com"
												/>
												{errors.reviewLink && (
													<p className="text-red-500 text-sm mt-1">{errors.reviewLink?.message}</p>
												)}
											</div>
										)}
									/>

									<FileUpload
										control={control}
										errors={errors}
										name={"companyLogo"}
										onDeleteFile={() => console.log("Clicked to delete file")}
									/>

									<Controller
										name="transportorStatus"
										control={control}
										render={({ field: { value, onChange } }) => (
											<div>
												<Dropdown
													value={value}
													onChange={onChange}
													options={statusOptions}
													label="Transporter Status*"
													className="text-sm"
												/>
												{errors.transportorStatus && (
													<p className="text-red-500 text-sm mt-1">{errors.transportorStatus?.message}</p>
												)}
											</div>
										)}
									/>
								</div>

								<div className="w-1/2">
									<Controller
										name="registration1"
										control={control}
										rules={{ required: "Registration Number 1 is required" }}
										render={({ field: { onChange, value }, fieldState: { error } }) => (
											<div>
												<Input
													label="Registration Number 1*"
													value={value}
													onChange={onChange}
													error={error ? error.message : null}
												/>
												{errors.registration1 && (
													<p className="text-red-500 text-sm mt-1">{errors.registration1?.message}</p>
												)}
											</div>
										)}
									/>
									<Controller
										name="registration2"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="Registration Number 2" value={value} onChange={onChange} />
											</div>
										)}
									/>

									<Controller
										name="epaId"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="EPA ID Number" value={value} onChange={onChange} />
											</div>
										)}
									/>

									<Controller
										name="dot"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="DOT Number" value={value} onChange={onChange} />
											</div>
										)}
									/>

									<Controller
										name="ein"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="EIN" value={value} onChange={onChange} />
											</div>
										)}
									/>

									<Controller
										name="entityId"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="Entity ID Number" value={value} onChange={onChange} />
											</div>
										)}
									/>

									<Controller
										name="sos"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="SOS Number" value={value} onChange={onChange} />
											</div>
										)}
									/>

									<Controller
										name="sic"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="SIC Code" value={value} onChange={onChange} />
											</div>
										)}
									/>

									<Controller
										name="naicsCode"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="NAICS Code" value={value} onChange={onChange} />
											</div>
										)}
									/>

									<Controller
										name="cageCode"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="CAGE Code" value={value} onChange={onChange} />
											</div>
										)}
									/>
								</div>
							</div>
							<div className="flex gap-8 w-full mt-4 p-6">
								{/* Billing Department*/}
								<div className="w-1/2">
									<div className="flex justify-between">
										<div className="pb-4">Billing Department</div>
										<div className="flex">
											<div className="pr-2">
												<Checkbox
													isChecked={billingCopyChecked}
													setIsChecked={handleBillingCopyChange}
												/>
											</div>
											<div className="text-gray-600">Copy from Service</div>
										</div>
									</div>
									<div className="border-t"></div>

									<Controller
										name="billingName"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input
													label="Name"
													type="text"
													value={billingCopyChecked ? serviceData.name : value}
													onChange={(e) => {
														onChange(e);
														setBillingData({ ...billingData, name: e.target.value });
													}}
												/>
											</div>
										)}
									/>

									<Controller
										name="billingStreet"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input
													label="Street"
													type="text"
													value={billingCopyChecked ? serviceData.street : value}
													onChange={(e) => {
														onChange(e);
														setBillingData({ ...billingData, street: e.target.value });
													}}
												/>
											</div>
										)}
									/>

									<Controller
										name="billingSuite"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input
													label="Suite"
													type="text"
													value={billingCopyChecked ? serviceData.suite : value}
													onChange={(e) => {
														onChange(e);
														setBillingData({ ...billingData, suite: e.target.value });
													}}
												/>
											</div>
										)}
									/>

									<Controller
										name="billingCity"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input
													label="City"
													type="text"
													value={billingCopyChecked ? serviceData.city : value}
													onChange={(e) => {
														onChange(e);
														setBillingData({ ...billingData, city: e.target.value });
													}}
												/>
											</div>
										)}
									/>

									<Controller
										name="billingState"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<CustomDropdown
													label="State"
													value={billingCopyChecked ? serviceData.state : value || ""}
													onChange={(selectedValue) => {
														if (selectedValue !== undefined && selectedValue !== null) {
															onChange(selectedValue);
															setBillingData((prevData) => ({
																...prevData,
																state: selectedValue,
															}));
														}
													}}
													options={stateOptions}
												/>
											</div>
										)}
									/>

									<Controller
										name="billingZip"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input
													label="Zip"
													type="text"
													value={billingCopyChecked ? serviceData.zip : value}
													onChange={(e) => {
														onChange(e);
														setBillingData({ ...billingData, zip: e.target.value });
													}}
												/>
											</div>
										)}
									/>

									<Controller
										name="billingEmail"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input
													label="Email"
													type="text"
													value={billingCopyChecked ? serviceData.email : value}
													onChange={(e) => {
														onChange(e);
														setBillingData({ ...billingData, email: e.target.value });
													}}
												/>
											</div>
										)}
									/>

									<Controller
										name="billingPhone"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<SatellitePhone
													label="Phone"
													value={billingCopyChecked ? serviceData.phone : value}
													onChange={(e) => {
														const formattedPhone = formatPhoneNumber(e.target.value);
														onChange(formattedPhone);
														setBillingData({ ...billingData, phone: formattedPhone });
													}}
												/>
											</div>
										)}
									/>
								</div>

								{/* Service Department */}
								<div className="w-1/2">
									<div className="flex justify-between">
										<div className="pb-4">Service Department</div>
										<div className="flex">
											<div className="pr-2">
												<Checkbox
													isChecked={serviceCopyChecked}
													setIsChecked={handleServiceCopyChange}
												/>
											</div>
											<div className="text-gray-600">Copy from Billing</div>
										</div>
									</div>
									<div className="border-t"></div>

									{/* Service Name */}
									<Controller
										name="serviceName"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input
													label="Name"
													type="text"
													value={serviceCopyChecked ? billingData.name : value}
													onChange={(e) => {
														onChange(e);
														setServiceData({ ...serviceData, name: e.target.value });
													}}
												/>
											</div>
										)}
									/>

									{/* Service Street */}
									<Controller
										name="serviceStreet"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input
													label="Street"
													type="text"
													value={serviceCopyChecked ? billingData.street : value}
													onChange={(e) => {
														onChange(e);
														setServiceData({ ...serviceData, street: e.target.value });
													}}
												/>
											</div>
										)}
									/>

									{/* Service Suite */}
									<Controller
										name="serviceSuite"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input
													label="Suite"
													type="text"
													value={serviceCopyChecked ? billingData.suite : value}
													onChange={(e) => {
														onChange(e);
														setServiceData({ ...serviceData, suite: e.target.value });
													}}
												/>
											</div>
										)}
									/>

									{/* Service City */}
									<Controller
										name="serviceCity"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input
													label="City"
													type="text"
													value={serviceCopyChecked ? billingData.city : value}
													onChange={(e) => {
														onChange(e);
														setServiceData({ ...serviceData, city: e.target.value });
													}}
												/>
											</div>
										)}
									/>

									{/* Service State */}
									<Controller
										name="serviceState"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<CustomDropdown
													label="State"
													value={serviceCopyChecked ? billingData.state : value || ""}
													onChange={(selectedValue) => {
														onChange(selectedValue);
														setServiceData((prevState) => ({
															...prevState,
															state: selectedValue,
														}));
													}}
													options={stateOptions}
												/>
											</div>
										)}
									/>

									{/* Service Zip */}
									<Controller
										name="serviceZip"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input
													label="Zip"
													type="text"
													value={serviceCopyChecked ? billingData.zip : value}
													onChange={(e) => {
														onChange(e);
														setServiceData({ ...serviceData, zip: e.target.value });
													}}
												/>
											</div>
										)}
									/>

									{/* Service Email */}
									<Controller
										name="serviceEmail"
										control={control}
										rules={{
											pattern: {
												value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
												message: 'Please enter a valid email address',
											},
										}}
										render={({ field: { onChange, value }, fieldState: { error } }) => (
											<div>
												<Input
													label="Email"
													type="text"
													value={serviceCopyChecked ? billingData.email : value}
													onChange={(e) => {
														onChange(e);
														setServiceData({ ...serviceData, email: e.target.value });
													}}
													error={error?.message}
												/>
												{error?.message && <p style={{ color: 'red' }}>{error.message}</p>}
											</div>
										)}
									/>

									{/* Service Phone */}
									<Controller
										name="servicePhone"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<SatellitePhone
													label="Phone"
													value={serviceCopyChecked ? billingData.phone : value}
													onChange={(e) => {
														const formattedPhone = formatPhoneNumber(e.target.value);
														onChange(formattedPhone);
														setServiceData({ ...serviceData, phone: formattedPhone });
													}}
												/>
											</div>
										)}
									/>

								</div>
							</div>
							<div className="mt-8 ml-6 mb-2 font-normal">About</div>
							<div className="border-t"></div>
							<div className="ml-6 mr-6">
								<div className="flex gap-8 w-full mt-2">
									<div className="w-1/2">
										<Controller
											name="companySlogan"
											control={control}
											rules={{ required: "Company Slogan is required" }}
											render={({ field: { onChange, value } }) => (
												<div>
													<Textarea label="Company Slogan*" type="Text" value={value} onChange={onChange} />
													{errors.companySlogan && (
														<p className="text-red-500 text-sm mt-1">{errors.companySlogan.message}</p>
													)}
												</div>
											)}
										/>
									</div>
									<div className="w-1/2">
										<Controller
											name="companyMissionStatement"
											control={control}
											rules={{ required: "Company Mission Statement is required" }}
											render={({ field: { onChange, value } }) => (
												<div>
													<Textarea label="Company Mission Statement*" type="Text" value={value} onChange={onChange} />
													{errors.companyMissionStatement && (
														<p className="text-red-500 text-sm mt-1">{errors.companyMissionStatement.message}</p>
													)}
												</div>
											)}
										/>
									</div>
								</div>

								<div className="flex gap-8 w-full ">
									<div className="w-1/2">
										<Controller
											name="companyVisionStatement"
											control={control}
											rules={{ required: "Company Vision Statement is required" }}
											render={({ field: { onChange, value } }) => (
												<div>
													<Textarea label="Company Vision Statement*" type="Text" value={value} onChange={onChange} />
													{errors.companyVisionStatement && (
														<p className="text-red-500 text-sm mt-1">{errors.companyVisionStatement.message}</p>
													)}
												</div>
											)}
										/>
									</div>
								</div>
							</div>
							<div className="ml-6">
								<div className="mt-12 mb-4">Services Offered</div>
								<div className="border-t"></div>
								<div className="grid gap-5 grid-cols-3 py-4 mt-2">
									<Controller
										name="serviceOffered.medicalWasteTransporter"
										control={control}
										render={({ field: { value, onChange } }) => <Checkbox label="Medical Waste Transporter" isChecked={value} setIsChecked={onChange} />}
									/>
									<Controller
										name="serviceOffered.medicalWasteTransferStation"
										control={control}
										render={({ field: { value, onChange } }) => <Checkbox label="Medical Waste Transfer Station" isChecked={value} setIsChecked={onChange} />}
									/>
									<Controller
										name="serviceOffered.medicalWasteTreatmentFacilities"
										control={control}
										render={({ field: { value, onChange } }) => <Checkbox label="Medical Waste Treatment Facilities" isChecked={value} setIsChecked={onChange} />}
									/>
									<Controller
										name="serviceOffered.rollOffContainer"
										control={control}
										render={({ field: { value, onChange } }) => <Checkbox label="Roll Off Container" isChecked={value} setIsChecked={onChange} />}
									/>
									<Controller
										name="serviceOffered.offsitePaperShredding"
										control={control}
										render={({ field: { value, onChange } }) => <Checkbox label="Offsite Paper Shredding" isChecked={value} setIsChecked={onChange} />}
									/>
									<Controller
										name="serviceOffered.onsitePaperShredding"
										control={control}
										render={({ field: { value, onChange } }) => <Checkbox label="Onsite Paper Shredding" isChecked={value} setIsChecked={onChange} />}
									/>
									<Controller
										name="serviceOffered.solidWaste"
										control={control}
										render={({ field: { value, onChange } }) => <Checkbox label="Solid Waste" isChecked={value} setIsChecked={onChange} />}
									/>
									<Controller
										name="serviceOffered.recycling"
										control={control}
										render={({ field: { value, onChange } }) => <Checkbox label="Recycling" isChecked={value} setIsChecked={onChange} />}
									/>
								</div>
								<div className="border-t"></div>
								<div className="grid gap-5 py-4 mt-2">
									<Controller
										name="createLink.treatmentFacility"
										control={control}
										render={({ field: { value, onChange } }) => <Checkbox label="Create / Link To Treatment Facility" isChecked={value} setIsChecked={onChange} />}
									/>

									<Controller
										name="createLink.transferStation"
										control={control}
										render={({ field: { value, onChange } }) => <Checkbox label="Create / Link To Transfer Station" isChecked={value} setIsChecked={onChange} />}
									/>
								</div>
								<div className="p-4">Dispatch Department</div>
								<div className="border-t"></div>
								<div className="flex gap-6 w-full px-4">

									<div className="w-1/2">
										<Controller
											name="dispatchEmail"
											control={control}
											rules={{
												required: "Email is required",
												pattern: {
													value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
													message: 'Please enter a valid email address'
												}
											}}
											render={({ field: { onChange, value }, fieldState: { error } }) => (
												<div>
													<Input
														label="Email*"
														type="text"
														value={value}
														onChange={onChange}
													/>
													{error?.message && (
														<p className="text-red-500 text-sm mt-1">{error.message}</p>
													)}
												</div>
											)}
										/>
									</div>

									<div className="w-1/2">
										<Controller
											name="dispatchPhone"
											control={control}
											rules={{ required: "Phone is required" }}
											render={({ field: { onChange, value } }) => (
												<div>
													<PhoneInput label="Phone" value={value} onChange={onChange} IsRequired={true} />
													{errors.dispatchPhone && (
														<p className="text-red-500 text-sm mt-1">{errors.dispatchPhone.message}</p>
													)}
												</div>
											)}
										/>
									</div>
								</div>
								<div className="p-4 mt-6">OCTO Connect: Subcontractor Partnership Program</div>
								<div className="border-t"></div>
								<div className="p-4 w-10/12  text-gray-600">
									By opting into the OCTO Connect Subcontractor Partnership Program, you agree to allow contractors to potentially connect with you based on the proximity of their generator s
									address to your service radius. When a contractor expresses interest in partnering with you, a notification will be sent to inform you of the potential partnership.
								</div>

								<div className="flex py-4 px-4 gap-6">
									<Controller
										name="optedForOctoConnect"
										control={control}
										render={({ field: { value, onChange } }) => (
											<>
												<Checkbox
													label="Opt In"
													isChecked={value === true}
													setIsChecked={(checked) => {
														if (checked) {
															onChange(true);
														} else {
															onChange(false);
														}
													}}
												/>
												<Checkbox
													label="Opt Out"
													isChecked={value === false}
													setIsChecked={(checked) => {
														if (checked) {
															onChange(false);
														} else {
															onChange(true);
														}
													}}
												/>
											</>
										)}
									/>
								</div>
							</div>

							<div className="flex justify-end gap-8 py-10">
								{/* <Button
									onClick={() => navigate("/admin/assets/manage-vendor")}
									btnStyle="form_nav_secondary"
									text="View Vendor List"
								/> */}
								<Button type="submit" btnStyle="form_nav_primary" text={"Submit"} />
							</div>
						</form>
					</div>
				</div>
			</div>

		</>
	);
};

export default AddTransporter;
