import React, { useState, useEffect, useRef } from "react";
import { IoIosArrowDown } from "react-icons/io";

const MultiselectDropdown = ({ buttonText, options, selectedOptions, onSelectedOptionsChange, width = "" }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [checkedState, setCheckedState] = useState(
		options.reduce((acc, option) => ({ ...acc, [option.value]: selectedOptions.includes(option.value) }), {})
	);

	const dropdownRef = useRef(null);

	const handleToggle = () => {
		setIsOpen((prev) => !prev);
	};

	// Handle the "All" selection logic
	const handleSelect = (option) => {
		let updatedCheckedState;
		if (option.value === "all") {
			// If "All" is selected, mark all options as selected/deselected
			const selectAll = !checkedState["all"];
			updatedCheckedState = options.reduce((acc, opt) => ({ ...acc, [opt.value]: selectAll }), {});
		} else {
			// If any other option is selected, deselect "All" and toggle the selected option
			updatedCheckedState = {
				...checkedState,
				all: false, // Deselect "All"
				[option.value]: !checkedState[option.value],
			};
		}

		// If all individual options are selected, mark "All" as selected
		const allSelected = Object.keys(updatedCheckedState).every((key) => updatedCheckedState[key] || key === "all");
		if (allSelected) {
			updatedCheckedState = options.reduce((acc, opt) => ({ ...acc, [opt.value]: true }), {});
		}

		setCheckedState(updatedCheckedState);

		// Update the selected options and pass them to the parent component
		const selectedOptions = Object.keys(updatedCheckedState).filter((key) => updatedCheckedState[key]);
		onSelectedOptionsChange(selectedOptions);
	};

	// Handle click outside the dropdown to close it
	const handleClickOutside = (event) => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
			setIsOpen(false);
		}
	};

	// Sync checkedState with the selectedOptions from the parent
	useEffect(() => {
		const updatedCheckedState = options.reduce(
			(acc, option) => ({ ...acc, [option.value]: selectedOptions.includes(option.value) }),
			{}
		);
		setCheckedState(updatedCheckedState);
	}, [selectedOptions, options]);

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => document.removeEventListener("mousedown", handleClickOutside);
	}, []);

	return (
		<div className={`relative inline-block text-left ${width}`} ref={dropdownRef}>
			<button
				type="button"
				onClick={handleToggle}
				className="bg-creamWhite border min-w-fit flex justify-between gap-2 px-4 w-full items-center hover:bg-cardTextGray hover:bg-opacity-10 text-cardTextGray py-2 rounded-lg focus:outline-none duration-200 ease-in-out"
				aria-expanded={isOpen}
				aria-haspopup="true"
			>
				<h6 className="min-w-fit text-nowrap">{buttonText}</h6>
				<IoIosArrowDown
					size={20}
					className={`font-bold min-w-fit transform transition-transform text-cardTextGray duration-200 ${
						isOpen ? "rotate-180" : "rotate-0"
					}`}
				/>
			</button>
			{isOpen && (
				<div className="absolute top-full left-0 overflow-scroll max-h-96 w-fit min-w-full bg-white rounded-lg shadow-lg z-10">
					{options.map((option, index) => (
						<button
							key={index}
							onClick={() => handleSelect(option)}
							className="flex items-center w-fit min-w-full pl-4 py-2 text-nowrap text-cardTextGray hover:bg-gray-100 text-left focus:outline-none relative"
							aria-checked={checkedState[option.value]}
						>
							<div className="relative flex items-center">
								<input
									type="checkbox"
									checked={checkedState[option.value]}
									onChange={() => handleSelect(option)}
									className="absolute opacity-0 w-0 h-0"
								/>
								<div
									className={`w-4 h-4 border rounded flex items-center justify-center relative ${
										checkedState[option.value] ? "border-green-600" : "border-gray-300 bg-white"
									}`}
								>
									{checkedState[option.value] && (
										<svg
											className="w-4 h-4 text-green-600 absolute"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
										>
											<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
										</svg>
									)}
								</div>
								<span className="ml-2">{option.label}</span>
							</div>
						</button>
					))}
				</div>
			)}
		</div>
	);
};

export default MultiselectDropdown;
