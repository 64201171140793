import React, { useState } from "react";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { RxCross2 } from "react-icons/rx";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Input from "../UI/Input";
import Dropdown from "../UI/dropdowns/Dropdown";
import Loader from "../UI/loaders/Loader";
import { createNewVendor } from "../../utils/firebaseOperations";
import MultiSelectRounded from "../UI/dropdowns/MultiSelectRounded";

const VendorStatus = [
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" }
];

const vendorOptions = [
    { value: "Supply Vendor", label: 'Supply Vendor' },
    { value: "Service Vendor", label: 'Service Vendor' },
];

const MiniVendorRegModal = ({ onClose }) => {
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            Vendor: [{ name: "", vendorWebsite: "", vendorType: [], vendorStatus: "Active" }]
        },
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: "Vendor",
    });

    const [inProgress, setInProgress] = useState(false);
    const navigate = useNavigate();

    const onSubmitForm = async (data) => {
        const vendorData = data.Vendor.map((vendor) => ({
            name: vendor.name || "--",
            vendorWebsite: vendor.vendorWebsite || "",
            vendorType: vendor.vendorType.length ? vendor.vendorType : ["--"],
            vendorStatus: vendor.vendorStatus || "--",
        }));

        toast.loading("Saving...");
        setInProgress(true);
        try {
            const newVendorIds = [];
            for (const vendor of vendorData) {
                const newVendorId = await createNewVendor(vendor);
                newVendorIds.push(newVendorId);
            }
            toast.dismiss();
            onClose();
            navigate(`/admin/assets/vendors/add-vendors/${newVendorIds[0]}/edit`);
        } catch (error) {
            toast.dismiss();
            console.error('Error saving vendor details:', error);
            toast.error("Failed to save vendor details. Please try again.");
        } finally {
            setInProgress(false);
        }
    };

    return (
        <div className="fixed inset-0 bg-[#CCCCCC87] bg-opacity-50 flex justify-center items-center z-40">
            <div className="bg-white rounded-cardRadii max-w-md w-full min-h-fit z-20">
                <form onSubmit={handleSubmit(onSubmitForm)} className="flex flex-col justify-between min-h-80 p-6">
                    <div className="flex justify-between pb-2">
                        <h6 className="font-bold text-xl text-black">Add New Vendor</h6>
                        <button
                            type="button"
                            disabled={inProgress}
                            className={`flex justify-center items-center h-fit w-fit p-1 rounded-full border shadow-md ${inProgress ? "" : "hover:bg-gray-200"}`}
                            onClick={onClose}
                        >
                            <RxCross2 size={20} className={`${!inProgress ? "hover:cursor-pointer" : ""}`} />
                        </button>
                    </div>

                    {fields.map((field, index) => (
                        <div className="gap-4 w-full" key={field.id}>
                            <div className="">
                                 <Controller
                                    name={`Vendor.${index}.name`}
                                    control={control}
                                    rules={{ required: "Vendor Name is required" }}
                                    render={({ field: { onChange, value } }) => (
                                        <div>
                                            <Input
                                                label="Vendor Name*"
                                                style="flex-col"
                                                className="w-full px-2 py-1 text-sm"
                                                value={value}
                                                onChange={onChange}
                                            />
                                            {errors.Vendor?.[index]?.name && <p className="text-red-500 text-sm mt-1">{errors.Vendor[index].name.message}</p>}
                                        </div>
                                    )}
                                />
                            </div>
                            <Controller
                                name={`Vendor.${index}.vendorWebsite`}
                                control={control}
                                rules={{
                                    //required: "Vendor website is required",
                                    pattern: {
                                        value: /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/,
                                        message: "Please enter a valid URL"
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <div>
                                        <Input
                                            label="Vendor Website"
                                            style="flex-col"
                                            value={value}
                                            onChange={onChange}
                                        />
                                        {errors.Vendor?.[index]?.vendorWebsite && (
                                            <p className="text-red-500 text-sm mt-1">
                                                {errors.Vendor[index].vendorWebsite.message}
                                            </p>
                                        )}
                                    </div>
                                )}
                            />
                             <Controller
                                name={`Vendor.${index}.vendorType`}
                                control={control}
                                // rules={{ required: "Vendor Type is required" }}
                                render={({ field: { value, onChange } }) => (
                                    <div>
                                        <MultiSelectRounded
                                        label="Vendor Type"
                                            styles="flex-col min-w-full"
                                            value={value}
                                            onChange={onChange}
                                            options={vendorOptions}
                                            className="text-sm"
                                        />
                                        {errors.Vendor?.[index]?.vendorType && <p className="text-red-500 text-sm mt-1">{errors.Vendor[index].vendorType.message}</p>}
                                    </div>
                                )}
                            />
                             <Controller
                                name={`Vendor.${index}.vendorStatus`}
                                control={control}
                                // rules={{ required: "Vendor Status is required" }}
                                render={({ field: { value, onChange } }) => (
                                    <div>
                                        <Dropdown
                                        label="Vendor Status"
                                            styles="flex-col min-w-full"
                                            value={value}
                                            onChange={onChange}
                                            options={VendorStatus}
                                            className="text-sm"
                                        />
                                        {errors.Vendor?.[index]?.vendorStatus && <p className="text-red-500 text-sm mt-1">{errors.Vendor[index].vendorStatus.message}</p>}
                                    </div>
                                )}
                            />
                        </div>
                    ))}
                    <button
                        type="submit"
                        className={`capitalize py-2 my-5 text-center px-5 gap-2 hover:opacity-90 ${inProgress ? "bg-cardTextGray bg-opacity-20" : "bg-dashBtnGradient"} w-full rounded-full text-white`}
                    >
                        {inProgress ? <Loader height="h-auto" /> : "Add New Vendor"}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default MiniVendorRegModal;
