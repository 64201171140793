import React, { useEffect } from "react";
import Input from "../../../../../../../../components/UI/Input";
import Checkbox from "../../../../../../../../components/UI/Checkbox";
import { useForm, Controller } from "react-hook-form";
import Textarea from "../../../../../../../../components/UI/Textarea";
import { toast } from "react-toastify";
import GeneratorInfoHeader from "./components/GeneratorInfoHeader";
import { useSelector, useDispatch } from "react-redux";
import { updateServiceNotes, updateServiceNotesInDB } from "../../../../../../../../store/sliceReducers/formSlice";
import FooterActionBtns from "./components/FooterActionBtns";

const ServiceNotes = ({ onNextClick, onClickBack, generatorData = {} }) => {
	const dispatch = useDispatch();
	const { serviceNotes } = useSelector((state) => state.form);

	console.log({ serviceNotes });

	const defaultValues = {
		permanentScheduleInstructions: "",
		tempoTimeScheduleInstructions: "",
		notifiPref24Hours: false,
		notifiPrefServiceDay: false,
		deliveryNote: "",
		parkingNote: "",
		serviceInstructions: "",
		lockBoxCode: "",
		locationOfWaste: "",
		tempoServiceInstructions: "",
	};

	const {
		control,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm({
		defaultValues,
	});

	useEffect(() => {
		toast.dismiss();
		if (generatorData) {
			Object.keys(defaultValues).forEach((key) => {
				setValue(key, generatorData[key]);
			});
		}
	}, [generatorData]);

	const onSubmitForm = async (data) => {
		let dataToBeSent = {
			id: generatorData?.id || "",
			...Object.fromEntries(Object.entries(data || {}).map(([key, value]) => [key, value ?? ""])),
		};

		if (!generatorData.onboardingStatus) dataToBeSent.onboardingStatus = {};
		else {
			dataToBeSent.onboardingStatus = generatorData.onboardingStatus;
		}
		dataToBeSent.onboardingStatus.registrationCompleted = true;
		dataToBeSent.onboardingStatus.profileCompleted = true;
		dataToBeSent.onboardingStatus.contactsCompleted = true;
		dataToBeSent.onboardingStatus.serviceNotesCompleted = true;

		try {
			dispatch(updateServiceNotes(dataToBeSent));
			toast.success("Saved successfully!");
		} catch (error) {
			console.error(error);
			toast.dismiss();
			toast.error("Error saving data");
		}
		onNextClick();
		dispatch(updateServiceNotesInDB(dataToBeSent));
	};

	return (
		<form onSubmit={handleSubmit(onSubmitForm)} className="bg-white p-8 py-6 flex flex-col gap-4 rounded-cardRadii">
			<GeneratorInfoHeader generatorData={generatorData} />
			<div className="flex flex-col gap-2">
				<div className="w-full grid gap-3">
					<h6 className="font-medium py-2 text-lg border-b border-[#CCCCCC]">Reminders/Notifications</h6>
					<div className="flex flex-col sm:flex-row pt-1 items-center gap-6">
						<label className="text-cardTextGray">Service Reminders</label>
					</div>
					<div className="flex flex-col gap-6 sm:flex-row items-center">
						<Controller
							name="notifiPref24Hours"
							control={control}
							render={({ field: { onChange, value } }) => (
								<Checkbox label="24-Hour Notice" isChecked={value} setIsChecked={(isChecked) => onChange(isChecked)} />
							)}
						/>
						<Controller
							name="notifiPrefServiceDay"
							control={control}
							render={({ field: { onChange, value } }) => (
								<Checkbox label="Same-Day Notice" isChecked={value} setIsChecked={(isChecked) => onChange(isChecked)} />
							)}
						/>
					</div>
				</div>
			</div>
			<div>
				<h6 className="font-medium py-2 text-lg border-b border-[#CCCCCC] mb-2">
					Generator Service Instructions{" "}
					<span className="text-sm text-cardTextGray">(Instructions appear in OCTO Field App)</span>
				</h6>
				<div className="w-full flex flex-col md:flex-row gap-8">
					<div className="w-1/2">
						<Controller
							name="deliveryNote"
							control={control}
							render={({ field: { onChange, value } }) => (
								<Textarea value={value} onChange={onChange} label="Delivery Note" />
							)}
						/>
						{errors.deliveryNote && <p className="text-red-500 text-sm mt-1">{errors.deliveryNote.message}</p>}
						<Controller
							name="parkingNote"
							control={control}
							render={({ field: { onChange, value } }) => (
								<Textarea label="Parking Note" value={value} onChange={onChange} />
							)}
						/>
						{errors.parkingNote && <p className="text-red-500 text-sm mt-1">{errors.parkingNote.message}</p>}
						<Controller
							name="locationOfWaste"
							control={control}
							render={({ field: { onChange, value } }) => (
								<Input label="Location of Waste" value={value} onChange={onChange} />
							)}
						/>
						{errors.locationOfWaste && <p className="text-red-500 text-sm mt-1">{errors.locationOfWaste.message}</p>}
						<Controller
							name="lockBoxCode"
							control={control}
							render={({ field: { onChange, value } }) => (
								<Input label="Lock Box Code" value={value} onChange={onChange} />
							)}
						/>
						{errors.lockBoxCode && <p className="text-red-500 text-sm mt-1">{errors.lockBoxCode.message}</p>}
						<Controller
							name="serviceInstructions"
							control={control}
							render={({ field: { onChange, value } }) => (
								<Textarea label="Service Instructions" value={value} onChange={onChange} />
							)}
						/>
						{errors.serviceInstructions && (
							<p className="text-red-500 text-sm mt-1">{errors.serviceInstructions.message}</p>
						)}
					</div>
					<div className="w-1/2">
						<Controller
							name="tempoServiceInstructions"
							control={control}
							render={({ field: { onChange, value } }) => (
								<Textarea label="Temporary Service Instructions" value={value} onChange={onChange} />
							)}
						/>
						{errors.tempoServiceInstructions && (
							<p className="text-red-500 text-sm mt-1">{errors.tempoServiceInstructions.message}</p>
						)}
					</div>
				</div>
				<div className="grid grid-cols-2 gap-8 mt-4">
					<div className="">
						<h6 className="font-medium py-2 text-lg border-b border-[#CCCCCC] mb-4">Dispatch Scheduling Note</h6>
						<Controller
							name="permanentScheduleInstructions"
							control={control}
							render={({ field: { onChange, value } }) => (
								<Textarea label="Permanent Schedule Instructions" value={value} onChange={onChange} />
							)}
						/>
						{errors.permanentScheduleInstructions && (
							<p className="text-red-500 text-sm mt-1">{errors.permanentScheduleInstructions.message}</p>
						)}

						<Controller
							name="tempoTimeScheduleInstructions"
							control={control}
							render={({ field: { onChange, value } }) => (
								<Textarea label="Temporary Schedule Instructions" value={value} onChange={onChange} />
							)}
						/>
					</div>
				</div>
			</div>
			<FooterActionBtns onClickBack={onClickBack} />
		</form>
	);
};

export default ServiceNotes;
