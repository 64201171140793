import React, { useEffect, useRef, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

const GeneratorManagement = () => {
	return (
		<div className="h-full p-6">
			<Outlet />
		</div>
	);
};

export default GeneratorManagement;
