import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { IoArrowForward } from "react-icons/io5";

const Modal = ({ isOpen, onClose, title = "Modal", children }) => {
	return (
		<AnimatePresence>
			{isOpen && (
				<div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
					{/* Backdrop */}
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 0.5 }}
						exit={{ opacity: 0 }}
						className="fixed inset-0 bg-black opacity-50"
						onClick={onClose}
					/>

					{/* Modal Content */}
					<motion.div
						initial={{
							opacity: 0,
							scale: 0.9,
							y: 50,
						}}
						animate={{
							opacity: 1,
							scale: 1,
							y: 0,
						}}
						exit={{
							opacity: 0,
							scale: 0.9,
							y: 50,
						}}
						transition={{
							type: "spring",
							damping: 15,
							stiffness: 300,
						}}
						className="relative min-w-lg max-w-3xl mx-auto my-6 bg-white rounded-lg shadow-xl"
					>
						{/* Modal Header */}
						<div className="flex items-center justify-between p-5 rounded-t">
							<h3 className="text-xl font-semibold text-gray-900">{title}</h3>
						</div>

						{/* Modal Body */}
						<div className="relative p-6 flex-auto">{children}</div>

						{/* Modal Footer */}
						<div className="flex items-center justify-end p-6 rounded-b">
							<motion.button
								className="disabled:cursor-not-allowed disabled:bg-[#898989] bg-[#007AFF] text-white rounded-lg hover:bg-blue-700"
								whileHover={{ scale: 1.05 }}
								whileTap={{ scale: 0.95 }}
								onClick={onClose}
							>
								<span className={`flex items-center gap-2 px-3 rounded-lg py-2`}>
									<IoArrowForward size={24} />
									Go to profile page
								</span>
							</motion.button>
						</div>
					</motion.div>
				</div>
			)}
		</AnimatePresence>
	);
};

export default Modal;
