import react, { useState } from "react";
import { IoIosArrowForward } from "react-icons/io";

let allSlots = [
	{
		id: 1,
		slot: "08:00 AM - 10:00 AM",
		data: "Residential waste collection in Oak Street area",
	},
	{
		id: 2,
		slot: "10:30 AM - 12:30 PM",
		data: "Recycling pickup for downtown businesses",
	},
	{
		id: 3,
		slot: "01:00 PM - 03:00 PM",
		data: "Bulk item removal from Pine Avenue apartments",
	},
	// {
	// 	id: 4,
	// 	slot: "09:00 PM - 11:00 PM",
	// 	data: "Hazardous waste removal from silver building",
	// },
];

const TimeSlotRepeater = () => {
	return (
		<ul className="grid w-auto h-full overflow-scroll gap-4">
			{allSlots.length > 0 ? (
				allSlots.map(({ slot, data, id }) => (
					<li key={id} className="bg-creamWhite flex justify-between rounded-md p-4">
						<div className="grid gap-2">
							<div className="flex gap-2 items-center">
								<div className="rounded-md h-8 w-8 bg-[#D9D9D9]">
									<img src="" alt="" />
								</div>
								<h6 className="opacity-40">{slot}</h6>
							</div>
							<h6 className="">{data}</h6>
						</div>
						<div className="flex hover:cursor-pointer justify-center items-center">
							<IoIosArrowForward />
						</div>
					</li>
				))
			) : (
				<div className="flex h-full text-cardTextGray text-xl justify-center items-center">
					<p>No routes found</p>
				</div>
			)}
		</ul>
	);
};

export default TimeSlotRepeater;
