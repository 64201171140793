import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { FaUserFriends, FaPaperPlane, FaInbox, FaUserPlus, FaUserCheck, FaUserTimes } from "react-icons/fa";
import useConnectionManager from "../../../../hooks/useConnectionManager";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../../config/firebase";
import { Link } from "react-router-dom";
import { FaUserClock, FaUsers } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../components/UI/loaders/Loader";

// Render connection card
const ConnectionCard = ({
	user,
	type,
	isApproving,
	isRejecting,
	isAceptingOrRejecting,
	handleAcceptRequest,
	handleCancelRequest,
	handleDeclineRequest,
}) => {
	console.log({ acceptedAt: user.acceptedAt });

	return (
		<motion.div
			className="container mx-auto px-6 py-8 bg-white shadow-md rounded-xl flex items-center space-x-4 hover:shadow-lg transition-all"
			initial={{ opacity: 0, y: 20 }}
			animate={{ opacity: 1, y: 0 }}
			whileHover={{ scale: 1.02 }}
		>
			<img
				src={
					user.userData?.profileImage?.length > 0
						? user.userData?.profileImage
						: "https://storage.googleapis.com/routes-123.appspot.com/assets/logo%201.png"
				}
				alt={user.userData?.companyName}
				className="w-16 h-16 rounded-full object-cover"
			/>
			<div className="flex-1">
				<Link
					to={`/market/${user.userId}`}
					className="text-lg font-semibold text-gray-800 cursor-pointer w-fit hover:underline"
				>
					{user.userData?.companyName}
				</Link>
				<div className="flex flex-col gap-1">
					<p className="text-gray-600 text-sm">{user.userData?.email}</p>
					<p className="text-gray-500 text-sm">{user.userData?.shortDesc}</p>
					<div className="flex gap-2 flex-wrap">
						{user.userData?.category?.map((cat, index) => (
							<span key={index} className="text-xs bg-blue-100 text-blue-800 px-2 py-1 rounded-full">
								{cat}
							</span>
						))}
					</div>
				</div>
			</div>

			{type === "received" && (
				<div className="flex space-x-4">
					<button
						className={`text-white p-3 rounded-full transition-colors duration-200 ${
							isApproving || isRejecting ? "bg-[#898989]" : "bg-green-500 hover:bg-green-600"
						}`}
						onClick={() => handleAcceptRequest(user.userId)}
						disabled={isApproving || isRejecting}
					>
						{isApproving ? <Loader height="" /> : <FaUserCheck size={20} />}
					</button>
					<button
						className={` text-white p-3 rounded-full transition-colors duration-200 ${
							isRejecting || isApproving ? "bg-[#898989]" : "bg-red-500 hover:bg-red-600"
						}`}
						disabled={isApproving || isRejecting}
						onClick={() => handleDeclineRequest(user.userId)}
					>
						{isRejecting ? <Loader height="" /> : <FaUserTimes size={20} />}
					</button>
				</div>
			)}

			{type === "sent" && (
				<button
					className={`px-6 py-2 border  text-red-500 rounded-full hover:bg-red-50 transition-colors ${
						isAceptingOrRejecting ? "border-[#898989]" : "border-red-500"
					}`}
					onClick={() => handleCancelRequest(user.userId)}
				>
					{isAceptingOrRejecting ? <Loader height="" /> : "Cancel"}
				</button>
			)}

			{type === "connections" && <div className="text-sm text-gray-500">Connected {user.acceptedAt}</div>}
		</motion.div>
	);
};

const Connections = () => {
	const [activeTab, setActiveTab] = useState("connections");
	const { notifications, connections, receivedConnections, sentConnections, unreadCount, loading, error } = useSelector(
		(state) => state.notification
	);
	const [isAceptingOrRejecting, setIsAceptingOrRejecting] = useState(false);
	const [isApproving, setIsApproving] = useState(false);
	const [isRejecting, setIsRejecting] = useState(false);
	const { data: octoUserData } = useSelector((state) => state.octoUserData);
	const [user] = useAuthState(auth);
	const dispatch = useDispatch();

	const { acceptConnectionRequest, declineConnectionRequest, cancelConnectionRequest } = useConnectionManager();

	const tabs = [
		{
			id: "connections",
			label: "Connections",
			icon: <FaUsers className="text-xl" />,
			count: connections.length,
		},
		{
			id: "received",
			label: "Received",
			icon: <FaUserClock className="text-xl" />,
			count: receivedConnections.length,
		},
		{
			id: "sent",
			label: "Sent",
			icon: <FaUserPlus className="text-xl" />,
			count: sentConnections.length,
		},
	];

	console.log(
		"octoUserData fetched after realtime callback",
		octoUserData,
		connections,
		receivedConnections,
		sentConnections
	);

	// Handle accept connection request
	const handleAcceptRequest = async (userId) => {
		try {
			setIsApproving(true);
			await acceptConnectionRequest(userId);
			setIsApproving(false);
		} catch (error) {
			console.error("Error accepting request:", error);
		}
	};

	// Handle decline connection request
	const handleDeclineRequest = async (userId) => {
		try {
			setIsRejecting(true);
			await declineConnectionRequest(userId);
			setIsRejecting(false);
		} catch (error) {
			console.error("Error declining request:", error);
		}
	};

	// Handle cancel sent request
	const handleCancelRequest = async (userId) => {
		try {
			setIsAceptingOrRejecting(true);
			await cancelConnectionRequest(userId);
			setIsAceptingOrRejecting(false);
		} catch (error) {
			console.error("Error cancelling request:", error);
		}
	};

	if (loading) {
		return (
			<div className="flex justify-center items-center h-64">
				<div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
			</div>
		);
	}

	return (
		<div className="max-w-4xl mx-auto px-4 py-8">
			<div className="flex justify-center my-8">
				{tabs.map((tab) => (
					<button
						key={tab.id}
						className={`
  flex items-center space-x-2 px-6 py-3 mx-2 rounded-full justify-between min-w-48 transition-all
  ${activeTab === tab.id ? "bg-blue-500 text-white" : "bg-gray-100 text-gray-700 hover:bg-gray-200"}
`}
						onClick={() => setActiveTab(tab.id)}
					>
						<div className="flex items-center gap-4">
							{tab.icon}
							<span>{tab.label}</span>
						</div>
						<span className="bg-white text-blue-500 rounded-full px-2 py-1 text-xs">{tab.count}</span>
					</button>
				))}
			</div>

			<motion.div className="grid gap-4" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.2 }}>
				{activeTab === "connections" &&
					connections.map((connection) => (
						<ConnectionCard
							key={connection.userId}
							user={connection}
							type="connections"
							handleAcceptRequest={handleAcceptRequest}
							handleCancelRequest={handleCancelRequest}
							handleDeclineRequest={handleDeclineRequest}
							isApproving={isApproving}
							isRejecting={isRejecting}
							isAceptingOrRejecting={isAceptingOrRejecting}
						/>
					))}

				{activeTab === "sent" &&
					sentConnections.map((request) => (
						<ConnectionCard
							key={request.userId}
							user={request}
							type="sent"
							handleAcceptRequest={handleAcceptRequest}
							handleCancelRequest={handleCancelRequest}
							handleDeclineRequest={handleDeclineRequest}
							isApproving={isApproving}
							isRejecting={isRejecting}
							isAceptingOrRejecting={isAceptingOrRejecting}
						/>
					))}

				{activeTab === "received" &&
					receivedConnections.map((request) => (
						<ConnectionCard
							key={request.userId}
							user={request}
							type="received"
							handleAcceptRequest={handleAcceptRequest}
							handleCancelRequest={handleCancelRequest}
							handleDeclineRequest={handleDeclineRequest}
							isApproving={isApproving}
							isRejecting={isRejecting}
							isAceptingOrRejecting={isAceptingOrRejecting}
						/>
					))}

				{/* Empty States */}
				{((activeTab === "connections" && connections.length === 0) ||
					(activeTab === "sent" && sentConnections.length === 0) ||
					(activeTab === "received" && receivedConnections.length === 0)) && (
					<motion.div
						className="text-center py-12 bg-gray-50 rounded-xl"
						initial={{ opacity: 0, scale: 0.9 }}
						animate={{ opacity: 1, scale: 1 }}
					>
						<FaUserPlus className="mx-auto text-6xl text-gray-400 mb-4" />
						<h2 className="text-xl text-gray-600">
							{activeTab === "connections"
								? "No connections yet"
								: activeTab === "sent"
								? "No sent requests"
								: "No received requests"}
						</h2>
						<p className="text-gray-500 mt-2">
							{activeTab === "connections"
								? "Start connecting with professionals"
								: activeTab === "sent"
								? "You haven't sent any connection requests"
								: "You have no pending connection requests"}
						</p>
					</motion.div>
				)}
			</motion.div>
		</div>
	);
};

export default Connections;
