import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { BiPlus } from "react-icons/bi";
import Dropdown from "../../../../../../../../components/UI/dropdowns/Dropdown";
import CustomDatePicker from "../../../../../../../../components/UI/CustomDatePicker";
import Textarea from "../../../../../../../../components/UI/Textarea";
import MultiSelectRounded from "../../../../../../../../components/UI/dropdowns/MultiSelectRounded";
import GeneratorInfoHeader from "./components/GeneratorInfoHeader";
import {
	frequencyPrimaryOptions,
	frequencySecondaryOptions,
	itemsMap,
	itemsOptions,
	serviceTypes,
	weekdayOptions,
} from "../../../../../../../../utils/constants";
import {
	getAllGeneratorsLocationSnapshot,
	getAllRoutes,
	getAllRoutesPathData,
	getAllTreatmentsLocationSnapshot,
	getAllVendorsLocationSnapshot,
	getServiceSchedules,
	removeRouteWaypoint,
	updateRouteWaypoint,
} from "../../../../../../../../utils/firebaseOperations";
import {
	formattedDate,
	getUpcomingDates,
	getUpcomingWeekdays,
	showErrorToastMessage,
	splitArrayIntoChunks,
} from "../../../../../../../../utils/helpers";
import {
	addDoc,
	arrayUnion,
	collection,
	deleteDoc,
	doc,
	documentId,
	FieldPath,
	getDoc,
	getDocs,
	onSnapshot,
	query,
	serverTimestamp,
	setDoc,
	Timestamp,
	updateDoc,
	where,
} from "firebase/firestore";
import {
	addOrUpdateGeneratorScheduleReq,
	deleteGeneratorScheduleReq,
	getGeneratorSchedules,
} from "../../../../../../../../utils/apiOps";
import GenRouteFooter from "./components/GenRouteFooter";
import FooterActionBtns from "./components/FooterActionBtns";
import RouteAssignment from "./components/RouteAssignment";
// import MapWithRoutes from "../../../../../../../../components/maps/MapWithRoutes";
import { FaCheck, FaExclamation } from "react-icons/fa";
import { useMap, useMapsLibrary } from "@vis.gl/react-google-maps";
import { COLLECTIONS, db } from "../../../../../../../../config/firebase";
import { Collapse } from "@mui/material";
import { Unsubscribe } from "@mui/icons-material";

const defaultOption = {
	serviceType: "",
	routeId: "",
	serviceFrequency: {
		type: "",
		days: [],
	},
	anchorDate: null,
	expectedItemOrService: [],
	notes: "",
	deliveryNotes: "",
	isWillCall: false,
	isSetUpService: false,
};

const GeneratorRoutes = ({ onClickBack, generatorData = {} }) => {
	const {
		control,
		handleSubmit,
		formState: { errors },
		watch,
		setValue,
		getValues,
		reset,
	} = useForm({
		defaultValues: {
			serviceSchedules: [defaultOption],
		},
	});
	const { fields, append, remove } = useFieldArray({
		control,
		name: "serviceSchedules",
	});
	const [serviceTypeOptions, setServiceTypeOptions] = useState([]);
	const [routeOptions, setRouteOptions] = useState([]);
	const [allRoutesOptions, setAllRoutesOptions] = useState([]);
	const [isLoadingServices, setIsLoadingServices] = useState(true);
	const [generatorScheduledServices, setGeneratorScheduledServices] = useState([]);
	const [selectedRouteIds, setSelectedRouteIds] = useState([]);
	const [routePaths, setRoutePaths] = useState([]);
	const map = useMap();
	const routesLibrary = useMapsLibrary("routes");
	const [directionsService, setDirectionsService] = useState();
	const [prevServiceSchedules, setPrevServiceSchedules] = useState([]);
	const [updatedRoutesId, setUpdatedRoutesId] = useState([]);
	const [allGeneratorsData, setAllGeneratorsData] = useState([]);
	const [allTreatmentData, setAllTreatmentData] = useState([]);
	const [allVendorData, setAllVendorData] = useState([]);
	const [isSubmitting, setIsSubmitting] = useState(false);

	// useEffect(() => {
	// 	if (generatorData?.serviceSchedules) {
	// 		const transformedSchedules = generatorData.serviceSchedules.map((schedule) => ({
	// 			...schedule,
	// 			expectedItemOrService: transformExpectedItems(schedule.expectedItemOrService || []),
	// 		}));
	// 		setValue("serviceSchedules", transformedSchedules);
	// 	}
	// }, [generatorData]);
	const formValues = watch();
	useEffect(() => {
		console.log({ formValues });
	}, [formValues]);

	// Helper function to transform expectedItemOrService to support quantities
	// const transformExpectedItems = (items) => {
	// 	return items.map((item) => ({
	// 		...item,
	// 		quantity: 1, // Default quantity
	// 	}));
	// };

	const fetchScheduledServices = async () => {
		try {
			let resp = await getGeneratorSchedules(generatorData.id, "all");
			if (resp.success) {
				setGeneratorScheduledServices(resp.data?.scheduled ?? []);
				setIsLoadingServices(false);
			}
		} catch (error) {
			console.error(error);
			toast.error("Error during loading scheduled services");
		}
	};
	useEffect(() => {
		let unsubscribe = onSnapshot(collection(db, COLLECTIONS.scheduledServices), (snap) => {
			fetchScheduledServices();
		});

		return () => {
			if (unsubscribe) unsubscribe();
		};
	}, []);

	useEffect(() => {
		if (!routesLibrary || !map) return;
		setDirectionsService(new routesLibrary.DirectionsService());
	}, [routesLibrary, map]);
	useEffect(() => {
		getAllOtherRoutes();
	}, []);
	const getAllOtherRoutes = async () => {
		const allRoutePathData = await getAllRoutesPathData();
		setRoutePaths(allRoutePathData);
	};

	useEffect(() => {
		let unsubscribe = getAllGeneratorsLocationSnapshot(setAllGeneratorsData);
		return () => {
			if (unsubscribe) unsubscribe();
		};
	}, []);
	useEffect(() => {
		let unsubscribe = getAllTreatmentsLocationSnapshot(setAllTreatmentData);
		return () => {
			if (unsubscribe) unsubscribe();
		};
	}, []);
	useEffect(() => {
		let unsubscribe = getAllVendorsLocationSnapshot(setAllVendorData);
		return () => {
			if (unsubscribe) unsubscribe();
		};
	}, []);

	const watchServiceSchedules = watch("serviceSchedules");

	const fetchAllServiceSchedules = async () => {
		if (Array.isArray(generatorData.serviceSchedules) && generatorData.serviceSchedules?.length > 0) {
			try {
				let resp = await getServiceSchedules(generatorData.serviceSchedules);
				console.log({ resp });

				setValue("serviceSchedules", resp);
				setPrevServiceSchedules(resp);
			} catch (error) {
				console.log(error);
			}
		} else {
			setValue("serviceSchedules", []);
		}
	};

	useEffect(() => {
		const fetchAllRoutesOptions = async () => {
			try {
				let resp = await getAllRoutes();
				setAllRoutesOptions(resp);
				let options = resp.map((item) => ({
					label: item.routeLabel,
					value: item.id,
				}));
				setRouteOptions(options);
			} catch (error) {
				console.log("Error fetching routes", error);
			}
		};
		fetchAllRoutesOptions();
		fetchAllServiceSchedules();
		fetchScheduledServices();
	}, []);

	useEffect(() => {
		if (generatorData?.serviceType) {
			setServiceTypeOptions(serviceTypes.filter((item) => generatorData.serviceType.includes(item.value)));
		}
	}, [generatorData]);

	// Effect for initial data loading and subsequent updates
	useEffect(() => {
		if (watchServiceSchedules) {
			watchServiceSchedules.forEach((schedule, index) => {
				const calculatedDates = calculateUpcomingDates(schedule);
				setValue(`serviceSchedules.${index}.upcomingDates`, calculatedDates, { shouldValidate: true });
			});
		}
	}, [watchServiceSchedules, setValue]);
	useEffect(() => {
		const subscription = watch((value, { name }) => {
			if (
				name &&
				name.startsWith("serviceSchedules") &&
				(name.endsWith("anchorDate") || name.includes("serviceFrequency"))
			) {
				const index = parseInt(name.split(".")[1]);
				const schedule = value.serviceSchedules[index];

				if (schedule && schedule.anchorDate && schedule.serviceFrequency) {
					let calculatedDates = [];
					if (schedule.serviceFrequency.type === "WC") {
						calculatedDates = [];
					} else if (schedule.serviceFrequency.type === "MTWM") {
						if (schedule.serviceFrequency.days.length > 0) {
							calculatedDates = getUpcomingWeekdays(new Date(schedule.anchorDate), schedule.serviceFrequency.days, 6);
						}
					} else {
						calculatedDates = getUpcomingDates(new Date(schedule.anchorDate), schedule.serviceFrequency.type, 6);
					}
					let dates = calculatedDates.filter((date) => date > new Date());
					setValue(`serviceSchedules.${index}.upcomingDates`, dates);
				}
			}
		});

		return () => subscription.unsubscribe();
	}, [watch, setValue]);

	const addWillCallService = () => {
		append({
			...defaultOption,
			serviceFrequency: {
				type: "WC",
				days: [],
			},
			isWillCall: true,
		});
	};
	const addSetUpService = () => {
		append({
			...defaultOption,
			isSetUpService: true,
		});
	};
	const serviceFrequencyOptions = [...frequencyPrimaryOptions, ...frequencySecondaryOptions];
	const onSubmitForm = async (data) => {
		try {
			setIsSubmitting(true);
			data.serviceSchedules = data.serviceSchedules.map((item) => {
				let { ...rest } = item;
				return {
					...rest,
					generatorId: generatorData.id,
					priceBook: "",
				};
			});

			toast.dismiss();
			toast.loading("Saving...");
			const response = await addOrUpdateGeneratorScheduleReq(generatorData.id, data.serviceSchedules);

			if (!response?.data?.data?.length) {
				showErrorToastMessage("Updating Schedules failed. Internal Server Error.");
				throw new Error("Updating Schedules failed. Internal Server Error.", { cause: "customError" });
			}

			console.log("Schedule updated successfully:", response.data);

			toast.dismiss();
			toast.success("Saved successfully!");
			await fetchScheduledServices();
		} catch (error) {
			if (error.cause === "customError") {
				showErrorToastMessage(error.message);
			} else {
				toast.dismiss();
				console.error("Error saving schedules:", error);
				toast.error("Error saving schedules. Please try again.");
			}
		} finally {
			setIsSubmitting(false);
		}
	};

	const deleteSchedule = async (field, index) => {
		console.log({ field });
		toast.dismiss();
		toast.loading("Deleting");
		let allSchedules = getValues("serviceSchedules");

		let dataToDelete = allSchedules.find((_, i) => index == i);
		console.log({ dataToDelete });

		let theData = {
			...field,
			id: dataToDelete.id,
		};

		if (theData.id) {
			try {
				const response = await deleteGeneratorScheduleReq(generatorData.id, theData);

				console.log("Schedule deleted successfully:", response.data);
				await fetchScheduledServices();
			} catch (error) {
				console.error("Failed to delete schedule:", error);
			}
		}
		toast.dismiss();
		toast.success("Deleted successfully!");
		remove(index);
	};

	const pendingScheduledService = useMemo(
		() => generatorScheduledServices?.filter((scheduledService) => scheduledService.status == "PENDING"),
		[generatorScheduledServices]
	);
	const completedScheduledService = useMemo(
		() => generatorScheduledServices?.filter((scheduledService) => scheduledService.status == "COMPLETED"),
		[generatorScheduledServices]
	);

	return (
		<form onSubmit={handleSubmit(onSubmitForm)} className="grid bg-white p-8 py-6 mb-6 rounded-cardRadii gap-2">
			<GeneratorInfoHeader generatorData={generatorData} />
			<div className="rounded-xl overflow-clip">
				<RouteAssignment
					allRoutes={routePaths}
					selectedRoutes={selectedRouteIds}
					generatorData={generatorData}
					getValues={getValues}
					allGeneratorsData={allGeneratorsData}
					allTreatmentData={allTreatmentData}
					allVendorData={allVendorData}
				/>
			</div>
			<h6 className="font-medium py-2 text-lg border-b border-[#CCCCCC]">Generator Service Requirements</h6>
			{fields.map((field, index) => (
				<div
					key={field.id || Date.now() + Math.random()}
					className={`${fields.length > 1 ? "border-b border-borderCol pb-4" : "pb-4"} pb-4`}
				>
					{/* <h6 className="font-medium py-2 text-lg">{`Service Schedule ${index + 1}`}</h6> */}
					<div className="flex gap-8 w-full">
						<div className="w-1/2">
							<Controller
								name={`serviceSchedules.${index}.routeId`}
								control={control}
								rules={{ required: "Route is required" }}
								render={({ field: { onChange, value } }) => (
									<Dropdown
										label="Route"
										options={routeOptions}
										value={value}
										onChange={(e) => {
											onChange(e);
										}}
										isRequired={true}
									/>
								)}
							/>
							{errors.serviceSchedules?.[index]?.routeId && (
								<p className="text-red-500 text-sm mt-1">{errors.serviceSchedules[index].routeId.message}</p>
							)}
							<Controller
								name={`serviceSchedules.${index}.serviceFrequency.type`}
								control={control}
								rules={{ required: "Service Frequency is required" }}
								render={({ field: { onChange, value } }) => (
									<Dropdown
										label="Service Frequency"
										options={serviceFrequencyOptions}
										value={value}
										onChange={onChange}
										isRequired={true}
										noCursor={field?.isWillCall}
									/>
								)}
							/>
							{errors.serviceSchedules?.[index]?.serviceFrequency?.type && (
								<p className="text-red-500 text-sm mt-1">
									{errors.serviceSchedules[index].serviceFrequency.type.message}
								</p>
							)}
							{watchServiceSchedules[index]?.serviceFrequency?.type === "MTWM" && (
								<Controller
									name={`serviceSchedules.${index}.serviceFrequency.days`}
									control={control}
									rules={{ required: "Weekdays are required for multiple times weekly" }}
									render={({ field: { onChange, value } }) => (
										<MultiSelectRounded
											value={value}
											onChange={onChange}
											options={weekdayOptions}
											label="Select Weekdays"
											id={`weekdays-input-${index}`}
										/>
									)}
								/>
							)}
							<div className="flex items-center justify-between my-4">
								<label htmlFor={`anchorDate-${index}`} className="truncate text-inputLabel font-normal">
									{field?.isWillCall ? "Will Call Date" : "Anchor Date"}
								</label>
								<div className="w-2/3">
									<Controller
										name={`serviceSchedules.${index}.anchorDate`}
										control={control}
										render={({ field: { value, onChange } }) => (
											<CustomDatePicker
												selectedDate={value}
												setSelectedDate={(value) => {
													console.log({ value });
													onChange(value);
												}}
												label={"Anchor Date"}
											/>
										)}
									/>
								</div>
							</div>
						</div>
						<div className="w-1/2">
							<Controller
								name={`serviceSchedules.${index}.serviceType`}
								control={control}
								rules={{ required: "Service Type is required" }}
								render={({ field: { onChange, value } }) => (
									<MultiSelectRounded
										value={value}
										onChange={onChange}
										options={serviceTypeOptions}
										isRequired={true}
										label="Service Type"
										id={`service-input-${index}`}
									/>
								)}
							/>
							{errors.serviceSchedules?.[index]?.serviceType && (
								<p className="text-red-500 text-sm mt-1">{errors.serviceSchedules[index].serviceType.message}</p>
							)}

							<Controller
								name={`serviceSchedules.${index}.expectedItemOrService`}
								control={control}
								rules={{
									required: "Expected Item/Service is required",
									validate: (value) => {
										// Ensure each item has a valid quantity
										return (
											value.every((item) => item.quantity >= 1 && item.quantity <= 20) ||
											"Quantity must be between 1 and 20"
										);
									},
								}}
								render={({ field: { value, onChange } }) => (
									<div>
										<MultiSelectRounded
											value={value.map((v) => v.item)} // Extract items for display
											onChange={(selectedItems) => {
												// When items change, update with items and default quantities
												const transformedItems = selectedItems.map((item) => {
													// Try to preserve existing quantity if item was previously selected
													const existingItem = value.find((v) => v.item === item);
													return {
														item,
														quantity: existingItem ? existingItem.quantity : 1,
													};
												});
												onChange(transformedItems);
											}}
											options={itemsOptions}
											isRequired={true}
											label="Expected Items/Services"
											id={`expected-items-services-${index}`}
										/>
										{/* Quantity selection for each item */}
										{value?.length > 0 && value && value?.[0]?.item?.length > 0 && (
											<div className="mb-6 flex flex-col items-end gap-2 justify-end">
												{value.map((itemObj, itemIndex) => (
													<div key={itemObj.item} className="flex items-center space-x-2 pl-2 w-2/3">
														<span className="text-sm w-1/3">{itemsMap?.[itemObj.item]}</span>
														<input
															type="number"
															min="1"
															max="2000"
															value={itemObj.quantity}
															onChange={(e) => {
																const newQuantity = Math.min(Math.max(1, Number(e.target.value)), 2000);
																const updatedItems = [...value];
																updatedItems[itemIndex] = {
																	...itemObj,
																	quantity: newQuantity,
																};
																onChange(updatedItems);
															}}
															className="border px-2 py-1 w-2/3 text-sm bg-inputBg rounded-full outline-none focus:ring-1 focus:ring-dashInActiveBtnText"
															placeholder="Quantity (1-20)"
														/>
													</div>
												))}
											</div>
										)}
									</div>
								)}
							/>
							{errors.serviceSchedules?.[index]?.expectedItemOrService && (
								<p className="text-red-500 text-sm mt-1">
									{errors.serviceSchedules[index].expectedItemOrService?.message}
								</p>
							)}
							{/* <Controller
								name={`serviceSchedules.${index}.priceBook`}
								control={control}
								render={({ field }) => (
									<div>
										<Dropdown label={"Select A Price Book"} options={[]} margin="my-0" {...field} />
										{errors.serviceSchedules?.[index]?.priceBook && (
											<p className="text-red-500 text-sm mt-1">{errors.serviceSchedules[index].priceBook.message}</p>
										)}
									</div>
								)}
							/> */}
						</div>
					</div>
					{watchServiceSchedules[index].upcomingDates?.length > 0 && (
						<div className="mb-4">
							<h6 className="font-medium pb-2">Upcoming Schedules</h6>
							<div className="flex gap-2 flex-wrap">
								{watchServiceSchedules[index].upcomingDates.map((date, dateIndex) => (
									<div
										key={dateIndex}
										className="inline-flex items-center bg-gray-100 rounded-full px-3 py-1 text-sm text-gray-700 mr-2 mb-2"
									>
										<span>{formattedDate(date)}</span>
									</div>
								))}
							</div>
						</div>
					)}
					{/* <div className="w-full flex gap-8">
						<div className="w-1/2">
							<Controller
								name={`serviceSchedules.${index}.notes`}
								control={control}
								render={({ field: { onChange, value } }) => (
									<Textarea value={value} onChange={onChange} label="Service Note" margin="mt-0" />
								)}
							/>
							<Controller
								name={`serviceSchedules.${index}.deliveryNotes`}
								control={control}
								render={({ field: { onChange, value } }) => (
									<Textarea value={value} onChange={onChange} label="Delivery Note" />
								)}
							/>
						</div>
						<div className="w-1/2"></div>
					</div> */}
					{index > 0 && (
						<div className="w-full flex justify-end">
							<button
								type="button"
								className="rounded-full px-4 py-1 text-sm border border-black hover:bg-cardTextGray hover:bg-opacity-10"
								onClick={() => deleteSchedule(field, index)}
							>
								Remove service schedule
							</button>
						</div>
					)}
				</div>
			))}
			<AddMoreServiceButtons addSetUpService={addSetUpService} addWillCallService={addWillCallService} />
			<FooterActionBtns nextBtnText={"Submit"} onClickBack={onClickBack} disabled={isSubmitting} />
			<GenRouteFooter
				isLoadingServices={isLoadingServices}
				pendingScheduledService={pendingScheduledService}
				completedScheduledService={completedScheduledService}
			/>
		</form>
	);
};

GeneratorRoutes.propTypes = {
	generatorData: PropTypes.object,
	onClickBack: PropTypes.func,
	onNextClick: PropTypes.func,
};

export default GeneratorRoutes;

function calculateUpcomingDates(schedule) {
	if (schedule.anchorDate && schedule.serviceFrequency) {
		let calculatedDates = [];
		if (schedule.serviceFrequency.type === "WC") {
			calculatedDates = [];
		} else if (schedule.serviceFrequency.type === "MTWM") {
			if (schedule.serviceFrequency.days.length > 0) {
				calculatedDates = getUpcomingWeekdays(new Date(schedule.anchorDate), schedule.serviceFrequency.days, 6);
			}
		} else {
			calculatedDates = getUpcomingDates(new Date(schedule.anchorDate), schedule.serviceFrequency.type, 6);
		}
		return calculatedDates?.filter((date) => date > Date.now());
	}
	return [];
}

const AddMoreServiceButtons = ({ addSetUpService, addWillCallService }) => {
	return (
		<div className="text-sm py-8 flex gap-4">
			<button
				type="button"
				className={`text-cardTextGray border-borderCol flex items-center justify-between gap-1 px-4 py-2 rounded-lg bg-creamWhite hover:bg-cardTextGray hover:bg-opacity-10 border transition-colors duration-200 ease-in-out`}
				onClick={addSetUpService}
			>
				<span>Add Set Up Service</span>
				<BiPlus size={16} />
			</button>
			<button
				type="button"
				className={`text-cardTextGray border-borderCol flex items-center justify-between gap-1 px-4 py-2 rounded-lg bg-creamWhite hover:bg-cardTextGray hover:bg-opacity-10 border transition-colors duration-200 ease-in-out`}
				onClick={addWillCallService}
			>
				<span>Add Will Call/ Off-Cycle Service</span>
				<BiPlus size={16} />
			</button>
		</div>
	);
};

AddMoreServiceButtons.propTypes = {
	addSetUpService: PropTypes.any,
	addWillCallService: PropTypes.any,
};
