import PropTypes from "prop-types";
import { useState } from "react";
import { AdvancedMarker, InfoWindow, useAdvancedMarkerRef } from "@vis.gl/react-google-maps";
const getARandomNumber = () => {
	return 0.00005;
	return Math.random();
};
const WaypointMarkers = ({ children, isEditable = true, headerContent, position, pin = null }) => {
	const [infoWindowOpen, setInfoWindowOpen] = useState(false);
	const [markerRef, marker] = useAdvancedMarkerRef();

	return (
		<>
			<AdvancedMarker
				ref={markerRef}
				onClick={() => setInfoWindowOpen(true)}
				position={{ lat: position.lat + getARandomNumber(), lng: position.lng }}
			>
				{pin}
			</AdvancedMarker>
			{infoWindowOpen && (
				<InfoWindow
					anchor={marker}
					className="w-80"
					onCloseClick={() => setInfoWindowOpen(false)}
					headerContent={headerContent}
					ariaLabel={"This is Aria Label"}
					shouldFocus={false}
				>
					{children}
				</InfoWindow>
			)}
		</>
	);
};

WaypointMarkers.propTypes = {
	children: PropTypes.node,
	isEditable: PropTypes.bool,
	pin: PropTypes.any,
	position: PropTypes.any.isRequired,
};

export default WaypointMarkers;
