const defaultValues = {
	parentAccount: "",
	generatorName: "",
	octoNumber: "",
	internalAccountNumber: "",
	generatorWebsite: "",
	generatorPhone: "",
	generatorStatus: "ACTIVE",
	generatorEmail: "",
	transporter: "",
	serviceType: [],
	serviceAddCoordinates: {},
	billingAddCoordinates: {},
	billingAddress: {
		street: "",
		suite: "",
		city: "",
		state: "",
		zipCode: "",
		email: "",
		phone: "",
	},
	serviceAddress: {
		street: "",
		suite: "",
		city: "",
		state: "",
		zipCode: "",
		email: "",
		phone: "",
	},
	contactDetail: {
		startDate: null,
		endDate: null,
		term: "",
		terminationFee: "",
		file: null,
	},
	serviceSchedules: [],
	onboardingStatus: {
		registrationCompleted: true,
		profileCompleted: false,
		contactsCompleted: false,
		serviceNotesCompleted: false,
		routesCompleted: false,
	},
	workingHours: {
		timeZone: "",
		Monday: {
			open: "",
			close: "",
			lunchStart: "",
			lunchEnd: "",
			closed: false,
			required: false,
		},
		Tuesday: {
			open: "",
			close: "",
			lunchStart: "",
			lunchEnd: "",
			closed: false,
			required: false,
		},
		Wednesday: {
			open: "",
			close: "",
			lunchStart: "",
			lunchEnd: "",
			closed: false,
			required: false,
		},
		Thursday: {
			open: "",
			close: "",
			lunchStart: "",
			lunchEnd: "",
			closed: false,
			required: false,
		},
		Friday: {
			open: "",
			close: "",
			lunchStart: "",
			lunchEnd: "",
			closed: false,
			required: false,
		},
		Saturday: {
			open: "",
			close: "",
			lunchStart: "",
			lunchEnd: "",
			closed: false,
			required: false,
		},
	},
	contacts: [],
	notifiPref24Hours: false,
	notifiPrefServiceDay: false,
	permanentScheduleInstructions: "",
	tempoTimeScheduleInstructions: "",
	serviceInstructions: "",
	tempoServiceInstructions: "",
	deliveryNote: "",
	parkingNote: "",
	lockBoxCode: "",
	locationOfWaste: "",
	childs: [],
};

import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { RxCross2 } from "react-icons/rx";
import Input from "./UI/Input";
import Dropdown from "./UI/dropdowns/Dropdown";
import { toast } from "react-toastify";
import { createOrUpdateGenerator, getNextGeneratorId } from "../utils/firebaseOperations";
import MultiSelectRounded from "./UI/dropdowns/MultiSelectRounded";
import { generatorStatus, serviceTypes } from "../utils/constants";
import { useNavigate } from "react-router-dom";
import Loader from "./UI/loaders/Loader";
import { useDispatch } from "react-redux";
// import { fetchGenerator } from "../store/sliceReducers/generatorSlice";

const MiniRegistrationModal = ({ onClose, activeSubmenu }) => {
	const {
		control,
		handleSubmit,
		setValue,
		getValues,
		formState: { errors },
	} = useForm({
		defaultValues,
	});
	const [inProgress, setInProgress] = useState(false);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const fetchGenId = async () => {
		let octoNumber = await getNextGeneratorId();
		setValue("octoNumber", octoNumber?.toString());
	};

	useEffect(() => {
		console.log("Fetching generator new id");
		fetchGenId();
	}, []);

	const onSubmitForm = async (data) => {
		if (activeSubmenu == "generator") {
			toast.loading("Saving..");
			setInProgress(true);
			console.log("dataToBeSent", data);
			// Save form data to local storage
			localStorage.setItem("generatorMiniRegFormData", JSON.stringify(data));
			if (activeSubmenu == "generator" || activeSubmenu == "Generator") {
				try {
					let resp = await createOrUpdateGenerator(data);
					toast.dismiss();
					onClose();
					// dispatch(fetchGenerator());
					navigate(`/admin/generators/${resp}/generator-profile`);
				} catch (error) {
					toast.dismiss();
					console.log(error);
				}
				toast.dismiss();
				setInProgress(false);
			}
		}
	};

	return (
		<div className="z-40 fixed inset-0 bg-[#CCCCCC87] bg-opacity-50 flex justify-center items-center">
			<div className="bg-white z-20 rounded-cardRadii max-w-md w-full min-h-fit">
				<form onSubmit={handleSubmit(onSubmitForm)} className="flex flex-col justify-between min-h-80 p-6">
					<div className="flex justify-between pb-2">
						<div className="flex items-center gap-4">
							<h6 className="font-bold text-xl text-black">Add New Generator</h6>
						</div>
						<button
							type="button"
							disabled={inProgress}
							className={`flex justify-center items-center h-fit w-fit p-1 rounded-full border shadow-md ${
								inProgress ? "" : "hover:bg-gray-200"
							}`}
							onClick={onClose}
						>
							<RxCross2 size={20} className={`${!inProgress ? "hover:cursor-pointer" : ""}`} />
						</button>
					</div>
					{activeSubmenu == "generator" && (
						<div>
							<Controller
								name="generatorName"
								control={control}
								rules={{
									required: "Generator name is required",
									validate: {
										noEmptyString: (value) => value.trim() !== "" || "Generator name cannot be an empty value!",
										noSpecialCharacters: (value) => /^[a-zA-Z0-9\s]+$/.test(value) || "No special characters allowed!",
									},
								}}
								render={({ field: { onChange, value } }) => (
									<Input
										label="Generator Name"
										style="flex-col gap-1"
										value={value}
										margin="mt-1"
										onChange={onChange}
										isRequired={true}
									/>
								)}
							/>
							{errors.generatorName && <p className="text-red-500 text-sm mt-1">{errors.generatorName.message}</p>}
							<Controller
								name="octoNumber"
								control={control}
								rules={{ required: "OCTO number is required" }}
								render={({ field: { onChange, value } }) => (
									<Input
										isLoading={getValues("octoNumber") ? false : true}
										label="OCTO Number"
										style="flex-col gap-1"
										isDisabled={true}
										value={value}
										margin="mt-1"
										extra="bg-inputBg"
										onChange={onChange}
										isRequired={true}
										isInfo="This is solely for visual purpose and it might change!"
									/>
								)}
							/>
							{errors.octoNumber && <p className="text-red-500 text-sm mt-1">{errors.octoNumber.message}</p>}
							<Controller
								name="internalAccountNumber"
								control={control}
								// rules={{ required: "Internal account number is required" }}
								render={({ field: { onChange, value } }) => (
									<Input
										label="Internal Account Number"
										style="flex-col gap-1"
										margin="mt-1"
										value={value}
										onChange={onChange}
									/>
								)}
							/>
							{/* {errors.internalAccountNumber && <p className="text-red-500 text-sm mt-1">{errors.internalAccountNumber.message}</p>} */}
							<Controller
								name="generatorStatus"
								control={control}
								rules={{ required: "Generator Status is required" }}
								render={({ field: { onChange, value } }) => (
									<Dropdown
										label="Generator Status"
										options={generatorStatus}
										styles="flex-col gap-1"
										margin="1"
										value={value}
										onChange={onChange}
										isRequired={true}
									/>
								)}
							/>
							{errors.generatorStatus && <p className="text-red-500 text-sm mt-1">{errors.generatorStatus.message}</p>}
							<Controller
								name="serviceType"
								control={control}
								rules={{ required: "Service Type is required" }}
								render={({ field: { onChange, value } }) => (
									<MultiSelectRounded
										styles="flex-col min-w-full gap-1"
										margin="0"
										value={value}
										onChange={onChange}
										options={serviceTypes}
										label="Service Type"
										id="serviceType-input"
										isRequired={true}
									/>
								)}
							/>
							{errors.serviceType && <p className="text-red-500 text-sm mt-1">{errors.serviceType.message}</p>}
						</div>
					)}
					<button
						type="submit"
						className={`capitalize py-2 my-5 text-center px-5 gap-2 hover:opacity-90 ${
							inProgress ? "bg-cardTextGray bg-opacity-20" : "bg-dashBtnGradient"
						} w-full rounded-full text-white`}
					>
						{inProgress ? <Loader height="h-auto" /> : `Add new ${activeSubmenu}`}
					</button>
				</form>
			</div>
		</div>
	);
};

export default MiniRegistrationModal;
