import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { BsGraphUp } from "react-icons/bs";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { RxArrowTopRight } from "react-icons/rx";
import { emailRegex } from "../../../../utils/mimes";
import { toast } from "react-toastify";
import { createNewUserWithEmailAndPassword, signInUserWithEmailAndPassword } from "../../../../config/firebase";
import ForgotPassword from "./ForgotPassword";
import { addUserToDb } from "../../../../utils/firebaseOperations";
import { useDispatch } from "react-redux";
import { setUserData } from "../../../../store/sliceReducers/octoUserSlice";
import { Timestamp } from "firebase/firestore";

const Auth = () => {
	const [defaultForm, setDefaultForm] = useState("LogIn");

	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			transition={{ duration: 0.7 }}
			className="flex flex-col items-center w-full justify-center md:flex-row px-12 md:px-20 xl:mx-auto gap-12 md:gap-20 h-screen"
		>
			<StatComp />

			<div className="flex w-full justify-center lg:w-1/2">
				<Form defaultForm={defaultForm} setDefaultForm={setDefaultForm} />
			</div>
		</motion.div>
	);
};

export default Auth;

const Form = ({ defaultForm, setDefaultForm }) => {
	const [showPassword, setShowPassword] = useState(false);
	const [email, setEmail] = useState("");
	const [name, setName] = useState("");
	const [password, setPassword] = useState("");
	const [checked, setChecked] = useState(false);
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();

	const resetFields = () => {
		setEmail("");
		setPassword("");
		setName("");
	};

	useEffect(() => {
		resetFields();
	}, [defaultForm]);

	const submitHandler = async (e) => {
		e.preventDefault();

		// Data Validation
		if (!emailRegex.test(email)) {
			return toast.error("Invalid email!");
		} else if (password?.trim()?.length == 0) {
			return toast.error("Invalid password!");
		} else if (password?.trim()?.length < 4) {
			return toast.error("Minimum required password legth is 4!");
		}

		// Simulating loading
		setLoading(true);

		if (defaultForm == "LogIn") {
			// User Login
			try {
				await signInUserWithEmailAndPassword(email, password);
			} catch (error) {
				console.log(error);
				if (error.code == "auth/invalid-credential") {
					return toast.error("Invalid Credential");
				}
			} finally {
				setLoading(false);
			}
		} else {
			// User SignUp
			if (name?.trim()?.length < 3) {
				return toast.error("Invalid name!");
			}
			try {
				let resp = await createNewUserWithEmailAndPassword(email, password);
				let timestamp = Timestamp.now();
				let dataToBeSent = {
					email,
					name,
					profileCompleted: false,
					approved: false,
					contacts: [],
					category: [],
					blocked: false,
					deleted: false,
					createdAt: timestamp,
				};
				dispatch(setUserData(dataToBeSent));
				await addUserToDb("octoMarketUsers", resp.user.uid, dataToBeSent);
			} catch (error) {
				console.log(error);
				if (error.code == "auth/email-already-in-use") {
					return toast.error("User does not exists!");
				}
			} finally {
				setLoading(false);
			}
		}

		await new Promise((resolve) => setTimeout(resolve, 1500));
	};

	if (defaultForm == "ForgotPassword") return <ForgotPassword setDefaultForm={setDefaultForm} />;

	return (
		<motion.div
			initial={{ opacity: 0, y: 20 }}
			animate={{ opacity: 1, y: 0 }}
			transition={{ duration: 0.5 }}
			className="flex flex-col justify-center w-full max-w-[600px]"
		>
			<h1 className="text-3xl text-center font-semibold mb-8">Welcome!</h1>

			<div className="space-y-6">
				{defaultForm == "SignUp" && (
					<motion.div initial={{ opacity: 0, x: -20 }} animate={{ opacity: 1, x: 0 }} transition={{ delay: 0.2 }}>
						<label className="block font-medium text-cardTextGray mb-1">Full Name *</label>
						<input
							type="name"
							value={name}
							onChange={(e) => setName(e.target.value)}
							className="w-full px-4 py-3 rounded-full bg-[#F1F1F4] transition-all outline-none duration-300 focus:ring-2 focus:ring-chartLine"
						/>
					</motion.div>
				)}
				<motion.div initial={{ opacity: 0, x: -20 }} animate={{ opacity: 1, x: 0 }} transition={{ delay: 0.2 }}>
					<label className="block font-medium text-cardTextGray mb-1">Email address *</label>
					<input
						type="email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						className="w-full px-4 py-3 rounded-full bg-[#F1F1F4] transition-all outline-none duration-300 focus:ring-2 focus:ring-chartLine"
					/>
				</motion.div>

				<motion.div initial={{ opacity: 0, x: 20 }} animate={{ opacity: 1, x: 0 }} transition={{ delay: 0.3 }}>
					<div className="flex justify-between items-center mb-1">
						<label className="block font-medium text-cardTextGray">Password *</label>
						{defaultForm !== "SignUp" && (
							<button
								onClick={() => setDefaultForm("ForgotPassword")}
								className="underline text-[#007AFF] hover:text-indigo-500 transition-colors duration-300 cursor-pointer"
							>
								Forgot password
							</button>
						)}
					</div>
					<div className="relative">
						<input
							type={showPassword ? "text" : "password"}
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							className="w-full px-4 py-3 rounded-full bg-[#F1F1F4] transition-all duration-300 outline-none focus:ring-2 focus:ring-chartLine"
						/>
						<button
							type="button"
							onClick={() => setShowPassword(!showPassword)}
							className="absolute right-4 top-1/2 -translate-y-1/2 text-cardTextGray transition-transform duration-300"
						>
							<AnimatePresence mode="wait">
								{showPassword ? (
									<motion.div
										key="eye"
										initial={{ opacity: 0 }}
										animate={{ opacity: 1 }}
										exit={{ opacity: 0 }}
										transition={{ duration: 0.2 }}
									>
										<FaEye size={20} />
									</motion.div>
								) : (
									<motion.div
										key="eye-slash"
										initial={{ opacity: 0 }}
										animate={{ opacity: 1 }}
										exit={{ opacity: 0 }}
										transition={{ duration: 0.2 }}
									>
										<FaEyeSlash size={20} />
									</motion.div>
								)}
							</AnimatePresence>
						</button>
					</div>
				</motion.div>

				<motion.div
					initial={{ opacity: 0, scale: 0.9 }}
					animate={{ opacity: 1, scale: 1 }}
					transition={{ delay: 0.4 }}
					className="flex items-center"
				>
					<Checkbox label="Keep me signed in" checked={checked} onChange={setChecked} />
				</motion.div>

				<motion.button
					whileHover={{ scale: 1.02 }}
					whileTap={{ scale: 0.95 }}
					type="submit"
					disabled={loading}
					className={`w-full h-14 font-bold text-white py-2 px-4 rounded-full transition-all duration-300 
						${
							loading
								? "bg-gray-400 cursor-not-allowed"
								: "bg-chartLine hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
						}`}
					onClick={submitHandler}
				>
					{loading
						? defaultForm == "SignUp"
							? "SIGNING UP"
							: "LOGGING IN..."
						: defaultForm == "SignUp"
						? "SIGNUP"
						: "LOGIN"}
				</motion.button>
			</div>
			{defaultForm == "LogIn" ? (
				<p className="mt-4 text-center">
					Not a member?{" "}
					<button
						onClick={() => setDefaultForm((pre) => (pre == "LogIn" ? "SignUp" : "LogIn"))}
						className="text-[#007AFF] underline hover:text-indigo-500"
					>
						Sign up
					</button>
				</p>
			) : (
				<p className="mt-4 text-center">
					Already a member?{" "}
					<button
						onClick={() => setDefaultForm((pre) => (pre == "LogIn" ? "SignUp" : "LogIn"))}
						className="text-[#007AFF] underline hover:text-indigo-500"
					>
						Log in
					</button>
				</p>
			)}
		</motion.div>
	);
};

const StatComp = () => {
	const data = [
		{ day: "Jan", value: 200, height: "h-20", color: "bg-blue-500" },
		{ day: "Feb", value: 280, height: "h-28", color: "bg-blue-500" },
		{ day: "Mar", value: 340, height: "h-36", color: "bg-blue-500" },
		{ day: "Apr", value: 400, height: "h-44", color: "bg-blue-500" },
		{ day: "May", value: 520, height: "h-52", color: "bg-green-400" },
		{ day: "Jun", value: 600, height: "h-56", color: "bg-blue-500" },
		{ day: "Jul", value: 680, height: "h-60", color: "bg-blue-500" },
	];

	const yAxisValues = [600, 500, 400, 300, 200, 100, 0];

	return (
		<div className="flex flex-col pt-10 lg:w-1/2">
			<motion.div
				initial={{ y: 50, opacity: 0 }}
				animate={{ y: 0, opacity: 1 }}
				transition={{ duration: 0.6, delay: 0.2 }}
				className="rounded-t-3xl rounded-l-3xl h-auto bg-[#EAEBEF8A]"
			>
				<div className="p-4 md:p-8">
					<div className="p-2 md:p-4">
						<motion.h2
							initial={{ x: -50, opacity: 0 }}
							animate={{ x: 0, opacity: 1 }}
							transition={{ delay: 0.3 }}
							className="text-2xl md:text-3xl xl:text-4xl font-extrabold text-center"
						>
							OCTO Market
						</motion.h2>
						<motion.h2
							initial={{ x: 50, opacity: 0 }}
							animate={{ x: 0, opacity: 1 }}
							transition={{ delay: 0.4 }}
							className="text-md md:text-2xl lg:text-3xl font-medium pt-4 text-center"
						>
							Powering Our Industry, One Connection at a Time.
						</motion.h2>
					</div>

					{/* Chart with hover and staggered animations */}
					<motion.div
						initial={{ scale: 0.9, opacity: 0 }}
						animate={{ scale: 1, opacity: 1 }}
						transition={{ duration: 0.5, delay: 0.5 }}
						className="bg-white shadow-lg p-4 md:p-6 mb-0 pb-0 md:mb-4 rounded-3xl my-4 md:my-8"
					>
						<div>
							<h3 className="text-cardTextGray mb-4 font-semibold">Revenue</h3>

							<div className="flex">
								{/* Y-axis */}
								<div className="flex flex-col justify-between mr-2 md:mr-4 h-48 md:h-64 text-sm pb-6 md:text-gray-400">
									{yAxisValues.map((value) => (
										<span key={value}>{value}K</span>
									))}
								</div>
								{/* Chart area with staggered animations */}
								<div className="flex-1">
									<div className="flex items-end justify-between h-48 md:h-64 gap-2 md:gap-4">
										{data.map((item, index) => (
											<motion.div
												key={index}
												initial={{ opacity: 0, height: 0 }}
												animate={{ opacity: 1, height: "auto" }}
												transition={{
													duration: 0.5,
													delay: 0.6 + index * 0.1,
												}}
												className="relative flex flex-col justify-center flex-1"
											>
												<div className="bg-gray-50 flex flex-col rounded-xl justify-end h-44 md:h-60">
													<motion.div
														whileHover={{ scale: 1.05 }}
														className={`w-full rounded-xl ${item.color} ${item.height} transition-all duration-300 hover:opacity-90`}
													></motion.div>
												</div>
												<div className="mt-2 text-sm md:text-center text-cardTextGray">{item.day}</div>
											</motion.div>
										))}
									</div>
								</div>
							</div>
						</div>
					</motion.div>
				</div>
			</motion.div>
			{/* the StatComp */}
			<div className="flex items-center h-full">
				<div className="bg-[#EAEBEF8A] h-full flex flex-col relative">
					<div className="bg-white rounded-tr-3xl w-full h-full z-10 absolute"></div>
					<div className="bg-[#4CD964] p-5 mt-6 mr-6 rounded-3xl h-full w-fit  z-20 md:w-auto">
						<RxArrowTopRight color="white" size={40} className="font-semibold" />
					</div>
				</div>
				<div className="h-full bg-[#EAEBEF8A] rounded-b-3xl px-8 pb-4 lg:pb-6 xl:pt-2">
					<div className="flex gap-4 items-center xl:gap-8">
						<div className="p-4 rounded-xl bg-white w-fit h-fit">
							<BsGraphUp size={30} />
						</div>
						<p className="text-cardTextGray md:text-base">
							Built by industry professionals for industry professionals, OCTO Market is proudly powered by Octopus SaaS
							to meet the unique needs of the medical waste disposal and paper shredding industry.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

const Checkbox = ({ label, checked, onChange, className = "" }) => {
	return (
		<motion.div
			whileTap={{ scale: 0.95 }}
			className={`flex gap-2 items-center ${className}`}
			onClick={() => onChange(!checked)}
		>
			<motion.div
				animate={{
					backgroundColor: checked ? "#007aff" : "#EEECF3",
				}}
				transition={{ duration: 0.15 }}
				className={`h-7 w-7 rounded flex items-center justify-center transition-colors duration-200 cursor-pointer`}
			>
				<AnimatePresence>
					{checked && (
						<motion.svg
							initial={{ opacity: 0, scale: 0.5 }}
							animate={{ opacity: 1, scale: 1 }}
							exit={{ opacity: 0, scale: 0.5 }}
							transition={{ duration: 0.05 }}
							className="h-6 w-6 text-white"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
						</motion.svg>
					)}
				</AnimatePresence>
			</motion.div>
			<label className="ml-2 block text-cardTextGray cursor-default">{label}</label>
		</motion.div>
	);
};
