import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ServiceDropdown from "../generator-management/components/reports/ServiceDropdown";
import Loader from "../../../components/UI/loaders/Loader";

const ServiceLocationList = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [hoveredRow, setHoveredRow] = useState(null);
	const [searchQuery, setSearchQuery] = useState("");
	const navigate = useNavigate();

	const generatorData = Array.from({ length: 3 }, (_, index) => ({
		id: index + 1,
		Customer: `${index + 1} Sub-Contractor Name`,
	}));

	const dropdownOptions = ["Listed", "Unlisted"];

	const handleNextClick = () => {
		console.log("Next button clicked");
		navigate("");
	};

	const handlePreviousBtn = () => {
		console.log("Previous button clicked");
		navigate(-1);
	};

	return (
		<div className="p-6">
			<div className="main-container flex flex-col border border-cardBorderCol bg-white rounded-cardRadii h-full">
				{/* <div className="mt-6"> */}
				<div className="text-xl xl:text-2xl font-semibold mx-8 mt-6 mb-2 text-black">Service Locations</div>

				<div className="overflow-x-scroll overflow-y-hidden">
					<div className="bg-[#E5F2FF] flex font-medium min-w-fit py-4 px-8">
						<div className="flex items-center">
							<div className="truncate w-80">Service Location</div>
							<div className="truncate w-60">OCTO Number</div>
							<div className="truncate w-80">Generator Name</div>
							<div className="truncate w-80">Subcontractor Name</div>
							<div className="truncate w-60">Status</div>
						</div>
					</div>
					<div className={`min-w-fit overflow-y-scroll h-[40%] overflow-x-hidden`}>
						{isLoading ? (
							<Loader height="h-12 pt-4" />
						) : generatorData.length > 0 ? (
							generatorData.map((generator, index) => (
								<div
									className={`flex w-full items-center px-8 transition-colors duration-300 ${
										index < generatorData.length - 1 ? "border-b border-gray-300" : ""
									} `}
									style={{ height: "45px" }}
								>
									<div className={`w-full flex items-center`}>
										<div className="truncate w-80 h-full">{generator.serviceLocation ?? "--"}</div>
										<div className="w-60 h-full text-cardTextBlue truncate">{generator.octoNumber ?? "--"}</div>
										<div className="truncate w-80 h-full">{generator.generatorName ?? "--"}</div>
										<div className="truncate w-80 h-full">{generator.subContractorName ?? "--"}</div>
										<div className="truncate h-full w-60">{generator.status ?? "--"}</div>
									</div>
								</div>
							))
						) : (
							<div className="flex min-h-40 text-cardTextGray text-lg justify-center items-center">
								<p>No result found</p>
							</div>
						)}
					</div>
				</div>
				{/* </div> */}
			</div>

			{/* Buttons Section */}
			{/* <div className="flex justify-end p-4 mb-12 mt-12">
				<button
					onClick={handlePreviousBtn}
					style={{ width: "184px", height: "44px" }}
					className="bg-[#007AFF] text-white px-4 py-2 rounded-full mr-4 border border-[#666666] hover:bg-white hover:text-black hover:border-[#666666] transition-opacity duration-300"
				>
					Previous
				</button>
				<button
					onClick={handleNextClick}
					style={{ width: "184px", height: "44px" }}
					className="bg-[#007AFF] text-white px-4 py-2 rounded-full border border-[#666666] hover:bg-white hover:text-black hover:border-[#666666] transition-opacity duration-300"
				>
					Next
				</button>
			</div> */}
		</div>
	);
};

export default ServiceLocationList;
