import React, { useEffect, useState } from "react";
import SearchBox from "../UI/searchBox/Search";
import Tooltip from "../UI/Tooltip";
import Notification from "../notifications/Notification";
import CalendarInput from "../UI/calendar/CalendarInput";
import UserAccBtn from "../userAccBtn/UserAccBtn";
import { useLocation, useNavigate } from "react-router-dom";
import HamburgerMenu from "./HamburgerMenu";
import AddNewBtnBar from "../AddNewBtnBar";
import Breadcrumb from "./Breadcrumb";
import { useBreadcrumb } from "../../context/BreadcrumbContext";
import { formattedDate } from "../../utils/helpers";
import { menuItems } from "../../utils/constants";

const Header = ({ activeId }) => {
	const [isNotificationOpen, setIsNotificationOpen] = useState(false);
	const [breadCrumbOptions, setBreadCrumbOptions] = useState([]);
	const location = useLocation();
	const navigate = useNavigate();
	const { breadcrumbData } = useBreadcrumb();

	const pathSegments = location.pathname?.split("/");
	const pathId = pathSegments?.[3] == "new" ? "New" : pathSegments?.[3];
	const formName = pathSegments?.[4];

	useEffect(() => {
		if (location.pathname.includes("/admin/generators")) {
			setBreadCrumbOptions(getGenBreadcrumbItems(location, formName, breadcrumbData.id, pathId));
		}
		if (location.pathname.includes("/admin/assets")) {
			setBreadCrumbOptions(getAssetBreadcrumbItems(location, formName, breadcrumbData.id, pathId));
		}
		if (location.pathname.includes("/admin/wastes")) {
			setBreadCrumbOptions(getWasteBreadcrumbItems(location, formName, pathId));
		}
		if (location.pathname.includes("/admin/octo-connect")) {
			setBreadCrumbOptions(getOctoConnectBreadcrumbItems(location, formName, pathId));
		}
	}, [location, pathId, breadcrumbData.id]);

	let activeIndex = breadCrumbOptions.findIndex(
		(item) => item?.label?.toString()?.toLocaleLowerCase() === formName || item.path.includes(formName) || !formName
	);

	return (
		<div
			className={`top-0 flex items-center px-8 text-sm xl:text-base justify-between ${
				location.pathname === "/admin" ? "bg-transparent pt-4" : "bg-white border border-borderCol h-20"
			}`}
		>
			<div className="md:hidden">
				<HamburgerMenu />
			</div>
			{location.pathname === "/admin" ? (
				<div className="md:w-1/3">
					<SearchBox menuItems={menuItems} />
				</div>
			) : (
				<Breadcrumb items={breadCrumbOptions} activeIndex={activeIndex} navigate={navigate} />
			)}
			<div className="flex gap-2 md:gap-4 items-center">
				{location.pathname === "/admin" ? (
					<>
						{/* <Tooltip text="Today's Date"> */}
						<div className="hidden xl:flex items-center bg-white border border-gray-200 rounded-full px-10 h-12">
							<h6 className="truncate">{formattedDate(new Date())}</h6>
						</div>
						{/* </Tooltip> */}
						<Notification isOpen={isNotificationOpen} setIsOpen={setIsNotificationOpen} />

						{/* <Tooltip text="Add new generator"> */}
						<div className="min-w-[137px]">
							<AddNewBtnBar />
						</div>
					</>
				) : (
					<AddNewBtnBar />
				)}
				<UserAccBtn />
			</div>
		</div>
	);
};

export default Header;

const getGenBreadcrumbItems = (location, formName, octoNumber, generatorId) => {
	const baseCrumbs =
		location.pathname === "/admin/generators" ? [{ label: "Generators", path: "/admin/generators" }] : [];
	const formCrumbs = [];
	if (location.pathname.includes("/admin/generators/reports")) {
		baseCrumbs.push({ label: "Today's Reports", path: "/admin/generators/reports" });
		// formCrumbs.push({ label: "Today's Reports", path: `/admin/generators/reports` });
		formCrumbs.push({ label: "Service Reporting", path: `/admin/generators/reports/servicereporting` });
		// formCrumbs.push({ label: "Service Reporting", path: `/admin/generators/reports/servicereporting/DropOffReport` });
		// formCrumbs.push({ label: "Box Location", path: `/admin/generators/reports/servicereporting/DropOffReport/boxlocation` });
		// formCrumbs.push({ label: "Weighing Containers", path: `/admin/generators/reports/servicereporting/DropOffReport/boxlocation/WeighingContainer` });
		// formCrumbs.push({ label: "Gate Waste Finder", path: `/admin/generators/reports/servicereporting/DropOffReport/boxlocation/WeighingContainer/gate-waste-finder` });
		// formCrumbs.push({ label: "Service Ticket", path: `/admin/generators/reports/servicereporting/DropOffReport/boxlocation/WeighingContainer/gate-waste-finder/ServiceTicket` });
	} else if (location.pathname == "/admin/generators/list" || location.pathname == "/admin/generators/map") {
		baseCrumbs.push({ label: "Generators", path: "/admin/generators/list" });
	} else if (
		formName === "generator-profile" ||
		formName === "generator-contacts" ||
		formName === "service-notes" ||
		formName === "routes"
	) {
		baseCrumbs.push({ label: "Generators", path: "/admin/generators/list", isCompleted: true });
		baseCrumbs.push({ label: octoNumber ?? generatorId, path: `/admin/generators/${generatorId}/generator-profile` });
		formCrumbs.push({ label: "Generator Profile", path: `/admin/generators/${generatorId}/generator-profile` });
		formCrumbs.push({ label: "Generator Contacts", path: `/admin/generators/${generatorId}/generator-contacts` });
		formCrumbs.push({ label: "Service Notes", path: `/admin/generators/${generatorId}/service-notes` });
		formCrumbs.push({ label: "Route Assignment", path: `/admin/generators/${generatorId}/routes` });
	}
	return [...baseCrumbs, ...formCrumbs];
};

const getAssetBreadcrumbItems = (location, formName, driverNumber, uId) => {
	const baseCrumbs = location.pathname == "/admin/assets" ? [{ label: "Assets", path: "/admin/assets" }] : [];
	const formCrumbs = [];
	if (location.pathname?.includes("/admin/assets/drivers")) {
		if (location.pathname == "/admin/assets/drivers") {
			baseCrumbs.push({ label: "Drivers", path: "/admin/assets/drivers" });
		} else {
			baseCrumbs.push({ label: "Drivers", path: "/admin/assets/drivers" });
			baseCrumbs.push({ label: "Add Driver", path: "/admin/assets/drivers/add-drivers" });
		}
	}
	console.log([...baseCrumbs, ...formCrumbs]);

	if (location.pathname.includes("/admin/assets/routes")) {
		baseCrumbs.push({ label: "Routes", path: "/admin/assets/routes" });
		if (formName === "add") {
			formCrumbs.push({ label: "Add Route", path: `/admin/assets/routes/add` });
		}
		if (location.pathname.endsWith("edit")) {
			formCrumbs.push({ label: "Route Detail", path: `${location.pathname}` });
		}
	}
	if (location.pathname === "/admin/assets/routes-optimization") {
		baseCrumbs.pop();
		baseCrumbs.push({ label: "Routes Optimization", path: "/admin/assets/routes-optimization" });
	}
	if (location.pathname.includes("/admin/assets/service-vehicles")) {
		baseCrumbs.push({ label: "Service Vehicle List", path: "/admin/assets/service-vehicles" });
		if (formName === "add") {
			formCrumbs.push({ label: "Add Service Vehicle", path: `/admin/assets/service-vehicles/add` });
		}
		if (location.pathname.endsWith("edit")) {
			formCrumbs.push({ label: "Service Vehicle Detail", path: `${location.pathname}` });
		}
	}
	if (location.pathname.includes("/admin/assets/vendors")) {
		baseCrumbs.push({ label: "Vendors", path: "/admin/assets/manage-vendor" });
		if (formName === "add-vendors") {
			formCrumbs.push({ label: "Add Vendor", path: `/admin/assets/vendors/add-vendors` });
		}
	}

	if (location.pathname.includes("/admin/assets/manage-vendor")) {
		if (location.pathname === "/admin/assets/manage-vendor") {
			baseCrumbs.push({ label: "Vendors", path: "/admin/assets/manage-vendor" });
		} else {
			baseCrumbs.push({ label: "Vendors", path: "/admin/assets/manage-vendor" });
		}
	}

	if (location.pathname.includes("/admin/assets/treatment/add-treatment")) {
		baseCrumbs.push({ label: "Treatment & Transfer", path: "/admin/assets/treatments-facilities" });
		formCrumbs.push({ label: "Add Treatment & Transfer", path: `/admin/assets/treatment/add-treatment` });
	}

	if (location.pathname.includes("/admin/assets/treatments-facilities")) {
		if (location.pathname === "/admin/assets/treatments-facilities") {
			baseCrumbs.push({ label: "Treatment & Transfer", path: "/admin/assets/treatments-facilities" });
		} else {
			baseCrumbs.push({ label: "Treatment & Transfer", path: "/admin/assets/treatments-facilities" });
		}
	}

	if (location.pathname.includes("/admin/assets/satellite/add-satellite")) {
		baseCrumbs.push({ label: "Satellite Locations", path: "/admin/assets/satellite-location" });
		formCrumbs.push({ label: "Add Satellite Location", path: `/admin/assets/satellite/add-satellite` });
	}

	if (location.pathname.includes("/admin/assets/satellite-location")) {
		if (location.pathname === "/admin/assets/satellite-location") {
			baseCrumbs.push({ label: "Satellite Locations", path: "/admin/assets/satellite-location" });
		} else {
			baseCrumbs.push({ label: "Satellite Locations", path: "/admin/assets/satellite-location" });
		}
	}

	return [...baseCrumbs, ...formCrumbs];
};

const getWasteBreadcrumbItems = (location, formName, pathId) => {
	const baseCrumbs = [{ label: "Waste Management", path: "/admin/wastes" }];
	const formCrumbs = [];

	if (location.pathname.includes("/admin/wastes/manage")) {
		formCrumbs.push({ label: "Manage Wastes", path: `/admin/wastes/manage` });
	}

	if (location.pathname.includes("/admin/wastes/wastefinder")) {
		formCrumbs.push({ label: "Waste Finder", path: `/admin/wastes/wastefinder` });
	}

	if (location.pathname.includes("/admin/wastes/boxlocations")) {
		formCrumbs.push({ label: "Box Locations", path: `/admin/wastes/boxlocations` });
	}

	if (location.pathname.includes("/admin/wastes/weights")) {
		formCrumbs.push({ label: "Weighing Interface", path: `/admin/wastes/weights` });
	}

	if (location.pathname.includes("/admin/wastes/service-ticket")) {
		formCrumbs.push({ label: "Service Tickets", path: `/admin/wastes/service-ticket` });
	}

	return [...baseCrumbs, ...formCrumbs];
};

const getOctoConnectBreadcrumbItems = (location, formName, pathId) => {
	console.log({ location, formName, pathId });

	const baseCrumbs = [{ label: "OCTO Connect", path: `/admin/octo-connect` }];
	const formCrumbs = [];

	if (location.pathname === "/admin/octo-connect/subcontractor-finder") {
		formCrumbs.push({ label: "Subcontractor Finder", path: `/admin/octo-connect/subcontractor-finder` });
	}

	if (location.pathname === "/admin/octo-connect/subcontractor-management") {
		formCrumbs.push({ label: "Subcontractor Management", path: `/admin/octo-connect/subcontractor-management` });
	}

	if (location.pathname === "/admin/octo-connect/service-locations") {
		formCrumbs.push({ label: "Service Locations", path: `/admin/octo-connect/service-locations` });
	}

	if (location.pathname === "/admin/octo-connect/contractor-management") {
		formCrumbs.push({ label: "Contractor Management", path: `/admin/octo-connect/contractor-management` });
	}

	if (location.pathname === "/admin/octo-connect/match-request") {
		formCrumbs.push({ label: "Match Request", path: `/admin/octo-connect/match-request` });
	}

	if (location.pathname === "/admin/octo-connect/linked-generators") {
		formCrumbs.push({ label: "Linked Generators", path: `/admin/octo-connect/linked-generators` });
	}

	if (location.pathname === "/admin/octo-connect/subcontractor-schedules") {
		formCrumbs.push({ label: "Subcontractors Schedules", path: `/admin/octo-connect/subcontractor-schedules` });
	}

	return [...baseCrumbs, ...formCrumbs];
};
