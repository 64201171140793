import React, { useState } from "react";
import { FaUser } from "react-icons/fa";
import { FaLock } from "react-icons/fa6";
import { emailRegex } from "../../utils/mimes";
import { signInUserWithEmailAndPassword } from "../../config/firebase";
import { toast } from "react-toastify";

const AdminAuthForm = () => {
	return (
		<div className="flex flex-col h-screen justify-center items-center gap-20 mx-auto">
			<img src="https://storage.googleapis.com/routes-123.appspot.com/assets/logoWithName.png" alt="" className="" />
			<Form />
		</div>
	);
};

export default AdminAuthForm;

const Form = () => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	const handleSubmit = async () => {
		if (!email?.length || !emailRegex.test(email)) {
			return toast.error("Invalid Email");
		}
		if (!password?.length || password.length < 4) {
			return toast.error("Invalid Password");
		}

		toast.loading("Signing in...");

		try {
			await signInUserWithEmailAndPassword(email, password);
		} catch (error) {
			console.log(error);
			if (error.code == "auth/invalid-credential") {
				return toast.error("Invalid Credential");
			}
			toast.error("Unexpected error");
		} finally {
			toast.dismiss();
		}
	};

	return (
		<div className="flex flex-col items-center gap-6">
			<h6 className="text-2xl font-medium">Welcome</h6>
			<div className="w-full px-8 py-3 bg-[#118FDE] text-white text-lg rounded-lg">
				Please login with your Username and Password.
			</div>
			<div className="w-full">
				<div className="flex items-center border border-blue-500 rounded-full px-4 py-3.5 my-6 w-full">
					<FaUser className="text-gray-400 mr-2" size={20} />
					<input
						type={"text"}
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						placeholder={"Enter Username"}
						className="flex-1 text-gray-500 placeholder-gray-400 bg-transparent outline-none"
					/>
				</div>
				<div className="flex items-center border border-blue-500 rounded-full px-4 py-3.5 my-6 w-full">
					<FaLock className="text-gray-400 mr-2" size={20} />
					<input
						type={"password"}
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						placeholder={"Enter Password"}
						className="flex-1 text-gray-500 placeholder-gray-400 bg-transparent outline-none"
					/>
				</div>
			</div>
			<button
				className="bg-dashBtnGradient w-52 h-12 text-white text-lg rounded-md hover:scale-[103%] transition-transform duration-200"
				onClick={handleSubmit}
			>
				Login
			</button>
		</div>
	);
};
