import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { doc, onSnapshot, getDoc } from "firebase/firestore";
import { db } from "../../config/firebase";
import { simplifyConnections } from "../../utils/constants";

// External listener to maintain across Redux store updates
let unsubscribeListener = null;

const initialState = {
	data: null,
	loading: true,
	error: null,
};

// Fetch initial user data
export const fetchUserData = createAsyncThunk("userData/fetch", async (userId) => {
	if (!userId) throw new Error("No user ID provided");

	const userDocRef = doc(db, "octoMarketUsers", userId);
	const initialDoc = await getDoc(userDocRef);

	if (!initialDoc.exists()) throw new Error("User document not found");

	return mapUserData({ id: initialDoc.id, ...initialDoc.data() });
});

// Add real-time listener for user data
export const listenToUserData = createAsyncThunk("userData/listen", async (userId, { dispatch }) => {
	if (!userId) throw new Error("No user ID provided");

	const userDocRef = doc(db, "octoMarketUsers", userId);

	// Clear any existing listener
	if (unsubscribeListener) {
		unsubscribeListener();
		unsubscribeListener = null;
	}

	unsubscribeListener = onSnapshot(
		userDocRef,
		(doc) => {
			if (doc.exists()) {
				dispatch(updateUserData(mapUserData({ id: doc.id, ...doc.data() })));
			}
		},
		(error) => {
			console.error("Real-time listener error:", error);
			dispatch(setUserDataError(error.message));
		}
	);
});

export const userDataSlice = createSlice({
	name: "userData",
	initialState,
	reducers: {
		setUserData: (state, action) => {
			state.data = action.payload;
			state.loading = false;
			state.error = null;
		},
		updateUserData: (state, action) => {
			if (state.data) {
				state.data = { ...state.data, ...action.payload };
			}
		},
		setUserDataError: (state, action) => {
			state.error = action.payload;
		},
		clearUserData: (state) => {
			state.data = null;
			state.loading = false;
			state.error = null;
		},
		clearNotificationListener: () => {
			if (unsubscribeListener) {
				unsubscribeListener();
				unsubscribeListener = null;
			}
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchUserData.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(fetchUserData.fulfilled, (state, action) => {
				state.data = action.payload;
				state.loading = false;
				state.error = null;
			})
			.addCase(fetchUserData.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message || "Error fetching user data";
			});
	},
});

export const { setUserData, updateUserData, setUserDataError, clearUserData, clearNotificationListener } =
	userDataSlice.actions;

export default userDataSlice.reducer;

// Helper function to map user data
const mapUserData = (data) => ({
	id: data.id,
	address: data?.address ?? "",
	region: data?.region ?? "",
	specialty: data?.specialty ?? "",
	category: data?.category ?? [],
	keyVerticals: data?.keyVerticals ?? "",
	timeZone: data?.timeZone ?? "",
	websiteUrl: data?.websiteUrl ?? "",
	bio: data?.bio ?? "",
	profileImage: data?.profileImage ?? "",
	contacts: data?.contacts ?? [],
	email: data?.email ?? "",
	companyName: data?.companyName ?? "",
	shortDesc: data?.shortDesc ?? "",
	serviceArea: data?.serviceArea ?? "",
	serviceAreaArr: data?.serviceAreaArr ?? "",
	approved: Boolean(data.approved),
	profileCompleted: Boolean(data.profileCompleted),
	phone: data?.phone ?? "",
	localAreas: data?.localAreas ?? [],
	rejected: data?.rejected,
	connections: simplifyConnections(data?.connections) ?? {},
});
