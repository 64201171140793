/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-comment-textnodes */
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect, useRef } from "react";

// eslint-disable-next-line react/prop-types
const DropdownReport = ({ buttonText, options }) => {
  const [isOpen, setIsOpen] = useState(false);
  // eslint-disable-next-line react/prop-types
  const [checkedState, setCheckedState] = useState(options.reduce((acc, option) => ({ ...acc, [option]: false }), {}));

  const dropdownRef = useRef(null);

  const handleToggle = () => {
    setIsOpen((prev) => !prev);
  };

  const handleSelect = (option) => {
    setCheckedState((prev) => ({ ...prev, [option]: !prev[option] }));
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="relative inline-block text-left" ref={dropdownRef}>
      <button
        type="button"
        onClick={handleToggle}
        className="bg-gray-100 text-[#666666] pl-5 pr-3 py-1 rounded-full"
        aria-expanded={isOpen}
        aria-haspopup="true"
      >
        {buttonText}
        <svg className="ml-8 w-4 h-4 inline-block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
        </svg>
      </button>
      {isOpen && (
        <div className="absolute top-full left-0 w-full bg-white rounded-b-lg shadow-lg z-10 mt-1">
           {options.map((option, index) => (
            <button
              key={index}
              onClick={() => handleSelect(option)}
              className="flex items-center w-full pl-3 py-2 text-gray-700 hover:bg-gray-100 text-left focus:outline-none"
              aria-checked={checkedState[option]}
              role="menuitemcheckbox"
            >
              <div className="relative flex items-center">
                <input 
                  type="checkbox" 
                  checked={checkedState[option]} 
                  onChange={() => handleSelect(option)} 
                  className="absolute opacity-0 w-0 h-0" 
                  aria-hidden="true"
                />
                <div className={`w-4 h-4 border rounded-md flex items-center justify-center ${checkedState[option] ? "border-green-600" : "border-gray-300"} bg-white`}>
                  {checkedState[option] && (
                    <svg className="w-4 h-4 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                    </svg>
                  )}
                </div>
                <span className="ml-2">{option}</span>
              </div>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropdownReport;
