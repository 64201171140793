import PropTypes from "prop-types";
import { Map, Pin } from "@vis.gl/react-google-maps";
import { useEffect, useState } from "react";
import GeneratorSingleMarkerWithInfoWindow from "../../../../../../../../../components/maps/GeneratorSingleMarkerWithInfoWindow";
import { Polyline } from "../../../../../../../../../components/maps/PolyLine";
import { MarkerWithInfoWindow } from "../../../../../../../../../components/maps/MarkerWithInfoWindow";
import TreatmentSingleMarkerWithInfoWindow from "../../../../../../../../../components/maps/TreatmentSingleMarkerWithInfoWindow";
import VendorSingleMarkerWithInfoWindow from "../../../../../../../../../components/maps/VendorSingleMarkerWithInfoWindow copy";

function RouteAssignment({
	generatorData = {},
	allRoutes = [],
	selectedRoutes = [],
	allGeneratorsData = [],
	allTreatmentData = [],
	allVendorData = [],
}) {
	const [routesToShow, setRoutesToShow] = useState([]);

	useEffect(() => {
		setRoutesToShow(() => allRoutes.map((el) => el.id));
	}, [allRoutes]);
	const renderLegends = () => {
		return (
			<div className="absolute top-5 left-5 bg-white shadow-xl rounded-xl">
				<div className="collapse collapse-arrow">
					<input type="checkbox" />
					<div className="collapse-title">
						<p className="">Legends</p>
					</div>
					<div className="collapse-content">
						<div className="grid grid-rows-6 grid-flow-col-dense ">
							<div className="grid grid-cols-2 ">
								<span>Generator</span>
								<span className="">
									<img className="w-10 h-10" src="/generator.svg" alt="Generator" />
								</span>
							</div>
							<div className="grid grid-cols-2">
								<span>Transfer Facility</span>
								<span>
									<img className="w-10 h-10" src="/transfer_facilities.svg" alt="Transfer Facilities" />
								</span>
							</div>
							<div className="grid grid-cols-2">
								<span>Treatment Facility</span>
								<span>
									<img className="w-10 h-10" src="/treatment_facilities.svg" alt="Treatment Facilities" />
								</span>
							</div>
							<div className="grid grid-cols-2">
								<span>Vendor</span>
								<span>
									<img className="w-10 h-10" src="/vendor.svg" alt="Vendor" />
								</span>
							</div>
							<div className="grid grid-cols-2">
								<span>Route Start</span>
								<span>
									<img className="w-10 h-10" src="/route_start.svg" alt="Route Start" />
								</span>
							</div>
							<div className="grid grid-cols-2">
								<span>Route End</span>
								<span>
									<img className="w-10 h-10" src="/route_end.svg" alt="Route End" />
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};
	const renderTreatmentFacilities = () => {
		return allTreatmentData?.length > 0
			? allTreatmentData.map((mark, i) => (
					<TreatmentSingleMarkerWithInfoWindow
						key={i}
						marker={{
							lat: mark?.treatmentCoordinates.lat,
							lng: mark?.treatmentCoordinates.lng,
							formattedAdd: `${mark?.street ?? ""}, ${mark?.city ?? ""}, ${mark?.state ?? ""} ${mark?.zipCode ?? ""}`,
							name: mark?.name,
						}}
					/>
			  ))
			: null;
	};
	const renderVendors = () => {
		return allVendorData?.length > 0
			? allVendorData.map((mark, i) => (
					<VendorSingleMarkerWithInfoWindow
						key={i}
						marker={{
							lat: mark?.vendorCoordinates.lat,
							lng: mark?.vendorCoordinates.lng,
							formattedAdd: `${mark?.street ?? ""}, ${mark?.city ?? ""}, ${mark?.state ?? ""} ${mark?.zipCode ?? ""}`,
							name: mark?.name,
							vendorType: mark?.vendorType,
						}}
					/>
			  ))
			: null;
	};
	
	return (
		<div className="h-[500px]">
			<Map
				reuseMaps
				mapId={`routeAssignmentMap`}
				defaultZoom={12}
				defaultCenter={generatorData.serviceAddCoordinates && { lat: 32.7364432, lng: -117.1460877 }}
				gestureHandling={"greedy"}
				disableDefaultUI
				zoomControl
				fullscreenControl
			>
				{allGeneratorsData.length > 0 &&
					allGeneratorsData.map((genData) => (
						<GeneratorSingleMarkerWithInfoWindow
							key={genData.id}
							isCurrent={generatorData.id === genData.id ? true : false}
							marker={{
								lat: genData?.serviceAddCoordinates.lat,
								lng: genData?.serviceAddCoordinates.lng,
								assignedRoutes: genData?.assignedRoutes,
								formattedAdd: `${genData.serviceAddress?.street}, ${genData.serviceAddress?.city}, ${genData.serviceAddress?.state} ${genData.serviceAddress?.zipCode}`,
								name: genData?.generatorName,
								octoNumber: genData?.octoNumber,
								serviceFrequency: genData?.serviceFrequency,
								serviceType: genData?.serviceType,
							}}
						/>
					))}
				{allRoutes.length > 0 &&
					allRoutes.map((path) => {
						const isSelected = selectedRoutes.findIndex((el) => el === path.id) > -1 ? true : false;
						return (
							routesToShow.findIndex((el) => el === path.id) > -1 && (
								<MarkerWithInfoWindow
									key={path.id}
									position={path.path[0]}
									name={path.startLocationName}
									pin={
										<Pin
											background={isSelected ? "#7E60BF" : "#22ccff"}
											borderColor={isSelected ? "#7E60BF" : "#22ccff"}
											glyphColor={"#0f677a"}
										>
											<img src="/route_start.svg" alt="Route Start" />
										</Pin>
									}
								></MarkerWithInfoWindow>
							)
						);
					})}
				{allRoutes.length > 0 &&
					allRoutes.map((path) => {
						const isSelected = selectedRoutes.findIndex((el) => el === path.id) > -1 ? true : false;
						return (
							routesToShow.findIndex((el) => el === path.id) > -1 && (
								<MarkerWithInfoWindow
									key={path.id}
									position={path.path[path.path.length - 1]}
									name={path.endLocationName}
									pin={
										<Pin
											background={isSelected ? "#7E60BF" : "#22ccff"}
											borderColor={isSelected ? "#7E60BF" : "#22ccff"}
											glyphColor={"#0f677a"}
										>
											<img src="/route_end.svg" alt="Route End" />
										</Pin>
									}
								></MarkerWithInfoWindow>
							)
						);
					})}
				{/* {allRoutes.length > 0 &&
					allRoutes.map((path) => {
						const isSelected = selectedRoutes.findIndex((el) => el === path.id) > -1 ? true : false;
						return (
							routesToShow.findIndex((el) => el === path.id) > -1 && (
								<Polyline
									key={path.id}
									strokeWeight={5}
									strokeColor={isSelected ? "#7E60BF" : "#0000FF"}
									path={path.path}
								/>
							)
						);
					})} */}
				{/* {renderRouteList()} */}
				{renderLegends()}
				{renderTreatmentFacilities()}
				{renderVendors()}
			</Map>
		</div>
	);
}

RouteAssignment.propTypes = {
	allGeneratorsData: PropTypes.array,
	allRoutes: PropTypes.array,
	allTreatmentData: PropTypes.array,
	allVendorData: PropTypes.array,
	generatorData: PropTypes.object,
	getValues: PropTypes.func,
	selectedRoutes: PropTypes.array,
};

export default RouteAssignment;
