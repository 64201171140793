import React, { useState } from "react";
import { auth, signOutUser } from "../../../config/firebase";
import Header from "./Header";
import { useAuthState } from "react-firebase-hooks/auth";
import Loader from "../../../components/UI/loaders/Loader";
import Home from "./home/Home";

const LandingPage = () => {
	const [user, loading] = useAuthState(auth);

	if (!user || loading) return <Loader />;

	return <Home />;
};

export default LandingPage;
