const Button = ({ text, onClick, btnStyle = "form_primary", type = "", disabled = false, className }) => {
	return (
		<button
			disabled={disabled}
			onClick={onClick}
			type={type ?? "button"}
			className={`${className} rounded-full text-center font-medium ${
				btnStyle == "form_primary"
					? "bg-cardTextBlue w-full min-w-fit  h-9 text-white"
					: btnStyle == "form_nav_primary"
					? "bg-cardTextBlue w-[184px] hover:bg-opacity-90 h-[50px] text-white"
					: btnStyle == "form_nav_secondary"
					? "border h-[50px] w-[184px] hover:bg-cardTextGray hover:bg-opacity-10 border-cardTextGray"
					: "border h-9 w-full border-cardTextGray"
			}`}
		>
			{text}
		</button>
	);
};

export default Button;
