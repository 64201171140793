import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { deleteBatchItemsInFirestore } from "../../utils/firebaseOperations";
import { toast } from "react-toastify";
import { collection, onSnapshot, query, orderBy, getDocs } from "firebase/firestore";
import { formattedDateTime, formattedDate } from "../../utils/helpers";
import { db } from "../../config/firebase";

// Storing unsubscribe function outside Redux
let unsubscribeListener = null;

const initialState = {
	list: [],
	loading: true,
	initialLoadComplete: false,
	error: null,
};

// Helper function to process generator data
const processGeneratorData = async (generatorDoc) => {
	try {
		const response = generatorDoc.data();

		// Get service schedules data
		const serviceSchedulesRef = collection(db, "serviceSchedules");
		const serviceSchedulesSnap = await getDocs(serviceSchedulesRef);
		const serviceSchedulesDocs = serviceSchedulesSnap.docs.map((doc) => ({
			id: doc.id,
			frequency: doc.data().serviceFrequency?.type,
		}));

		// Convert timestamps
		const createdAt = response.createdAt?.seconds ? new Date(response.createdAt.toDate()).toISOString() : null;
		const updatedAt = response.updatedAt?.seconds ? new Date(response.updatedAt.toDate()).toISOString() : null;

		// Process service frequency
		let serviceFrequency = ["Unscheduled"];
		if (Array.isArray(response.serviceSchedules) && response.serviceSchedules.length > 0) {
			serviceFrequency = response.serviceSchedules.map((scheduleId) => {
				const schedule = serviceSchedulesDocs.find((item) => item.id === scheduleId);
				return schedule?.frequency || "Unscheduled";
			});
		}

		// Construct address
		const address =
			[
				response.serviceAddress?.street,
				response.serviceAddress?.city,
				response.serviceAddress?.state,
				response.serviceAddress?.zipCode,
			]
				.filter(Boolean)
				.join(", ") || "--";

		// Return processed data
		return {
			id: generatorDoc.id,
			...response,
			name: response.generatorName ?? "--",
			email: response.generatorEmail?.length ? response.generatorEmail : "--",
			phone: response.generatorPhone?.length ? response.generatorPhone : "",
			internalAccountNumber: response.internalAccountNumber?.length ? response.internalAccountNumber : "--",
			octoNumber: response.octoNumber ?? "--",
			serviceType: response.serviceType ?? [],
			customerType: response.customerType ?? "--",
			contacts: [],
			serviceFrequency,
			address,
			scheduleDate: response.scheduleDate ? formattedDateTime(response.scheduleDate) : "",
			contractStartDate: response.contractStartDate ? formattedDate(response.contractStartDate) : "",
			contractEndDate: response.contractEndDate ? formattedDate(response.contractEndDate) : "",
			createdAt,
			updatedAt,
		};
	} catch (error) {
		console.error("Error processing generator data:", error);
		return null;
	}
};

// Thunk to start real-time listener
export const startGeneratorListener = createAsyncThunk("generator/startListener", async (_, { dispatch }) => {
	try {
		// Seting loading state when starting listener
		dispatch(generatorSlice.actions.setLoading(true));

		if (unsubscribeListener) {
			unsubscribeListener();
		}

		const generatorsRef = collection(db, "generators");
		const q = query(generatorsRef, orderBy("createdAt", "desc"));

		unsubscribeListener = onSnapshot(
			q,
			async (snapshot) => {
				try {
					// Process all documents
					const processedDocs = await Promise.all(
						snapshot.docs.map((doc) => processGeneratorData(doc)).filter(Boolean)
					);

					// Update generators and mark initial load as complete
					dispatch(
						generatorSlice.actions.updateGenerators({
							generators: processedDocs,
							isInitialLoad: !snapshot.metadata.hasPendingWrites,
						})
					);
				} catch (error) {
					console.error("Error processing snapshot data:", error);
					dispatch(generatorSlice.actions.setError(error.message));
				}
			},
			(error) => {
				console.error("Firestore listener error:", error);
				dispatch(generatorSlice.actions.setError(error.message));
			}
		);

		return null;
	} catch (error) {
		console.error("Error starting listener:", error);
		throw error;
	}
});

export const stopGeneratorListener = createAsyncThunk("generator/stopListener", async () => {
	if (unsubscribeListener) {
		unsubscribeListener();
		unsubscribeListener = null;
	}
});

export const deleteGenerator = createAsyncThunk("generator/deleteGenerator", async (generatorIds) => {
	try {
		await deleteBatchItemsInFirestore("generators", generatorIds);
		return generatorIds;
	} catch (error) {
		console.error("Error deleting generators:", error);
		throw error;
	}
});

export const generatorSlice = createSlice({
	name: "generator",
	initialState,
	reducers: {
		setLoading: (state, action) => {
			state.loading = action.payload;
		},
		updateGenerators: (state, action) => {
			const { generators, isInitialLoad } = action.payload;
			state.list = generators;
			if (isInitialLoad) {
				state.initialLoadComplete = true;
				state.loading = false;
			}
			state.error = null;
		},
		setError: (state, action) => {
			state.error = action.payload;
			state.loading = false;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(startGeneratorListener.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(startGeneratorListener.fulfilled, (state) => {
				// Don't set loading to false here, let the first snapshot do it
			})
			.addCase(startGeneratorListener.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})
			.addCase(deleteGenerator.pending, () => {
				toast.loading("Deleting...");
			})
			.addCase(deleteGenerator.fulfilled, (state, action) => {
				toast.dismiss();
				toast.success("Deleted successfully!");
			})
			.addCase(deleteGenerator.rejected, (state, action) => {
				toast.dismiss();
				toast.error("Failed to delete!");
				state.error = action.error.message;
			});
	},
});

export const { updateGenerators, setError, setLoading } = generatorSlice.actions;
export const allGenerators = (state) => state.generator.list;
export const isLoading = (state) => state.generator.loading;
export const hasError = (state) => state.generator.error;
export default generatorSlice.reducer;
