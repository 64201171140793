import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ManageDrivers from "./ManageDrivers";
import AddDriverForm from "./components/AddDriver";

const DriverManagement = () => {
	return (
		<div>

			{/* <AddDriverForm /> */}
			<ManageDrivers />
		</div>
	)
}

export default DriverManagement