import React, { useState } from "react";

const Tooltip = ({ text, children, bottom = "6" }) => {
	const [showTooltip, setShowTooltip] = useState(false);

	return (
		<div
			className="w-fit h-full flex relative items-center justify-center"
			onMouseEnter={() => {
				setShowTooltip(true);
			}}
			onMouseLeave={() => {
				setShowTooltip(false);
			}}
		>
			{children}
			{showTooltip && <div className={`absolute w-fit px-1 text-sm z-50 bottom-6 text-white bg-gray-800 rounded shadow-lg`}>{text}</div>}
		</div>
	);
};

export default Tooltip;
