import React from "react";

const AuthForm = ({ isLogin, handleInputChange, handleSubmit }) => {
	return (
		<form onSubmit={handleSubmit}>
			{/* {!isLogin && (
				<div className="mb-4">
					<label className="block text-gray-700">Name</label>
					<input type="text" name="name" onChange={handleInputChange} className="w-full px-3 py-2 border rounded focus:outline-none focus:ring" placeholder="Your Name" />
				</div>
			)} */}

			<div className="mb-4">
				<label className="block text-gray-700">Email</label>
				<input type="email" name="email" onChange={handleInputChange} className="w-full px-3 py-2 border rounded focus:outline-none focus:ring" placeholder="Email Address" />
			</div>

			<div className="mb-4">
				<label className="block text-gray-700">Password</label>
				<input type="password" name="password" onChange={handleInputChange} className="w-full px-3 py-2 border rounded focus:outline-none focus:ring" placeholder="Password" />
			</div>

			{!isLogin && (
				<div className="mb-4">
					<label className="block text-gray-700">Confirm Password</label>
					<input
						type="password"
						name="confirmPassword"
						onChange={handleInputChange}
						className="w-full px-3 py-2 border rounded focus:outline-none focus:ring"
						placeholder="Confirm Password"
					/>
				</div>
			)}
			<button type="submit" className="w-full bg-dashBtnGradient text-white py-[9px] rounded transition duration-200">
				{isLogin ? "Login" : "Sign Up"}
			</button>
		</form>
	);
};

export default AuthForm;
