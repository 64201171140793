import { collection, getDocs, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import {
	ResponsiveContainer,
	LineChart,
	Line,
	BarChart,
	Bar,
	PieChart,
	Pie,
	Cell,
	XAxis,
	YAxis,
	Tooltip,
	Legend,
} from "recharts";
import { db } from "../../config/firebase";
import { formattedDate } from "../../utils/helpers";
import { useNavigate } from "react-router-dom";

const DashboardPanel = () => {
	const [users, setUsers] = useState([]);
	const navigate = useNavigate();
	// Sparkline data for the stats cards
	const sparklineData = [
		{ value: 400 },
		{ value: 380 },
		{ value: 350 },
		{ value: 370 },
		{ value: 360 },
		{ value: 380 },
		{ value: 400 },
	];

	const barData = [
		{ name: "Jan", value1: 260, value2: 180 },
		{ name: "Feb", value1: 120, value2: 100 },
		{ name: "Mar", value1: 210, value2: 200 },
		{ name: "Apr", value1: 200, value2: 250 },
		{ name: "May", value1: 230, value2: 220 },
		{ name: "Jun", value1: 150, value2: 140 },
		{ name: "Jul", value1: 200, value2: 190 },
	];

	const pieData = [
		{ name: "Lorem ipsum 1", value: 43 },
		{ name: "Lorem ipsum 2", value: 28 },
		{ name: "Lorem ipsum 3", value: 29 },
	];

	const COLORS = ["#007AFF", "#C0C0C0", "#4CD964"];

	// Fetch all users
	const fetchAllUsers = async () => {
		try {
			const userQuery = query(collection(db, "octoMarketUsers"));
			const querySnapshot = await getDocs(userQuery);
			const allUsers = querySnapshot.docs.map((doc) => ({
				id: doc.id,
				...doc.data(),
				createdAt: doc.data()?.createdAt ? doc.data()?.createdAt?.toDate() : "",
			}));
			console.log(allUsers);
			setUsers(
				allUsers?.filter((item) => new Date(item.createdAt).getDate() == new Date().getDate() && item.profileCompleted)
			);
		} catch (error) {
			console.error("Error fetching users:", error);
		}
	};

	useEffect(() => {
		fetchAllUsers();
	}, []);

	console.log(users);

	return (
		<div className="p-4 my-4 w-full">
			{/* Stats Cards */}
			<div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6 mb-4 md:mb-8">
				<div className="p-4 md:p-6 rounded-lg border border-[#E2E2E2]">
					<div className="flex justify-between items-start">
						<div>
							<h2 className="text-lg font-medium mb-2">Total Users</h2>
							<p className="text-2xl font-bold">$456.3k</p>
							<p className="text-green-500 text-sm mt-1">+3.25% compared to last week</p>
						</div>
						<div className="w-24 h-12">
							<LineChart width={96} height={48} data={sparklineData}>
								<Line
									type="monotone"
									dataKey="value"
									stroke="#00C853"
									strokeWidth={2}
									dot={false}
									isAnimationActive={false}
								/>
							</LineChart>
						</div>
					</div>
				</div>
				<div className="p-4 md:p-6 rounded-lg border border-[#E2E2E2]">
					<div className="flex justify-between items-start">
						<div>
							<h2 className="text-lg font-medium mb-2">Total Sales</h2>
							<p className="text-2xl font-bold">$456.3k</p>
							<p className="text-green-500 text-sm mt-1">+3.25% compared to last week</p>
						</div>
						<div className="w-24 h-12">
							<LineChart width={96} height={48} data={sparklineData}>
								<Line
									type="monotone"
									dataKey="value"
									stroke="#00C853"
									strokeWidth={2}
									dot={false}
									isAnimationActive={false}
								/>
							</LineChart>
						</div>
					</div>
				</div>
			</div>

			{/* Charts Section */}
			<div className="grid grid-cols-1 lg:grid-cols-3 gap-4 md:gap-6 mb-4 md:mb-8">
				<div className="lg:col-span-2 p-4 md:p-6 rounded-lg border border-[#E2E2E2]">
					<div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 gap-2">
						<h2 className="text-lg font-medium">Sales Overview</h2>
						<button className="px-3 py-1 border rounded-md text-gray-600 whitespace-nowrap">6 months ▾</button>
					</div>
					<div className="h-[300px] md:h-[400px] w-full">
						<ResponsiveContainer width="100%" height="100%">
							<BarChart data={barData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
								<XAxis dataKey="name" />
								<YAxis />
								<Tooltip />
								<Legend />
								<Bar dataKey="value1" name="Revenue" fill="#3366FF" />
								<Bar dataKey="value2" name="Profit" fill="#00C853" />
							</BarChart>
						</ResponsiveContainer>
					</div>
				</div>

				<div className="p-4 md:p-6 rounded-lg border border-[#E2E2E2]">
					<h2 className="text-lg font-medium mb-4">Distribution</h2>
					<div className="h-[300px] md:h-[400px] w-full">
						<ResponsiveContainer width="100%" height="100%">
							<PieChart>
								<Pie data={pieData} cx="50%" cy="50%" innerRadius={60} outerRadius={90} dataKey="value" label>
									{pieData.map((entry, index) => (
										<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
									))}
								</Pie>
								<Tooltip />
								<Legend
									layout="vertical"
									align="end"
									verticalAlign="bottom"
									wrapperStyle={{
										paddingLeft: "20px",
									}}
								/>
							</PieChart>
						</ResponsiveContainer>
					</div>
				</div>
			</div>

			{/* Table Section */}
			{/* <div className="bg-white p-4 md:p-6 rounded-lg border border-[#E2E2E2] overflow-x-auto">
				<div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 gap-2">
					<h2 className="text-lg font-medium">User listing</h2>
					<p className="text-gray-500">Today</p>
				</div>
				<div className="min-w-full overflow-x-auto">
					<table className="w-full text-left table-auto">
						<thead>
							<tr className="text-gray-600 border-b">
								<th className="p-2 whitespace-nowrap">Name</th>
								<th className="p-2 whitespace-nowrap">Email ID</th>
								<th className="p-2 whitespace-nowrap">Phone Number</th>
								<th className="p-2 whitespace-nowrap">Company Name</th>
								<th className="p-2 whitespace-nowrap">Address</th>
								<th className="p-2 whitespace-nowrap">Created</th>
							</tr>
						</thead>
						<tbody>
							{users.map((user, i) => (
								<tr
									key={user?.id ?? i?.toString()}
									className={`${
										i + 1 < users.length ? "border-b" : ""
									} cursor-pointer hover:bg-cardTextGray hover:bg-opacity-15`}
									onClick={() => navigate(`/market-admin/users/${user.id}`)}
								>
									<td className="p-2 whitespace-nowrap">{user.name}</td>
									<td className="p-2 whitespace-nowrap">{user.email}</td>
									<td className="p-2 whitespace-nowrap">{user.phone}</td>
									<td className="p-2 whitespace-nowrap">{user.companyName}</td>
									<td className="p-2 whitespace-nowrap">{user.address}</td>
									<td className="p-2 whitespace-nowrap">{formattedDate(user.createdAt) ?? "--"}</td>
								</tr>
							))}
						</tbody>
					</table>
					{users.length == 0 && <div className="text-center w-full py-6">No users found</div>}
				</div>
			</div> */}
		</div>
	);
};

export default DashboardPanel;
