import PropTypes from "prop-types";
import { AdvancedMarker, InfoWindow, Pin } from "@vis.gl/react-google-maps";
import { useCallback, useState } from "react";
import { IoMdClose } from "react-icons/io";

const VendorSingleMarkerWithInfoWindow = ({ marker }) => {
	const [infoWindowOpen, setInfoWindowOpen] = useState(false);

	const handleMarkerClick = useCallback(() => {
		setInfoWindowOpen(true);
	}, []);

	const handleInfoWindowClose = useCallback(() => {
		setInfoWindowOpen(false);
	}, []);

	return (
		<>
			{infoWindowOpen && (
				<InfoWindow onCloseClick={handleInfoWindowClose} position={{ lat: marker.lat, lng: marker.lng }}>
					<div className="bg-white shadow-lg rounded-lg overflow-hidden relative">
						<div className="flex justify-between items-center bg-gray-100 p-2 border-b border-gray-300">
							<h2 className="text-lg font-semibold text-gray-800">{marker.name}</h2>
							<button
								onClick={handleInfoWindowClose}
								className="text-gray-500 hover:text-red-500 transition-colors duration-200"
							>
								<IoMdClose size={24} />
							</button>
						</div>
						<div className="border-t border-b h-fit p-4 text-sm">
							<div className="flex gap-6">
								<div className="font-medium text-nowrap w-1/3">
									<p>Vendor Name:</p>
									<p>Address:</p>
									<p>Type:</p>
								</div>
								<div className="w-2/3">
									<p className="text-nowrap">{marker.name}</p>
									<p>{marker.formattedAdd}</p>
									<p>{marker.vendorType?.join(", ") ?? "--"}</p>
								</div>
							</div>
						</div>
					</div>
					<style>{`
        .gm-style-iw.gm-style-iw-c {
          padding: 0 !important;
          overflow: hidden !important;
        }
        .gm-style-iw-ch {
          padding: 0 !important;
          overflow: hidden !important;
        }
        .gm-ui-hover-effect {
          display: none !important;
        }
        .gm-style-iw-d {
          padding: 0 !important;
          overflow: hidden !important;
        }
      `}</style>
				</InfoWindow>
			)}
			<AdvancedMarker position={{ lat: marker.lat, lng: marker.lng }} onClick={handleMarkerClick}>
				<Pin scale={1.2}>
					<img src="/vendor.svg" alt={marker?.name ?? ""} />
				</Pin>
			</AdvancedMarker>
		</>
	);
};

VendorSingleMarkerWithInfoWindow.propTypes = {
	marker: PropTypes.shape({
		assignedRoutes: PropTypes.string,
		formattedAdd: PropTypes.any,
		lat: PropTypes.number.isRequired,
		lng: PropTypes.number.isRequired,
		name: PropTypes.any,
		octoNumber: PropTypes.any,
		serviceFrequency: PropTypes.string,
		serviceType: PropTypes.array,
		vendorType: PropTypes.array,
	}),
};

export default VendorSingleMarkerWithInfoWindow;
