import { configureStore } from "@reduxjs/toolkit";
import formReducer from "./sliceReducers/formSlice";
import generatorReducer from "./sliceReducers/generatorSlice";
import userDataReducer from "./sliceReducers/octoUserSlice";
import notificationReducer from "./sliceReducers/notificationSlice";
import adminNotificationReducer from "./sliceReducers/adminNotificationSlice";

const store = configureStore({
	reducer: {
		form: formReducer,
		generator: generatorReducer,
		octoUserData: userDataReducer,
		notification: notificationReducer,
		adminNotification: adminNotificationReducer,
	},
});

export default store;
