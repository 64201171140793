const defaultValues = {
	parentAccount: "",
	generatorName: "",
	octoNumber: "",
	internalAccountNumber: "",
	generatorWebsite: "",
	generatorPhone: "",
	generatorStatus: "",
	generatorEmail: "",
	billingAddress: { street: "", suite: "", city: "", state: "", zipCode: "", email: "", phone: "" },
	serviceAddress: { street: "", suite: "", city: "", state: "", zipCode: "", email: "", phone: "" },
	// contractStartDate: "",
	// contractEndDate: "",
	// contractFileName: "",
	// contractFiles: null,
	// contractTerm: "",
	// terminationFee: "",
	contractDetail: {
		startDate: null,
		endDate: null,
		term: "",
		terminationFee: "",
		file: null,
	},
	serviceType: [],
	workingHours: {
		timeZone: "",
		Monday: {
			open: "",
			close: "",
			lunchStart: "",
			lunchEnd: "",
			closed: false,
			required: false,
		},
		Tuesday: {
			open: "",
			close: "",
			lunchStart: "",
			lunchEnd: "",
			closed: false,
			required: false,
		},
		Wednesday: {
			open: "",
			close: "",
			lunchStart: "",
			lunchEnd: "",
			closed: false,
			required: false,
		},
		Thursday: {
			open: "",
			close: "",
			lunchStart: "",
			lunchEnd: "",
			closed: false,
			required: false,
		},
		Friday: {
			open: "",
			close: "",
			lunchStart: "",
			lunchEnd: "",
			closed: false,
			required: false,
		},
		Saturday: {
			open: "",
			close: "",
			lunchStart: "",
			lunchEnd: "",
			closed: false,
			required: false,
		},
	},
};

import React, { useEffect, useMemo, useRef, useState } from "react";
import MapWithMarkers from "../../../../../../../../components/maps/MapWithMarkers";
import Dropdown from "../../../../../../../../components/UI/dropdowns/Dropdown";
import { generatorStatus, serviceTypes, stateTimezones } from "../../../../../../../../utils/constants";
import Input from "../../../../../../../../components/UI/Input";
import Button from "../../../../../../../../components/UI/Button";
import { useForm, Controller } from "react-hook-form";
import GeneratorWorkingHours from "./components/GeneratorWorkingHours";
import { createOrUpdateGenerator } from "../../../../../../../../utils/firebaseOperations";
import { toast } from "react-toastify";
import AddressForm from "./components/AddressForm";
import { useNavigate } from "react-router-dom";
import MultiSelectRounded from "../../../../../../../../components/UI/dropdowns/MultiSelectRounded";
import PhoneInput from "../../../../../../../../components/UI/PhoneInput";
import { useGeocoding } from "../../../../../../../../hooks/useGeocoding";
import GeneratorInfoHeader from "./components/GeneratorInfoHeader";
import CustomDatePicker from "../../../../../../../../components/UI/CustomDatePicker";
import FileUpload from "../../../../../../../../components/UI/FileUpload";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../../../../../../../../config/firebase";
import { getContractEndDate } from "../../../../../../../../utils/helpers";
import { updateProfileData, updateProfileInDB } from "../../../../../../../../store/sliceReducers/formSlice";
import { useDispatch, useSelector } from "react-redux";
import FooterActionBtns from "./components/FooterActionBtns";
import MapWithSingleMarker from "../../../../../../../../components/maps/MapWithSingleMarker";
import { debounce } from "lodash";

const GeneratorProfile = ({ onNextClick, onClickBack, generatorData = {} }) => {
	const {
		handleSubmit,
		control,
		setValue,
		getValues,
		watch,
		trigger,
		formState: { errors },
	} = useForm({
		defaultValues,
	});

	const watchBillingAddress = watch("billingAddress") || {};
	const watchServiceAddress = watch("serviceAddress") || {};
	const contractTerm = watch("contractDetail.term");
	const contractStartDate = watch("contractDetail.startDate");
	const [isAddressesLoaded, setIsAddressesLoaded] = useState(false);
	const { batchGeocodeGeneratorsAddresses, calculateCenterPosition } = useGeocoding();
	const [currentCoordinates, setCurrentCoordinates] = useState(null);
	const [markers, setMarkers] = useState([]);
	const [parentList, setParentList] = useState([]);
	const dispatch = useDispatch();
	const { list: generators, loading } = useSelector((state) => state.generator);

	useEffect(() => {
		setParentList(
			generators
				.map((item) => ({ label: item.generatorName, value: item.id }))
				.filter((item) => item.value !== generatorData.id)
		);
	}, [generators, generatorData.id]);

	const debouncedGeocoding = useMemo(
		() =>
			debounce(async (address) => {
				if (!address) return;

				try {
					setIsAddressesLoaded(false);
					console.log("Fetching new coordinates for:", address);

					const markers = await batchGeocodeGeneratorsAddresses([
						{
							name: generatorData.generatorName || "New Generator",
							serviceAddress: address,
						},
					]);

					if (markers && markers[0]) {
						setMarkers(markers);
						setCurrentCoordinates({
							lat: markers[0].lat,
							lng: markers[0].lng,
						});
					}

					setIsAddressesLoaded(true);
				} catch (error) {
					console.error("Geocoding error:", error);
					toast.error("Error getting location coordinates");
					setIsAddressesLoaded(true);
				}
			}, 1000),
		[generatorData.generatorName]
	);

	// Watch for service address changes
	useEffect(() => {
		const serviceAddress = watchServiceAddress;

		// Check if we have all required fields
		if (!serviceAddress.city?.trim() || !serviceAddress.zipCode?.trim() || !serviceAddress.state?.trim()) {
			return;
		}

		// Set timezone based on state
		const genTimeZone = stateTimezones[serviceAddress.state];
		setValue("workingHours.timeZone", genTimeZone);

		// Format address for comparison
		const currentAddressString = `${
			serviceAddress.street?.trim() || ""
		} ${serviceAddress.city.trim()} ${serviceAddress.state.trim()} ${serviceAddress.zipCode.trim()}`.toLowerCase();
		const oldAddressString = generatorData.serviceAddress
			? `${
					generatorData.serviceAddress.street?.trim() || ""
			  } ${generatorData.serviceAddress.city.trim()} ${generatorData.serviceAddress.state.trim()} ${generatorData.serviceAddress.zipCode.trim()}`.toLowerCase()
			: "";

		// If address has changed, update coordinates
		if (currentAddressString !== oldAddressString) {
			debouncedGeocoding(serviceAddress);
		} else {
			if (generatorData.serviceAddCoordinates && Object.keys(generatorData.serviceAddCoordinates).length) {
				const { lat, lng } = generatorData.serviceAddCoordinates;
				setMarkers([
					{
						...generatorData,
						lat,
						lng,
						name: generatorData.generatorName,
						key: Math.random().toString(36).substr(2, 9),
						formattedAdd: `${generatorData.serviceAddress?.street}, ${generatorData.serviceAddress?.city}, ${generatorData.serviceAddress?.state} ${generatorData.serviceAddress?.zipCode}`,
					},
				]);
			} else {
				debouncedGeocoding(serviceAddress);
				console.log({ serviceAddress });
			}
			setIsAddressesLoaded(true);
		}

		return () => {
			debouncedGeocoding.cancel();
		};
	}, [watchServiceAddress.street, watchServiceAddress.city, watchServiceAddress.state, watchServiceAddress.zipCode]);

	useEffect(() => {
		if (generatorData) {
			setCurrentCoordinates(generatorData["serviceAddCoordinates"]);
			Object.keys(defaultValues).forEach((key) => {
				setValue(key, generatorData[key]);
			});

			if (
				!generatorData.serviceAddress?.city &&
				!generatorData.serviceAddress?.state &&
				!generatorData.serviceAddress?.zipCode
			) {
				setIsAddressesLoaded(true);
			} else {
				console.log("Not set to true", generatorData.serviceAddress);
			}
		}
	}, [generatorData]);

	console.log({ generatorData, currentCoordinates });

	const onSubmit = async (data) => {
		try {
			// Address validation
			const isBillingAddressFilled = Object.values(data.billingAddress).some((value) => value.trim() !== "");
			const isServiceAddressFilled = Object.values(data.serviceAddress).some((value) => value.trim() !== "");

			if (isBillingAddressFilled) {
				const isBillingValid = await trigger("billingAddress");
				if (!isBillingValid) {
					toast.error("Please fill all required fields in the billing address.");
					return;
				}
			}

			if (isServiceAddressFilled) {
				const isServiceValid = await trigger("serviceAddress");
				if (!isServiceValid) {
					toast.error("Please fill all required fields in the service address.");
					return;
				}
			}

			// If we don't have coordinates but have a service address, something went wrong
			if (isServiceAddressFilled && !currentCoordinates) {
				toast.error("Unable to get location coordinates. Please try again.");
				console.log({ isServiceAddressFilled, currentCoordinates });

				return;
			}

			// Prepare data for submission
			const dataToBeSent = {
				id: generatorData.id,
				...data,
				onboardingStatus: {
					...(generatorData.onboardingStatus || {}),
					registrationCompleted: true,
					profileCompleted: true,
					contactsCompleted: true,
				},
			};

			// Always using the most recent coordinates
			if (currentCoordinates) {
				dataToBeSent.serviceAddCoordinates = currentCoordinates;
			}

			// Handling contract file upload
			if (data.contractFiles) {
				const { contractFiles, isServiceCopyChecked, isBillingCopyChecked, ...rest } = dataToBeSent;

				const storageRef = ref(storage, `/admin1/generator-${dataToBeSent?.id}/contractFiles/${contractFiles?.name}`);

				const snapshot = await uploadBytes(storageRef, contractFiles);
				const downloadURL = await getDownloadURL(snapshot.ref);
				const urlParams = new URL(downloadURL).searchParams;
				const token = urlParams.get("token");

				rest.contractDetail = {
					...data.contractDetail,
					file: {
						name: contractFiles?.name,
						link: downloadURL,
						mime: contractFiles?.type,
						path: storageRef.fullPath,
						ref: "",
						token: token ?? "",
					},
				};

				dispatch(updateProfileData(rest));
				await dispatch(updateProfileInDB(rest));
			} else {
				const { isServiceCopyChecked, isBillingCopyChecked, contractFiles, ...rest } = dataToBeSent;

				rest.contractDetail = {
					startDate: null,
					endDate: null,
					term: "",
					terminationFee: "",
					file: null,
					...rest.contractDetail,
				};

				dispatch(updateProfileData(rest));
				await dispatch(updateProfileInDB(rest));
			}

			toast.success("Saved successfully!");
			onNextClick();
		} catch (error) {
			console.error("Error saving generator profile:", error);
			toast.error("Error saving data: " + error.message);
		}
	};

	const handleCopyAddress = (isChecked, copyfrom) => {
		if (copyfrom == "service") {
			if (isChecked && watchBillingAddress) {
				Object.keys(watchBillingAddress).forEach((key) => {
					setValue(`serviceAddress.${key}`, watchBillingAddress[key]);
				});
			}
		} else if (copyfrom == "billing") {
			if (isChecked && watchServiceAddress) {
				Object.keys(watchServiceAddress).forEach((key) => {
					setValue(`billingAddress.${key}`, watchServiceAddress[key]);
				});
			}
		}
	};

	const initialCenter = useMemo(() => {
		return markers.length > 0 ? calculateCenterPosition(markers) : { lat: 32.7157, lng: -117.1611 };
	}, [markers]);

	const onDeleteFile = () => {
		console.log("File deleted");
	};

	useEffect(() => {
		console.log({ contractStartDate });

		if (contractStartDate && contractTerm) {
			let contractEndDate = getContractEndDate(contractStartDate, contractTerm);
			setValue("contractDetail.endDate", contractEndDate);
		}
	}, [contractTerm, contractStartDate]);

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			className="bg-white p-8 py-6 mb-8 flex flex-col gap-6 rounded-cardRadii flex-grow"
		>
			<GeneratorInfoHeader generatorData={generatorData} />
			<div className="flex flex-col h-[480px] z-0 gap-4">
				<h6 className="font-medium text-lg">Generator Service Location</h6>
				{isAddressesLoaded && (
					<MapWithSingleMarker
						geocodedMarker={markers[0]}
						isAddressesLoaded={isAddressesLoaded}
						initialCenter={initialCenter}
						zoomVal={16}
					/>
				)}
			</div>
			<div className="grid gap-2">
				<h6 className="font-medium py-2 text-lg border-b border-[#CCCCCC]">Generator Profile</h6>
				<div className="flex gap-8 w-full">
					<div className="w-1/2">
						<Controller
							name="generatorName"
							control={control}
							rules={{ required: "Generator Name is required" }}
							render={({ field: { onChange, value } }) => (
								<Input label="Generator Name" value={value} onChange={onChange} isRequired={true} />
							)}
						/>
						{errors.generatorName && <p className="text-red-500 text-sm mt-1">{errors.generatorName.message}</p>}
						<Controller
							name="octoNumber"
							control={control}
							rules={{ required: "Octo Number is required" }}
							render={({ field: { onChange, value } }) => (
								<Input
									label="OCTO Number"
									isDisabled={true}
									extra="bg-inputBg"
									value={value}
									onChange={onChange}
									isRequired={true}
								/>
							)}
						/>
						{errors.octoNumber && <p className="text-red-500 text-sm mt-1">{errors.octoNumber.message}</p>}
						<Controller
							name="internalAccountNumber"
							control={control}
							// rules={{ required: "Internal Account Number is required" }}
							render={({ field: { onChange, value } }) => (
								<Input label="Internal Account Number" value={value} onChange={onChange} />
							)}
						/>
						{/* {errors.internalAccountNumber && <p className="text-red-500 text-sm mt-1">{errors.internalAccountNumber.message}</p>} */}
						<Controller
							name="generatorWebsite"
							control={control}
							render={({ field: { onChange, value } }) => (
								<Input label="Generator Website" value={value} onChange={onChange} type="url" />
							)}
						/>
					</div>

					<div className="w-1/2">
						<Controller
							name="parentAccount"
							control={control}
							// rules={{ required: "Parent Account is required" }}
							render={({ field: { onChange, value } }) => (
								<Dropdown label="Parent Account" options={parentList} value={value} onChange={onChange} />
							)}
						/>
						{errors.parentAccount && <p className="text-red-500 text-sm mt-1">{errors.parentAccount.message}</p>}
						<Controller
							name="generatorPhone"
							control={control}
							// rules={{ required: "Generator main phone is required" }}
							render={({ field: { onChange, value } }) => (
								// <Input label="Generator Main Phone" value={value} onChange={onChange} />
								<PhoneInput label="Generator Main Phone" value={value} onChange={onChange} />
							)}
						/>
						{errors.generatorPhone && <p className="text-red-500 text-sm mt-1">{errors.generatorPhone.message}</p>}
						<Controller
							name="generatorEmail"
							control={control}
							rules={{
								// required: "Generator Email is required",
								pattern: {
									value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
									message: "Please enter a valid email address",
								},
							}}
							render={({ field: { onChange, value } }) => (
								<Input label="Generator Email" value={value} onChange={onChange} />
							)}
						/>
						{/* {errors.generatorEmail && <p className="text-red-500 text-sm mt-1">{errors.generatorEmail.message}</p>} */}
						<Controller
							name="generatorStatus"
							control={control}
							rules={{ required: "Generator Status is required" }}
							render={({ field: { onChange, value } }) => (
								<Dropdown
									label="Generator Status"
									options={generatorStatus}
									value={value}
									onChange={onChange}
									isRequired={true}
								/>
							)}
						/>
						{errors.generatorStatus && <p className="text-red-500 text-sm mt-1">{errors.generatorStatus.message}</p>}
					</div>
				</div>
			</div>
			{/* <AddressForm control={control} handleCopyAddress={handleCopyAddress} /> */}
			<AddressForm control={control} handleCopyAddress={handleCopyAddress} errors={errors} trigger={trigger} />
			<div className="w-full">
				{/* Contract Details */}
				<h6 className="font-medium py-2 text-lg border-b mb-2 border-[#CCCCCC]">Contract Details</h6>
				<div className="grid grid-cols-2 items-start gap-8">
					<div className="mt-4">
						<div className="flex items-center justify-between">
							<label htmlFor={"contractStartDate"} className="truncate text-inputLabel font-normal">
								Contract Start Date
							</label>
							<div className="w-2/3">
								<Controller
									name="contractDetail.startDate"
									control={control}
									// rules={{ required: "Contract Start Date is required" }}
									render={({ field: { value, onChange } }) => (
										<CustomDatePicker selectedDate={value} setSelectedDate={onChange} label={"Contract Start Date"} />
									)}
								/>
							</div>
						</div>
						{errors.contractDetail?.startDate && (
							<p className="text-red-500 text-sm mt-1">{errors.contractDetail?.startDate?.message}</p>
						)}
						<div className="flex items-center justify-between mt-4">
							<label htmlFor={"contractEndDate"} className="truncate text-inputLabel font-normal">
								Contract End Date
							</label>
							<div className="w-2/3">
								<Controller
									name="contractDetail.endDate"
									control={control}
									// rules={{ required: "Contract End Date is required" }}
									render={({ field: { value, onChange } }) => (
										<CustomDatePicker
											selectedDate={value}
											setSelectedDate={onChange}
											label={"Contract End Date"}
											isDisabled={true}
										/>
									)}
								/>
							</div>
						</div>
						{errors.contractDetail?.endDate && (
							<p className="text-red-500 text-sm mt-1">{errors.contractDetail?.endDate?.message}</p>
						)}
						<FileUpload
							control={control}
							errors={errors}
							existingFile={generatorData?.contractDetail?.file}
							onDeleteFile={onDeleteFile}
						/>
					</div>
					<div>
						<Controller
							name="contractDetail.term"
							control={control}
							// rules={{ required: "Contract Term is required" }}
							render={({ field: { value, onChange } }) => (
								<div>
									<Dropdown
										label={"Contract Term"}
										options={[
											{ label: "Month to Month", value: "MTM" },
											{ label: "12 Months", value: "12_MONTHS" },
											{ label: "24 Months", value: "24_MONTHS" },
											{ label: "36 Months", value: "36_MONTHS" },
											{ label: "48 Months", value: "48_MONTHS" },
											{ label: "60 Months", value: "60_MONTHS" },
										]}
										value={value}
										onChange={onChange}
									/>
									{errors.contractDetail?.term && (
										<p className="text-red-500 text-sm mt-1">{errors.contractDetail?.term?.message}</p>
									)}
								</div>
							)}
						/>
						<Controller
							name="contractDetail.terminationFee"
							control={control}
							// rules={{ required: "Termination fee is required" }}
							render={({ field: { onChange, value } }) => (
								<Input label="Estimated Termination Fee" value={value} onChange={onChange} />
							)}
						/>
						{errors.contractDetail?.terminationFee && (
							<p className="text-red-500 text-sm mt-1">{errors.contractDetail?.terminationFee?.message}</p>
						)}
						<Controller
							name="serviceType"
							control={control}
							rules={{ required: "Service Type is required" }}
							render={({ field: { onChange, value } }) => (
								<MultiSelectRounded
									value={value}
									onChange={onChange}
									options={serviceTypes}
									label="Service Type"
									id="service-input"
									isRequired={true}
								/>
							)}
						/>
						{errors.serviceType && <p className="text-red-500 text-sm mt-1">{errors.serviceType.message}</p>}
					</div>
				</div>
			</div>
			<div className="">
				<GeneratorWorkingHours
					control={control}
					errors={errors}
					getValues={getValues}
					setValue={setValue}
					watch={watch}
				/>
			</div>
			<FooterActionBtns onClickBack={onClickBack} />
		</form>
	);
};

export default GeneratorProfile;
