import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../../config/firebase';
 import { toast } from 'react-toastify';


const ProtectedRoute = ({ children, requiredRoles }) => {
  const [user, loading, error] = useAuthState(auth);

  // Handle loading state
  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        loading...
       </div>
    );
  }

  // Handle authentication errors
  if (error) {
    toast.error('Authentication error occurred');
    return <Navigate to="/transporter-auth" replace />;
  }

  // No user found, redirect to authentication
  if (!user) {
    toast.info('Please log in to access this page');
    return <Navigate to="/transporter-auth" replace />;
  }

  // Optional: Role-based access control
//   if (requiredRoles && requiredRoles.length > 0) {
//      const userRole = user.role;  
//     if (!requiredRoles.includes(userRole)) {
//       toast.error('You do not have permission to access this page');
//       return <Navigate to="/transporter-auth" replace />;
//     }
//   }

   return children;
};

export default ProtectedRoute;