import React, { useEffect, useState } from "react";
import AuthForm from "./components/auth/Auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, signOutUser } from "../../config/firebase";
import LandingPage from "./components/LandingPage";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/UI/loaders/Loader";
import Header from "./components/Header";

const OctoMarket = () => {
	const [user, loading] = useAuthState(auth);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		setIsLoading(false);
	}, []);

	if (loading || isLoading) return <Loader />;
	if (!user && !isLoading && !loading) return <AuthForm />;

	return (
		<div className="h-full">
			<LandingPage />
			<Footer />
		</div>
	);
};

export default OctoMarket;

const Footer = () => {
	return (
		<div className="h-[240px] text-white flex items-center justify-center text-center mt-20 bg-[#007AFF]">
			<div className="flex flex-col gap-4">
				<div>
					<p className="font-semibold text-3xl">San Diego Medical Waste Services, LLC</p>
					<p className="text-xl">8940 Activity Rd. Suite H, San Diego, CA 92126</p>
				</div>
				<div>
					<p className="text-lg">
						<strong>Email:</strong>{" "}
						<a href="mailto:info@medicalwastecompany.com" className="underline hover:text-blue-200">
							info@medicalwastecompany.com
						</a>
					</p>
					<p className="text-lg">
						<strong>Phone:</strong>{" "}
						<a href="tel:619-990-4604" className="hover:text-blue-200">
							619-990-4604
						</a>
					</p>
				</div>
			</div>
		</div>
	);
};
