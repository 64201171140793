import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { doc, updateDoc, onSnapshot, arrayRemove, arrayUnion, serverTimestamp, getDoc } from "firebase/firestore";
import { db } from "../../config/firebase";
import { formatRelativeTimeDetailed } from "../../utils/constants";

// External listener to maintain across Redux store updates
let unsubscribeListener = null;

// Admin document ID (fixed)
const ADMIN_DOC_ID = "nv2LiJeJRbZcrKGTT4RByJdw3pA3";

const initialState = {
	notifications: [],
	unreadCount: 0,
	loading: false,
	error: null,
};

// Utility function to convert Timestamp to serializable format
const convertTimestampToDate = (notification) => {
	let timestamp = notification.timestamp;

	if (!timestamp || typeof timestamp === "string") {
		return notification;
	}

	if (timestamp?.toDate instanceof Function) {
		timestamp = timestamp.toDate();
	}

	if (timestamp instanceof Date) {
		timestamp = timestamp;
	}

	if (typeof timestamp === "number") {
		timestamp = new Date(timestamp);
	}

	if (timestamp?.seconds) {
		timestamp = new Date(timestamp.seconds * 1000);
	}

	notification.timestamp = formatRelativeTimeDetailed(timestamp);
	return notification;
};

// Async Thunk for setting up real-time notification listener
export const setupAdminNotificationListener = createAsyncThunk(
	"adminNotifications/setupListener",
	(_, { dispatch, rejectWithValue }) => {
		try {
			// Clear existing listener if it exists
			if (unsubscribeListener) {
				unsubscribeListener();
			}

			// Reference to admin document
			const adminDocRef = doc(db, "admins", ADMIN_DOC_ID);

			// Set up for real-time listener on admin document
			unsubscribeListener = onSnapshot(
				adminDocRef,
				(doc) => {
					const adminData = doc.data();
					const notifications = adminData?.notifications || [];

					// Convert timestamps and sort notifications
					const processedNotifications = notifications
						.sort((a, b) => {
							const dateA = a.timestamp?.toDate();
							const dateB = b.timestamp?.toDate();
							return dateB - dateA;
						})
						?.map((item) => convertTimestampToDate(item));

					// Dispatch actions to update notifications and unread count
					dispatch(adminNotificationSlice.actions.setNotifications(processedNotifications));
					dispatch(adminNotificationSlice.actions.setUnreadCount(processedNotifications.filter((n) => !n.read).length));
				},
				(error) => {
					dispatch(adminNotificationSlice.actions.setError(error.message));
				}
			);

			return null;
		} catch (error) {
			console.error("Error starting admin listener:", error);
			throw error;
		}
	}
);

// Async Thunk for marking a notification as read
export const markAdminNotificationAsRead = createAsyncThunk(
	"adminNotifications/markAsRead",
	async (notificationId, { getState, rejectWithValue }) => {
		try {
			const adminRef = doc(db, "admins", ADMIN_DOC_ID);
			let adminData = await getDoc(adminRef);

			// Find the specific notification
			const notificationToUpdate = adminData.data().notifications.find((n) => n.id === notificationId);
			let filteredNotifications = adminData.data().notifications.filter((n) => n.id !== notificationId);

			if (!notificationToUpdate) {
				throw new Error("Notification not found");
			}

			const updatedNotification = {
				...notificationToUpdate,
				read: true,
			};

			await updateDoc(adminRef, {
				notifications: [...filteredNotifications, updatedNotification],
			});

			return notificationId;
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

// Async Thunk for adding a new notification
export const addAdminNotification = createAsyncThunk(
	"adminNotifications/add",
	async (notification, { rejectWithValue }) => {
		try {
			const adminRef = doc(db, "admins", ADMIN_DOC_ID);

			const newNotification = {
				...notification,
				id: `admin_notification_${Date.now()}`,
				timestamp: serverTimestamp(),
				read: false,
			};

			await updateDoc(adminRef, {
				notifications: arrayUnion(newNotification),
			});

			return newNotification;
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

// Create Admin Notification Slice
const adminNotificationSlice = createSlice({
	name: "adminNotifications",
	initialState,
	reducers: {
		setNotifications: (state, action) => {
			state.notifications = action.payload;
		},
		setUnreadCount: (state, action) => {
			state.unreadCount = action.payload;
		},
		setError: (state, action) => {
			state.error = action.payload;
			state.loading = false;
		},
		clearNotificationListener: () => {
			if (unsubscribeListener) {
				unsubscribeListener();
				unsubscribeListener = null;
			}
		},
	},
	extraReducers: (builder) => {
		builder
			// Handle setup listener
			.addCase(setupAdminNotificationListener.pending, (state) => {
				state.loading = true;
			})
			.addCase(setupAdminNotificationListener.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(setupAdminNotificationListener.rejected, (state, action) => {
				state.error = action.payload;
				state.loading = false;
			})
			// Handle mark as read
			.addCase(markAdminNotificationAsRead.pending, (state) => {
				state.loading = true;
			})
			.addCase(markAdminNotificationAsRead.fulfilled, (state, action) => {
				state.notifications = state.notifications.map((notification) =>
					notification.id === action.payload ? { ...notification, read: true } : notification
				);
				state.unreadCount = state.notifications.filter((n) => !n.read).length;
				state.loading = false;
			})
			.addCase(markAdminNotificationAsRead.rejected, (state, action) => {
				state.error = action.payload;
				state.loading = false;
			});
	},
});

export const { setNotifications, setUnreadCount, setError, clearNotificationListener } = adminNotificationSlice.actions;

export default adminNotificationSlice.reducer;
