import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	setupUserNotificationListener,
	clearNotificationListener,
	markNotificationAsRead,
} from "../../../store/sliceReducers/notificationSlice";
import { AnimatePresence, motion } from "framer-motion";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../config/firebase";
import { IoClose, IoNotifications } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const NotificationItem = ({ id, title, message, time, read, onClickHandler, type, data }) => {
	console.log({ data });
	return (
		<div
			key={id}
			className="border-b border-gray-200 last:border-0 p-2 hover:bg-gray-200 cursor-pointer transition-colors"
			onClick={onClickHandler}
		>
			<div
				className={`flex gap-1 ${
					read == false ? "text-gray-900 pl-1.5" : "text-gray-600 pl-3.5"
				} font-medium items-center`}
			>
				{!read && <span className="h-2.5 w-2.5 rounded-full bg-[#4CD964] border-2 border-white"></span>}
				<h4 className={`${read}`}>
					{type == "connection_request_received"
						? "Connection request received!"
						: type == "connection_request_sent"
						? "Connection request sent!"
						: ""}
					{type == "connection_request_canceled_by_sender" ? `Connection request canceled!` : ""}
					{type == "connection_request_canceled" ? `Connection request canceled!` : ""}
					{type == "connection_request_declined" ? `Connection request declined!` : ""}
					{type == "connection_request_declined_by_user" ? `Connection request declined!` : ""}
					{type == "connection_accepted" ? `Connection request accepted!` : ""}
					{type == "connection_request_accepted" ? `Connection request accepted!` : ""}
					{type == "approval_request_sent" ? `Approval request sent!` : ""}
					{type == "approval_request_received" ? `Approval request received!` : ""}
					{type == "request_rejected_for_user" ? `Approval request rejected!` : ""}
					{type == "request_approved_for_user" ? `Approval request accepted!` : ""}
					{type == "request_rejected_by_admin" ? `Approval request rejected!` : ""}
					{type == "request_approved_by_admin" ? `Approval request accepted!` : ""}
				</h4>
			</div>
			<div className={`${read == false ? "text-gray-700 px-5" : "px-3.5 text-gray-500"}`}>
				<p className="text-sm mt-1">
					{type == "connection_request_received"
						? `${data?.senderData?.companyName} has sent you a request to connect.`
						: type == "connection_request_sent"
						? `Connect request has been sent to ${data.targetUserData?.companyName}`
						: ""}
					{type == "connection_request_canceled_by_sender"
						? `Connection request has been canceled by the user ${data?.senderData?.companyName}`
						: ""}
					{type == "connection_request_declined_by_user"
						? `Connection request has been declined by the user ${data?.senderData?.companyName}`
						: ""}
					{type == "connection_request_canceled"
						? `Connection requested to ${data?.targetUserData?.companyName} has been canceled`
						: ""}
					{type == "connection_request_declined"
						? `Connection requested by ${data?.targetUserData?.companyName} has been declined`
						: ""}
					{type == "connection_accepted"
						? `Connection requested by ${data?.targetUserData?.companyName} has been accepted!`
						: ""}
					{type == "connection_request_accepted"
						? `Connection request has been accepted by ${data?.acceptorData?.companyName}!`
						: ""}
					{type == "approval_request_sent" ? `Approval pending from admin, it will take a while!` : ""}
					{type == "approval_request_received"
						? `Profile completed by ${data?.senderData?.name || data?.senderData?.companyName}, approval pending!`
						: ""}
					{type == "request_rejected_for_user"
						? `Approval request rejected of user ${data?.senderData?.name || data?.senderData?.companyName}!`
						: ""}
					{type == "request_approved_for_user"
						? `Approval request approved of user ${data?.senderData?.name || data?.senderData?.companyName}!`
						: ""}
					{type == "request_rejected_by_admin" ? `Approval request rejected by admin!` : ""}
					{type == "request_approved_by_admin" ? `Approval request approved by admin, Woo!` : ""}
				</p>
				<span className="text-xs text-gray-500 mt-1 block">{time}</span>
			</div>
		</div>
	);
};

export const UsersNotifications = () => {
	const dispatch = useDispatch();
	const [user, loading] = useAuthState(auth);
	const {
		notifications,
		unreadCount,
		loading: notificationLoading,
		error,
	} = useSelector((state) => state.notification);
	const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
	const [hasUnreadNotification, setHasUnreadNotification] = useState(false);
	const navigate = useNavigate();

	// Close notifications when clicking outside
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (isNotificationsOpen && !event.target.closest(".notifications-container")) {
				setIsNotificationsOpen(false);
			}
		};

		document.addEventListener("click", handleClickOutside);
		return () => document.removeEventListener("click", handleClickOutside);
	}, [isNotificationsOpen]);

	// Set up listener when component mounts
	useEffect(() => {
		if (user) {
			const listenerUnsubscribe = dispatch(setupUserNotificationListener(user.uid));

			// Cleanup listener when component unmounts
			return () => {
				dispatch(clearNotificationListener());
			};
		}
	}, [user, dispatch]);

	const onClickHandler = async (notification) => {
		console.warn("Clicked to mark as read!!", user.uid, notification.id);
		setIsNotificationsOpen(false);
		dispatch(
			markNotificationAsRead({
				userId: user.uid,
				notificationId: notification.id,
			})
		);
		navigate("/market/connections");
	};

	const toggleNotifications = (e) => {
		e.stopPropagation();
		setIsNotificationsOpen(!isNotificationsOpen);
	};

	useEffect(() => {
		let doesExist = notifications.find((notification) => notification.read == false);
		setHasUnreadNotification(doesExist);
	}, [notifications]);

	return <Notifications notifications={notifications} onNotificationClick={onClickHandler} onClose={() => {}} />;
};

const Notifications = ({ notifications, onNotificationClick, onClose }) => {
	const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
	const [hasUnreadNotification, setHasUnreadNotification] = useState(false);
	const notificationRef = useRef(null);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (notificationRef.current && !notificationRef.current.contains(event.target)) {
				setIsNotificationsOpen(false);
			}
		};

		document.addEventListener("click", handleClickOutside);
		return () => document.removeEventListener("click", handleClickOutside);
	}, []);

	// Check if there are unread notifications
	useEffect(() => {
		setHasUnreadNotification(notifications.some((n) => !n.read));
	}, [notifications]);

	const toggleNotifications = (e) => {
		e.stopPropagation();
		setIsNotificationsOpen(!isNotificationsOpen);
	};

	const handleNotificationClick = (notification) => {
		onNotificationClick(notification); // Notify parent about the click
		setIsNotificationsOpen(false);
	};

	const closeDropdown = () => {
		setIsNotificationsOpen(false);
		if (onClose) onClose(); // Optional callback for additional actions on close
	};

	return (
		<div ref={notificationRef} className="notifications-container relative h-6 w-fit">
			<NotificationButton hasUnreadNotification={hasUnreadNotification} toggleNotifications={toggleNotifications} />
			<NotificationDropdown
				isOpen={isNotificationsOpen}
				notifications={notifications}
				onClose={closeDropdown}
				onClickNotification={(notification) => handleNotificationClick(notification)}
			/>
		</div>
	);
};

export default Notifications;

const NotificationButton = ({ hasUnreadNotification, toggleNotifications }) => {
	return (
		<motion.button
			whileHover={{ scale: 1.1 }}
			whileTap={{ scale: 0.95 }}
			onClick={toggleNotifications}
			className="relative w-fit h-fit"
		>
			<IoNotifications color="#000000B2" size={24} className="cursor-pointer" />
			{hasUnreadNotification && (
				<span className="h-2.5 w-2.5 rounded-full bg-[#4CD964] absolute border-2 border-white top-0 right-0"></span>
			)}
		</motion.button>
	);
};

const NotificationDropdown = ({ isOpen, notifications, onClose, onClickNotification }) => {
	const notificationVariants = {
		hidden: { opacity: 0, y: -10 },
		visible: { opacity: 1, y: 0 },
		exit: { opacity: 0, y: -10 },
	};

	const clickHandle = (notification) => {
		console.log(notification);
		onClickNotification(notification);
	};

	return (
		<AnimatePresence>
			{isOpen && (
				<motion.div
					variants={notificationVariants}
					initial="hidden"
					animate="visible"
					exit="exit"
					className="absolute right-0 mt-2 w-80 bg-white rounded-lg shadow-lg border border-gray-200 overflow-hidden z-50"
				>
					<div className="flex items-center justify-between p-4 py-3 border-b border-gray-200">
						<h3 className="font-medium text-gray-900">Notifications</h3>
						<motion.button
							whileHover={{ scale: 1.1 }}
							whileTap={{ scale: 0.95 }}
							onClick={onClose}
							className="text-gray-500 hover:text-gray-700"
						>
							<IoClose size={20} />
						</motion.button>
					</div>
					<div className="max-h-96 overflow-y-auto">
						{notifications.length > 0 ? (
							notifications.map((notification, i) => (
								<NotificationItem
									key={(notification.id + i)?.toString()}
									id={(notification.id + i)?.toString()}
									type={notification.type}
									data={notification}
									read={notification.read}
									time={notification?.timestamp}
									onClickHandler={() => clickHandle(notification)}
								/>
							))
						) : (
							<div className="p-4 text-center text-gray-500">No new notifications</div>
						)}
					</div>
				</motion.div>
			)}
		</AnimatePresence>
	);
};
