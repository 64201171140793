import React, { useState, useEffect, useRef } from "react";
import { HiOutlineChevronDown } from "react-icons/hi"; // Importing the icon

const CustomDropdown = ({ label, options, value, onChange, placeholder = "", styles, error }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const optionsRef = useRef([]);
  const dropdownRef = useRef(null); // Create a ref for the dropdown

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const handleSelect = (selectedOption) => {
    onChange(selectedOption.value);
    setIsOpen(false);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    
    // Add event listener to close the dropdown when clicked outside
    document.addEventListener("mousedown", handleClickOutside);
    
    // Cleanup the event listener when the component is unmounted or updated
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="relative flex flex-col my-4 w-full" ref={dropdownRef}>
       <div className="flex items-center w-full mb-2">
        {/* Label */}
        <label
          className="text-inputLabel font-normal w-1/3"  
          htmlFor={label?.toLowerCase().replace(" ", "-")}
        >
          {label}
        </label>

        {/* Input Button */}
        <div
          className="relative w-2/3 py-4 h-[40px] cursor-pointer text-cardTextGray bg-inputBg border border-gray-100 rounded-full max-h-9 px-4 text-left flex justify-between items-center focus:outline-none focus:ring-1 focus:ring-dashInActiveBtnText"
           onClick={toggleDropdown}
        >
          <span className={`${!value ? "opacity-50" : "truncate"}`}>
            {value || placeholder}
          </span>

          {/* Dropdown Arrow Icon */}
          <HiOutlineChevronDown
            className={`h-5 w-5 text-cardTextGray transition-transform duration-250 ${
              isOpen ? "transform rotate-180" : ""
            }`}
            style={{
              position: "absolute",
              right: "15px",
              top: "50%",
              transform: "translateY(-50%)",
            }}
          />
        </div>
      </div>

      {/* Dropdown List */}
      {isOpen && (
        <ul
          role="listbox"
          className={`absolute z-20 overflow-y-auto min-w-fit max-h-60 ${
            styles ? "w-full" : label ? "w-2/3 right-0" : "w-full left-0"
          } text-nowrap top-full mt-1 bg-white border border-dropdownDownArrow rounded-2xl shadow-md`}
        >
          {/* Search Bar */}
          {/* <input
            type="text"
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="Search..."
            className="w-full p-2 border-b border-gray-300 box-border"
          /> */}

          {/* Options List */}
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option, index) => (
              <li
                key={option.value}
                ref={(el) => (optionsRef.current[index] = el)}
                onClick={() => handleSelect(option)}
                onMouseEnter={() => setHighlightedIndex(index)}
                role="option"
                aria-selected={option.value === value}
                className={`px-4 h-9 py-2 flex flex-col justify-center hover:bg-[#BBBBBB3B] cursor-pointer transition-colors duration-150 ${
                  value === option.value ? "bg-[#BBBBBB3B]" : ""
                } ${highlightedIndex === index ? "bg-[#BBBBBB3B]" : ""}`}
              >
                {option.label}
              </li>
            ))
          ) : (
            <li className="px-4 py-2 cursor-not-allowed text-gray-400">
              No results found
            </li>
          )}
        </ul>
      )}

      {/* Error Message */}
      {error && (
        <p className="absolute left-0 bottom-[-20px] text-red-500 text-xs">{error}</p>
      )}
    </div>
  );
};

export default CustomDropdown;
