import React, { useEffect, useRef, useState, forwardRef } from "react";
import { HiOutlineChevronDown } from "react-icons/hi";

const SearchableDropdown = forwardRef(
	(
		{
			id,
			label,
			options,
			value,
			onChange,
			placeholder = "",
			styles = null,
			margin = "4",
			error,
			isDisabled,
			isRequired = false,
		},
		ref
	) => {
		const [isOpen, setIsOpen] = useState(false);
		const [searchTerm, setSearchTerm] = useState("");
		const [filteredOptions, setFilteredOptions] = useState(options);
		const [highlightedIndex, setHighlightedIndex] = useState(-1);
		const dropdownRef = useRef(null);
		const inputRef = useRef(null);
		const optionsRef = useRef([]);

		useEffect(() => {
			setSearchTerm(value);
		}, [value, options]);

		console.log({ value, searchTerm });

		const toggleDropdown = () => {
			setIsOpen(!isOpen);
			if (!isOpen) {
				setTimeout(() => inputRef.current?.focus(), 0);
			}
		};

		const handleSelect = (option) => {
			onChange(option.value);
			setSearchTerm(option.label);
			setIsOpen(false);
		};

		const handleInputChange = (event) => {
			const term = event.target.value;
			setSearchTerm(term);
			setIsOpen(true);
			const filtered = options.filter((option) => option.label.toLowerCase().includes(term.toLowerCase()));
			setFilteredOptions(filtered);
			setHighlightedIndex(-1);
			if (term === "") {
				onChange("");
			}
		};

		const handleKeyDown = (event) => {
			if (event.key === "Enter") {
				event.preventDefault();
				if (highlightedIndex !== -1) {
					handleSelect(filteredOptions[highlightedIndex]);
				} else if (filteredOptions.length === 1) {
					handleSelect(filteredOptions[0]);
				}
			} else if (event.key === "Escape") {
				setIsOpen(false);
			} else if (isOpen) {
				switch (event.key) {
					case "ArrowDown":
						event.preventDefault();
						setHighlightedIndex((prev) => (prev < filteredOptions.length - 1 ? prev + 1 : 0));
						break;
					case "ArrowUp":
						event.preventDefault();
						setHighlightedIndex((prev) => (prev > 0 ? prev - 1 : filteredOptions.length - 1));
						break;
					default:
						break;
				}
			}
		};

		useEffect(() => {
			const handleClickOutside = (event) => {
				if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
					setIsOpen(false);
				}
			};
			document.addEventListener("mousedown", handleClickOutside);
			return () => document.removeEventListener("mousedown", handleClickOutside);
		}, []);

		useEffect(() => {
			if (!isOpen) {
				setHighlightedIndex(-1);
			}
		}, [isOpen]);

		useEffect(() => {
			setFilteredOptions(options);
		}, [options]);

		useEffect(() => {
			if (isOpen && highlightedIndex !== -1 && optionsRef.current[highlightedIndex]) {
				optionsRef.current[highlightedIndex].scrollIntoView({ block: "nearest" });
			}
		}, [isOpen, highlightedIndex]);

		return (
			<div className={`relative flex ${styles ?? "justify-between items-center"} my-${margin}`} ref={dropdownRef}>
				{label && (
					<label
						htmlFor={id ? id : label?.toLowerCase().replace(" ", "-")}
						className={`${styles ? "w-full" : label ? "w-1/3" : "w-full"} text-inputLabel font-normal`}
					>
						{`${label} ${isRequired ? "*" : ""}`}
					</label>
				)}
				<div className={`${styles ? "w-full" : label ? "w-2/3" : "w-full"} relative`}>
					<div className="relative">
						<input
							id={id ? id : label?.toLowerCase().replace(" ", "-")}
							ref={inputRef}
							type="text"
							value={searchTerm}
							onChange={handleInputChange}
							onKeyDown={handleKeyDown}
							onClick={toggleDropdown}
							placeholder={placeholder}
							disabled={isDisabled}
							aria-haspopup="listbox"
							aria-expanded={isOpen}
							aria-required={isRequired}
							className={`w-full ${
								label ? (isDisabled ? "" : "bg-inputBg") : "bg-inputBg"
							} border-none rounded-full max-h-9 px-4 py-2 text-left text-cardTextGray focus:outline-none focus:ring-1 focus:ring-dashInActiveBtnText`}
							style={{ height: "40px" }}
						/>
						<div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
							<HiOutlineChevronDown className={`h-5 w-5 text-cardTextGray transform ${isOpen ? "rotate-180" : ""}`} />
						</div>
					</div>
					{isOpen && (
						<ul
							role="listbox"
							className="absolute z-20 w-full mt-1 max-h-60 overflow-y-auto bg-white border border-dropdownDownArrow rounded-2xl shadow-md"
						>
							{filteredOptions.map((option, index) => (
								<li
									key={option.value}
									ref={(el) => (optionsRef.current[index] = el)}
									onClick={() => handleSelect(option)}
									onMouseEnter={() => setHighlightedIndex(index)}
									role="option"
									aria-selected={option.value === value}
									className={`px-4 h-9 py-2 flex flex-col justify-center hover:bg-[#BBBBBB3B] cursor-pointer transition-colors duration-150 ${
										value === option.value ? "bg-[#BBBBBB3B]" : ""
									} ${highlightedIndex === index ? "bg-[#BBBBBB3B]" : ""}`}
								>
									{option.label}
								</li>
							))}
							{filteredOptions.length === 0 && <li className="px-4 h-9 py-2 text-gray-500">No results found</li>}
						</ul>
					)}
				</div>
				{error && <p className="absolute left-0 bottom-[-20px] text-red-500 text-xs">{error}</p>}
			</div>
		);
	}
);

SearchableDropdown.displayName = "SearchableDropdown";

export default SearchableDropdown;
