import React, { useState } from "react";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import { sendResetPasswordEmail } from "../../../../config/firebase";
import { emailRegex } from "../../../../utils/mimes";

const ForgotPassword = ({ setDefaultForm }) => {
	const [email, setEmail] = useState("");
	const [loading, setLoading] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();

		// Email validation
		if (!emailRegex.test(email)) {
			return toast.error("Invalid email!");
		}

		setLoading(true);
		try {
			await sendResetPasswordEmail(email);
			toast.success("Password reset link sent to your email!");
			// Redirecting to login after successful password reset email
			setDefaultForm("LogIn");
		} catch (error) {
			console.log(error);
			if (error.code === "auth/user-not-found") {
				toast.error("No user found with this email!");
			} else {
				toast.error("Failed to send reset email. Please try again.");
			}
		} finally {
			setLoading(false);
		}
	};

	return (
		<motion.div
			initial={{ opacity: 0, y: 20 }}
			animate={{ opacity: 1, y: 0 }}
			transition={{ duration: 0.5 }}
			className="flex flex-col justify-center w-full max-w-[600px]"
		>
			<h1 className="text-3xl text-center font-semibold mb-8">Reset Password</h1>

			<div className="space-y-6">
				<motion.div initial={{ opacity: 0, x: -20 }} animate={{ opacity: 1, x: 0 }} transition={{ delay: 0.2 }}>
					<label className="block font-medium text-cardTextGray mb-1">Email address *</label>
					<input
						type="email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						className="w-full px-4 py-3 rounded-full bg-[#F1F1F4] transition-all outline-none duration-300 focus:ring-2 focus:ring-chartLine"
						placeholder="Enter your email"
					/>
				</motion.div>

				<motion.button
					whileHover={{ scale: 1.02 }}
					whileTap={{ scale: 0.95 }}
					type="submit"
					disabled={loading}
					onClick={handleSubmit}
					className={`w-full h-14 font-bold text-white py-2 px-4 rounded-full transition-all duration-300 
            ${
							loading
								? "bg-gray-400 cursor-not-allowed"
								: "bg-chartLine hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
						}`}
				>
					{loading ? "SENDING..." : "SEND RESET LINK"}
				</motion.button>
			</div>

			<p className="mt-4 text-center">
				Remember your password?{" "}
				<button onClick={() => setDefaultForm("LogIn")} className="text-[#007AFF] underline hover:text-indigo-500">
					Log in
				</button>
			</p>
		</motion.div>
	);
};

export default ForgotPassword;
