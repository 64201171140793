import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const ManageWastes = () => {
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		if (location.pathname == "/admin/wastes") navigate("/admin/wastes/manage");
		else navigate(location.pathname);
	}, [location.pathname]);

	return (
		<div>
			<Outlet />
		</div>
	);
};

export default ManageWastes;
