import React, { useEffect, useRef, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, signOutUser } from "../../config/firebase";
import { AnimatePresence, motion } from "framer-motion";

const UserAccBtn = () => {
	const [isAccDropOpen, setIsAccDropOpen] = useState(false);
	const accDropRef = useRef(null);
	const [user] = useAuthState(auth);
	const userName = user?.displayName ?? user?.email?.split("@")[0];

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (accDropRef.current && !accDropRef.current.contains(event.target)) {
				setIsAccDropOpen(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);
	console.log({user})

	const toggleDropdown = () => {
		setIsAccDropOpen((prev) => !prev);
	};

	const logoutUser = async () => {
		await signOutUser();
	};

	return (
		<div className="relative px-2 mx-2" ref={accDropRef}>
			<div onClick={toggleDropdown} className="flex items-center gap-4 hover:cursor-pointer">
				<div className="flex items-center justify-center h-12 w-12 bg-white border border-gray-200 rounded-full">
					<img
						src={user?.photoURL ?? `https://api.dicebear.com/9.x/initials/png?seed=${userName}`}
						alt="User Avatar"
						className="rounded-full"
					/>
				</div>
				<div className="flex flex-col">
					<h6 className="text-sm font-semibold text-gray-700 truncate">
						{userName ? user.displayName || userName.charAt(0).toUpperCase() + userName.slice(1) : "Default User"}
					</h6>
					<p className="text-xs text-cardTextGray truncate">{user?.email ?? "user@test.com"}</p>
				</div>
			</div>
			<AnimatePresence>
				{isAccDropOpen && (
					<motion.ul
						className="absolute z-50 bg-white top-12 min-w-full w-fit rounded-xl overflow-hidden shadow-lg"
						initial={{ height: 0, opacity: 0 }}
						animate={{ height: "auto", opacity: 1 }}
						exit={{ height: 0, opacity: 0 }}
						transition={{ duration: 0.25 }}
					>
						<li className="pl-3 py-2 pr-10 bg-white hover:text-white hover:bg-lightBlue cursor-pointer">Account</li>
						<li
							className="pl-3 py-2 pr-10 bg-white hover:text-white hover:bg-lightBlue cursor-pointer"
							onClick={logoutUser}
						>
							Logout
						</li>
					</motion.ul>
				)}
			</AnimatePresence>
		</div>
	);
};

export default UserAccBtn;
