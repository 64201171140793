const serviceOptions = [
	{ label: "Consulting Services", value: "CONSULTING_SERVICES" },
	{ label: "Container Supplier (Medical Waste)", value: "CONTAINER_SUPPLIER_MEDICAL_WASTE" },
	{ label: "Container Supplier (Paper Shredding)", value: "CONTAINER_SUPPLIER_PAPER_SHREDDING" },
	{ label: "E-Waste Recycling Services", value: "E_WASTE_RECYCLING_SERVICES" },
	{ label: "Financial Services", value: "FINANCIAL_SERVICES" },
	{ label: "Hazardous Waste Services", value: "HAZARDOUS_WASTE_SERVICES" },
	{ label: "Legal Services", value: "LEGAL_SERVICES" },
	{ label: "Linen & Uniform Services", value: "LINEN_AND_UNIFORM_SERVICES" },
	{ label: "Medical Waste Processing Equipment", value: "MEDICAL_WASTE_PROCESSING_EQUIPMENT" },
	{ label: "Medical Waste Transfer Station (TS)", value: "MEDICAL_WASTE_TRANSFER_STATION" },
	{ label: "Medical Waste Transporter", value: "MEDICAL_WASTE_TRANSPORTER" },
	{ label: "Medical Waste Treatment Facility (TF)", value: "MEDICAL_WASTE_TREATMENT_FACILITY" },
	{ label: "Online OSHA Training", value: "ONLINE_OSHA_TRAINING" },
	{ label: "Paper Shredding Services (Offsite)", value: "PAPER_SHREDDING_SERVICES_OFFSITE" },
	{ label: "Paper Shredding Services (Onsite)", value: "PAPER_SHREDDING_SERVICES_ONSITE" },
	{ label: "Product Supplier Reverse Distributions", value: "PRODUCT_SUPPLIER_REVERSE_DISTRIBUTIONS" },
	{ label: "Training & Certification Services", value: "TRAINING_AND_CERTIFICATION_SERVICES" },
	{ label: "Transportation Insurance Providers", value: "TRANSPORTATION_INSURANCE_PROVIDERS" },
];

import React, { useEffect, useRef, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { motion, AnimatePresence } from "framer-motion";
import { emailRegex } from "../../../../utils/mimes";
import { RxCross2 } from "react-icons/rx";
import { FiPlus, FiUpload } from "react-icons/fi";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { db, storage } from "../../../../config/firebase";

const StyledMultiSelect = ({ label, options, selectedOptions, setSelectedOptions, placeholder }) => {
	const [isOpen, setIsOpen] = useState(false);
	const dropdownRef = useRef(null);

	// Handle selection with a max limit of 3 options
	const handleSelect = (option) => {
		if (selectedOptions?.some((item) => item === option?.value)) {
			// Deselect the option
			setSelectedOptions(selectedOptions.filter((item) => item !== option.value));
		} else {
			// Allow selection only if less than 3 options are selected
			if (selectedOptions.length < 3) {
				setSelectedOptions([...selectedOptions, option.value]);
			}
		}
	};

	// Remove an option when clicking the 'X' button
	const removeOption = (optionToRemove, e) => {
		e.stopPropagation();
		setSelectedOptions(selectedOptions.filter((option) => option !== optionToRemove));
	};

	// Close dropdown when clicking outside
	const handleClickOutside = (event) => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
			setIsOpen(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	// Find the label for the selected options
	const getSelectedLabels = () => {
		return selectedOptions.map((selectedValue) => options.find((option) => option.value === selectedValue)?.label);
	};

	return (
		<div className="relative" ref={dropdownRef}>
			<div
				type="button"
				onClick={() => setIsOpen(!isOpen)}
				className={`
			w-full p-3 px-5 bg-[#F1F1F4] text-cardTextGray rounded-full
			flex items-center justify-between cursor-pointer
			outline-none focus:outline focus:outline-2 focus:outline-blue-500
			transition-all min-h-[48px]
		  `}
			>
				<div className="flex overflow-scroll scrollbar-hide gap-1 items-center pr-2">
					{selectedOptions?.length > 0 ? (
						getSelectedLabels().map((label, index) => (
							<span
								key={selectedOptions[index]}
								className="text-nowrap inline-flex items-center gap-1 px-2 bg-white rounded-full text-sm"
							>
								{label}
								<button
									type="button"
									onClick={(e) => removeOption(selectedOptions[index], e)}
									className="p-0.5 hover:bg-gray-300 hover:text-red-500 rounded-full"
								>
									<RxCross2 className="h-3 w-3" />
								</button>
							</span>
						))
					) : (
						<span>{placeholder || `Select ${label}`}</span>
					)}
				</div>
				<IoIosArrowDown size={20} className={`transform transition-transform ${isOpen ? "rotate-180" : ""}`} />
			</div>

			{isOpen && (
				<div
					className="absolute z-10 w-full bg-white shadow-md rounded-md mt-1 max-h-60 overflow-auto"
					style={{ animation: "scaleIn 200ms ease-out" }}
				>
					{options.map((option) => (
						<div
							key={option.value}
							onClick={() => handleSelect(option)}
							className={`p-3 px-5 flex items-center gap-2 ${
								selectedOptions.length < 3
									? "hover:bg-cardTextGray hover:bg-opacity-10 cursor-pointer"
									: "cursor-default"
							}`}
						>
							<input
								type="checkbox"
								checked={selectedOptions?.some((item) => item === option?.value)}
								readOnly
								className="h-4 w-4 rounded border-gray-300"
							/>
							{option.label}
						</div>
					))}
				</div>
			)}

			<style jsx>{`
				@keyframes scaleIn {
					from {
						opacity: 0;
						transform: scale(0.95);
					}
					to {
						opacity: 1;
						transform: scale(1);
					}
				}
			`}</style>
		</div>
	);
};

const ProfileSettings = () => {
	const [contacts, setContacts] = useState([]);
	const defaultContact = {
		id: "",
		firstName: "",
		lastName: "",
		phone: "",
		email: "",
	};
	const [newContact, setNewContact] = useState(defaultContact);
	const [isAddingContact, setIsAddingContact] = useState(false);
	const [newContactError, setNewContactError] = useState({});
	const [profileImage, setProfileImage] = useState("");
	const [profileLoaded, setIsProfileLoaded] = useState(false);
	const [isHoveringImage, setIsHoveringImage] = useState(false);
	const [isFormChanged, setIsFormChanged] = useState(false);
	const [openSections, setOpenSections] = useState({});
	const [charCount, setCharCount] = useState(0);
	const { data: octoUserData, loading: userLoading } = useSelector((state) => state.octoUserData);
	const { sendApprovalRequest } = useConnectionManager();

	const defaultValues = {
		companyName: "",
		address: "",
		phone: "",
		email: "",
		websiteUrl: "",
		contacts: [],
		category: [],
		serviceArea: "",
		localAreas: [],
		shortDesc: "",
		bio: "",
		// profileImage: "",
	};

	const methods = useForm({
		defaultValues,
	});

	// Watch all form fields
	const watchAllFields = methods.watch();
	const toggleSection = (id) => {
		setOpenSections((prev) => ({
			...prev,
			[id]: !prev[id],
		}));
	};

	// Check for form changes
	useEffect(() => {
		// Only check for changes if octoUserData is fully loaded
		if (octoUserData) {
			console.log(JSON.stringify(methods.getValues()));
			console.log(JSON.stringify(octoUserData));

			let allValues = methods.getValues();

			const isChanged = Object.keys(allValues).find((key) =>
				Array.isArray(allValues[key])
					? JSON.stringify(allValues[key]) !== JSON.stringify(octoUserData[key])
					: allValues[key] !== octoUserData[key]
			);

			console.log({ isChanged, valueBefore: octoUserData[isChanged], valueAfter: allValues[isChanged] });

			setIsFormChanged(isChanged);
		}
	}, [watchAllFields, profileImage, contacts, octoUserData]);

	useEffect(() => {
		if (octoUserData) {
			setProfileImage(
				octoUserData["profileImage"]?.length > 0
					? octoUserData["profileImage"]
					: "https://storage.googleapis.com/routes-123.appspot.com/assets/logo%201.png"
			);
			setIsProfileLoaded(true);
			let shortDescData = methods.getValues("shortDesc");
			setCharCount(shortDescData.length);
			Object.keys(defaultValues).forEach((key) => {
				if (octoUserData[key]) methods.setValue(key, octoUserData[key]);
			});
			let allContacts = methods.getValues("contacts");
			setContacts(allContacts ?? []);
			setIsFormChanged(false);
		}
	}, [octoUserData]);

	console.log({ octoUserData });

	const handleImageChange = async (event) => {
		const file = event.target.files[0];
		if (file) {
			// First show local preview
			const reader = new FileReader();
			reader.onloadend = () => {
				setProfileImage(reader.result);
			};
			reader.readAsDataURL(file);
			let imgUrl = await uploadImageToFirebase(file);

			let docRef = doc(db, "octoMarketUsers", octoUserData.id);
			await updateDoc(docRef, { profileImage: imgUrl });
		}
	};

	const handleAddContact = () => {
		// Verify new contact
		console.log({ newContact });
		let err = {};
		if (
			!newContact.email.trim() ||
			contacts.find((contact) => contact.email == newContact.email?.trim()) ||
			!emailRegex.test(newContact.email?.trim())
		) {
			err.email = "Invalid Email";
		}
		if (newContact.firstName?.trim()?.length < 3) {
			err.firstName = "Invalid first name";
		}
		console.log({ newContact: newContact });

		if (newContact.lastName?.trim()?.length < 3) {
			err.lastName = "Invalid last name";
		}
		if (!newContact.phone?.trim()?.length) {
			err.phone = "Invalid phone";
		}

		if (Object.keys(err).length > 0) {
			setNewContactError(err);
			return;
		}

		const newContacts = [...contacts, { ...newContact, id: Math.floor(Math.random() * 100000) }];
		setContacts(newContacts);
		setNewContact(defaultContact);
		setIsAddingContact(false);
		methods.setValue("contacts", newContacts);
	};

	const onChangeHandler = (val, type = "") => {
		setNewContact((prev) => ({ ...prev, [type]: val }));
		let err = {};
		if (type == "email") {
			if (!val.trim() || contacts.find((contact) => contact.email == val?.trim()) || !emailRegex.test(val?.trim())) {
				err.email = "Invalid Email";
			}
		}
		if (type == "firstName")
			if (val?.trim()?.length < 3) {
				err.firstName = "Invalid first name";
			}
		console.log({ newContact: newContact });
		if (type == "lastName")
			if (val?.trim()?.length < 3) {
				err.lastName = "Invalid last name";
			}
		if (type == "phone")
			if (!val?.trim()?.length) {
				err.phone = "Invalid phone";
			}

		setNewContactError((prev) => ({ ...prev, [type]: err[type] }));
	};

	const handleDeleteContact = (index) => {
		const newContacts = contacts.filter((_, i) => index !== i);
		setContacts(newContacts);
		methods.setValue("contacts", newContacts);
	};

	console.log({ newContactError });

	const uploadImageToFirebase = async (imageFile) => {
		try {
			// Create unique filename
			const timestamp = Date.now();
			const storageRef = ref(storage, `profile-images/${timestamp}-${imageFile.name}`);

			// Upload file
			const snapshot = await uploadBytes(storageRef, imageFile);

			// Get download URL
			const downloadURL = await getDownloadURL(snapshot.ref);
			return downloadURL;
		} catch (error) {
			console.error("Error uploading image:", error);
			throw error;
		}
	};

	const onSubmit = async (data) => {
		console.log("After onsubmit the data", data);
		localStorage.setItem("octoUserProfileData", JSON.stringify(data));

		// Now Save finalImageUrl to database
		const formData = {
			...data,
			contacts,
		};

		toast.loading("Saving..");

		console.log("Form submitted:", formData, data);

		let toUpdateDb = {
			...octoUserData,
			...formData,
			profileCompleted: true,
		};
		console.log({ toUpdateDb });

		// Remove keys with undefined values
		toUpdateDb = Object.fromEntries(Object.entries(toUpdateDb).filter(([_, value]) => value !== undefined));

		let docRef = doc(db, "octoMarketUsers", octoUserData.id);

		await updateDoc(docRef, toUpdateDb);

		if (!octoUserData.profileCompleted) {
			// Send notification for approval
			console.log("Sending approval request");
			await sendApprovalRequest();
		}

		// Update defaultValues and reset form
		Object.keys(formData).forEach((key) => {
			defaultValues[key] = formData[key];
		});

		setIsFormChanged(false);
		methods.reset(formData);
		toast.dismiss();
		toast.success("Saved successfully!");
	};

	return (
		<div className="">
			<div className="container mx-auto py-16">
				{/* Header Banner */}
				<motion.div
					className="h-32 mx-4 bg-[#007AFF] p-4 rounded-t-xl"
					initial={{ opacity: 0, scale: 0.95 }}
					animate={{ opacity: 1, scale: 1 }}
					transition={{ duration: 0.5 }}
				/>

				<FormProvider {...methods}>
					<form onSubmit={methods.handleSubmit(onSubmit)} className="mx-4 px-8 mb-20 bg-white pb-16 rounded-b-xl">
						{/* Profile Header */}
						<motion.div
							className="flex items-center justify-between py-10"
							initial={{ opacity: 0, x: -50 }}
							animate={{ opacity: 1, x: 0 }}
							transition={{ duration: 0.5 }}
						>
							<div className="flex items-center gap-4">
								<div
									className="relative bg-[#dfdfdf] rounded-lg w-28 h-28 overflow-hidden"
									onMouseEnter={() => setIsHoveringImage(true)}
									onMouseLeave={() => setIsHoveringImage(false)}
								>
									{!profileLoaded ? (
										<Loader height="" />
									) : (
										<img
											src={
												profileImage?.length > 0
													? profileImage
													: "https://storage.googleapis.com/routes-123.appspot.com/assets/logo%201.png"
											}
											className="w-full h-full rounded-lg object-cover"
											alt="Profile"
										/>
									)}
									{isHoveringImage && (
										<div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
											<label className="cursor-pointer">
												<input type="file" className="hidden" accept="image/*" onChange={handleImageChange} />
												<FiUpload className="text-white text-2xl" />
											</label>
										</div>
									)}
								</div>
								<div>
									<h1 className="text-xl font-semibold capitalize">{octoUserData?.companyName}</h1>
									<p className="text-gray-500">{octoUserData?.address}</p>
								</div>
							</div>
							<motion.button
								type="submit"
								disabled={!isFormChanged}
								// className={`btn btn-primary`}
								className={`px-10 py-3 rounded-full ${
									isFormChanged
										? "bg-[#007AFF] text-white hover:bg-blue-700"
										: "bg-gray-300 text-gray-500 cursor-not-allowed"
								}`}
								whileHover={isFormChanged ? { scale: 1.05 } : {}}
								whileTap={isFormChanged ? { scale: 0.95 } : {}}
							>
								Save
							</motion.button>
						</motion.div>

						{/* Main Content Grid */}
						<div className="grid grid-cols-2 gap-12 xl:gap-20">
							{/* Left Column */}
							<div className="space-y-8">
								<div>
									<label className="block text-black mb-2">Company Name *</label>
									<input
										{...methods.register("companyName")}
										className="w-full p-3 px-5 bg-[#F1F1F4] text-gray-700 rounded-full focus:outline-blue-500"
									/>
								</div>

								<div>
									<label className="block text-black mb-2">Address *</label>
									<input
										{...methods.register("address")}
										className="w-full p-3 px-5 bg-[#F1F1F4] text-gray-700 rounded-full focus:outline-blue-500"
									/>
								</div>

								<div>
									<label className="block text-black mb-2">Phone *</label>
									<Controller
										name="phone"
										control={methods.control}
										render={({ field: { value, onChange } }) => (
											<PhoneInput
												addStyle={`w-full p-3 px-5 bg-[#F1F1F4] text-gray-700 outline-none rounded-full ${
													newContactError.phone ? "outline-red-500" : "focus:outline-blue-500"
												}`}
												label={null}
												value={value}
												onChange={onChange}
												margin=""
											/>
										)}
									/>
								</div>
								<div>
									<label className="block text-black mb-2">Email *</label>
									<input
										{...methods.register("email")}
										className="w-full p-3 px-5 bg-[#F1F1F4] text-gray-700 rounded-full focus:outline-blue-500"
									/>
								</div>
								<div>
									<label className="block text-black mb-2">Website URL</label>
									<input
										{...methods.register("websiteUrl")}
										className="w-full p-3 px-5 bg-[#F1F1F4] text-gray-700 rounded-full focus:outline-blue-500"
									/>
								</div>

								{/* Email Section */}
								<div>
									<label className="block text-xl font-medium text-black mb-2">Contacts *</label>
									<div className="space-y-3">
										{contacts.map((contact, index) => (
											<div
												key={contact.firstName + contact.lastName + contact.email}
												className="rounded-lg overflow-hidden"
											>
												<button
													type="button"
													onClick={() => toggleSection(contact.id)}
													className="w-full text-[#007AFF] text-lg flex justify-between items-center bg-white transition-colors"
												>
													<span className="font-medium capitalize">{`Contact ${index + 1}`}</span>
													<IoIosArrowDown
														className={`w-5 h-5 transition-transform duration-200 ${
															openSections[contact.id] ? "transform rotate-180" : ""
														}`}
													/>
												</button>

												<div
													className={`overflow-hidden flex justify-between transition-all duration-200 ${
														openSections[contact.id] ? "max-h-48" : "max-h-0"
													}`}
												>
													<div className="p-4 space-y-2">
														<p className="text-sm text-gray-600 capitalize">
															<span className="font-medium">Name:</span> {`${contact.firstName} ${contact.lastName}`}
														</p>
														<p className="text-sm text-gray-600">
															<span className="font-medium">Phone:</span> {contact.phone}
														</p>
														<p className="text-sm text-gray-600">
															<span className="font-medium">Email:</span> {contact.email}
														</p>
													</div>
													<button
														type="button"
														onClick={() => handleDeleteContact(index)}
														className="h-fit relative group p-1.5 rounded-full hover:bg-cardTextGray hover:bg-opacity-10 transition-opacity duration-200 text-red-500 hover:text-red-700"
													>
														<RxCross2 size={20} />
													</button>
												</div>
											</div>
										))}
										{contacts.length < 2 ? (
											!isAddingContact ? (
												<button
													type="button"
													className="text-[#007AFF] flex gap-2 items-center bg-[#007AFF] hover:bg-primaryBlue hover:bg-opacity-20 bg-opacity-10 px-6 rounded-lg py-2 hover:scale-[102%] transition-transform duration-200 hover:text-blue-700"
													onClick={() => setIsAddingContact(true)}
												>
													<FiPlus />
													<p className="">Add Contact</p>
												</button>
											) : (
												<>
													<div className="grid grid-cols-1 gap-4">
														<div>
															<label className="block text-black mb-2">First Name</label>
															<input
																type="text"
																className={`w-full p-3 px-5 bg-[#F1F1F4] text-gray-700 outline-none rounded-full ${
																	newContactError.firstName ? "outline-red-500" : "focus:outline-blue-500"
																}`}
																value={newContact.firstName}
																onChange={(e) => onChangeHandler(e.target.value, "firstName")}
																placeholder="Enter First Name"
																autoFocus={newContactError.firstName}
															/>
														</div>
														<div>
															<label className="block text-black mb-2">Last Name</label>
															<input
																type="text"
																className={`w-full p-3 px-5 bg-[#F1F1F4] text-gray-700 outline-none rounded-full ${
																	newContactError.lastName ? "outline-red-500" : "focus:outline-blue-500"
																}`}
																value={newContact.lastName}
																onChange={(e) => onChangeHandler(e.target.value, "lastName")}
																placeholder="Enter Last Name"
																autoFocus={newContactError.lastName}
															/>
														</div>
														<div>
															<label className="block text-black mb-2">Phone</label>
															<PhoneInput
																addStyle={`w-full p-3 px-5 bg-[#F1F1F4] text-gray-700 outline-none rounded-full ${
																	newContactError.phone ? "outline-red-500" : "focus:outline-blue-500"
																}`}
																label={null}
																value={newContact.phone ?? ""}
																onChange={(val) => onChangeHandler(val, "phone")}
																margin=""
															/>
														</div>
														<div>
															<label className="block text-black mb-2">Email</label>
															<input
																type="email"
																className={`w-full p-3 px-5 bg-[#F1F1F4] text-gray-700 outline-none rounded-full ${
																	newContactError.email ? "outline-red-500" : "focus:outline-blue-500"
																}`}
																value={newContact.email}
																onChange={(e) => onChangeHandler(e.target.value, "email")}
																placeholder="Enter Email"
																autoFocus={newContactError.email}
															/>
														</div>
													</div>
													<div className="flex gap-4">
														<motion.button
															type="button"
															className="border border-[#007AFF] text-white px-6 h-11 rounded-lg hover:bg-cardTextGray hover:bg-opacity-10"
															whileHover={{ scale: 1.05 }}
															whileTap={{ scale: 0.95 }}
															onClick={handleAddContact}
														>
															<FiPlus size={20} color="#007AFF" />
														</motion.button>
														<motion.button
															type="button"
															className="border border-red-500 text-[#007AFF] px-4 h-11 rounded-lg hover:bg-cardTextGray hover:bg-opacity-10"
															whileHover={{ scale: 1.05 }}
															whileTap={{ scale: 0.95 }}
															onClick={() => {
																setIsAddingContact(false);
																setNewContactError({});
															}}
														>
															<RxCross2 className="text-red-500" />
														</motion.button>
													</div>
												</>
											)
										) : (
											<p className="text-cardTextGray">Maximum of 2 contacts has already been added</p>
										)}
									</div>
								</div>
							</div>

							{/* Right Column */}
							<div className="space-y-8">
								<div>
									<div className="flex justify-between">
										<label className="block text-black mb-2">Category *</label>
										<p className="text-cardTextGray text-sm opacity-60">Select upto 3 categories</p>
									</div>
									<Controller
										name="category"
										control={methods.control}
										render={({ field }) => (
											<StyledMultiSelect
												label="Category"
												options={serviceOptions}
												selectedOptions={field.value}
												setSelectedOptions={(value) => field.onChange(value)}
											/>
										)}
									/>
								</div>
								<ServiceAreaSelector />
								<div>
									<div className="flex justify-between items-center">
										<label className="block text-black mb-2">Short Description *</label>
										<div className="text-right text-sm text-gray-500">{charCount}/100</div>
									</div>
									<textarea
										{...methods.register("shortDesc")}
										className="w-full p-3 px-5 h-fit bg-[#F1F1F4] text-gray-700 rounded-[24px] focus:outline-blue-500"
										maxLength={100}
										onInput={(e) => {
											e.target.style.height = "48px"; // Reset height to base height
											e.target.style.height = `${e.target.scrollHeight}px`; // Adjust height to fit content
											setCharCount(e.target.value.length); // Update character count
										}}
									/>

									{/* <textarea
										{...methods.register("shortDesc")}
										className="w-full p-3 px-5 h-fit bg-[#F1F1F4] text-gray-700 rounded-[24px] focus:outline-blue-500"
										onInput={(e) => {
											e.target.style.height = "48px"; // Reset height to base height
											e.target.style.height = `${e.target.scrollHeight}px`; // Set height to fit content
										}}
									/> */}
								</div>
								<div>
									<label className="block text-black mb-2">Bio *</label>
									<textarea
										{...methods.register("bio")}
										className="w-full p-3 px-5 bg-[#F1F1F4] text-gray-700 rounded-[24px] h-40 focus:outline-blue-500"
									/>
								</div>
							</div>
						</div>
					</form>
				</FormProvider>
			</div>
		</div>
	);
};

export default ProfileSettings;

import { useFormContext } from "react-hook-form";
import PhoneInput from "../../../../components/UI/PhoneInput";
import { FaRegTrashAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import useConnectionManager from "../../../../hooks/useConnectionManager";
import Loader from "../../../../components/UI/loaders/Loader";
import { toast } from "react-toastify";

const ServiceAreaSelector = ({}) => {
	const {
		register,
		watch,
		setValue,
		formState: { errors },
	} = useFormContext();
	const shippingType = watch("serviceArea");
	const areas = watch("localAreas");
	const [localAreas, setLocalAreas] = useState([]);

	console.log({ areas });

	useEffect(() => {
		setLocalAreas(areas ?? []);
	}, [areas]);

	const containerVariants = {
		hidden: { opacity: 0 },
		visible: {
			opacity: 1,
			transition: { staggerChildren: 0.1 },
		},
	};

	const itemVariants = {
		hidden: { opacity: 0, y: 20 },
		visible: {
			opacity: 1,
			y: 0,
			transition: { type: "spring", stiffness: 300, damping: 24 },
		},
	};

	const radioVariants = {
		unchecked: { scale: 1 },
		checked: {
			scale: 1.1,
			transition: { type: "spring", stiffness: 300, damping: 10 },
		},
	};

	const localFieldsVariants = {
		hidden: {
			opacity: 0,
			height: 0,
			transition: { duration: 0.2 },
		},
		visible: {
			opacity: 1,
			height: "auto",
			transition: { duration: 0.3, ease: "easeOut" },
		},
	};

	const handleAddServiceArea = () => {
		const newServiceAreaVal = watch("serviceAreaArr");

		if (newServiceAreaVal) {
			const updatedAreas = [...localAreas, newServiceAreaVal];
			setLocalAreas(updatedAreas);

			setValue("localAreas", updatedAreas);
			setValue("serviceAreaArr", "");
		}
	};

	const handleRemoveServiceArea = (indexToRemove) => {
		const updatedAreas = localAreas.filter((_, index) => index !== indexToRemove);
		setLocalAreas(updatedAreas);
		setValue("localAreas", updatedAreas);
	};

	console.log({ localAreas });

	return (
		<motion.div className="" initial="hidden" animate="visible" variants={containerVariants}>
			<motion.h2 className="text-lg mb-4" variants={itemVariants}>
				Service Area *
			</motion.h2>

			<motion.div className="flex items-center gap-8" variants={itemVariants}>
				<label className="flex items-center space-x-3 cursor-pointer">
					<motion.div
						variants={radioVariants}
						className="h-5"
						animate={shippingType === "nationwide" ? "checked" : "unchecked"}
					>
						<input
							type="radio"
							value="nationwide"
							{...register("serviceArea", { required: "Please select a shipping type" })}
							className="h-5 w-5 text-blue-600 border-gray-300 focus:ring-blue-500"
						/>
					</motion.div>
					<span>Nationwide Shipping</span>
				</label>

				<label className="flex items-center space-x-3 cursor-pointer">
					<motion.div
						variants={radioVariants}
						className="h-5"
						animate={shippingType === "local" ? "checked" : "unchecked"}
					>
						<input
							type="radio"
							value="local"
							{...register("serviceArea", { required: "Please select a shipping type" })}
							className="h-5 w-5 text-blue-600 border-gray-300 focus:ring-blue-500"
						/>
					</motion.div>
					<span>City, State, Radius (Miles)</span>
				</label>
			</motion.div>

			<AnimatePresence>
				{shippingType === "local" && (
					<motion.div
						className="flex flex-col pt-4 gap-4"
						initial="hidden"
						animate="visible"
						exit="hidden"
						variants={localFieldsVariants}
					>
						<div className="flex items-center w-full gap-4">
							<motion.div variants={itemVariants} className="w-[75%]">
								<input
									type="text"
									id="serviceAreaArr"
									{...register("serviceAreaArr")}
									placeholder="City, State, Radius (Miles)"
									className="w-full p-3 px-5 bg-[#F1F1F4] text-gray-700 rounded-full focus:outline-blue-500"
								/>
							</motion.div>
							<motion.div variants={itemVariants} className="w-[25%] h-full">
								<button
									type="button"
									onClick={handleAddServiceArea}
									className="bg-blue-500 text-white px-8 w-full py-3 rounded-full flex justify-center items-center hover:bg-blue-600 transition-colors"
								>
									Add
								</button>
							</motion.div>
						</div>

						{localAreas.length > 0 && (
							<motion.div variants={itemVariants} initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
								<h3 className="text-md mb-2">Added Service Areas</h3>
								<div className="space-y-2">
									{localAreas.map((area, index) => (
										<motion.div
											key={index}
											className="flex justify-between items-center bg-gray-100 p-3 rounded-full px-5"
											initial={{ opacity: 0, x: -20 }}
											animate={{ opacity: 1, x: 0 }}
											exit={{ opacity: 0, x: 20 }}
										>
											<div>
												<span>{area}</span>
											</div>
											<button
												type="button"
												onClick={() => handleRemoveServiceArea(index)}
												className="text-red-500 hover:text-red-700"
											>
												<FaRegTrashAlt size={20} />
											</button>
										</motion.div>
									))}
								</div>
							</motion.div>
						)}
					</motion.div>
				)}
			</AnimatePresence>
		</motion.div>
	);
};
