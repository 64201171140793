import React, { useState } from "react";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { RxCross2 } from "react-icons/rx";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Input from "../UI/Input";
import Dropdown from "../UI/dropdowns/Dropdown";
import Loader from "../UI/loaders/Loader";
import { createNewTreatment } from "../../utils/firebaseOperations";

const statusOptions = [
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" }
];

const notificationTypeOptions = [
    { value: "Full Manifest", label: "Full Manifest" },
    { value: "Combined Manifest", label: "Combined Manifest" },
];

const MiniTreatmentRegModal = ({ onClose }) => {
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            treatment: [{
                name: "",
                email1: "",
                octoNumber: "",
                notificationTypeOption: "Combined Manifest",
                treatmentStatus: "Active"
            }]
        },
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: "treatment",
    });

    const [inProgress, setInProgress] = useState(false);
    const navigate = useNavigate();

    const onSubmitForm = async (data) => {
        const treatmentData = data.treatment.map((treatment) => ({
            name: treatment.name || "--",
            email1: treatment.email1 || "",
            octoNumber: treatment.octoNumber || "",
            notificationTypeOption: treatment.notificationTypeOption.length ? treatment.notificationTypeOption : ["--"],
            treatmentStatus: treatment.treatmentStatus || "--",
        }));

        toast.loading("Saving...");
        setInProgress(true);
        try {
            const newTreatmentIds = [];
            for (const treatment of treatmentData) {
                const newTreatmentId = await createNewTreatment(treatment);
                newTreatmentIds.push(newTreatmentId);
            }
            toast.dismiss();
            onClose();
            navigate(`/admin/assets/treatment/add-treatment/${newTreatmentIds[0]}/edit`);
        } catch (error) {
            toast.dismiss();
            console.error('Error saving treatment details:', error);
            toast.error("Failed to save treatment details. Please try again.");
        } finally {
            setInProgress(false);
        }
    };

    return (
        <div className="fixed inset-0 bg-[#CCCCCC87] bg-opacity-50 flex justify-center items-center z-40">
            <div className="bg-white rounded-cardRadii max-w-md w-full min-h-fit z-20">
                <form onSubmit={handleSubmit(onSubmitForm)} className="flex flex-col justify-between min-h-80 p-6">
                    <div className="flex justify-between pb-2">
                        <h6 className="font-bold text-xl text-black">Add New TF/TS</h6>
                        <button
                            type="button"
                            disabled={inProgress}
                            className={`flex justify-center items-center h-fit w-fit p-1 rounded-full border shadow-md ${inProgress ? "" : "hover:bg-gray-200"}`}
                            onClick={onClose}
                        >
                            <RxCross2 size={20} className={`${!inProgress ? "hover:cursor-pointer" : ""}`} />
                        </button>
                    </div>

                    {fields.map((field, index) => (
                        <div className="gap-4 w-full" key={field.id}>
                            <div>
                                <Controller
                                    name={`treatment.${index}.name`}
                                    control={control}
                                    rules={{ required: "Treatment Name is required" }}
                                    render={({ field: { onChange, value } }) => (
                                        <div>
                                            <Input
                                                label="Name*"
                                                style="flex-col"
                                                className="w-full px-2 py-1 text-sm"
                                                value={value}
                                                onChange={onChange}
                                            />
                                            {errors.treatment?.[index]?.name && <p className="text-red-500 text-sm mt-1">{errors.treatment[index].name.message}</p>}
                                        </div>
                                    )}
                                />
                            </div>
                            <Controller
                                name={`treatment.${index}.email1`}
                                control={control}
                                rules={{
                                    pattern: {
                                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                        message: "Please enter a valid email address"
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <div>
                                        <Input
                                            label="Email"
                                            style="flex-col"
                                            className="w-full px-2 py-1 text-sm"
                                            value={value}
                                            onChange={onChange}
                                        />
                                        {errors.treatment?.[index]?.email1 && (
                                            <p className="text-red-500 text-sm ">{errors.treatment[index].email1.message}</p>
                                        )}
                                    </div>
                                )}
                            />
                            {/* <Controller
                                name={`treatment.${index}.octoNumber`}
                                control={control}
                                rules={{ required: "OCTO Number is required" }}
                                render={({ field: { onChange, value } }) => (
                                    <div>
                                        <Input
                                            label="OCTO Number*"
                                            style="flex-col"
                                            value={value}
                                            onChange={onChange}
                                        />
                                        {errors.treatment?.[index]?.octoNumber && (
                                            <p className="text-red-500 text-sm mt-1">
                                                {errors.treatment[index].octoNumber.message}
                                            </p>
                                        )}
                                    </div>
                                )}
                            /> */}
                            <Controller
                                name={`treatment.${index}.notificationTypeOption`}
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                    <div>
                                        <Dropdown
                                            label="Notification Type"
                                            styles="flex-col min-w-full"
                                            value={value}
                                            onChange={onChange}
                                            options={notificationTypeOptions}
                                        />
                                    </div>
                                )}
                            />
                            <Controller
                                name={`treatment.${index}.treatmentStatus`}
                                control={control}
                                rules={{ required: "Treatment Status is required" }}
                                render={({ field: { value, onChange } }) => (
                                    <div>
                                        <Dropdown
                                            label="Treatment Status"
                                            styles="flex-col min-w-full"
                                            value={value}
                                            onChange={onChange}
                                            options={statusOptions}
                                        />
                                        {errors.treatment?.[index]?.treatmentStatus && (
                                            <p className="text-red-500 text-sm mt-1">
                                                {errors.treatment[index].treatmentStatus.message}
                                            </p>
                                        )}
                                    </div>
                                )}
                            />
                        </div>
                    ))}
                    <button
                        type="submit"
                        className={`capitalize py-2 my-5 text-center px-5 gap-2 hover:opacity-90 ${inProgress ? "bg-cardTextGray bg-opacity-20" : "bg-dashBtnGradient"} w-full rounded-full text-white`}
                    >
                        {inProgress ? <Loader height="h-auto" /> : "Add New TF/TS"}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default MiniTreatmentRegModal;
