import React, { useEffect, useRef, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { auth, signOutUser } from "../../config/firebase";
import Loader from "../../components/UI/loaders/Loader";
import Header from "./components/Header";
import AuthForm from "./components/auth/Auth";
import Modal from "./components/auth/RegistrationNotCompleted";
import { clearNotificationListener, fetchUserData, listenToUserData } from "../../store/sliceReducers/octoUserSlice";

const tabs = [
	{ label: "Home", link: "/market" },
	{ label: "Connections", link: "/market/connections" },
	{ label: "Company Profile", link: "/market/profile" },
];

const Index = () => {
	const [user, loading] = useAuthState(auth);
	const [isLoading, setIsLoading] = useState(true);
	const [activeTab, setActiveTab] = useState(0);
	const { data: octoUserData, loading: userLoading } = useSelector((state) => state.octoUserData);
	const [modalOpen, setModalOpen] = useState(true);
	const scrollContainerRef = useRef(null);
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	// Fetch initial user data
	useEffect(() => {
		if (user) {
			dispatch(fetchUserData(user.uid));

			// Add listener for real-time updates
			dispatch(listenToUserData(user.uid));
		}

		// Cleanup listener on unmount
		return () => {
			dispatch(clearNotificationListener());
		};
	}, [dispatch, user]);

	// Handle scroll to top and active tab
	useEffect(() => {
		if (scrollContainerRef.current) {
			scrollContainerRef.current.scrollTo({
				top: 0,
				behavior: "smooth",
			});
		}

		if (location.pathname === "/market/profile") {
			setModalOpen(false);
		} else if (octoUserData?.profileCompleted === false) {
			setModalOpen(true);
		}

		let indexOfActiveTab = tabs.findIndex((tab) => tab?.link === location.pathname);
		setActiveTab(indexOfActiveTab);

		if (octoUserData?.role == "admin") {
			navigate("/market-admin");
		}
	}, [location.pathname, octoUserData]);

	// Logout handler
	const logOutHandler = async () => {
		await signOutUser();
		navigate("/market");
	};

	// Initial loading state setup
	useEffect(() => {
		setIsLoading(false);
		let indexOfActiveTab = tabs.findIndex((tab) => tab?.link === location.pathname);
		setActiveTab(indexOfActiveTab);
	}, []);

	// Loading and authentication checks
	if (loading || isLoading) return <Loader />;
	if (!user && !isLoading && !loading) return <AuthForm />;

	// Profile completion check
	if (!octoUserData?.profileCompleted && !loading && !userLoading && modalOpen) {
		return (
			<Modal isOpen={modalOpen} onClose={() => navigate("/market/profile")} title="Profile Incomplete">
				<p>You will need to complete the profile first in order to access the site!</p>
			</Modal>
		);
	}

	console.log({ octoUserData, loading, userLoading });

	if (userLoading) return <Loader />;

	return (
		<div className="h-screen">
			<Header user={user} logOut={logOutHandler} tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
			<div
				style={{ height: `calc(100%)` }}
				className="bg-[#F9F9F9] w-auto z-40 pt-16 overflow-scroll"
				ref={scrollContainerRef}
			>
				<Outlet />
			</div>
		</div>
	);
};

export default Index;
