import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Input from "./Input";

const PasswordInput = ({ label, isDisabled = false, value, onChange, margin = "mt-4" }) => {
	const [showPassword, setShowPassword] = useState(false);

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};
	return (
		<div className="relative">
			<Input isDisabled={isDisabled} label={label} onChange={onChange} value={value} type={showPassword ? "text" : "password"} margin={margin} />
			{!isDisabled && (
				<button type="button" className="absolute inset-y-0 right-0 pr-3 flex items-center" onClick={togglePasswordVisibility}>
					{showPassword ? <FaEye className="h-5 w-5 text-gray-400" /> : <FaEyeSlash className="h-5 w-5 text-gray-400" />}
				</button>
			)}
		</div>
	);
};

export default PasswordInput;
