import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { FaLocationDot } from "react-icons/fa6";
import { IoArrowBackCircleSharp } from "react-icons/io5";
import { LuBookmarkMinus } from "react-icons/lu";
import { MdCall, MdEmail } from "react-icons/md";
import { PiLinkFill } from "react-icons/pi";
import { useNavigate, useParams } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import { useSelector } from "react-redux";
import Loader from "../../../components/UI/loaders/Loader";
import { doc, getDoc } from "firebase/firestore";
import { auth, db } from "../../../config/firebase";
import { initialFilterValue, simplifyConnections } from "../../../utils/constants";
import useConnectionManager from "../../../hooks/useConnectionManager";
import { toast } from "react-toastify";
import { fetchUserData } from "../../../store/sliceReducers/octoUserSlice";
import { useAuthState } from "react-firebase-hooks/auth";

const ProfileCard = () => {
	const [user, loading] = useAuthState(auth);
	const [openSections, setOpenSections] = useState({});
	const { data: octoUserData, loading: userLoading } = useSelector((state) => state.octoUserData);
	const [isUserIdDataLoaded, setIsUserIdDataLoaded] = useState(false);
	const [alreadySent, setAlreadySent] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [userIdData, setUserIdData] = useState({});
	const { sendConnectionRequest } = useConnectionManager();
	const [error, setError] = useState(false);
	const navigate = useNavigate();
	const { id } = useParams();

	const toggleSection = (id) => {
		setOpenSections((prev) => ({
			...prev,
			[id]: !prev[id],
		}));
	};

	const goBackHandler = () => {
		navigate("/market");
	};

	console.log({ userIdData });

	useEffect(() => {
		const fetchUserDetail = async () => {
			let docSnap = await getDoc(doc(db, "octoMarketUsers", id));
			if (!docSnap.exists) {
				setError(true);
			} else {
				let data = { ...docSnap.data(), id: docSnap.id };
				const userData = {
					id: data.id,
					name: data?.name ?? data.companyName,
					address: data?.address ?? "",
					region: data?.region ?? "",
					specialty: data?.specialty ?? "",
					category: data?.category ?? [],
					websiteUrl: data?.websiteUrl ?? "",
					bio: data?.bio ?? "",
					profileImage: data?.profileImage ?? "",
					contacts: data?.contacts ?? [],
					email: data?.email ?? "",
					companyName: data?.companyName ?? "",
					shortDesc: data?.shortDesc ?? "",
					serviceArea: data?.serviceArea ?? "",
					serviceAreaArr: data?.serviceAreaArr ?? "",
					approved: Boolean(data.approved),
					profileCompleted: Boolean(data.profileCompleted),
					phone: data?.phone ?? "",
					localAreas: data?.localAreas ?? [],
					connections: simplifyConnections(data?.connections ?? {}) ?? {},
				};

				setUserIdData(userData);
			}
			setIsUserIdDataLoaded(true);
		};
		if (id != octoUserData?.id) {
			fetchUserDetail();
		} else {
			navigate("/market");
		}
	}, [octoUserData]);

	useEffect(() => {
		console.log(octoUserData);

		Object.keys(octoUserData?.connections ?? {}).map((key) => {
			if (key == userIdData.id) {
				let x = octoUserData.connections?.[key] ?? {};
				let resp = "Sent";
				console.log({ status: x.status, data: octoUserData.connections, userIdData });

				if (x.type == "received" && x.status == "pending") {
					resp = "Received";
				} else if (x.status == "accepted") {
					resp = "Connected";
				} else if (!x.status) {
					resp = "";
				}
				setAlreadySent(resp);
			}
		});
		if (Object.keys(octoUserData?.connections ?? {}).length == 0) {
			setAlreadySent("");
		}
	}, [userIdData]);

	const contactItems = [
		{ icon: <FaLocationDot size={24} color="#007AFF" />, text: "address" },
		{ icon: <MdCall size={24} color="#007AFF" />, text: "phone" },
		{ icon: <MdEmail size={24} color="#007AFF" />, text: "email" },
		{ icon: <PiLinkFill size={28} color="#007AFF" />, text: "websiteUrl" },
	];

	const sendConnectRequest = async () => {
		setIsLoading(true);
		console.log("Sending connection req to user-", userIdData.id, ", from current user-", octoUserData.id);
		await sendConnectionRequest(userIdData.id);
		// await dispatch(fetchUserData(user.uid));
		setAlreadySent("Sent");
		toast.success("Sent successfully");
		setIsLoading(false);
	};

	if (!isUserIdDataLoaded) return <Loader />;

	if (error)
		return (
			<div className="h-[50%] flex w-full items-center justify-center">
				<h2 className="text-center">No user found</h2>
			</div>
		);

	// let simplifiedCategories = userIdData?.category?.map((category) => initialFilterValue[category]).join(", ");

	console.log({ localAreas: userIdData.localAreas });

	return (
		<div className="container mx-auto px-4 py-16">
			{/* Header Banner */}
			<motion.div
				className="relative z-10"
				initial={{ opacity: 0, y: -50 }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: 0.5 }}
			>
				<div className="h-32 bg-[#007AFF] p-2 rounded-t-xl">
					<motion.button whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
						<IoArrowBackCircleSharp className="text-white cursor-pointer" size={36} onClick={goBackHandler} />
					</motion.button>
				</div>
				<motion.div
					className="absolute -bottom-12 left-8"
					initial={{ scale: 0 }}
					animate={{ scale: 1 }}
					transition={{ delay: 0.3, type: "spring", stiffness: 200 }}
				>
					<div className="bg-[#dfdfdf]rounded-lg z-30 w-32 h-32 overflow-hidden flex items-center justify-center">
						<img
							src={
								userIdData.profileImage?.length > 0
									? userIdData.profileImage
									: "https://storage.googleapis.com/routes-123.appspot.com/assets/logo%201.png"
							}
							alt=""
							className="w-full h-full rounded-lg object-cover"
						/>
					</div>
				</motion.div>
			</motion.div>

			{/* Content Section */}
			<motion.div
				className="bg-white rounded-b-xl pt-16 px-8"
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				transition={{ delay: 0.5 }}
			>
				<div className="flex justify-between items-start">
					<motion.div
						className="flex flex-col gap-2"
						initial={{ x: -50, opacity: 0 }}
						animate={{ x: 0, opacity: 1 }}
						transition={{ delay: 0.6 }}
					>
						<h1 className="text-2xl font-bold text-gray-900">
							{userIdData.companyName ? userIdData.companyName : userIdData.name}
						</h1>
						<p className="text-[#898989] mt-1">
							{userIdData?.category?.length ? (
								<ul className="list-disc list-inside text-sm text-gray-600">
									{userIdData?.category?.map((category, idx) => (
										<li key={idx}>{initialFilterValue[category]}</li>
									))}
								</ul>
							) : (
								"Category not provided"
							)}
						</p>
						<div>
							<p className="text-[#898989]">{userIdData?.shortDesc}</p>
						</div>
						<div className="flex items-center gap-2 mt-2 text-[#898989]">
							<FaLocationDot size={20} />
							{userIdData.address?.length ? userIdData.address : "--"}
						</div>
					</motion.div>
					<motion.div
						className="flex gap-6"
						initial={{ x: 50, opacity: 0 }}
						animate={{ x: 0, opacity: 1 }}
						transition={{ delay: 0.6 }}
					>
						<motion.button
							className={`disabled:cursor-not-allowed disabled:bg-[#898989] ${
								isLoading ? "bg-[#898989]" : "bg-[#007AFF] hover:bg-blue-700"
							} text-white px-10 py-3 rounded-full`}
							disabled={(octoUserData?.profileCompleted && !octoUserData?.approved) || alreadySent}
							whileHover={{ scale: 1.05 }}
							whileTap={{ scale: 0.95 }}
							onClick={sendConnectRequest}
						>
							{isLoading ? <Loader height="" /> : alreadySent ? alreadySent : "Connect"}
						</motion.button>
					</motion.div>
				</div>

				<div className="flex py-20 justify-between gap-12">
					<div className="flex flex-col w-3/5 gap-12">
						{/* Bio Section */}
						<motion.div
							className="w-full lg:w-2/3"
							initial={{ opacity: 0, y: 50 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ delay: 0.7 }}
						>
							<h2 className="text-xl font-semibold mb-4">Bio</h2>
							<p className="text-[#898989] leading-relaxed">{userIdData.bio?.length ? userIdData.bio : "--"}</p>
						</motion.div>
						<motion.div
							className="w-full lg:w-2/3"
							initial={{ opacity: 0, y: 50 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ delay: 0.7 }}
						>
							<h2 className="text-xl font-semibold mb-4">Service Area</h2>
							{userIdData.serviceArea == "local" ? (
								userIdData.localAreas?.map((area, i) => (
									<p className="text-[#898989] leading-relaxed" key={i?.toString()}>
										{area}
									</p>
								))
							) : (
								<p className="text-[#898989] leading-relaxed capitalize">
									{userIdData.serviceArea?.length ? userIdData.serviceArea + " Shipping" : "--"}
								</p>
							)}
						</motion.div>
					</div>
					{/* Contact Information */}
					<motion.div
						className="w-full lg:w-1/3 flex flex-col gap-6"
						initial={{ opacity: 0, y: 50 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{ delay: 0.8 }}
					>
						<div className="flex-1">
							<h2 className="text-[#007AFF] font-semibold text-2xl mb-6">Contact Us</h2>

							<div className="space-y-4">
								{contactItems.map((item, index) => (
									<motion.div key={index} className="flex items-center gap-3">
										<div className="w-11 h-11 bg-[#007AFF] bg-opacity-10 rounded-full flex items-center justify-center">
											{item.icon}
										</div>
										<span className="text-black">
											{userIdData?.[item.text]?.length ? userIdData?.[item.text] : "--"}
										</span>
									</motion.div>
								))}
							</div>
						</div>
						{/* <div className="flex flex-col gap-4">
							{(userIdData.contacts ?? []).map((contact, i) => (
								<div key={contact.id} className="rounded-lg overflow-hidden">
									<button
										onClick={() => toggleSection(contact.id)}
										className="w-full text-[#007AFF] text-lg flex justify-between items-center bg-white transition-colors"
									>
										<span className="font-medium">{`Contact ${i + 1}`}</span>
										<IoIosArrowDown
											className={`w-5 h-5 transition-transform duration-200 ${
												openSections[contact.id] ? "transform rotate-180" : ""
											}`}
										/>
									</button>

									<div
										className={`overflow-hidden transition-all duration-200 ${
											openSections[contact.id] ? "max-h-48" : "max-h-0"
										}`}
									>
										<div className="p-4 space-y-2">
											<p className="text-sm text-gray-600 capitalize">
												<span className="font-medium">Name:</span> {`${contact.firstName} ${contact.lastName}`}
											</p>
											<p className="text-sm text-gray-600">
												<span className="font-medium">Phone:</span> {contact.phone}
											</p>
											<p className="text-sm text-gray-600">
												<span className="font-medium">Email:</span> {contact.email}
											</p>
										</div>
									</div>
								</div>
							))}
						</div> */}
					</motion.div>
				</div>
			</motion.div>
		</div>
	);
};

export default ProfileCard;
