const events = [
	{
		id: "1",
		title: "Number of Stops: 7",
		start: "2024-09-04",
		end: "2024-09-06",
		color: "#007AFF",
	},
	{
		id: "13",
		title: "Number of Stops: 7",
		start: "2024-10-09",
		end: "2024-10-09",
		color: "#36CFC9",
	},
	{
		id: "12",
		title: "Number of Stops: 7",
		start: "2024-09-11",
		end: "2024-09-11",
		color: "#FFA940",
	},
	{
		id: "11",
		title: "Number of Stops: 7",
		start: "2024-09-17",
		end: "2024-09-18",
		color: "#40A9FF",
	},
	{
		id: "2",
		title: "Number of Stops: 7",
		start: "2024-10-15",
		end: "2024-10-15",
		color: "#FF4D4F",
	},
	{
		id: "3",
		title: "Number of Stops: 7",
		start: "2024-10-20T10:00:00",
		end: "2024-10-20T11:00:00",
		color: "#FF9900",
	},
	{
		id: "4",
		title: "Number of Stops: 7",
		start: "2024-10-25",
		end: "2024-10-27",
		color: "#8E44AD",
	},
	{
		id: "5",
		title: "Number of Stops: 7",
		start: "2024-10-31T18:00:00",
		end: "2024-10-31T18:20:00",
		color: "#2ECC71",
	},
	// New events for October
	{
		id: "6",
		title: "Number of Stops: 7",
		start: "2024-10-31T18:30:00",
		end: "2024-10-31T18:50:00",
		color: "#FF5733",
	},
	{
		id: "7",
		title: "Number of Stops: 7",
		start: "2024-10-31T19:00:00",
		end: "2024-10-31T19:30:00",
		color: "#3498DB",
	},
	{
		id: "8",
		title: "Number of Stops: 7",
		start: "2024-10-31T19:50:00",
		end: "2024-10-31T20:00:00",
		color: "#F39C12",
	},
	{
		id: "9",
		title: "Number of Stops: 7",
		start: "2024-10-28",
		end: "2024-10-28",
		color: "#8E44AD",
	},
	{
		id: "10",
		title: "Number of Stops: 7",
		start: "2024-10-30T14:00:00",
		end: "2024-10-30T15:00:00",
		color: "#F39C12",
	},
];

// Color codes should be mapped later based on the status....

const colorCodes = {
	blue: "#0895D8",
	magenta: "#396AFF",
	yellow: "#E2A907",
	green: "#3AC389",
};

import React, { useState, useRef, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

const FullCalender = () => {
	const calendarRef = useRef(null);
	const [expandedEventId, setExpandedEventId] = useState(null);
	const [slideDirection, setSlideDirection] = useState("right");
	const [prevView, setPrevView] = useState("dayGridMonth");

	useEffect(() => {
		const style = document.createElement("style");
		style.textContent = `
      /* Button styling to match the image */
      .fc .fc-button-primary {
        background-color: rgb(85, 85, 85) !important;
        border-color: rgb(85, 85, 85) !important;
        color: white !important;
        text-transform: capitalize !important;
        padding: 6px 12px !important;
        font-weight: normal !important;
      }

      .fc .fc-button-primary:not(:disabled).fc-button-active,
      .fc .fc-button-primary:not(:disabled):active {
        background-color: #18232f !important;
        border-color: rgb(52, 58, 64) !important;
      }

      .fc .fc-button-primary:hover {
        background-color: #666666 !important;
        border-color: rgb(73, 80, 87) !important;
      }

      .fc .fc-today-button {
        background-color: #666666 !important;
        border-color: #666666 !important;
      }

      .fc .fc-today-button:hover {
        background-color: rgb(90, 98, 104) !important;
        border-color: rgb(90, 98, 104) !important;
      }

      .fc .fc-toolbar-title {
        font-size: 1.25rem !important;
        font-weight: 600 !important;
      }
              
      /* Event container styling */
      .fc .fc-daygrid-day-frame {
        min-height: 120px !important;
        height: 100% !important;
        position: relative;
        overflow: visible !important;
      }
      
      .fc .fc-daygrid-day-events {
        margin: 0 !important;
        position: relative !important;
        overflow: visible !important;
      }
      
      .fc .fc-daygrid-event-harness {
        margin: 2px 2px !important;
        width: calc(100% - 4px) !important;
        position: relative !important;
        overflow: visible !important;
      }
      
      .event-card {
        display: flex;
        align-items:center;
        min-height: 40px !important;
        width: 100% !important;
        cursor: pointer;
        border-radius: 4px;
        position: relative;
        z-index: 1;
      }
      
      .event-card.expanded {
        position: relative;
        z-index: 1000 !important;
      }
      
      .fc-daygrid-event-harness:not(:has(.event-card.expanded)) {
        z-index: 1 !important;
      }
      
      .event-content-wrapper {
        min-height: 32px !important;
        display: flex !important;
        align-items: center !important;
        padding: 4px 8px;
        position: relative;
        z-index: 2;
      }
      
      .expanded-content {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        padding: 8px;
        background-color: inherit;
        border-radius: 0px 0px 4px 4px;
        opacity: 0;
        transform: translateY(-10px);
        pointer-events: none;
        transition: opacity 0.2s ease-out, transform 0.2s ease-out;
        z-index: 999;
      }
      
      .event-card.expanded .expanded-content {
        opacity: 1;
        transform: translateY(0);
        pointer-events: auto;
        z-index: 1001;
      }
      
      .expanded-content-inner {
        background-color: rgba(255, 255, 255, 0.95);
        border-radius: 4px;
        padding: 8px;
        margin-top: 4px;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
      }
      
      .fc-view-harness {
        overflow: visible !important;
        position: relative;
        z-index: 1;
      }
      
      .fc-scrollgrid-sync-inner {
        position: relative;
        overflow: visible !important;
      }
      
      .fc-daygrid-day-frame {
        overflow: visible !important;
      }
      
      .fc-daygrid-day-events {
        min-height: 0 !important;
      }
      
      .fc-daygrid-event-harness {
        background: transparent !important;
        position: relative !important;
      }
      
      .fc-daygrid-event-harness + .fc-daygrid-event-harness {
        margin-top: 2px !important;
      }
      
      .fc-daygrid-event-harness:hover {
        z-index: 100 !important;
      }
      
      .fc .fc-popover {
        z-index: 2000 !important;
      }

      .time-info {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 6px 8px;
        background-color: rgba(0, 0, 0, 0.04);
        border-radius: 6px;
        margin-bottom: 8px;
      }
      
      .time-info-text {
        display: flex;
        flex-direction: column;
        gap: 2px;
      }
      
      .time-label {
        font-size: 11px;
        color: #6B7280;
        text-transform: uppercase;
        letter-spacing: 0.05em;
        font-weight: 500;
      }
      
      .time-value {
        font-size: 13px;
        color: #374151;
        font-weight: 500;
      }
      
      .description-section {
        padding: 8px;
        background-color: rgba(0, 0, 0, 0.02);
        border-radius: 6px;
      }
      
      .description-label {
        font-size: 11px;
        color: #6B7280;
        text-transform: uppercase;
        letter-spacing: 0.05em;
        font-weight: 500;
        margin-bottom: 4px;
      }
      
      .description-text {
        font-size: 13px;
        color: #374151;
        line-height: 1.5;
      }
    `;
		document.head.appendChild(style);
		return () => document.head.removeChild(style);
	}, []);

	const formatEventDate = (date) => {
		if (!date) return "";
		const eventDate = new Date(date);
		return new Intl.DateTimeFormat("en-US", {
			hour: "numeric",
			minute: "numeric",
			hour12: true,
		}).format(eventDate);
	};

	const renderEventContent = (eventInfo) => {
		const isExpanded = expandedEventId === eventInfo.event.id;

		return (
			<div
				className={`event-card ${isExpanded ? "expanded min-w-fit" : ""}`}
				style={{
					backgroundColor: eventInfo.event.backgroundColor || eventInfo.event.extendedProps.color,
				}}
			>
				<div className="event-content-wrapper">
					<div className="flex items-center space-x-2 h-full w-full">
						<div className="w-2 h-2 rounded-full bg-white flex-shrink-0"></div>
						<strong className="text-white hover:text-opacity-90 flex-grow text-sm truncate">
							{eventInfo.event.title}
						</strong>
					</div>
				</div>
				<div className="expanded-content">
					<div className="bg-white p-2">
						<div className="time-info-text">
							<span className="time-value text-wrap">
								{formatEventDate(eventInfo.event.start)} - {formatEventDate(eventInfo.event.end)}
							</span>
						</div>

						{eventInfo.event.extendedProps?.description && (
							<div className="description-section">
								<div className="description-label">Description</div>
								<div className="description-text">{eventInfo.event.extendedProps.description}</div>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	};

	const handleEventClick = (clickInfo) => {
		clickInfo.jsEvent.preventDefault();
		setExpandedEventId((prev) => (prev === clickInfo.event.id ? null : clickInfo.event.id));
	};

	const handleViewChange = (viewType) => {
		const viewOrder = ["timeGridDay", "dayGridWeek", "dayGridMonth", "dayGridYear"];
		const prevIndex = viewOrder.indexOf(prevView);
		const newIndex = viewOrder.indexOf(viewType);

		setSlideDirection(prevIndex < newIndex ? "right" : "left");
		setPrevView(viewType);
	};

	return (
		<div className="bg-white calendar-container">
			<div
				className={`view-transition ${slideDirection === "right" ? "slide-enter-active" : "slide-left-enter-active"}`}
			>
				<FullCalendar
					ref={calendarRef}
					plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
					initialView="dayGridMonth"
					headerToolbar={{
						left: "prev,next today",
						center: "title",
						right: "dayGridYear,dayGridMonth,dayGridWeek,timeGridDay",
					}}
					buttonText={{
						prev: "Prev",
						next: "Next",
						today: "Today",
						month: "Month",
						week: "Week",
						day: "Day",
						year: "Year",
					}}
					events={events}
					eventMaxStack={2}
					eventClick={handleEventClick}
					eventContent={renderEventContent}
					viewDidMount={(view) => handleViewChange(view.view.type)}
				/>
			</div>
		</div>
	);
};

export default FullCalender;
