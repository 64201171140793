import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { getDrivers, deleteDriver } from "../../../../../utils/firebaseOperations";
import { toast } from "react-toastify";
import MiniDriverRegistrationForm from "../../../../../components/MiniDriverRegModal";
import Tooltip from "../../../../../components/UI/Tooltip";
import ShimmerLoader from "../../../../../components/UI/loaders/ShimmerLoader";
import MultiselectDropdown from "../../../../../components/UI/dropdowns/MultiselectDropdown";

const ManageDrivers = () => {
	const [selectedDrivers, setSelectedDrivers] = useState([]);
	const [selectAll, setSelectAll] = useState(false);
	const [indeterminate, setIndeterminate] = useState(false);
	const [data, setData] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [isModalOpen, setIsModalOpen] = useState(false);
	// const [statusFilter, setStatusFilter] = useState("all");
	const navigate = useNavigate();
	const [statusFilter, setStatusFilter] = useState(["all"]);


	console.log('data', data)
	useEffect(() => {
		getDriversData();
	}, []);

	useEffect(() => {
		filterData();
	}, [statusFilter, data]);

	useEffect(() => {
		if (selectedDrivers.length === 0) {
			setSelectAll(false);
			setIndeterminate(false);
		} else if (selectedDrivers.length === filteredData.length) {
			setSelectAll(true);
			setIndeterminate(false);
		} else {
			setSelectAll(false);
			setIndeterminate(true);
		}
	}, [selectedDrivers, filteredData]);

	// const filterData = () => {
	// 	if (statusFilter === "all") {
	// 		setFilteredData(data);
	// 	} else {
	// 		const filtered = data.filter(driver =>
	// 			(driver.driverStatus || "active").toLowerCase() === statusFilter.toLowerCase()
	// 		);
	// 		setFilteredData(filtered);
	// 	}
	// };
	const filterData = () => {
		if (statusFilter.includes('all')) {
			setFilteredData(data);
		} else {
			const filtered = data.filter(driver =>
				statusFilter.includes((driver.driverStatus || 'active').toLowerCase())
			);
			setFilteredData(filtered);
		}
	};

	const statusOptions = [{ label: "Active", value: "active" },
	{ label: "Inactive", value: "inactive" }]


	const getDriversData = async () => {
		setLoading(true);
		try {
			const drivers = await getDrivers();
			setData(drivers);
		} catch (err) {
			setError("Error loading drivers");
		} finally {
			setLoading(false);
		}
	};

	const handleSelectAllChange = () => {
		const newSelectAll = !selectAll;
		setSelectAll(newSelectAll);
		setIndeterminate(false);
		if (newSelectAll) {
			setSelectedDrivers([...Array(filteredData.length).keys()]);
		} else {
			setSelectedDrivers([]);
		}
	};

	const handleCheckboxChange = (index) => {
		setSelectedDrivers(prev => {
			if (prev.includes(index)) {
				return prev.filter(i => i !== index);
			} else {
				return [...prev, index];
			}
		});
	};

	const handleRowClick = (driver) => {
		navigate(`${driver.id}/edit`);
	};

	const openAddDriverModal = () => {
		setIsModalOpen(true);
	};

	// const StatusFilter = () => (
	// 	<select
	// 		className="ml-4 p-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
	// 		value={statusFilter}
	// 		onChange={(e) => setStatusFilter(e.target.value)}
	// 	>
	// 		<option value="all">All Status</option>
	// 		<option value="active">Active</option>
	// 		<option value="inactive">Inactive</option>
	// 		<option value="limited">Limited</option>
	// 	</select>
	// );


	const StatusFilter = () => {
		// State to hold selected statuses
		// const [statusFilter, setStatusFilter] = useState(["all"]);

		// Handle changes in selected options
		const handleStatusChange = (selectedOptions) => {
			setStatusFilter(selectedOptions);
		};

		return (
			<MultiselectDropdown
				width="w-44"
				buttonText="Driver Status"
				options={[
					{ label: "All", value: "all" },
					...statusOptions
				]}
				selectedOptions={statusFilter}
				onSelectedOptionsChange={handleStatusChange}
			/>
		);
	};

	return (
		<div className="p-6">
			<div className="main-container flex flex-col border border-cardBorderCol bg-white rounded-cardRadii h-full">
				<div className="text-lg px-8 py-4 flex justify-between items-center">
					<div>Drivers List</div>
					<StatusFilter />
				</div>
				<div
					className="overflow-x-scroll overflow-y-hidden"
					style={{
						height: `calc(100% - 10px)`,
					}}
				>
					<div className="bg-[#E5F2FF] flex font-medium min-w-fit py-4">
						<div className="w-8 mx-6 flex justify-center items-center hover:cursor-pointer">
							<input
								type="checkbox"
								className="w-4 h-4 bg-white"
								checked={selectAll}
								ref={(input) => input && (input.indeterminate = indeterminate)}
								onChange={handleSelectAllChange}
							/>
						</div>
						<div className="flex items-center">
							<div className="truncate w-60">Name</div>
							<div className="truncate w-32">OCTO ID #</div>
							<div className="truncate w-40">Phone</div>
							<div className="truncate w-60">Email</div>
							<div className="truncate w-60">Location Assignment</div>
							<div className="truncate w-32">Duty Status</div>
						</div>
					</div>

					{loading ? (
						<ShimmerLoader cols={6} rows={20} />
					) : (
						<div style={{ height: `calc(100% - 60px)` }} className="min-w-fit overflow-y-scroll overflow-x-hidden">
							{filteredData.length > 0 ? (
								filteredData.map((driver, index) => (
									<DriverItem
										key={index}
										driver={driver}
										index={index}
										selectedDrivers={selectedDrivers}
										handleCheckboxChange={handleCheckboxChange}
										handleRowClick={handleRowClick}
									/>
								))
							) : (
								<div className="flex h-full text-cardTextGray text-xl justify-center items-center">
									<p>No drivers found</p>
								</div>
							)}
						</div>
					)}

					<FooterActionItems
						driversSelected={selectedDrivers}
						drivers={filteredData}
						onRefresh={getDriversData}
					/>
				</div>
			</div>

			{/* Add New Driver Button - Hidden when checkboxes are selected */}
			{selectedDrivers.length === 0 && (
				<div className="fixed bottom-8 right-8">
					<button
						onClick={openAddDriverModal}
						className="bg-lightBlue hover:bg-cardTextBlue text-white rounded-full p-4 shadow-lg transition-all duration-300 flex items-center space-x-2"
					>
						<svg
							className="w-6 h-6"
							fill="none"
							stroke="currentColor"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth={2}
								d="M12 4v16m8-8H4"
							/>
						</svg>
						<span>Add New Driver</span>
					</button>
				</div>
			)}

			{isModalOpen && (
				<MiniDriverRegistrationForm
					onClose={() => setIsModalOpen(false)}
					activeSubmenu="driver"
				/>
			)}
		</div>
	);
};

const DriverItem = ({ driver, index, selectedDrivers, handleCheckboxChange, handleRowClick }) => {
	const [activeTooltip, setActiveTooltip] = useState(null);
	const [tooltipPosition, setTooltipPosition] = useState({ left: 0, top: 0 });
	const [transformOrigin, setTransformOrigin] = useState("top left");
	const timeoutRef = useRef(null);
	const tooltipRef = useRef(null);

	const handleMouseEnter = (driverId, event) => {
		clearTimeout(timeoutRef.current);
		timeoutRef.current = setTimeout(() => {
			setActiveTooltip(driverId);
			updateTooltipPosition(event);
		}, 1000);
	};

	const handleMouseLeave = (event) => {
		clearTimeout(timeoutRef.current);
		if (!tooltipRef.current || !event.relatedTarget || !(event.relatedTarget instanceof Node) || !tooltipRef.current.contains(event.relatedTarget)) {
			setActiveTooltip(null);
		}
	};

	const updateTooltipPosition = (event) => {
		const viewportWidth = window.innerWidth;
		const cursorX = event.clientX;
		const cursorY = event.clientY;
		const tooltipWidth = 500;
		const tooltipHeight = 300;
		const offset = 10;
		let left, top;

		if (cursorX > viewportWidth / 2) {
			left = cursorX - tooltipWidth - offset;
			setTransformOrigin("top right");
		} else {
			left = cursorX + offset;
			setTransformOrigin("top left");
		}

		top = cursorY - offset;
		left = Math.max(0, Math.min(left, viewportWidth - tooltipWidth));
		top = Math.max(0, Math.min(top, window.innerHeight - tooltipHeight));

		setTooltipPosition({ left, top });
	};

	const handlePhoneClick = (event, phone) => {
		event.stopPropagation();
		window.location.href = `tel:${phone}`;
	};

	useEffect(() => {
		return () => clearTimeout(timeoutRef.current);
	}, []);

	return (
		<div
			className="flex w-full items-center transition-colors duration-300 border-b border-gray-300 hover:bg-cardTextGray hover:bg-opacity-20"
			style={{ height: "45px" }}
		>
			<div className="min-w-8 mx-6 flex justify-center hover:cursor-pointer">
				<input
					type="checkbox"
					className="w-4 h-4 bg-white"
					checked={selectedDrivers.includes(index)}
					onChange={() => handleCheckboxChange(index)}
				/>
			</div>
			<div
				className="w-full flex items-center cursor-pointer"
				onClick={() => handleRowClick(driver)}
				onMouseEnter={(e) => handleMouseEnter(driver.OctoDriverId || driver.id, e)}
				onMouseLeave={handleMouseLeave}
			>
				<div className="truncate w-60">{`${driver.firstName} ${driver.middleInitial || ''} ${driver.lastName}`}</div>
				<div className="truncate w-32">{(driver.OctoDriverId || driver.id)?.slice(0, 6) || '--'}</div>
				<div className="w-40">
					<span
						className={`truncate ${driver.phoneNumber ? 'hover:text-blueText hover:underline' : ''}`}
						onClick={(e) => driver.phoneNumber && handlePhoneClick(e, driver.phoneNumber)}
					>
						{driver.phoneNumber || '--'}
					</span>
				</div>
				<div className="truncate w-60">{driver.email || '--'}</div>
				<div className="truncate w-60">{driver.driverLocation || '--'}</div>
				<div className="truncate w-32">{driver.limitedDutyStatus || 'Active'}</div>
			</div>
			<AnimatePresence>
				{activeTooltip === (driver.OctoDriverId || driver.id) && (
					<motion.div
						ref={tooltipRef}
						className="fixed bg-white rounded-cardRadii min-w-[500px] shadow-2xl border border-cardTextGray border-opacity-10 p-6 z-10"
						style={{
							left: `${tooltipPosition.left}px`,
							top: `${tooltipPosition.top}px`,
							width: "300px",
						}}
						initial={{ opacity: 0, scale: 0.8, transformOrigin }}
						animate={{ opacity: 1, scale: 1 }}
						exit={{ opacity: 0, scale: 0.8 }}
						transition={{ duration: 0.2 }}
						onMouseEnter={() => setActiveTooltip(driver.OctoDriverId || driver.id)}
						onMouseLeave={handleMouseLeave}
					>
						<ToolTipContent driver={driver} />
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};

const ToolTipContent = ({ driver }) => {
	const details = [
		{ label: "Name", value: `${driver.firstName} ${driver.middleInitial || ''} ${driver.lastName}` },
		{ label: "Phone", value: driver.phoneNumber || "--" },
		{ label: "Email", value: driver.email || "--" },
		{ label: "Address", value: driver.driverLocation || "--" },
		{ label: "ID", value: driver.OctoDriverId || driver.id || "--" },
		{ label: "Status", value: driver.driverStatus || "Active" },
		{ label: "License", value: driver.driverLicenseInfo || "--" },
	];

	return (
		<div className="w-full">
			{details.map((item) => (
				<div key={item.label} className="w-full flex">
					<div className="w-1/2 p-2 text-cardTextGray truncate">{item.label}</div>
					<div className="w-1/2 p-2 truncate">{item.value}</div>
				</div>
			))}
		</div>
	);
};
const StepsComp = ({ steps }) => {
	const stepsList = [

		{ key: "personalInfo", label: "Personal Info" },
		{ key: "license", label: "License" },
		{ key: "training", label: "Training" },
		{ key: "documents", label: "Documents" },
		{ key: "vehicle", label: "Vehicle" }
	];

	return (
		<div className="flex h-11 items-center justify-center gap-1">
			{stepsList.map(step => (
				<Tooltip key={step.key} text={step.label}>
					<span className={`h-3.5 w-1.5 rounded-full ${steps[step.key] ? "bg-lightBlue" : "bg-cardTextGray"}`}></span>
				</Tooltip>
			))}
		</div>
	);
};

const FooterActionItems = ({ driversSelected, drivers, onRefresh }) => {
	const handleAction = async (actionType) => {
		const selectedDriverIds = drivers
			.filter((_, i) => driversSelected.includes(i))
			.map(driver => driver.id);

		switch (actionType) {
			case "Delete":
				const loadingToast = toast.loading("Deleting drivers...");
				try {
					await Promise.all(selectedDriverIds.map(id => deleteDriver(id)));
					toast.update(loadingToast, {
						render: "Drivers deleted successfully!",
						type: "success",
						isLoading: false,
						autoClose: 3000,
					});
					onRefresh();
				} catch (error) {
					toast.update(loadingToast, {
						render: "Error deleting drivers!",
						type: "error",
						isLoading: false,
						autoClose: 3000,
					});
				}
				break;
			case "Email":
				const selectedEmails = drivers
					.filter((_, i) => driversSelected.includes(i))
					.map(driver => driver.email)
					.filter(Boolean)
					.join(";");
				window.open(`mailto:${selectedEmails}`, "_blank");
				break;
			// Add more actions as needed
		}
	};

	return (
		<div className={`fixed bottom-0 left-0 right-0 ml-72 shadow-md bg-white px-8 transition-all duration-300 ease-in-out ${driversSelected?.length > 0 ? "translate-y-0" : "translate-y-full"}`}>
			<div className="w-full py-4 flex justify-between items-center">
				<span className="truncate">{driversSelected?.length} item(s) selected</span>
				<div className="space-x-2">
					{["Export", "Edit", "Send Message", "Email", "Delete"].map((actionBtn) => (
						<button
							key={actionBtn}
							className={`px-4 py-2 rounded-lg ${actionBtn === "Delete" ? "bg-lightBlue text-white hover:bg-cardTextBlue" : "bg-creamWhite hover:bg-cardTextGray hover:bg-opacity-10"
								} border transition-colors duration-200 ease-in-out`}
							onClick={() => handleAction(actionBtn)}
						>
							{actionBtn}
						</button>
					))}
				</div>
			</div>
		</div>
	);
};

export default ManageDrivers;