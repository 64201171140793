import React, { useEffect, useRef, useState, useMemo, useCallback } from "react";
import { IoMdNotificationsOutline } from "react-icons/io";
import { GoDotFill } from "react-icons/go";
import NotificationItem from "./NotificationItem";
import { motion, AnimatePresence } from "framer-motion";

const sampleNotifications = [
	{ id: 1, message: "New message received", unread: true },
	{ id: 2, message: "You have a new follower", unread: true },
	{ id: 3, message: "Your post was liked", unread: false },
];

const Notification = ({ isOpen, setIsOpen }) => {
	const [notifications, setNotifications] = useState(sampleNotifications);
	const notificationRef = useRef(null);
	const cardRef = useRef(null);

	const hasUnreadNotifications = useMemo(() => notifications.some((notif) => notif.unread), [notifications]);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				notificationRef.current &&
				!notificationRef.current.contains(event.target) &&
				cardRef.current &&
				!cardRef.current.contains(event.target)
			) {
				setIsOpen(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [setIsOpen]);

	const markAllAsRead = useCallback(() => {
		setNotifications((prevNotifications) => prevNotifications.map((notif) => ({ ...notif, unread: false })));
	}, []);

	const markAsRead = useCallback((id) => {
		setNotifications((prevNotifications) =>
			prevNotifications.map((notif) => (notif.id === id ? { ...notif, unread: false } : notif))
		);
	}, []);

	const toggleNotifications = useCallback(
		(e) => {
			e.stopPropagation();
			setIsOpen((prevIsOpen) => !prevIsOpen);
		},
		[setIsOpen]
	);

	return (
		<div className="hidden sm:block">
			<div
				onClick={toggleNotifications}
				ref={notificationRef}
				className="hover:bg-cardTextGray hover:bg-opacity-10 transition-colors duration-200 ease-in-out flex items-center relative hover:cursor-pointer bg-white justify-center h-12 w-12 border border-gray-200 rounded-full"
			>
				<IoMdNotificationsOutline size={24} className="text-dashInActiveBtnText" />
				{hasUnreadNotifications && <GoDotFill size={16} className="text-[#FF2D55] absolute top-3 left-5" />}
			</div>
			<AnimatePresence>
				{isOpen && (
					<motion.div
						initial={{ height: 0, opacity: 0 }}
						animate={{ height: "auto", opacity: 1 }}
						exit={{ height: 0, opacity: 0 }}
						transition={{ duration: 0.25 }}
						ref={cardRef}
						className="absolute top-20 w-72 md:w-80 overflow-hidden bg-white shadow-lg border border-borderCol rounded-2xl z-50"
					>
						<div className="flex justify-between items-center px-4 py-2">
							<h3 className="text-base md:text-lg font-semibold text-cardTextGray">Notifications</h3>
							<button
								onClick={markAllAsRead}
								className="text-sm max-w-fit hover:text-lightBlue text-dashInActiveBtnText"
							>
								Mark all as read
							</button>
						</div>
						{notifications.length > 0 ? (
							<NotificationItem markAsRead={markAsRead} allNotification={notifications} />
						) : (
							<div className="my-2 flex justify-center min-h-20 items-center text-cardTextGray">No notifications</div>
						)}
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};

export default React.memo(Notification);
