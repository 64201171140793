import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { getAllVendor, deleteVendor } from "../../../../../utils/firebaseOperations";
import { toast } from "react-toastify";
import MultiselectDropdown from "../../../../../components/UI/dropdowns/MultiselectDropdown";
import { motion, AnimatePresence } from "framer-motion";
import MiniVendorRegModal from "../../../../../components/userAccBtn/MiniVendorRegModal";

const VendorFacilities = () => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [selectedStatuses, setSelectedStatuses] = useState(["all", "Active", "Inactive"]);
	const [selectedVendors, setSelectedVendors] = useState([]);
	const [selectAll, setSelectAll] = useState(false);
	const [indeterminate, setIndeterminate] = useState(false);
	const [anyChecked, setAnyChecked] = useState(false);
	const [modalData, setModalData] = useState(null);
	const [activeTooltip, setActiveTooltip] = useState(null);
	const [tooltipTimer, setTooltipTimer] = useState(null);
	const [tooltipPosition, setTooltipPosition] = useState({ left: 0, top: 0 });
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isTooltipHovered, setIsTooltipHovered] = useState(false);
	const [isTooltipActive, setIsTooltipActive] = useState(false);

	const navigate = useNavigate();

	const formatPhoneNumber = (value) => {
		if (!value) return "";
		const phoneNumber = value.replace(/\D/g, "");
		const match = phoneNumber.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
		if (match) {
			return `(${match[1]}) ${match[2]}-${match[3]}`;
		}
		return value;
	};

	useEffect(() => {
		const fetchVendors = async () => {
			try {
				const vendors = await getAllVendor();
				setData(vendors);
			} catch (err) {
				console.error("Error fetching vendor data:", err);
				setError("Failed to load vendor data.");
			} finally {
				setLoading(false);
			}
		};
		fetchVendors();
	}, []);

	const handleRowClick = (id) => {
		navigate(`/admin/assets/vendors/add-vendors/${id}/edit`);
	};

	const handleDelete = async (id) => {
		const loadingToast = toast.loading("Deleting...");
		try {
			await deleteVendor(id);
			toast.update(loadingToast, {
				render: "Vendor deleted successfully!",
				type: "success",
				isLoading: false,
				autoClose: 3000,
			});
			const vendors = await getAllVendor();
			setData(vendors);
		} catch (error) {
			toast.update(loadingToast, {
				render: "Error deleting vendor!",
				type: "error",
				isLoading: false,
				autoClose: 3000,
			});
			console.error("Error deleting vendor: ", error);
		}
	};
	const statusFilterChangeHandler = (selected) => {
		if (selected.includes(["all", "ACTIVE", "INACTIVE"])) {
			setSelectedStatuses(["all", "ACTIVE", "INACTIVE"]);
		} else {
			setSelectedStatuses([...selected]);
		}
	};

	useEffect(() => {
		console.log({ selectedStatuses });
	}, [selectedStatuses]);

	const filteredData = useMemo(() => {
		return data.filter((item) => {
			if (selectedStatuses.includes("all") || selectedStatuses.includes("ACTIVE") || selectedStatuses.includes("INACTIVE")) {
				return true;
			}
			return selectedStatuses.includes(item.vendorStatus);
		});
	}, [data, selectedStatuses]);

	const handleCheckboxChange = (index) => {
		const newSelected = selectedVendors.includes(index)
			? selectedVendors.filter((i) => i !== index)
			: [...selectedVendors, index];

		setSelectedVendors(newSelected);
		setSelectAll(newSelected.length === filteredData.length);
		setIndeterminate(newSelected.length > 0 && newSelected.length < filteredData.length);
		setAnyChecked(newSelected.length > 0);
	};

	const handleSelectAllChange = () => {
		if (selectAll) {
			setSelectedVendors([]);
			setAnyChecked(false);
		} else {
			setSelectedVendors(filteredData.map((_, index) => index));
			setAnyChecked(true);
		}
		setSelectAll(!selectAll);
		setIndeterminate(false);
	};

	const clickHandler = (action) => {
		if (action === "Delete") {
			selectedVendors.forEach((index) => {
				handleDelete(filteredData[index].id);
			});
			setSelectedVendors([]);
			setAnyChecked(false);
		} else if (action === "Edit") {
			selectedVendors.forEach((index) => {
				handleRowClick(filteredData[index].id);
			});
		}
	};

	const handleMouseEnter = (item, event) => {
		clearTimeout(tooltipTimer);
		const timer = setTimeout(() => {
			setActiveTooltip(item.id);
			setModalData(item);
			updateTooltipPosition(event);
			setIsTooltipActive(true);
		}, 3000);
		setTooltipTimer(timer);
	};

	const handleMouseLeave = () => {
		clearTimeout(tooltipTimer);
		if (!isTooltipHovered) {
			setActiveTooltip(null);
			setIsTooltipActive(false);
		}
	};

	const updateTooltipPosition = (event) => {
		const viewportWidth = window.innerWidth;
		const cursorX = event.clientX;
		const cursorY = event.clientY;
		const tooltipWidth = 500;
		const tooltipHeight = 500;
		const offset = 10;
		let left, top;

		if (cursorX > viewportWidth / 2) {
			left = cursorX - tooltipWidth - offset;
		} else {
			left = cursorX + offset;
		}

		top = cursorY - offset;

		left = Math.max(0, Math.min(left, viewportWidth - tooltipWidth));
		top = Math.max(0, Math.min(top, window.innerHeight - tooltipHeight));

		setTooltipPosition({ left, top });
	};

	const openAddVendorModal = () => {
		setIsModalOpen(true);
	};

	if (error) {
		return <div>{error}</div>;
	}

	return (
		<div className="p-6">
			<div className="main-container flex flex-col border border-cardBorderCol bg-white rounded-cardRadii h-full">
				<div className="flex justify-between items-center mx-8">
					<div className="text-lg font-medium text-black">Vendors List</div>
					<div className="my-4 px-2">
						<MultiselectDropdown
							buttonText="Vendor Status"
							options={[{ label: "All", value: "all" }, { label: "Active", value: "Active" }, { label: "Inactive", value: "Inactive" }]}
							selectedOptions={selectedStatuses}
							onSelectedOptionsChange={statusFilterChangeHandler}
						/>
					</div>
				</div>
				<div className="overflow-scroll">
					<div className="flex font-medium py-4 min-w-fit bg-whiteBlue">
						<div className="w-8 mx-6 flex justify-center items-center hover:cursor-pointer">
							<input
								type="checkbox"
								className="w-4 h-4 bg-white"
								checked={selectAll}
								ref={(input) => input && (input.indeterminate = indeterminate)}
								onChange={handleSelectAllChange}
							/>
						</div>
						<div className="flex items-center">
							<div className="truncate min-w-60">Name</div>
							<div className="truncate min-w-60">Address</div>
							<div className="truncate min-w-60">Email</div>
							<div className="truncate min-w-48">Phone</div>
							<div className="truncate min-w-48">Vendor Type</div>
							<div className="truncate min-w-48">Status</div>
						</div>
					</div>

					<div className={`second-child min-w-full`}>
						{loading ? (
							Array.from({ length: 9 }).map((_, index) => (
								<div
									key={index}
									className="flex flex-col md:flex-row items-center border-b border-gray-300 animate-pulse"
									style={{ minHeight: "60px" }}
								>
									<div className="w-20"></div>
									<div className="min-w-60 text-sm font-normal">
										<div className="h-4 bg-gray-300 rounded w-3/4"></div>
									</div>
									<div className="min-w-60 text-sm font-normal">
										<div className="h-4 bg-gray-300 rounded w-3/4"></div>
									</div>
									<div className="min-w-60 text-sm font-normal">
										<div className="h-4 bg-gray-300 rounded w-3/4"></div>
									</div>
									<div className="min-w-48 text-sm font-normal">
										<div className="h-4 bg-gray-300 rounded w-3/4"></div>
									</div>
									<div className="min-w-48 text-sm font-normal">
										<div className="h-4 bg-gray-300 rounded w-3/4"></div>
									</div>
									<div className="min-w-48 text-sm font-normal">
										<div className="h-4 bg-gray-300 rounded w-3/4"></div>
									</div>
								</div>
							))
						) : filteredData.length > 0 ? (
							filteredData.map((item, index) => (
								<div
									id={item.id}
									key={item.id}
									className={`flex flex-col min-w-fit md:flex-row items-start py-1 ${index < filteredData.length - 1 ? "border-b border-gray-300" : ""
										} hover:bg-gray-200`}
									onMouseEnter={(e) => handleMouseEnter(item, e)}
									onMouseLeave={handleMouseLeave}
								>
									<div className="mx-8 py-4 flex justify-center items-center">
										<input
											type="checkbox"
											className="w-4 h-4 bg-white"
											checked={selectedVendors.includes(index)}
											onChange={() => handleCheckboxChange(index)}
										/>
									</div>
									<div className="truncate min-w-60 h-full cursor-pointer py-2" onClick={() => handleRowClick(item.id)}>
										{item.name || "Name not provided"}
									</div>
									<div className="truncate min-w-60 h-full cursor-pointer py-2" onClick={() => handleRowClick(item.id)}>
										{item.street || "Address not provided"}
									</div>
									<div className="truncate min-w-60 h-full cursor-pointer py-2" onClick={() => handleRowClick(item.id)}>
										{item.email || " Email not provided"}
									</div>
									<div
										className="truncate min-w-48 h-full cursor-pointer py-2 hover:text-blueText hover:underline"
										onClick={() => handleRowClick(item.id)}
									>
										{formatPhoneNumber(item.phoneNumber) || "Phone not provided"}
									</div>
									<div className="truncate min-w-48 h-full py-2 cursor-pointer" onClick={() => handleRowClick(item.id)}>
										{item.vendorType || "--"}
									</div>
									<div className="truncate min-w-48 h-full py-2 cursor-pointer" onClick={() => handleRowClick(item.id)}>
										{item.vendorStatus || "Active"}
									</div>
								</div>
							))
						) : (
							<div className="w-full text-center py-4 text-cardTextGray">No vendors found</div>
						)}
					</div>
				</div>

				<div
					className={`fixed bottom-0 left-0 right-0 ml-72 shadow-md px-8 transition-all duration-300 ease-in-out ${selectedVendors.length > 0 ? "translate-y-0" : "translate-y-full"
						}`}
				>
					<div className="w-full py-4 flex justify-between items-center">
						<span className="truncate">{selectedVendors.length} item(s) selected</span>
						<div className="space-x-2">
							{["Edit", "Save as PDF", "Share", "Delete"].map((actionBtn) => (
								<button
									key={actionBtn}
									className={`px-4 py-2 rounded-lg ${actionBtn === "Delete"
										? "bg-lightBlue text-white hover:bg-cardTextBlue"
										: "bg-creamWhite hover:bg-cardTextGray hover:bg-opacity-10"
										} border transition-colors duration-200 ease-in-out`}
									onClick={() => clickHandler(actionBtn)}
								>
									{actionBtn}
								</button>
							))}
						</div>
					</div>
				</div>

				{!anyChecked && (
					<div className="fixed bottom-8 right-8">
						<button
							onClick={openAddVendorModal}
							className="bg-lightBlue hover:bg-cardTextBlue text-white rounded-full p-4 shadow-lg transition-all duration-300 flex items-center space-x-2"
						>
							<svg
								className="w-6 h-6"
								fill="none"
								stroke="currentColor"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
							</svg>
							<span>Add New Vendor</span>
						</button>
					</div>
				)}
				{isModalOpen && <MiniVendorRegModal onClose={() => setIsModalOpen(false)} activeSubmenu="vendor" />}
			</div>

			<AnimatePresence>
				{activeTooltip && modalData && isTooltipActive && (
					<motion.div
						className="fixed bg-white rounded-cardRadii min-w-[500px] shadow-2xl border border-cardTextGray border-opacity-10 p-6 z-10 overflow-hidden"
						style={{
							left: `${tooltipPosition.left}px`,
							top: `${tooltipPosition.top}px`,
						}}
						initial={{ opacity: 0, scale: 0.8 }}
						animate={{ opacity: 1, scale: 1 }}
						exit={{ opacity: 0, scale: 0.8 }}
						transition={{ duration: 0.2 }}
						onMouseEnter={() => setIsTooltipHovered(true)}
						onMouseLeave={() => setIsTooltipHovered(false)}
					>
						<ToolTipContent vendor={modalData} />
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};

const ToolTipContent = ({ vendor }) => {
	const details = [
		{ label: "Name", value: vendor.name || "--" },
		{ label: "Phone", value: vendor.phoneNumber || "--" },
		{ label: "Email", value: vendor.email || "--" },
		// { label: "Apartment", value: vendor.apartment || "--" },
		{ label: "State", value: vendor.state || "--" },
		{ label: "Address", value: vendor.street || "--" },
		// { label: "ZipCode", value: vendor.zipCode || "--" },
		{ label: "Type", value: vendor.vendorType?.join(", ") || "--" },
		{ label: "Vendor Status", value: vendor.vendorStatus || "--" },
	];

	return (
		<div className="w-full">
			{details.map((item) => (
				<div key={item.label} className={`w-full flex`}>
					<div className="w-1/2 p-2 text-cardTextGray truncate">{item.label}</div>
					<div className="w-1/2 p-2 truncate">{item.value}</div>
				</div>
			))}
		</div>
	);
};

export default VendorFacilities;
