export const statusOptions = [
	{ label: "Active", value: "ACTIVE" },
	{ label: "Inactive", value: "INACTIVE" },
];
export const DEFAULT_ROUTE_VALUES = {
	routeLabel: "",
	type: [],
	defaultStartLocation: {
		name: "",
	},
	defaultEndLocation: {
		name: "",
	},
	truckCostPerMile: "",
	isActive: true,
	totalDistanceInMeters: 0,
	totalTimeInSeconds: 0,
	status:"ACTIVE"
};
