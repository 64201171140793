import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const Assets = () => {
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		if (location.pathname == "/admin/assets") navigate("/admin/assets/drivers");
		else navigate(location.pathname);
	}, [location.pathname]);

	return (
		<div>
			<Outlet />
		</div>
	);
};

export default Assets;
