import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Dashboard from "./dashboard/Dashboard";
import AdminSidebar from "../../components/sidebars/admin-sidebar/AdminSidebar";
import DriverManagement from "./assets/components/drivers/DriverManagement";
import GeneratorManagement from "./generator-management/GeneratorManagement";
import Vehicles from "./vehicles/Vehicles";
import Routes from "./assets/components/routes/index";
import Audit from "./audit/Audit";
import Settings from "./settings/Settings";
import Assets from "./assets/Assets";
import { BreadcrumbProvider } from "../../context/BreadcrumbContext";
import Header from "../../components/header/Header";
import ManageWastes from "./wastes/ManageWastes";
import Subcontractor from "./octo-connect/Subcontractor";

const GradientDefinition = () => (
	<svg width="0" height="0" style={{ position: "absolute" }}>
		<defs>
			<linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
				<stop offset="0%" stopColor="#007AFF" />
				<stop offset="100%" stopColor="#4CD964" />
			</linearGradient>
		</defs>
	</svg>
);

const AdminLayout = () => {
	const [active, setActive] = useState(null);
	const scrollContainerRef = useRef(null);
	const location = useLocation();

	useEffect(() => {
		if (scrollContainerRef.current) {
			scrollContainerRef.current.scrollTo({
				top: 0,
				behavior: "smooth",
			});
		}
	}, [location.pathname]);

	useEffect(() => {
		if (location.pathname.endsWith("/admin")) setActive("dashboard");
		if (location.pathname.includes("/admin/generators")) setActive("generator");
		if (location.pathname.includes("/admin/assets")) setActive("asset");
		if (location.pathname.includes("/admin/wastes")) setActive("waste");
		if (location.pathname.includes("/admin/users")) setActive("user");
		if (location.pathname.includes("/admin/reports")) setActive("report");
		if (location.pathname.includes("/admin/billings")) setActive("billing");
		if (location.pathname.includes("/admin/routes")) setActive("route");
		if (location.pathname.includes("/admin/settings")) setActive("setting");
		if (location.pathname.includes("/admin/audit")) setActive("audit");
		if (location.pathname.includes("/admin/vendor")) setActive("vendor");
		if (location.pathname.includes("/admin/octo-connect")) setActive("octo-connect");
	}, [location]);

	return (
		<BreadcrumbProvider>
			<div className="">
				<GradientDefinition />
				<AdminSidebar active={active} />
				<div
					className={`${
						location.pathname == "/admin" ? "overflow-scroll" : ""
					} flex-1 h-screen ml-0 md:ml-72 bg-primaryGray`}
				>
					<Header />
					<div
						className={`${location.pathname == "/admin" ? "" : "h-[calc(100%-80px)] overflow-scroll"}`}
						ref={scrollContainerRef}
					>
						{active == "dashboard" && <Dashboard />}
						{active == "generator" && <GeneratorManagement />}
						{active == "asset" && <Assets />}
						{active == "route" && <Routes />}
						{active == "setting" && <Settings />}
						{active == "audit" && <Audit />}
						{active == "waste" && <ManageWastes />}
						{active == "octo-connect" && <Subcontractor />}
					</div>
				</div>
			</div>
		</BreadcrumbProvider>
	);
};

export default AdminLayout;
