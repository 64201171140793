import React, { useEffect, useState } from "react";
import AuthForm from "./components/AuthForm";
import GoogleAuthButton from "./components/GoogleAuthButton";
import AuthSwitch from "./components/AuthSwitch";
import { emailRegex } from "../../utils/mimes.js";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { createNewUserWithEmailAndPassword, createUserDocFromAuth, signInUserWithEmailAndPassword, signInWithGooglePopup, auth } from "../../config/firebase";
import { toast } from "react-toastify";

const Auth = () => {
	const [isLogin, setIsLogin] = useState(true);
	const [formData, setFormData] = useState({
		email: "",
		password: "",
		confirmPassword: "",
	});
	const navigate = useNavigate();
	const [user, loading, error] = useAuthState(auth);

	const toggleAuthMode = () => {
		setIsLogin(!isLogin);
	};

	useEffect(() => {
		if (!loading && user) {
			navigate("/admin");
		}

		if (error) {
			console.error(error);
		}
	}, [user, loading, auth]);

	const handleGoogleAuth = async () => {
		try {
			toast.loading("Please wait while we are authenticating..");
			await signInWithGooglePopup();
			toast.dismiss();
		} catch (error) {
			toast.dismiss();
			console.log("Error", error);
			if (error.code === "auth/popup-closed-by-user") {
				toast.error("User closed the pop-up without signing in.");
			} else {
				toast.error("Something went wrong while logging in with google");
			}
		}
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (isLogin) {
			if (!emailRegex.test(formData.email)) {
				toast.error("Invalid email");
			} else if (!formData.password?.trim()) {
				toast.error("Invalid password");
			} else {
				try {
					toast.loading("Logging in...");
					await signInUserWithEmailAndPassword(formData.email, formData.password);
					toast.dismiss();
				} catch (error) {
					toast.dismiss();
					if (error.code == "auth/invalid-credential") {
						toast.error("Invalid credentials");
					} else {
						toast.error("Error during logging in");
					}
				}
			}
		} else {
			if (!emailRegex.test(formData.email)) {
				toast.error("Invalid email");
			} else if (!formData.password?.trim()) {
				toast.error("Invalid password");
			} else if (!formData.confirmPassword?.trim()) {
				toast.error("Invalid confirm password");
			} else if (formData.password?.trim() !== formData.confirmPassword?.trim()) {
				toast.error("Passwords don't match");
			} else {
				try {
					toast.loading("Signing Up..");
					await createNewUserWithEmailAndPassword(formData.email, formData.password);
					toast.dismiss();
				} catch (error) {
					toast.dismiss();
					console.log(error);
					if (error.code == "auth/email-already-in-use") {
						toast.error("Email already in use");
					} else {
						toast.error("Error during signing up");
					}
				}
			}
		}
	};

	return (
		<div className="h-screen w-full bg-creamWhite flex items-center">
			<div className="mx-auto bg-white p-6 min-w-96 shadow-lg rounded-lg">
				<h2 className="text-2xl font-bold mb-4 text-center">{isLogin ? "Login" : "Sign Up"}</h2>

				<AuthForm isLogin={isLogin} handleInputChange={handleInputChange} formData={formData} handleSubmit={handleSubmit} />
				<GoogleAuthButton isLogin={isLogin} handleGoogleAuth={handleGoogleAuth} />
				<AuthSwitch isLogin={isLogin} toggleAuthMode={toggleAuthMode} />
			</div>
		</div>
	);
};

export default Auth;
