import React from "react";

const AuthSwitch = ({ isLogin, toggleAuthMode }) => {
	return (
		<p className="mt-4 text-center text-gray-600">
			{isLogin ? "Don't have an account?" : "Already have an account?"}
			<button onClick={toggleAuthMode} className="text-blueText hover:underline ml-2">
				{isLogin ? "Sign Up" : "Login"}
			</button>
		</p>
	);
};

export default AuthSwitch;
