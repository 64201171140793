/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useState, useEffect, useRef } from "react";

const ServiceDropdown = ({ buttonText, options }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [selectedOption, setSelectedOption] = useState(null);

	const dropdownRef = useRef(null);

	const handleToggle = () => {
		setIsOpen((prev) => !prev);
	};

	const handleSelect = (option) => {
		setSelectedOption(option);
		setIsOpen(false); // Close dropdown on selection
	};

	const handleClickOutside = (event) => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
			setIsOpen(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => document.removeEventListener("mousedown", handleClickOutside);
	}, []);

	return (
		<div className="relative inline-block text-left" ref={dropdownRef}>
			<button type="button" onClick={handleToggle} className="bg-gray-100 text-[#666666] pl-5 pr-3 py-1 rounded-full" aria-expanded={isOpen} aria-haspopup="true">
				{selectedOption || buttonText}
				<svg className="ml-8 w-4 h-4 inline-block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
					<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
				</svg>
			</button>
			{isOpen && (
				<div className="absolute top-full left-0 w-full bg-white rounded-b-lg shadow-lg z-10 mt-1">
					{options.map((option, index) => (
						<div key={index}>
							<button
								onClick={() => handleSelect(option)}
								className={`flex items-center w-full pl-3 py-2 text-gray-700 hover:bg-gray-100 text-left focus:outline-none ${selectedOption === option ? "bg-gray-200" : ""}`}
								aria-checked={selectedOption === option}
								role="menuitem"
							>
								<span className="ml-2">{option}</span>
							</button>
							<hr className="border-gray-300" />
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export default ServiceDropdown;
