import { createContext, useContext, useEffect, useState } from "react";
import { useMapsLibrary } from "@vis.gl/react-google-maps";

const MapServicesContext = createContext(null);

export const useMapServices = () => useContext(MapServicesContext);

export const MapServicesProvider = ({ children }) => {
	const [services, setServices] = useState(null);
	const routesLib = useMapsLibrary("routes");

	useEffect(() => {
		if (!routesLib) return;
		console.log("called service ");

		const directionsService = new routesLib.DirectionsService();
		const directionsRenderer = new routesLib.DirectionsRenderer({
			suppressMarkers: true,
			polylineOptions: {
				strokeColor: "#1493D7",
				strokeWeight: 4,
				strokeOpacity: 0.7,
			},
		});

		setServices({
			directionsService,
			directionsRenderer,
			TravelMode: routesLib.TravelMode,
		});
	}, [routesLib]);

	return <MapServicesContext.Provider value={services}>{children}</MapServicesContext.Provider>;
};
