import PropTypes from "prop-types";
import React, { useState, useRef, useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
	// some basic styles to make the items look a bit nicer
	userSelect: "none",
	padding: "1rem",
	margin: `1rem`,

	// change background colour if dragging
	background: isDragging ? "lightgreen" : "white",

	// styles we need to apply on draggables
	...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
	background: isDraggingOver ? "lightblue" : "lightgrey",
	padding: "0.5rem 0",
	width: "auto",
	height: "100%",
});

const DraggableList = ({ items, onReorder }) => {
	const [state, setState] = useState({ items });
	const onDragEnd = (result) => {
		// dropped outside the list
		if (!result.destination) {
			return;
		}

		const reorderedItems = reorder(state.items, result.source.index, result.destination.index);

		setState({ items: reorderedItems });
		onReorder(reorderedItems);
	};

	return (
		<div className="h-full">
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId="droppable">
					{(provided, snapshot) => (
						<div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)} className="rounded-xl">
							{state.items.map((item, index) => (
								<Draggable key={item.id} draggableId={item.id} index={index}>
									{(provided, snapshot) => (
										<div
											ref={provided.innerRef}
											{...provided.draggableProps}
											{...provided.dragHandleProps}
											style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
											className="rounded-xl text-gray-500 text-sm"
										>
											<div>
												<p>
													[{index + 1}] {item.content.name}
												</p>
												<p>Octo Number : {item.content.octoNumber}</p>
											</div>
										</div>
									)}
								</Draggable>
							))}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
		</div>
	);
};

DraggableList.propTypes = {
	items: PropTypes.array.isRequired,
	onReorder: PropTypes.func.isRequired,
};

export default DraggableList;
